import React, { FC } from "react"
import { BorderProps, Box, Flex, FlexProps, keyframes } from "@chakra-ui/react"
import { easeInOut, useTransform } from "framer-motion"
import { ProgressBar, VFlex } from "~/components"
import { useProgress, useStateFromMV } from "~/shared/useProgress"
import { getScaleValue } from "~/utils/getScaleValue"
import arrowsSrc from "./arrows.svg?url"

const PhotoItem: FC<FlexProps> = (props) => (
  <Flex
    flexGrow={1}
    borderRightRadius="brand24"
    borderLeftRadius="brand24"
    bgSize="cover"
    minH="200px"
    alignItems="flex-end"
    justifyContent="center"
    padding={2}
    sx={{ aspectRatio: "0.75" }}
    {...props}
  />
)

type ProgressNumberProps = {
  color: string
  bgColor?: string
  value: number
  title: string
  subTitle: string
} & BorderProps
const ProgressNumber: FC<ProgressNumberProps> = ({
  color,
  bgColor = "Base/neutralDisabled",
  value,
  title,
  subTitle,
  ...props
}) => (
  <VFlex
    gap={0.5}
    justifyContent="stretch"
    w="full"
    paddingX={6}
    paddingY={3}
    borderRightRadius="brand24"
    borderLeftRadius="brand24"
    bgColor="Base/neutralPrimary"
    shadow="LoviShort"
    {...props}
  >
    <Box textStyle="Header/Tertiary" mb="1" w="full">
      {title}
    </Box>
    <ProgressBar bgColor={bgColor} h={1.5} color={color} value={value} />
    <Box textStyle="Paragraph/Secondary" color="Base/baseSecondary" w="full">
      {subTitle}
    </Box>
  </VFlex>
)

const ProgressNumberAnimated: FC<ProgressNumberProps> = ({ value, ...props }) => {
  const { motionValue } = useProgress({ duration: 0.5 })
  const transformed = useTransform(motionValue, (v) => v * easeInOut(v) * value)
  const progress = useStateFromMV(transformed)

  return <ProgressNumber value={progress} {...props} />
}

const defaultOriginalPhoto = "https://storage.pora.ai/6e4d34d235ebe6cec99b7965b3cef9f5.webp"
const defaultEnhancedPhoto = "https://storage.pora.ai/d1b44adf0113ad30269d6648ab74e4c6.webp"

const moveKeyframes = keyframes`
0% { transform: translateX(-100%); }
60% { transform: translateX(100%); }
100% { transform: translateX(100%); }
`
const moveAnimation = `${moveKeyframes} 2.2s ease-in-out infinite`

export const EnhancedPhoto2: FC<{
  type: "user" | "default"
  originalPhoto: string | undefined
  enhancedPhoto: string | undefined
}> = ({ type, originalPhoto, enhancedPhoto }) => (
  <VFlex gap={0.5} w="full">
    <Flex gap={0.5} w="full">
      <ProgressNumberAnimated
        value={getScaleValue("HYDRATION", false)}
        color="Other/Warning"
        title="Now"
        subTitle="Skin Hydration"
        borderRightRadius="brand4"
      />
      <ProgressNumberAnimated
        value={getScaleValue("HYDRATION", true)}
        color="Other/Green"
        title="Your Goal"
        subTitle="Skin Hydration"
        borderLeftRadius="brand4"
      />
    </Flex>
    <Box
      display="grid"
      placeItems="center"
      gridTemplateAreas="'stack'"
      overflow="hidden"
      sx={{ aspectRatio: 1.5 }}
    >
      <Flex gap={0.5} w="full" gridArea="stack">
        <PhotoItem
          borderRightRadius="brand4"
          bgImage={type === "user" ? originalPhoto : defaultOriginalPhoto}
          justifyContent="flex-start"
        />
        <PhotoItem
          borderLeftRadius="brand4"
          bgImage={type === "user" ? enhancedPhoto : defaultEnhancedPhoto}
          justifyContent="flex-end"
        />
      </Flex>
      <Box
        gridArea="stack"
        w="full"
        h="full"
        bgImage={arrowsSrc}
        bgPosition="center"
        mixBlendMode="soft-light"
        filter="blur(20px)"
        animation={moveAnimation}
      />
    </Box>
  </VFlex>
)
