import { useCallback } from "react"
import { useDispatch } from "~/utils/redux"
import { moveDomElementsContent } from "~/utils"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { usePaymentFormPopupIsOpen } from "~/store/selectors"
import { paymentFormPopupSetIsOpen } from "~/store/actions"

import { useUpsaleSubscriptionOnExit } from "../UpsaleSubscriptionOnExitBlock"
import { useLogInitiateCheckout } from "./useLogInitiateCheckout"

export const usePaymentFormPopupDisclosure = () => {
  const log = useAmplitude()
  const logInitiateCheckout = useLogInitiateCheckout()
  const isOpen = usePaymentFormPopupIsOpen()
  const { open: startUpsaleOnExitFlow } = useUpsaleSubscriptionOnExit()
  const dispatch = useDispatch()

  const close = useCallback(() => {
    const src = document.getElementById("applepay-modal-placeholder")
    const dst = document.getElementById("applepay-page-placeholder")
    moveDomElementsContent(src, dst)
    log.paymentFormPopupClose()
    dispatch(paymentFormPopupSetIsOpen(false))
    startUpsaleOnExitFlow()
  }, [paymentFormPopupSetIsOpen, startUpsaleOnExitFlow])

  const open = useCallback(() => {
    const src = document.getElementById("applepay-page-placeholder")
    const dst = document.getElementById("applepay-modal-placeholder")
    moveDomElementsContent(src, dst)
    log.paymentFormPopupOpen()
    logInitiateCheckout()
    dispatch(paymentFormPopupSetIsOpen(true))
  }, [paymentFormPopupSetIsOpen, logInitiateCheckout])

  return { isOpen, open, close }
}
