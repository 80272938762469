import React, { FC, ReactNode, useState } from "react"
import { Box, Button, Collapse, Divider, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { StarIcon } from "./Icons"
import { ThemeContentSwitch, usePoraReplacer } from "~/theme/theme-from-interview/themeProvider"

export type Review = {
  author: string
  usageLength: number
  img: string
  date: string
  title: string
  text: string
}

const Stars: FC = () => (
  <Flex gap={0.5}>
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
  </Flex>
)

const Reviews5Item: FC<Review> = ({ img, usageLength, author, text, title, date }) => {
  const replacer = usePoraReplacer()
  return (
    <Flex
      borderWidth={1}
      borderColor="Base/neutralSecondary"
      flexDirection="column"
      p={4}
      borderRadius="3xl"
      bgColor="Base/neutralPrimary"
    >
      <Flex w="full" gap={2} alignItems="center">
        <Image flexShrink={0} w="40px" h="40px" borderRadius="full" src={img} alt="author" />
        <Box>
          <Box textStyle="Subtitle/Tertiary" textColor="Base/basePrimary" mb={0.5}>
            {author}
          </Box>
          <Box textStyle="Paragraph/Tertiary" textColor="Base/baseSecondary">
            Used <ThemeContentSwitch pora="Pora" lovi="Lovi" /> for {usageLength} months
          </Box>
        </Box>
      </Flex>
      <Divider borderColor="Base/neutralSecondary" my={3} />
      <Flex w="full" justifyContent="space-between" alignItems="center" mb={3}>
        <Stars />
        <Box textStyle="Paragraph/Tertiary" textColor="Base/baseSecondary">
          {date}
        </Box>
      </Flex>
      <Heading
        mb={1}
        as="h5"
        size="Subtitle/Secondary"
        textStyle="Subtitle/Secondary"
        variant="text"
      >
        {replacer(title)}
      </Heading>
      <Text textStyle="Paragraph/Secondary">{replacer(text)}</Text>
    </Flex>
  )
}

export const Reviews5: FC<{ reviews: Review[] }> = ({ reviews }) => {
  const [showAllReviews, setShowAllReviews] = useState(false)

  if (reviews.length <= 2) {
    return <div>reviews are loading...</div>
  }

  return (
    <Flex flexDirection="column" overflow="hidden">
      {reviews[0] && (
        <Box mb={3}>
          <Reviews5Item {...reviews[0]} />
        </Box>
      )}
      {!showAllReviews && (
        <Box
          textStyle="Subtitle/Secondary"
          cursor="pointer"
          color="Base/accentPrimary"
          w="full"
          textAlign="center"
          onClick={() => setShowAllReviews(true)}
        >
          View More
        </Box>
      )}
      <Collapse in={showAllReviews}>
        <Flex flexDirection="column" gap={3}>
          {reviews.slice(1).map((review, i) => (
            <Box key={i}>
              <Reviews5Item {...review} />
            </Box>
          ))}
        </Flex>
      </Collapse>
    </Flex>
  )
}
