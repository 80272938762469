import React, { FC, useMemo } from "react"
import { AspectRatio, Box, Flex, Heading, Image } from "@chakra-ui/react"
import { VFlex } from "~/components"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { HeaderLogo } from "./HeaderLogo"
import { useBlockProperties } from "~/store/selectors"
import { formatDate } from "../../../../../../quiz-app/src/utils/formatDate"
import { getProgramEndDate } from "~/utils"

const defaultProps = {
  upTitle: "Get your",
  image: "https://storage.pora.ai/9d49248295cd57b38ad188a6f8549d31.webp",
} as const

const Header4: FC<{ upTitle?: string; title: string; image?: string }> = ({
  upTitle = defaultProps.upTitle,
  title,
  image = defaultProps.image,
}) => (
  <Flex w="full">
    <VFlex paddingBottom={6} paddingX={6} alignItems="center" w="full" gap={6}>
      <Box textAlign="center" py={4}>
        <HeaderLogo />
      </Box>
      <Box textAlign="center">
        <Box textStyle="Subtitle/Secondary" mb={2}>
          {upTitle}
        </Box>
        <Heading whiteSpace="pre-wrap" as="h1" size="Header/Primary">
          {title}
        </Heading>
      </Box>
      <Box w="full">
        <AspectRatio ratio={109 / 72} w="100%">
          <Image src={image}></Image>
        </AspectRatio>
        <Box mt={2} textStyle="Paragraph/Tertiary" color="Base/baseDisabled">
          This chart is for illustrative purposes only
        </Box>
      </Box>
    </VFlex>
  </Flex>
)

const PROGRAM_LENGTH_WEEKS = 5
export const Header4Container: FC = () => {
  const blockProperties = useBlockProperties()

  const resultsDate = useMemo(
    () => formatDate("MONTH_DAY")(getProgramEndDate(PROGRAM_LENGTH_WEEKS)),
    []
  )

  const title = "Visible results by \n" + resultsDate

  return (
    <Header4
      upTitle={blockProperties["PB_HEADER_4.upTitle"]}
      title={title}
      image={blockProperties["PB_HEADER_4.image"]}
    />
  )
}

export const Header4Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper
    padding={0}
    borderTopRadius={0}
    borderBottomRadius="24px"
    _first={{
      borderBottomRadius: "24px",
    }}
    _last={{
      borderBottomRadius: "24px",
    }}
    {...props}
  >
    <Header4Container />
  </BlockWrapper>
)
