import { createIcon } from "@chakra-ui/react"
import React from "react"

export const StarIcon = createIcon({
  displayName: "FireIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M7.05544 2.71739C7.44812 1.5877 7.64445 1.02286 8 1.02286C8.35555 1.02286 8.55188 1.5877 8.94456 2.71739L9.65244 4.75386C9.76293 5.07172 9.81817 5.23065 9.94507 5.32285C10.072 5.41504 10.2402 5.41847 10.5766 5.42532L12.7322 5.46925C13.9279 5.49362 14.5258 5.5058 14.6357 5.84394C14.7455 6.18209 14.269 6.54336 13.3159 7.26592L11.5979 8.56845C11.3297 8.77176 11.1957 8.87341 11.1472 9.02258C11.0987 9.17175 11.1474 9.3328 11.2449 9.6549L11.8692 11.7185C12.2155 12.8633 12.3887 13.4356 12.1011 13.6446C11.8134 13.8536 11.3226 13.512 10.3409 12.8289L8.57118 11.5975C8.29496 11.4052 8.15685 11.3091 8 11.3091C7.84315 11.3091 7.70504 11.4052 7.42882 11.5975L5.65913 12.8289C4.67743 13.512 4.18658 13.8536 3.89894 13.6446C3.6113 13.4356 3.78446 12.8633 4.13079 11.7185L4.75511 9.6549C4.85256 9.3328 4.90129 9.17175 4.85282 9.02258C4.80435 8.87341 4.67027 8.77176 4.40211 8.56845L2.68405 7.26592C1.731 6.54336 1.25447 6.18209 1.36434 5.84394C1.47421 5.5058 2.07208 5.49362 3.26782 5.46925L5.42337 5.42532C5.75982 5.41847 5.92804 5.41504 6.05493 5.32285C6.18183 5.23065 6.23707 5.07172 6.34756 4.75386L7.05544 2.71739Z"
      fill="#FF7F00"
    />
  ),
})
