const TEST_QUERY_PREFIX = "test_"
const UTM_QUERY_PREFIX = "utm_"
/* TODO memoize */
export function getTestQueryParams() {
  const queryParamsEntries = new URLSearchParams(window.location.search).entries()
  const testQueryParamsEntries = Array.from(queryParamsEntries).filter(
    ([queryParamName]) =>
      queryParamName.startsWith(TEST_QUERY_PREFIX) || queryParamName.startsWith(UTM_QUERY_PREFIX)
  )

  return Object.fromEntries(testQueryParamsEntries)
}
