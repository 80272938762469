import React, { FC } from "react"
import { Box, Flex, FlexProps } from "@chakra-ui/react"

const LevelItem: FC<{ color: string; opacity: number }> = ({ color, opacity }) => (
  <Box borderRadius="full" minHeight={1} height={1.5} flex="1" bgColor={color} opacity={opacity} />
)

const mapVariantColors = {
  low: { color: "Other/Error", opacity: [1, 1, 0.2] },
  moderate: { color: "Other/Warning", opacity: [1, 1, 0.2] },
  good: { color: "Other/Green", opacity: [1, 1, 0.2] },
  good2: { color: "Other/Green", opacity: [1, 1, 1] },
  excellent: { color: "Other/GreenDark", opacity: [1, 1, 1] },
} as const

export type LevelVariant = keyof typeof mapVariantColors

export const Level: FC<{ variant: LevelVariant; width: FlexProps["width"] }> = ({
  variant,
  width = "full",
}) => {
  const { color, opacity } = mapVariantColors[variant]

  return (
    <Flex direction="row" gap={1} width={width}>
      {opacity.map((opacity, i) => (
        <LevelItem key={i} color={color} opacity={opacity} />
      ))}
    </Flex>
  )
}
