import React, { FC } from "react"
import { FlexProps, Flex } from "@chakra-ui/react"
import { ReactComponent as ClockIcon } from "./assets/clockIcon.min.svg"

export const TimerNotice1: FC<FlexProps> = ({ children, ...props }) => (
  <Flex
    borderRadius="full"
    w="full"
    paddingX={4}
    paddingY={3}
    textAlign="center"
    textStyle="Subtitle/Secondary"
    color="Base/neutralPrimary"
    bgColor="Other/Error"
    gap={1}
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    <ClockIcon />
    {children}
  </Flex>
)
