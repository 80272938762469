import React, { FC, ReactNode } from "react"
import { Box, Flex, FlexProps } from "@chakra-ui/react"

export const ProcessingBase: FC<
  { topSlot?: ReactNode; children: ReactNode; bottomSlot?: ReactNode } & FlexProps
> = ({ children, topSlot = null, bottomSlot = null, ...props }) => (
  <Flex
    direction="column"
    paddingX={6}
    paddingY={4}
    height="$100vh"
    width="full"
    minW="300px"
    bgColor="white"
    {...props}
  >
    {topSlot && (
      <Box marginX={1} marginY={0.5}>
        {topSlot}
      </Box>
    )}
    <Flex direction="column" flexGrow={1}>
      {children}
    </Flex>
    {bottomSlot && (
      <Flex minH="88px" alignItems="center">
        {bottomSlot}
      </Flex>
    )}
  </Flex>
)
