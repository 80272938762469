const _formatter = Intl.NumberFormat(undefined, {
  useGrouping: false,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})
export const toFractionTuple = (n: number): [string, string] => {
  let integer = ""
  let fraction = ""
  _formatter.formatToParts(n).forEach(({ type, value }) => {
    if (type === "integer") {
      integer = value
    } else if (type === "fraction") {
      fraction = value
    }
  })

  return [integer, fraction]
}
