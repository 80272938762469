export const serialQueue = <T>(fn: (opts: T) => Promise<unknown>) => {
  const queue: T[] = []
  let working = false

  const execute = async () => {
    if (!working) {
      working = true
      while (queue.length > 0) {
        const opts = queue.shift()
        if (opts) {
          await fn(opts)
        }
      }
      working = false
    }
  }

  const append = (opts: T) => {
    queue.push(opts)
    return execute()
  }

  return { append }
}
