import React, { FC, ReactNode } from "react"
import { Flex, Heading, HeadingProps, Image } from "@chakra-ui/react"
import { AttributedString, AttributedStringType } from "~/components"

import { HeaderLogo } from "./HeaderLogo"
import { ReactComponent as Lines } from "./lines.svg"
import bgLovi from "./bg_lovi.svg?url"

export const HeaderHeadingPart1: FC<HeadingProps> = (props) => (
  <Heading
    w="full"
    size="Header/Primary"
    textAlign="center"
    bgImage={bgLovi}
    bgRepeat="no-repeat"
    bgPosition="center top"
    whiteSpace="pre-wrap"
    sx={{ "& em": { whiteSpace: "pre-wrap", fontStyle: "normal" } }}
    {...props}
  />
)

export const HeaderHeadingPartWithLogo: FC<{ title: AttributedStringType | string }> = ({
  title,
}) => (
  <Flex pt={2} direction="column" alignItems="center" w="full">
    <HeaderLogo />
    <HeaderHeadingPart1 paddingTop={8}>
      <AttributedString>{title}</AttributedString>
    </HeaderHeadingPart1>
  </Flex>
)

export const HeaderHeadingPart2: FC<{ children: ReactNode }> = ({ children }) => (
  <Flex>
    <Heading size="Header/Primary" whiteSpace="pre-wrap">
      {children}
    </Heading>
    <Lines style={{ position: "relative", top: "-42px", flexGrow: 1 }} />
  </Flex>
)
