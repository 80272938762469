import { FORMAT_NUMBER_OPTS, LOCALE } from "./locale"
import { logError } from "./logError"

export type CurrencyCode = string // TODO BrandType
const re = new RegExp(/^[A-Z]{3}$/)
/* TODO memoize getCurrencySymbol() and checkCurrency() */
const checkCurrency = (currencyCode: CurrencyCode) => re.test(currencyCode)

export const getCurrencySymbol = (currency: CurrencyCode) => formatPrice(currency, 0).split("0")[0]
export const formatPrice = (_currency: CurrencyCode, num: number) => {
  let currency = "USD"
  if (checkCurrency(_currency.toUpperCase())) {
    currency = _currency.toUpperCase()
  } else {
    logError(`Invalid currency code ${currency}`)
  }

  return new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency,
    ...FORMAT_NUMBER_OPTS,
  }).format(num)
}
export const formatPercent = (num: number, maximumFractionDigits = 0) => {
  return new Intl.NumberFormat(LOCALE, {
    style: "percent",
    ...FORMAT_NUMBER_OPTS,
    maximumFractionDigits,
  }).format(num / 100)
}
export const formatNumber = new Intl.NumberFormat(LOCALE, FORMAT_NUMBER_OPTS).format
