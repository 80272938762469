import { useEffect } from "react"
import { useDispatch } from "~/utils/redux"
import { useAuthInfo } from "~/utils/useAuthInfo"
import { useInterviewVariant } from "~/utils/useInterviewVariant"
import { downloadData } from "~/store/actions"
import { useIsContentLoaded } from "~/store/selectors"

export const useDownloadData = () => {
  const dispatch = useDispatch()
  const isContentLoaded = useIsContentLoaded()

  const { user, loginWithRedirect } = useAuthInfo()
  const interviewVariant = useInterviewVariant() || ""
  useEffect(() => {
    if (!user?.userId) {
      loginWithRedirect()
    }

    if (user?.userId && !isContentLoaded) {
      dispatch(downloadData(user.userId, interviewVariant))
    }
  }, [dispatch, user, isContentLoaded, interviewVariant])

  return
}
