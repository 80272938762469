import React, { FC } from "react"
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react"

import { useUserEmail } from "~/store/selectors"
import { ProcessingBase } from "./ProcessingBase"
import letterSvg from "./assets/letter.svg"

export const ProcessingInProgress: FC = () => {
  const email = useUserEmail("")
  return (
    <ProcessingBase>
      <Flex direction="column" h="full" justifyContent="center" flexGrow={1} paddingY={5} gap={4}>
        <Image src={letterSvg} alt="" w="57px" h="46px" />
        <Heading size="MediumHeaderPrimary">Thank you...</Heading>
        <Box color="Base/baseSecondary" textStyle="Paragraph/Primary">
          <Text mb={4}>Processing your payment may take a few minutes</Text>
          <Text>
            When the payment will be done, we’ll send notification to e-mail address
            {email && (
              <>
                {" "}
                <Text as="span" color="black">
                  {email}
                </Text>
              </>
            )}
          </Text>
        </Box>
      </Flex>
    </ProcessingBase>
  )
}
