import React from "react"
import { createIcon } from "@chakra-ui/react"

export const MarkIcon = createIcon({
  viewBox: "0 0 20 20",
  defaultProps: {
    fontSize: "20px",
    fill: "none",
  },
  path: (
    <path
      d="M5 8.69286C7.68571 13.2586 9.25238 14.4 9.7 14.4L15.4071 5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
  ),
})

export const UnlockIcon = createIcon({
  viewBox: "0 0 20 20",
  defaultProps: {
    fontSize: "20px",
    fill: "none",
  },
  path: (
    <>
      <path
        d="M4.21852 10.0753C4.15426 8.79009 5.17895 7.71289 6.46571 7.71289H13.539C14.8258 7.71289 15.8504 8.79009 15.7862 10.0753L15.5193 15.4118C15.4595 16.6093 14.4711 17.5495 13.2722 17.5495H6.73254C5.53358 17.5495 4.54522 16.6093 4.48535 15.4118L4.21852 10.0753Z"
        fill="white"
      />
      <path
        d="M12.8992 4.50034C11.5008 1.00037 4.89923 2.00035 6.64038 7.91485"
        stroke="white"
        strokeWidth="2.25"
        strokeLinecap="round"
      />
    </>
  ),
})

export const RingIcon = createIcon({
  viewBox: "0 0 20 20",
  defaultProps: {
    fontSize: "20px",
    fill: "none",
  },
  path: (
    <>
      <path
        d="M5.02876 14.1749C3.31061 13.5789 4.31286 11.9399 5.02876 11.1949C4.79013 8.4633 5.45832 3 10.04 3C14.6218 3 15.2899 8.4633 15.0513 11.1949C15.7672 11.9399 16.7695 13.5789 15.0513 14.1749C12.9036 14.9199 10.7559 14.9199 10.04 14.9199C9.32415 14.9199 7.17644 14.9199 5.02876 14.1749Z"
        fill="white"
      />
      <path
        d="M10.0406 15.7457C9.88789 15.7457 9.74628 15.7419 9.61521 15.7349C8.68339 15.6858 8.21747 15.6612 8.16063 15.7841C8.10379 15.907 8.34998 16.1675 8.84236 16.6884C9.12799 16.9906 9.51952 17.2127 10.0406 17.2127C10.5617 17.2127 10.9532 16.9906 11.2389 16.6884C11.7313 16.1675 11.9774 15.907 11.9206 15.7841C11.8638 15.6612 11.3979 15.6858 10.466 15.7349C10.335 15.7419 10.1934 15.7457 10.0406 15.7457Z"
        fill="white"
      />
    </>
  ),
})

export const StarIcon = createIcon({
  viewBox: "0 0 20 20",
  defaultProps: {
    fontSize: "20px",
    fill: "none",
  },
  path: (
    <>
      <path
        d="M8.57341 2.89058C9.02244 1.50861 10.9776 1.50861 11.4266 2.89058L12.2451 5.40983C12.446 6.02786 13.0219 6.4463 13.6717 6.4463H16.3206C17.7737 6.4463 18.3779 8.30573 17.2023 9.15983L15.0593 10.7168C14.5336 11.0988 14.3136 11.7758 14.5144 12.3939L15.3329 14.9131C15.782 16.2951 14.2003 17.4443 13.0247 16.5902L10.8817 15.0332C10.3559 14.6512 9.64405 14.6512 9.11832 15.0332L6.97532 16.5902C5.79975 17.4443 4.21803 16.2951 4.66705 14.9131L5.48561 12.3939C5.68642 11.7758 5.46643 11.0988 4.9407 10.7168L2.7977 9.15983C1.62213 8.30573 2.22629 6.4463 3.67937 6.4463H6.32827C6.97811 6.4463 7.55405 6.02786 7.75486 5.40983L8.57341 2.89058Z"
        fill="white"
      />
    </>
  ),
})

export const CrossLineIcon = createIcon({
  viewBox: "0 0 132 4",
  defaultProps: {
    fill: "none",
    height: "4px",
    width: "full",
  },
  path: (
    <>
      <path
        d="M1 3C24.4522 3 47.9043 3 71.3565 3C74.8071 3 78.2577 3 81.7083 3C87.5663 3 93.1815 2.35695 98.8611 1.80088C109.668 0.742816 120.286 1.0378 131 1.0378"
        stroke="#45B87C"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </>
  ),
})
