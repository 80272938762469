import { Heading, Text } from "@chakra-ui/react"
import React, { FC } from "react"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { ProgramList } from "./ProgramList"
import { ProgramList2Container } from "./ProgramList2Container"

export const ProgramBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <Heading size="MediumHeaderSecondary" textAlign="center">
      You will get complete skincare program
    </Heading>
    <Text
      textAlign="center"
      color="Base/baseSecondary"
      textStyle="mediumCondensed"
      marginBottom={4}
      marginTop={3}
    >
      {/* eslint-disable-next-line no-irregular-whitespace */}
      We’ve created your unique pora program to achieve your main and secondary goals
    </Text>
    <ProgramList />
  </BlockWrapper>
)

export const Program2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} header="What you get">
    <ProgramList2Container />
  </BlockWrapper>
)
