import { createIcon } from "@chakra-ui/react"
import React from "react"

export const AppStoreIcon = createIcon({
  viewBox: "0 0 120 41",
  path: (
    <>
      <path
        d="M110.135 0.996224H9.53468C9.16798 0.996224 8.80568 0.996224 8.43995 0.998224C8.1338 1.00022 7.83009 1.00603 7.521 1.01092C6.84951 1.01883 6.17961 1.0779 5.5171 1.18763C4.85552 1.29976 4.21467 1.51114 3.61622 1.81463C3.0185 2.1207 2.47234 2.51839 1.99757 2.99329C1.5203 3.46686 1.12246 4.01424 0.81935 4.61438C0.5154 5.21333 0.30464 5.85516 0.19435 6.5177C0.0830109 7.17941 0.0230984 7.84874 0.01515 8.5197C0.00587 8.8263 0.00489 9.13392 0 9.44056V32.5548C0.00489 32.8653 0.00587 33.1661 0.01515 33.4767C0.0231008 34.1476 0.0830134 34.817 0.19435 35.4786C0.304336 36.1416 0.515108 36.7837 0.81935 37.3829C1.12233 37.9811 1.52022 38.5263 1.99757 38.9972C2.47054 39.4742 3.01705 39.8722 3.61622 40.1759C4.21467 40.4802 4.85545 40.6929 5.5171 40.8067C6.17972 40.9156 6.84956 40.9747 7.521 40.9835C7.83009 40.9903 8.1338 40.9942 8.43995 40.9942C8.80567 40.9962 9.168 40.9962 9.53468 40.9962H110.135C110.494 40.9962 110.859 40.9962 111.219 40.9942C111.523 40.9942 111.836 40.9903 112.141 40.9835C112.811 40.9751 113.479 40.916 114.141 40.8067C114.804 40.6921 115.448 40.4795 116.049 40.1759C116.647 39.872 117.193 39.4741 117.666 38.9972C118.142 38.5244 118.541 37.9797 118.848 37.3829C119.15 36.7833 119.358 36.1412 119.467 35.4786C119.578 34.8169 119.64 34.1477 119.652 33.4767C119.656 33.1661 119.656 32.8653 119.656 32.5548C119.664 32.1915 119.664 31.8302 119.664 31.461V10.5324C119.664 10.1661 119.664 9.80286 119.656 9.44056C119.656 9.13392 119.656 8.8263 119.652 8.51966C119.64 7.84865 119.578 7.17946 119.467 6.51766C119.358 5.85551 119.149 5.21373 118.848 4.61434C118.23 3.41142 117.252 2.43225 116.049 1.81454C115.448 1.51179 114.804 1.30046 114.141 1.18754C113.48 1.07733 112.811 1.01823 112.141 1.01078C111.836 1.0059 111.523 1.00004 111.219 0.998094C110.859 0.996094 110.494 0.996224 110.135 0.996224Z"
        fill="#A6A6A6"
      />
      <path
        d="M8.44487 40.1211C8.14019 40.1211 7.84287 40.1172 7.54058 40.1104C6.91436 40.1022 6.2896 40.0477 5.67144 39.9473C5.09503 39.848 4.53664 39.6634 4.0147 39.3994C3.49754 39.1376 3.02585 38.7944 2.6177 38.3828C2.20364 37.9761 1.85891 37.5043 1.59719 36.9863C1.33258 36.4649 1.14945 35.906 1.05419 35.3291C0.951311 34.7092 0.895651 34.0824 0.887687 33.4541C0.881347 33.2432 0.873047 32.541 0.873047 32.541V9.44043C0.873047 9.44043 0.881887 8.74902 0.887737 8.54589C0.895363 7.91858 0.950699 7.29275 1.05327 6.67382C1.14871 6.09534 1.33197 5.53484 1.59673 5.01172C1.85749 4.49404 2.2003 4.02195 2.61187 3.61377C3.02297 3.20172 3.49617 2.8567 4.01421 2.5913C4.53495 2.32819 5.09228 2.14483 5.66753 2.04736C6.28772 1.94593 6.91465 1.89109 7.54304 1.8833L8.44536 1.87109H111.214L112.127 1.88379C112.75 1.89119 113.371 1.94554 113.985 2.04638C114.566 2.14507 115.13 2.32971 115.656 2.59423C116.694 3.12908 117.539 3.97526 118.071 5.01416C118.332 5.53367 118.512 6.0896 118.607 6.66308C118.71 7.28708 118.768 7.91783 118.78 8.55029C118.783 8.83349 118.783 9.13769 118.783 9.44043C118.791 9.81543 118.791 10.1724 118.791 10.5322V31.4609C118.791 31.8242 118.791 32.1787 118.783 32.5361C118.783 32.8613 118.783 33.1592 118.779 33.4658C118.768 34.087 118.711 34.7065 118.608 35.3193C118.515 35.9004 118.333 36.4636 118.068 36.9893C117.805 37.5017 117.462 37.9694 117.053 38.375C116.644 38.7888 116.172 39.134 115.653 39.3975C115.128 39.6635 114.566 39.8488 113.985 39.9473C113.367 40.0483 112.742 40.1028 112.116 40.1104C111.823 40.1172 111.517 40.1211 111.219 40.1211L110.135 40.1231L8.44487 40.1211Z"
        fill="black"
      />
      <path
        d="M24.7719 21.3003C24.7827 20.4657 25.0044 19.6473 25.4164 18.9213C25.8284 18.1954 26.4174 17.5854 27.1285 17.1483C26.6767 16.5031 26.0808 15.9722 25.388 15.5976C24.6951 15.2231 23.9245 15.0152 23.1373 14.9906C21.4581 14.8143 19.8301 15.9954 18.9744 15.9954C18.1022 15.9954 16.7847 15.0081 15.3659 15.0373C14.4482 15.0669 13.5539 15.3338 12.77 15.8118C11.9861 16.2899 11.3395 16.9629 10.893 17.7651C8.959 21.1136 10.4016 26.0346 12.2542 28.7412C13.1811 30.0666 14.2644 31.547 15.6818 31.4945C17.0689 31.437 17.5869 30.61 19.2612 30.61C20.92 30.61 21.406 31.4945 22.8522 31.4611C24.3406 31.437 25.2784 30.1299 26.1727 28.792C26.8387 27.8476 27.3512 26.8039 27.6912 25.6995C26.8265 25.3338 26.0885 24.7216 25.5694 23.9393C25.0503 23.157 24.7729 22.2392 24.7719 21.3003Z"
        fill="white"
      />
      <path
        d="M22.0395 13.2094C22.8511 12.2352 23.2509 10.983 23.154 9.71875C21.9142 9.84897 20.769 10.4415 19.9465 11.3784C19.5443 11.836 19.2363 12.3685 19.0401 12.9452C18.8438 13.522 18.7632 14.1318 18.8028 14.7397C19.4229 14.7461 20.0364 14.6117 20.5971 14.3466C21.1577 14.0815 21.6509 13.6927 22.0395 13.2094Z"
        fill="white"
      />
      <path
        d="M42.3047 28.1358H37.5713L36.4346 31.4922H34.4297L38.9131 19.0742H40.9961L45.4795 31.4922H43.4404L42.3047 28.1358ZM38.0615 26.5869H41.8135L39.9639 21.1397H39.9121L38.0615 26.5869Z"
        fill="white"
      />
      <path
        d="M55.1612 26.9675C55.1612 29.781 53.6553 31.5886 51.3828 31.5886C50.8072 31.6187 50.2347 31.4861 49.7309 31.206C49.2271 30.9258 48.8124 30.5095 48.5342 30.0046H48.4912V34.489H46.6328V22.4401H48.4317V23.946H48.4659C48.7568 23.4435 49.1785 23.0294 49.6862 22.7475C50.1938 22.4657 50.7683 22.3267 51.3487 22.3454C53.6465 22.3454 55.1612 24.1618 55.1612 26.9675ZM53.251 26.9675C53.251 25.1345 52.3037 23.9294 50.8584 23.9294C49.4385 23.9294 48.4834 25.1599 48.4834 26.9675C48.4834 28.7917 49.4385 30.0134 50.8584 30.0134C52.3037 30.0134 53.251 28.8171 53.251 26.9675Z"
        fill="white"
      />
      <path
        d="M65.126 26.9675C65.126 29.781 63.6201 31.5886 61.3477 31.5886C60.772 31.6187 60.1995 31.4861 59.6957 31.206C59.1919 30.9258 58.7772 30.5095 58.4991 30.0046H58.4561V34.489H56.5977V22.4401H58.3965V23.946H58.4307C58.7216 23.4435 59.1433 23.0293 59.651 22.7475C60.1586 22.4657 60.7332 22.3267 61.3135 22.3454C63.6114 22.3454 65.126 24.1618 65.126 26.9675ZM63.2158 26.9675C63.2158 25.1345 62.2686 23.9294 60.8233 23.9294C59.4033 23.9294 58.4483 25.1599 58.4483 26.9675C58.4483 28.7917 59.4033 30.0134 60.8233 30.0134C62.2686 30.0134 63.2158 28.8171 63.2158 26.9675Z"
        fill="white"
      />
      <path
        d="M71.7129 28.0323C71.8506 29.2637 73.0469 30.0723 74.6817 30.0723C76.2481 30.0723 77.375 29.2637 77.375 28.1533C77.375 27.1895 76.6953 26.6123 75.086 26.2168L73.4766 25.8291C71.1963 25.2783 70.1377 24.2119 70.1377 22.4814C70.1377 20.3389 72.0049 18.8672 74.6563 18.8672C77.2803 18.8672 79.0791 20.3389 79.1397 22.4814H77.2637C77.1514 21.2422 76.127 20.4941 74.6299 20.4941C73.1328 20.4941 72.1084 21.251 72.1084 22.3525C72.1084 23.2305 72.7627 23.7471 74.3633 24.1425L75.7314 24.4785C78.2793 25.081 79.3379 26.1045 79.3379 27.9209C79.3379 30.2441 77.4873 31.6992 74.5439 31.6992C71.79 31.6992 69.9307 30.2783 69.8105 28.0322L71.7129 28.0323Z"
        fill="white"
      />
      <path
        d="M83.3476 20.2969V22.4395H85.0693V23.9111H83.3476V28.9023C83.3476 29.6777 83.6924 30.0391 84.4492 30.0391C84.6536 30.0355 84.8577 30.0211 85.0605 29.9961V31.459C84.7203 31.5225 84.3744 31.5513 84.0283 31.5449C82.1953 31.5449 81.4805 30.8564 81.4805 29.1006V23.9111H80.1641V22.4395H81.4804V20.2969H83.3476Z"
        fill="white"
      />
      <path
        d="M86.0664 26.9668C86.0664 24.1182 87.7441 22.3281 90.3604 22.3281C92.9854 22.3281 94.6553 24.1181 94.6553 26.9668C94.6553 29.8232 92.9941 31.6055 90.3604 31.6055C87.7275 31.6055 86.0664 29.8232 86.0664 26.9668ZM92.7617 26.9668C92.7617 25.0127 91.8662 23.8594 90.3603 23.8594C88.8545 23.8594 87.96 25.0215 87.96 26.9668C87.96 28.9287 88.8545 30.0732 90.3603 30.0732C91.8662 30.0732 92.7617 28.9287 92.7617 26.9668Z"
        fill="white"
      />
      <path
        d="M96.1875 22.4401H97.96V23.9811H98.003C98.1229 23.4998 98.4048 23.0744 98.8014 22.7766C99.198 22.4787 99.6851 22.3264 100.181 22.3453C100.395 22.3446 100.608 22.3678 100.817 22.4147V24.1529C100.547 24.0703 100.265 24.0324 99.9824 24.0406C99.7124 24.0297 99.4433 24.0773 99.1935 24.1802C98.9436 24.2831 98.719 24.4388 98.535 24.6367C98.351 24.8346 98.2121 25.0699 98.1276 25.3266C98.0432 25.5833 98.0153 25.8552 98.0459 26.1236V31.4938H96.1875L96.1875 22.4401Z"
        fill="white"
      />
      <path
        d="M109.386 28.834C109.136 30.4775 107.535 31.6055 105.487 31.6055C102.854 31.6055 101.219 29.8408 101.219 27.0098C101.219 24.1699 102.862 22.3281 105.409 22.3281C107.914 22.3281 109.489 24.0488 109.489 26.7939V27.4307H103.095V27.543C103.065 27.8762 103.107 28.2118 103.217 28.5277C103.327 28.8435 103.503 29.1323 103.734 29.3748C103.964 29.6174 104.243 29.8082 104.553 29.9345C104.863 30.0608 105.196 30.1197 105.53 30.1074C105.969 30.1486 106.41 30.0469 106.787 29.8174C107.164 29.588 107.456 29.2431 107.621 28.834L109.386 28.834ZM103.104 26.1318H107.63C107.647 25.8322 107.601 25.5324 107.496 25.2512C107.392 24.97 107.23 24.7135 107.022 24.4977C106.813 24.2819 106.562 24.1116 106.285 23.9974C106.007 23.8832 105.709 23.8275 105.409 23.834C105.107 23.8322 104.807 23.8903 104.527 24.0051C104.246 24.1198 103.992 24.2889 103.778 24.5025C103.563 24.7161 103.393 24.9701 103.278 25.2498C103.162 25.5294 103.103 25.8292 103.104 26.1318Z"
        fill="white"
      />
      <path
        d="M37.8271 9.72943C38.2168 9.70147 38.6077 9.76033 38.9718 9.90176C39.3359 10.0432 39.6641 10.2637 39.9327 10.5473C40.2013 10.8309 40.4036 11.1706 40.525 11.5419C40.6464 11.9132 40.6839 12.3068 40.6348 12.6943C40.6348 14.6005 39.6045 15.6963 37.8271 15.6963H35.6719V9.72943H37.8271ZM36.5986 14.8524H37.7236C38.002 14.8691 38.2806 14.8234 38.5392 14.7188C38.7977 14.6142 39.0297 14.4532 39.2182 14.2477C39.4067 14.0421 39.5469 13.7971 39.6288 13.5305C39.7107 13.2639 39.7321 12.9824 39.6914 12.7064C39.7291 12.4316 39.7056 12.1518 39.6224 11.8872C39.5393 11.6225 39.3987 11.3795 39.2106 11.1756C39.0226 10.9716 38.7917 10.8118 38.5346 10.7076C38.2776 10.6033 38.0006 10.5573 37.7236 10.5726H36.5986V14.8524Z"
        fill="white"
      />
      <path
        d="M41.6819 13.4418C41.6536 13.1459 41.6875 12.8474 41.7813 12.5653C41.8751 12.2832 42.0269 12.0239 42.2268 11.8039C42.4268 11.5839 42.6705 11.4081 42.9423 11.2879C43.2142 11.1676 43.5082 11.1055 43.8055 11.1055C44.1027 11.1055 44.3967 11.1676 44.6686 11.2879C44.9404 11.4081 45.1841 11.5839 45.3841 11.8039C45.584 12.0239 45.7358 12.2832 45.8296 12.5653C45.9235 12.8474 45.9573 13.1459 45.929 13.4418C45.9578 13.7381 45.9244 14.0371 45.8308 14.3196C45.7372 14.6021 45.5855 14.8619 45.3855 15.0823C45.1856 15.3027 44.9417 15.4789 44.6695 15.5994C44.3974 15.72 44.1031 15.7822 43.8055 15.7822C43.5078 15.7822 43.2135 15.72 42.9414 15.5994C42.6693 15.4789 42.4254 15.3027 42.2254 15.0823C42.0254 14.8619 41.8737 14.6021 41.7801 14.3196C41.6865 14.0371 41.6531 13.7381 41.6819 13.4418ZM45.0149 13.4418C45.0149 12.4658 44.5764 11.895 43.8069 11.895C43.0345 11.895 42.5999 12.4658 42.5999 13.4419C42.5999 14.4257 43.0345 14.9921 43.8069 14.9921C44.5765 14.9921 45.0149 14.4218 45.0149 13.4418Z"
        fill="white"
      />
      <path
        d="M51.5742 15.6943H50.6523L49.7217 12.3779H49.6514L48.7246 15.6943H47.8115L46.5703 11.1914H47.4717L48.2783 14.6274H48.3447L49.2705 11.1914H50.1231L51.0488 14.6274H51.1191L51.9219 11.1914H52.8105L51.5742 15.6943Z"
        fill="white"
      />
      <path
        d="M53.8555 11.1929H54.7109V11.9082H54.7773C54.89 11.6513 55.08 11.436 55.3209 11.2921C55.5617 11.1483 55.8415 11.0833 56.1211 11.106C56.3402 11.0895 56.5602 11.1226 56.7648 11.2027C56.9694 11.2827 57.1533 11.4079 57.303 11.5687C57.4527 11.7295 57.5643 11.922 57.6295 12.1318C57.6947 12.3416 57.7118 12.5634 57.6797 12.7808V15.6958H56.791V13.004C56.791 12.2803 56.4766 11.9205 55.8193 11.9205C55.6706 11.9135 55.5221 11.9388 55.384 11.9947C55.246 12.0505 55.1216 12.1355 55.0195 12.2439C54.9174 12.3523 54.8399 12.4815 54.7924 12.6226C54.7448 12.7637 54.7284 12.9135 54.7441 13.0616V15.6958H53.8555L53.8555 11.1929Z"
        fill="white"
      />
      <path d="M59.0938 9.43359H59.9824V15.6943H59.0938V9.43359Z" fill="white" />
      <path
        d="M61.219 13.4419C61.1907 13.146 61.2246 12.8474 61.3185 12.5653C61.4123 12.2832 61.5641 12.0239 61.7641 11.8039C61.964 11.5839 62.2078 11.4082 62.4796 11.2879C62.7515 11.1676 63.0455 11.1055 63.3428 11.1055C63.6401 11.1055 63.9341 11.1676 64.206 11.2879C64.4778 11.4082 64.7215 11.5839 64.9215 11.8039C65.1215 12.0239 65.2733 12.2832 65.3671 12.5653C65.461 12.8474 65.4949 13.146 65.4666 13.4419C65.4954 13.7382 65.4619 14.0371 65.3683 14.3197C65.2747 14.6022 65.123 14.862 64.9229 15.0824C64.7229 15.3028 64.479 15.479 64.2069 15.5995C63.9348 15.72 63.6404 15.7823 63.3428 15.7823C63.0452 15.7823 62.7508 15.72 62.4787 15.5995C62.2065 15.479 61.9626 15.3028 61.7626 15.0824C61.5626 14.862 61.4109 14.6022 61.3173 14.3197C61.2237 14.0371 61.1902 13.7382 61.219 13.4419ZM64.552 13.4419C64.552 12.4659 64.1135 11.8951 63.344 11.8951C62.5715 11.8951 62.137 12.4659 62.137 13.4419C62.137 14.4258 62.5716 14.9922 63.344 14.9922C64.1135 14.9922 64.552 14.4219 64.552 13.4419Z"
        fill="white"
      />
      <path
        d="M66.4023 14.4219C66.4023 13.6113 67.0059 13.144 68.0771 13.0776L69.2969 13.0073V12.6186C69.2969 12.1431 68.9824 11.8745 68.375 11.8745C67.8789 11.8745 67.5352 12.0566 67.4365 12.375H66.5762C66.667 11.6016 67.3945 11.1055 68.416 11.1055C69.5449 11.1055 70.1816 11.6675 70.1816 12.6186V15.6953H69.3262V15.0625H69.2559C69.1131 15.2895 68.9127 15.4745 68.6751 15.5988C68.4375 15.723 68.1712 15.7819 67.9033 15.7695C67.7143 15.7892 67.5232 15.769 67.3425 15.7103C67.1617 15.6516 66.9953 15.5557 66.8538 15.4287C66.7124 15.3017 66.5992 15.1465 66.5214 14.9731C66.4437 14.7997 66.4031 14.6119 66.4023 14.4219ZM69.2969 14.0371V13.6606L68.1973 13.7309C67.5771 13.7724 67.2959 13.9834 67.2959 14.3804C67.2959 14.7856 67.6474 15.0215 68.1309 15.0215C68.2725 15.0358 68.4156 15.0215 68.5516 14.9794C68.6876 14.9373 68.8138 14.8683 68.9226 14.7765C69.0313 14.6846 69.1205 14.5718 69.1849 14.4448C69.2492 14.3178 69.2873 14.1791 69.2969 14.0371Z"
        fill="white"
      />
      <path
        d="M71.3477 13.4409C71.3477 12.0181 72.0791 11.1167 73.2168 11.1167C73.4982 11.1037 73.7775 11.1712 74.022 11.311C74.2666 11.4509 74.4662 11.6575 74.5977 11.9067H74.6641V9.43359H75.5527V15.6943H74.7012V14.9829H74.6309C74.4893 15.2304 74.2826 15.4344 74.0334 15.5729C73.7841 15.7114 73.5017 15.779 73.2168 15.7685C72.0713 15.7686 71.3477 14.8672 71.3477 13.4409ZM72.2657 13.4409C72.2657 14.396 72.7159 14.9707 73.4688 14.9707C74.2178 14.9707 74.6807 14.3877 74.6807 13.4448C74.6807 12.5064 74.2129 11.9151 73.4688 11.9151C72.7207 11.9151 72.2657 12.4937 72.2657 13.4409Z"
        fill="white"
      />
      <path
        d="M79.2307 13.4418C79.2024 13.1459 79.2363 12.8474 79.3301 12.5653C79.424 12.2832 79.5757 12.0239 79.7757 11.8039C79.9756 11.5839 80.2193 11.4081 80.4912 11.2879C80.763 11.1676 81.057 11.1055 81.3543 11.1055C81.6516 11.1055 81.9455 11.1676 82.2174 11.2879C82.4892 11.4081 82.7329 11.5839 82.9329 11.8039C83.1328 12.0239 83.2846 12.2832 83.3784 12.5653C83.4723 12.8474 83.5061 13.1459 83.4778 13.4418C83.5067 13.7381 83.4732 14.0371 83.3796 14.3196C83.286 14.6021 83.1344 14.8619 82.9344 15.0823C82.7344 15.3027 82.4905 15.4789 82.2184 15.5994C81.9462 15.72 81.6519 15.7822 81.3543 15.7822C81.0567 15.7822 80.7623 15.72 80.4902 15.5994C80.2181 15.4789 79.9742 15.3027 79.7742 15.0823C79.5742 14.8619 79.4225 14.6021 79.3289 14.3196C79.2353 14.0371 79.2019 13.7381 79.2307 13.4418ZM82.5637 13.4418C82.5637 12.4658 82.1253 11.895 81.3557 11.895C80.5833 11.895 80.1487 12.4658 80.1487 13.4419C80.1487 14.4257 80.5833 14.9921 81.3557 14.9921C82.1253 14.9921 82.5637 14.4218 82.5637 13.4418Z"
        fill="white"
      />
      <path
        d="M84.6699 11.1929H85.5254V11.9082H85.5918C85.7045 11.6513 85.8945 11.436 86.1353 11.2921C86.3762 11.1483 86.6559 11.0833 86.9356 11.106C87.1547 11.0895 87.3746 11.1226 87.5792 11.2027C87.7838 11.2827 87.9678 11.4079 88.1175 11.5687C88.2672 11.7295 88.3787 11.922 88.4439 12.1318C88.5091 12.3416 88.5263 12.5634 88.4941 12.7808V15.6958H87.6055V13.004C87.6055 12.2803 87.291 11.9205 86.6338 11.9205C86.485 11.9135 86.3365 11.9388 86.1985 11.9947C86.0604 12.0505 85.9361 12.1355 85.8339 12.2439C85.7318 12.3523 85.6543 12.4815 85.6068 12.6226C85.5593 12.7637 85.5428 12.9135 85.5586 13.0616V15.6958H84.6699V11.1929Z"
        fill="white"
      />
      <path
        d="M93.5146 10.0703V11.2119H94.4902V11.9605H93.5146V14.2759C93.5146 14.7476 93.709 14.9541 94.1514 14.9541C94.2646 14.9537 94.3778 14.9469 94.4902 14.9336V15.6738C94.3307 15.7024 94.169 15.7176 94.0068 15.7192C93.0186 15.7192 92.625 15.3716 92.625 14.5034V11.9604H91.9102V11.2119H92.625V10.0703H93.5146Z"
        fill="white"
      />
      <path
        d="M95.707 9.43359H96.5879V11.915H96.6582C96.7763 11.6557 96.9716 11.4391 97.2172 11.2948C97.4629 11.1504 97.7472 11.0854 98.0312 11.1084C98.2492 11.0965 98.467 11.1329 98.6693 11.215C98.8716 11.297 99.0533 11.4227 99.2014 11.583C99.3495 11.7434 99.4604 11.9344 99.5262 12.1426C99.592 12.3507 99.611 12.5708 99.582 12.7871V15.6943H98.6924V13.0063C98.6924 12.2871 98.3574 11.9228 97.7295 11.9228C97.5768 11.9103 97.4232 11.9313 97.2794 11.9844C97.1356 12.0374 97.0052 12.1212 96.8972 12.23C96.7892 12.3387 96.7062 12.4697 96.6542 12.6139C96.6021 12.758 96.5822 12.9118 96.5957 13.0644V15.6943H95.7071L95.707 9.43359Z"
        fill="white"
      />
      <path
        d="M104.761 14.4796C104.641 14.8911 104.379 15.2471 104.022 15.4852C103.666 15.7234 103.237 15.8285 102.81 15.7823C102.514 15.7901 102.219 15.7333 101.946 15.6158C101.674 15.4982 101.43 15.3228 101.232 15.1017C101.034 14.8806 100.887 14.619 100.8 14.3352C100.713 14.0514 100.69 13.7521 100.73 13.4581C100.691 13.1632 100.715 12.8633 100.801 12.5786C100.888 12.2939 101.035 12.0312 101.231 11.8081C101.428 11.585 101.671 11.4068 101.943 11.2856C102.214 11.1644 102.509 11.103 102.806 11.1055C104.059 11.1055 104.815 11.9615 104.815 13.3755V13.6856H101.635V13.7354C101.621 13.9007 101.642 14.067 101.696 14.2238C101.751 14.3805 101.837 14.5242 101.95 14.6457C102.063 14.7671 102.2 14.8637 102.352 14.929C102.504 14.9944 102.669 15.0273 102.835 15.0254C103.047 15.051 103.263 15.0127 103.453 14.9155C103.644 14.8183 103.802 14.6665 103.906 14.4795L104.761 14.4796ZM101.635 13.0284H103.91C103.921 12.8773 103.9 12.7255 103.85 12.5827C103.799 12.44 103.718 12.3096 103.614 12.1997C103.51 12.0898 103.383 12.003 103.243 11.9449C103.103 11.8867 102.953 11.8584 102.801 11.8619C102.648 11.86 102.495 11.8888 102.353 11.9468C102.21 12.0047 102.081 12.0906 101.972 12.1993C101.864 12.308 101.778 12.4374 101.72 12.5798C101.662 12.7222 101.633 12.8747 101.635 13.0284Z"
        fill="white"
      />
    </>
  ),
})
