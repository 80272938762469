import { PriceContentV2_PriceItem } from "~/generated/paywall"
import {
  addPeriod,
  formatDate,
  formatPrice,
  formatUnitWithPrefixLength,
  formatUnitWithPrefixLengthDash,
} from "~/utils"

export const textAbove = ({
  currency_code,
  trial_price_cents,
  trial_period_unit,
  trial_period_length,
}: PriceContentV2_PriceItem) =>
  `You will only be charged ${formatPrice(
    currency_code,
    trial_price_cents / 100
  )} for your first ${formatUnitWithPrefixLength(trial_period_unit, trial_period_length)}`

const _formatDate = formatDate("FULL_NUMERIC")

export const text1 = (
  {
    currency_code,
    trial_period_unit,
    trial_period_length,
    subscription_price_cents,
    subscription_period_unit,
    subscription_period_length,
  }: PriceContentV2_PriceItem,
  app_name: string
) =>
  `Your ${formatUnitWithPrefixLength(trial_period_unit, trial_period_length)} trial will last
until ${_formatDate(addPeriod(trial_period_unit, trial_period_length))}.
Cancel the subscription at any time at least 24 hours before the
due date if you want to avoid the renewal charge. If you decide ${app_name} is right for you, on
${_formatDate(addPeriod(trial_period_unit, trial_period_length))}
you will be charged ${formatPrice(currency_code, subscription_price_cents / 100)} for the
next ${formatUnitWithPrefixLength(subscription_period_unit, subscription_period_length)}.
${app_name} will automatically charge your card ${formatPrice(
    currency_code,
    subscription_price_cents / 100
  )} every
${formatUnitWithPrefixLength(subscription_period_unit, subscription_period_length)} until you
cancel the subscription so you don't forget the date or lose your progress.`

export const text2 = ({
  currency_code,
  trial_period_unit,
  trial_period_length,
  subscription_price_cents,
  subscription_period_unit,
  subscription_period_length,
}: PriceContentV2_PriceItem) =>
  `After your ${formatUnitWithPrefixLengthDash(
    trial_period_unit,
    trial_period_length
  )} intro offer ends, you will be automatically enrolled in our
  ${formatUnitWithPrefixLengthDash(subscription_period_unit, subscription_period_length)}
   subscription at the price of ${formatPrice(currency_code, subscription_price_cents / 100)}`

export const list1 = ({
  currency_code,
  trial_period_unit,
  trial_period_length,
  trial_price_cents,
}: PriceContentV2_PriceItem) => [
  `${formatUnitWithPrefixLengthDash(trial_period_unit, trial_period_length)} for ${formatPrice(
    currency_code,
    trial_price_cents / 100
  )}`,
  `You will be sent an email receipt each time your subscription renews`,
]

export const list2 = ({
  currency_code,
  trial_period_unit,
  trial_period_length,
  trial_price_cents,
}: PriceContentV2_PriceItem) => [
  `${formatUnitWithPrefixLengthDash(
    trial_period_unit,
    trial_period_length
  )} special trial price of just ${formatPrice(currency_code, trial_price_cents / 100)}`,
  `You will be sent an email receipt each time your subscription renews`,
]
