import React, { FC } from "react"
import { Box, BoxProps, Icon, Text } from "@chakra-ui/react"
import { ReactComponent as TagIcon } from "./tag.svg"
import { useUpsaleSubscriptionOnExit } from "./useUpsaleSubscriptionOnExit"

export const DiscountText: FC<{ visible: boolean; discountPercent: number }> = ({
  visible,
  discountPercent,
}) => (
  <Box
    transitionProperty="height"
    transitionDuration="500ms"
    transitionDelay="100ms"
    transitionTimingFunction="ease-in-out"
    overflow="hidden"
    height={visible ? "34px" : 0}
  >
    <Box textStyle="Subtitle/Secondary" color="Base/baseSecondary" pb={2}>
      <Icon
        as={TagIcon}
        w="20px"
        h="20px"
        mr="1ex"
        pos="relative"
        top="3px"
        color="Base/accentPrimary"
      />
      You got{" "}
      <Text as="span" color="Base/accentPrimary">
        an extra discount –{discountPercent}%
      </Text>
    </Box>
  </Box>
)

export const DiscountTextContainer: FC = () => {
  const { active, data } = useUpsaleSubscriptionOnExit()
  return <DiscountText visible={active} discountPercent={data.discount_percent} />
}
