import React, { FC } from "react"
import { useUpsaleSubscriptionDiscountedPrice } from "~/store/selectors"
import { InvoiceSkipTrialDiscounted } from "~/pages/Main/Invoice/InvoiceSkipTrialDiscounted"

export const InvoiceSkipTrialDiscountedContainer: FC = () => {
  const {
    trial_period_length: trialLength = 0,
    trial_period_unit: trialUnit,
    trial_price_cents: trialPrice = Number.NaN,
    subscription_period_length: subscriptionLength = 0,
    subscription_period_unit: subscriptionUnit,
    subscription_price_per_unit_cents: subscriptionPricePerUnit = Number.NaN,
    subscription_price_cents: subscriptionTotalPrice = Number.NaN,
    economy_price = "",
    economy_percent: discount = "",
    price_with_trial_cents: priceWithTrial = Number.NaN,
    price_diff_to_pay_cents: diffToPay = Number.NaN,
    previous_price = Number.NaN,
  } = useUpsaleSubscriptionDiscountedPrice() ?? {}
  const economyPrice = Number(economy_price)
  const previousPrice = Number(previous_price)
  const props = {
    economyPrice,
    priceWithTrial,
    diffToPay,
    previousPrice,
    trialLength,
    trialUnit,
    trialPrice,
    subscriptionLength,
    subscriptionUnit,
    subscriptionPricePerUnit,
    subscriptionTotalPrice,
    discount,
  }
  return <InvoiceSkipTrialDiscounted {...props} />
}
