import React from "react"
import { Review, Reviews6 } from "./Reviews6"

const reviews: Review[] = [
  {
    usageLength: 4,
    title: "Just thank you!",
    text: "I scanned all the cosmetics I currently use, eventually I stopped using one oil as it actually was harmful for my skin. Didn’t try the rituals yet, but will do!",
  },
]

export const Reviews6Container = () => {
  return <Reviews6 reviews={reviews} />
}
