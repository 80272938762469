import React, { FC } from "react"
import { Box, Image, Flex, Icon, Text } from "@chakra-ui/react"
import { useUpsaleSubscriptionOnExit } from "./useUpsaleSubscriptionOnExit"

const DiscountDividerIcon = () => {
  return (
    <svg width="12" height="96" viewBox="0 0 12 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 14C7.10457 14 8 13.1046 8 12C8 10.8954 7.10457 10 6 10C4.89543 10 4 10.8954 4 12C4 13.1046 4.89543 14 6 14Z"
        fill="#F6F5F4"
      />
      <path
        d="M6 22C7.10457 22 8 21.1046 8 20C8 18.8954 7.10457 18 6 18C4.89543 18 4 18.8954 4 20C4 21.1046 4.89543 22 6 22Z"
        fill="#F6F5F4"
      />
      <path
        d="M8 28C8 29.1046 7.10457 30 6 30C4.89543 30 4 29.1046 4 28C4 26.8954 4.89543 26 6 26C7.10457 26 8 26.8954 8 28Z"
        fill="#F6F5F4"
      />
      <path
        d="M6 38C7.10457 38 8 37.1046 8 36C8 34.8954 7.10457 34 6 34C4.89543 34 4 34.8954 4 36C4 37.1046 4.89543 38 6 38Z"
        fill="#F6F5F4"
      />
      <path
        d="M8 44C8 45.1046 7.10457 46 6 46C4.89543 46 4 45.1046 4 44C4 42.8954 4.89543 42 6 42C7.10457 42 8 42.8954 8 44Z"
        fill="#F6F5F4"
      />
      <path
        d="M6 54C7.10457 54 8 53.1046 8 52C8 50.8954 7.10457 50 6 50C4.89543 50 4 50.8954 4 52C4 53.1046 4.89543 54 6 54Z"
        fill="#F6F5F4"
      />
      <path
        d="M8 60C8 61.1046 7.10457 62 6 62C4.89543 62 4 61.1046 4 60C4 58.8954 4.89543 58 6 58C7.10457 58 8 58.8954 8 60Z"
        fill="#F6F5F4"
      />
      <path
        d="M6 70C7.10457 70 8 69.1046 8 68C8 66.8954 7.10457 66 6 66C4.89543 66 4 66.8954 4 68C4 69.1046 4.89543 70 6 70Z"
        fill="#F6F5F4"
      />
      <path
        d="M8 76C8 77.1046 7.10457 78 6 78C4.89543 78 4 77.1046 4 76C4 74.8954 4.89543 74 6 74C7.10457 74 8 74.8954 8 76Z"
        fill="#F6F5F4"
      />
      <path
        d="M6 86C7.10457 86 8 85.1046 8 84C8 82.8954 7.10457 82 6 82C4.89543 82 4 82.8954 4 84C4 85.1046 4.89543 86 6 86Z"
        fill="#F6F5F4"
      />
      <path
        d="M12 0C12 1.5913 11.3679 3.11742 10.2426 4.24264C9.11742 5.36786 7.5913 6 6 6C4.4087 6 2.88258 5.36786 1.75736 4.24264C0.632141 3.11742 2.40279e-07 1.5913 0 9.05975e-07L12 0Z"
        fill="#F6F5F4"
      />
      <path
        d="M12 96C12 94.4087 11.3679 92.8826 10.2426 91.7574C9.11742 90.6321 7.5913 90 6 90C4.4087 90 2.88258 90.6321 1.75736 91.7574C0.632141 92.8826 2.40279e-07 94.4087 0 96H12Z"
        fill="#F6F5F4"
      />
    </svg>
  )
}

export const DiscountText2: FC<{ visible: boolean; discountPercent: number }> = ({
  visible,
  discountPercent,
}) => (
  <Box
    transitionProperty="height"
    transitionDuration="500ms"
    transitionDelay="100ms"
    transitionTimingFunction="ease-in-out"
    overflow="hidden"
    height={visible ? "96px" : 0}
    mb={visible ? 4 : 0}
  >
    <Box h="full" textStyle="Subtitle/Secondary" color="Base/basePrimary" mb={4}>
      <Flex borderRadius="20px" alignItems="center" bgColor="white">
        <Box p={4}>
          Claim Your Skincare Plan With Additional{" "}
          <Box as="span" textStyle="Subtitle/AccentSecondary" color="Base/accentSecondary">
            {discountPercent}% Off
          </Box>
        </Box>
        <Box p={4} pos="relative">
          <Box pos="absolute" left={-2} top={0}>
            <DiscountDividerIcon />
          </Box>
          <Image
            maxW="none"
            w="64px"
            h="64px"
            src="https://storage.pora.ai/2f93a8ca6345e7a544488090a61a998f.webp"
          />
        </Box>
      </Flex>
    </Box>
  </Box>
)

export const DiscountText2Container: FC = () => {
  const { active, data } = useUpsaleSubscriptionOnExit()
  return <DiscountText2 visible={active} discountPercent={data.discount_percent} />
}
