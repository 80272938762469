import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  useRadioGroup,
} from "@chakra-ui/react"
import React, { FC, useEffect } from "react"
import { RadioAppButton } from "~/components"
import { PriceSelectProperties } from "~/generated/paywall"
import { ReactComponent as ArrowImage } from "./arrow.svg"

interface PriceSelectPopupProps {
  pricesOptions: { title: string; value: string }[]
  defaultValue: string
  buttonText: PriceSelectProperties["button_text"]
  preSelectorTexts: PriceSelectProperties["pre_selector_texts"]
  onConfirm: (value: string) => void
  onChange?: (value: string) => void
  onShow?: () => void
  fullscreen?: boolean
}
export const PriceSelectPopup: FC<PriceSelectPopupProps> = ({
  pricesOptions,
  onShow,
  defaultValue,
  buttonText,
  preSelectorTexts,
  onConfirm,
  onChange,
  fullscreen,
}) => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })
  const {
    getRootProps,
    getRadioProps,
    value: selectedPrice,
  } = useRadioGroup({
    onChange,
    defaultValue,
    name: "popup-select",
  })

  useEffect(() => {
    if (onShow) {
      onShow()
    }
  }, [onShow])

  const handleConfirm = () => {
    onConfirm(String(selectedPrice))
    onClose()
  }

  return (
    <>
      <Modal size={fullscreen ? "full" : undefined} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay backdropFilter={"blur(6px)"} />
        <ModalContent
          alignItems="center"
          borderRadius={fullscreen ? undefined : "3xl"}
          bgColor="Utils/screenBgColor"
        >
          <ModalBody maxWidth={450} display="flex" pt={6} alignItems="center">
            <Flex flexDirection="column">
              <Flex direction="column" gap={3} mb={6}>
                {preSelectorTexts[0] && (
                  <Text textStyle="RegularParagraphSecondary" color="Base/basePrimary">
                    {preSelectorTexts[0]}
                  </Text>
                )}
                {preSelectorTexts[1] && (
                  <Text textStyle="MediumSubtitleTertiary" color="Base/basePrimary">
                    {preSelectorTexts[1]}
                  </Text>
                )}
                {preSelectorTexts[2] && (
                  <Text textStyle="MediumSubtitleTertiary" color="Base/accentPrimary">
                    {preSelectorTexts[2]}
                  </Text>
                )}
                {preSelectorTexts[3] && (
                  <Text textStyle="RegularParagraphSecondary" color="Base/basePrimary">
                    {preSelectorTexts[3]}
                  </Text>
                )}
              </Flex>
              <Box mb={3}>
                <Flex flexGrow={1} gap={2} {...getRootProps()}>
                  {pricesOptions.map(({ value, title }) => {
                    const radio = getRadioProps({ value })
                    return (
                      <RadioAppButton key={value} {...radio}>
                        {title}
                      </RadioAppButton>
                    )
                  })}
                </Flex>
              </Box>
              <Flex>
                <Text textStyle="RegularParagraphSecondary" color="Base/baseSecondary">
                  This option will help us to support those who need to select the lowest trial
                  prices!
                </Text>
                <Box>
                  <ArrowImage />
                </Box>
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter width="full" maxWidth={450}>
            <Box width="full" textStyle="MediumSubtitlePrimary">
              <Button variant="next" w="full" onClick={handleConfirm}>
                {buttonText}
              </Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
