import { Box, BoxProps, Flex } from "@chakra-ui/react"
import React, { FC, ReactNode } from "react"
import { Timer } from "~/components"
import { BlockHeading, BlockWrapper, BlockWrapperProps } from "../BlockWrapper"

import ph01 from "./assets/ph01.webp?url"
import ph02 from "./assets/ph02.webp?url"
import bg from "./assets/bg.svg?url"
import { ActionButton } from "../../ActionButton"
import { useBlockProperties, useSelectedPrice } from "~/store/selectors"
import { formatPercent } from "~/utils"

const Photo: FC<BoxProps> = (props) => (
  <Box
    flexGrow={1}
    bgSize="contain"
    bgRepeat="no-repeat"
    bgPosition="center"
    sx={{ aspectRatio: "201 / 328" }}
    {...props}
  />
)

export const BeforeAfterWithTimer: FC<{ topSlot: ReactNode; headerBgColor?: string }> = ({
  topSlot,
  headerBgColor = "Other/Error",
}) => (
  <Box borderRadius="brand24" overflow="hidden" maxW="450px" minW="327px" w="full">
    <Flex
      w="full"
      h="46px"
      textAlign="center"
      textStyle="Subtitle/Secondary"
      color="Base/neutralPrimary"
      bgColor={headerBgColor}
      gap={1}
      alignItems="center"
      justifyContent="center"
    >
      {topSlot}
    </Flex>
    <Flex
      w="full"
      bgImage={bg}
      maxHeight="304px"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Photo bgImage={ph01} maxHeight="304px" />
      <Photo bgImage={ph02} maxHeight="304px" />
    </Flex>
  </Box>
)

export const BeforeAfterWithTimerContainer: FC = () => {
  const { economy_percent } = useSelectedPrice() ?? { economy_percent: "0" }
  const blockProperties = useBlockProperties()
  const headerBgColor = blockProperties["PB_BEFORE_AFTER_WITH_TIMER.headerBgColor"]
  return (
    <BeforeAfterWithTimer
      headerBgColor={headerBgColor}
      topSlot={
        <>
          {`-${formatPercent(parseFloat(economy_percent))} DISCOUNT RESERVED FOR `}
          <Box as="span" display="inline-block" minW="5ex">
            <Timer />
          </Box>
        </>
      }
    />
  )
}

export const BeforeAfterWithTimerBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" paddingX={6} {...props}>
    <BlockHeading>Get affordable dream skin without skincare confusion</BlockHeading>
    <BeforeAfterWithTimerContainer />
    <ActionButton mt={6} actionSource="PB_BEFORE_AFTER_WITH_TIMER">
      Get My Plan
    </ActionButton>
  </BlockWrapper>
)
