import { createIcon } from "@chakra-ui/react"
import React from "react"

export const TargetIcon = createIcon({
  viewBox: "0 0 36 36",
  defaultProps: { fill: "none" },
  path: (
    <>
      <g clipPath="url(#clip0_74_3687)">
        <path
          d="M32.1182 13.3938C32.6641 14.9982 32.9672 16.7943 32.9672 18.7794C32.9672 38.7033 2.43254 39.5838 2.43262 18.7793C2.43267 5.29048 15.2685 0.917488 24.2957 4.94025M25.6997 15.1435C27.9784 20.2928 25.4324 27.6509 17.8589 27.6509C6.20897 27.6509 6.45515 10.2403 17.8589 10.2403C19.5012 10.2403 20.912 10.6014 22.0893 11.2195"
          stroke="#0C2330"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.3594 7.32456C29.5739 7.87976 28.2274 9.07398 26.6494 10.5818M30.3594 7.32456C30.6554 6.40707 30.29 3.9897 30.0751 2.56772C29.2209 2.98787 27.4567 4.41839 26.8558 5.23655C26.275 6.02742 26.3805 8.80264 26.6494 10.5818M30.3594 7.32456C31.1449 6.76936 33.4195 7.75681 35.1292 8.20283C34.2411 9.03851 31.9858 10.4242 30.6349 11.0646C29.8236 11.4491 27.8063 11.205 26.6494 10.5818M16.5737 20.1969C17.5976 19.1826 22.1917 14.8412 26.6494 10.5818M16.5737 20.1969C16.7668 19.8768 17.6771 18.0873 17.6013 16.8188M16.5737 20.1969C16.8284 19.9645 18.5222 19.2768 19.8033 19.3157"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_74_3687">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
})

export const CosmeticsIcon = createIcon({
  viewBox: "0 0 36 36",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        d="M26.2615 28.9757C26.2615 29.9379 26.2615 30.419 26.5385 30.7428C26.5892 30.8021 26.6459 30.8564 26.7077 30.9051C27.0452 31.1708 27.5467 31.1708 28.5496 31.1708C29.5525 31.1708 30.054 31.1708 30.3916 30.9051C30.4533 30.8564 30.51 30.8021 30.5607 30.7428C30.8377 30.419 30.8377 29.9379 30.8377 28.9757M22.5999 5.39917C21.8555 6.15894 23.2688 27.4705 24.0856 28.2433C24.9024 29.0162 32.501 28.7246 33.0103 28.2433C33.5196 27.7621 35.2426 6.15894 34.4995 5.39917C33.7565 4.6394 23.3443 4.63941 22.5999 5.39917Z"
        stroke="#0C2330"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.28004 13.7991C3.28004 11.9738 3.28004 11.5239 4.11158 10.9096C4.26381 10.7972 4.43389 10.6941 4.61938 10.6018C5.63261 10.0977 7.13801 10.0977 10.1488 10.0977C13.1596 10.0977 14.665 10.0977 15.6782 10.6018C15.8637 10.6941 16.0338 10.7972 16.186 10.9096C17.0176 11.5239 17.0176 11.9739 17.0176 13.7991M1.90621 24.9035C0.705896 23.5714 1.50464 15.6168 2.82176 14.2618C4.13887 12.9068 16.656 13.418 17.4772 14.2618C18.2985 15.1056 19.5895 23.5714 18.3913 24.9035C17.1931 26.2356 3.10653 26.2356 1.90621 24.9035Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})

export const SmileIcon = createIcon({
  viewBox: "0 0 36 36",
  defaultProps: { fill: "none" },
  path: (
    <>
      <path
        d="M11.7792 15.4416C12.1522 15.4416 12.4545 15.1392 12.4545 14.7662M11.7792 15.4416C11.4063 15.4416 11.1039 15.1392 11.1039 14.7662M11.7792 15.4416V14.0909M12.4545 14.7662C12.4545 14.3933 12.1522 14.0909 11.7792 14.0909M12.4545 14.7662H11.1039M11.7792 14.0909C11.4063 14.0909 11.1039 14.3933 11.1039 14.7662M20.2208 15.4416C20.5937 15.4416 20.8961 15.1392 20.8961 14.7662M20.2208 15.4416C19.8478 15.4416 19.5455 15.1392 19.5455 14.7662M20.2208 15.4416V14.0909M20.8961 14.7662C20.8961 14.3933 20.5937 14.0909 20.2208 14.0909M20.8961 14.7662H19.5455M20.2208 14.0909C19.8478 14.0909 19.5455 14.3933 19.5455 14.7662M12.9187 20.4783C13.8813 21.736 17.0249 23.2396 19.3066 20.4671M3 20C3 2.66661 29 2.66672 29 20C29 37.3333 3 37.3334 3 20Z"
        stroke="#0C2330"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.25 8.125C32.5 8.125 32.5 5.875 32.5 4.75C32.5 5.875 32.5 8.125 34.75 8.125C32.5 8.125 32.5 10.375 32.5 11.5C32.5 10.375 32.5 8.125 30.25 8.125Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 3.8125C24.0625 3.8125 24.0625 1.9375 24.0625 1C24.0625 1.9375 24.0625 3.8125 26.125 3.8125C24.0625 3.8125 24.0625 5.6875 24.0625 6.625C24.0625 5.6875 24.0625 3.8125 22 3.8125Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})

export const Underline = createIcon({
  viewBox: "0 0 8 4",
  defaultProps: {
    height: "4px",
    width: "calc(100% + 10px)",
    preserveAspectRatio: "none",
  },
  path: (
    <>
      <path
        d="M0.84668 0.866615C25.496 0.86656 64.3131 0.634807 87.1544 1.41189"
        stroke="currentColor"
        strokeWidth="1.01538"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.03564 3.16553C24.7811 3.16553 55.2666 3.16559 86.789 3.16555"
        stroke="currentColor"
        strokeWidth="1.01538"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
})
