import React, { FC, ReactNode } from "react"
import {
  Box,
  Heading,
  Image,
  Button,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"

import chartSrc from "./assets/chart.svg?url"

const Content: FC<{ children: ReactNode }> = ({ children }) => (
  <VFlex
    gap={6}
    alignItems="center"
    borderRadius="32px"
    paddingX={8}
    pt={16}
    pb={8}
    bgColor="white"
  >
    <VFlex gap={2} alignItems="center">
      <Heading size="Header/Secondary" textAlign="center">
        Did you know?
      </Heading>
      <Box textStyle="Paragraph/Primary" textAlign="center">
        Lovi users who start with Skincare Activation get visible results 34% faster
      </Box>
    </VFlex>
    <Image src={chartSrc} alt="Results 34% faster with workbook" />
    <Box
      textStyle="Paragraph/Primary"
      color="Base/baseSecondary"
      textAlign="center"
      sx={{
        "& em": {
          color: "Base/accentPrimary",
          fontWeight: 600,
          fontStyle: "normal",
        },
      }}
    >
      We want you to see the best results possible, so we&apos;re offering you an extra{" "}
      <em>25% off</em> the workbook!
    </Box>
    {children}
  </VFlex>
)

export const Step2Modal: FC<Pick<ModalProps, "onClose" | "isOpen">> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="transparent">
        <ModalCloseButton borderRadius="full" color="Base/baseSecondary" />
        <Content>
          <Button w="full" variant="action" onClick={onClose}>
            Continue
          </Button>
        </Content>
      </ModalContent>
    </Modal>
  )
}
