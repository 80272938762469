import React, { FC, ReactNode } from "react"
import { useBlockProperties } from "~/store/selectors"

export const BlockPropertyContent: FC<{
  propKey: string
  children: ReactNode
}> = ({ propKey, children }) => {
  const blockProperties = useBlockProperties()
  let result = children
  if (blockProperties[propKey] ?? false) {
    result = blockProperties[propKey] ?? ""
  }

  return <>{result}</>
}

export const ChildrenIfBlockPropertyExist: FC<{
  propKey: string
  children: ReactNode
  defaultSlot?: ReactNode
}> = ({ propKey, defaultSlot, children }) => {
  const blockProperties = useBlockProperties()
  let result = defaultSlot
  if (blockProperties[propKey]) {
    result = children
  }

  return <>{result}</>
}
