import { ComponentStyleConfig } from "@chakra-ui/react"

const pora = {
  baseStyle: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItem: "center",

    padding: "7px",
    borderColor: "Base/neutralSecondary",
    boxShadow: undefined,
    borderWidth: 2,
    borderRadius: 16,
  },
  variants: {
    default: {},
    selected: {
      borderColor: "Base/accentPrimary",
      boxShadow: "smallOne",
    },
  },
}

const lovi = {
  baseStyle: {
    height: "55px",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",

    borderColor: "Base/neutralSecondary",
    boxShadow: undefined,
    borderWidth: 2,
    borderRadius: "full",
  },
  variants: {
    default: {},
    selected: {
      borderColor: "Base/accentPrimary",
      boxShadow: "LoviMedium",
    },
  },
}
export const PaymentSwitchButtonTheme = (theme: Theme): ComponentStyleConfig =>
  theme === "lovi" ? lovi : pora
