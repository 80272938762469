import React, { FC } from "react"
import { FlexProps, Flex, Box, SpaceProps } from "@chakra-ui/react"
import { ExternalBox, Timer, VFlex } from "~/components"
import { formatPercent } from "~/utils"

const Badge: FC<FlexProps> = (props) => (
  <ExternalBox top="20px">
    <Flex color="Base/neutralPrimary" borderRadius="full" paddingY={0.5} paddingX={2} {...props} />
  </ExternalBox>
)

const RoundVFlex: FC<FlexProps> = (props) => (
  <VFlex
    shadow="LoviMedium"
    borderRadius="brand12"
    bgColor="Base/neutralPrimary"
    justify="center"
    alignItems="center"
    w="full"
    {...props}
  />
)

const BadgeContent: FC<{ discount: number; previousDiscount?: number }> = ({
  discount,
  previousDiscount = 0,
}) => (
  <>
    {previousDiscount > 0 && (
      <Box
        textStyle="Other/PriceTag"
        textDecorationLine="line-through"
        textDecorationColor="Base/basePrimary"
        marginRight={1}
      >
        {formatPercent(previousDiscount)}
      </Box>
    )}
    <Box textStyle="Subtitle/Hint">{formatPercent(discount)} off</Box>
  </>
)

const DiscountCodeContent: FC<{ code: string; previousCode?: string }> = ({
  previousCode,
  code,
}) => (
  <VFlex gap={0.5} alignItems="center">
    {previousCode && (
      <Box textStyle="Subtitle/Hint" color="Base/baseSecondary" textDecorationLine="line-through">
        {previousCode}
      </Box>
    )}
    <Box textStyle="Subtitle/Secondary" fontWeight="bold">
      {code}
    </Box>
  </VFlex>
)
export interface TimerNotice2Props extends SpaceProps {
  discount: number
  previousDiscount: number | undefined
  code: string
  previousCode: string | undefined
  variant: "2" | "3"
}
export const TimerNotice2: FC<TimerNotice2Props> = ({
  discount,
  previousDiscount,
  code,
  previousCode,
  variant,
  ...props
}) => {
  const { bgColor, badgeColor } = {
    "2": { bgColor: "Base/accentPrimary", badgeColor: "Other/New" },
    "3": { bgColor: "Base/accentSecondary", badgeColor: "Other/Info" },
  }[variant]
  return (
    <Flex
      borderRadius="brand20"
      padding={3}
      gap={3}
      bgColor={bgColor}
      w="full"
      color="Base/basePrimary"
      {...props}
    >
      <VFlex gap="10px" flexGrow={1} alignItems="center">
        <VFlex alignItems="center" justify="flex-end">
          <Box textStyle="Subtitle/Hint" color="Base/neutralPrimary">
            Applied limited discount
          </Box>
          <Badge bgColor={badgeColor}>
            <BadgeContent discount={discount} previousDiscount={previousDiscount} />
          </Badge>
        </VFlex>
        <RoundVFlex w="full" h="full">
          <DiscountCodeContent code={code} previousCode={previousCode} />
        </RoundVFlex>
      </VFlex>

      <VFlex gap="10px" alignItems="center">
        <Box textStyle="Subtitle/Hint" color="Base/neutralPrimary">
          Applicable for
        </Box>
        <Flex gap={1}>
          <RoundVFlex padding={2} paddingTop={3} gap={0.5}>
            <Box textStyle="Header/MonospacedSecondary" fontFamily="heading">
              <Timer format="mm" />
            </Box>
            <Box textStyle="Paragraph/Tertiary">min</Box>
          </RoundVFlex>
          <RoundVFlex padding={2} paddingTop={3} gap={0.5}>
            <Box textStyle="Header/MonospacedSecondary" fontFamily="heading">
              <Timer format="ss" />
            </Box>
            <Box textStyle="Paragraph/Tertiary">sec</Box>
          </RoundVFlex>
        </Flex>
      </VFlex>
    </Flex>
  )
}
