/* eslint-disable */
import { ActionOpenProductScanner, ActionType } from "./actions";
import { FaceCameraScreen } from "./face_camera";
import { VideoPlayerWithChapters } from "./face_yoga";
import { IoSPaywallScreen, OneSubscriptionPaywallScreen } from "./ios_paywall";
import { LoadingScreen } from "./loading";
import { ParameterValues, Request, Response } from "./networking";
import { SkinIssues, skinIssuesFromJSON, skinIssuesToJSON } from "./paywall";
import { PersonalizationScreen } from "./personalization";
import { AttributedString, FilledButton, Image, IOSTextField } from "./presentation";
import { ProductsListScreen, ProductsSearchScreen } from "./products";
import { ScanReportScreen } from "./scan_report";
import { SkinIDScreen, SummaryListItem, WebScanSkinIDScreen } from "./skin_id";
import { VideoInstructionScreen } from "./video_instruction";

export const protobufPackage = "interview_service";

export enum QuestionScreenLayout {
  SCREEN_LAYOUT_NONE = "SCREEN_LAYOUT_NONE",
  ONE_COLUMN = "ONE_COLUMN",
  TWO_COLUMNS = "TWO_COLUMNS",
  ONE_COLUMN_IMAGES = "ONE_COLUMN_IMAGES",
  TWO_COLUMNS_IMAGES = "TWO_COLUMNS_IMAGES",
  TWO_COLUMNS_IMAGES_TRANSPARENT = "TWO_COLUMNS_IMAGES_TRANSPARENT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function questionScreenLayoutFromJSON(object: any): QuestionScreenLayout {
  switch (object) {
    case 0:
    case "SCREEN_LAYOUT_NONE":
      return QuestionScreenLayout.SCREEN_LAYOUT_NONE;
    case 1:
    case "ONE_COLUMN":
      return QuestionScreenLayout.ONE_COLUMN;
    case 2:
    case "TWO_COLUMNS":
      return QuestionScreenLayout.TWO_COLUMNS;
    case 3:
    case "ONE_COLUMN_IMAGES":
      return QuestionScreenLayout.ONE_COLUMN_IMAGES;
    case 4:
    case "TWO_COLUMNS_IMAGES":
      return QuestionScreenLayout.TWO_COLUMNS_IMAGES;
    case 5:
    case "TWO_COLUMNS_IMAGES_TRANSPARENT":
      return QuestionScreenLayout.TWO_COLUMNS_IMAGES_TRANSPARENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QuestionScreenLayout.UNRECOGNIZED;
  }
}

export function questionScreenLayoutToJSON(object: QuestionScreenLayout): string {
  switch (object) {
    case QuestionScreenLayout.SCREEN_LAYOUT_NONE:
      return "SCREEN_LAYOUT_NONE";
    case QuestionScreenLayout.ONE_COLUMN:
      return "ONE_COLUMN";
    case QuestionScreenLayout.TWO_COLUMNS:
      return "TWO_COLUMNS";
    case QuestionScreenLayout.ONE_COLUMN_IMAGES:
      return "ONE_COLUMN_IMAGES";
    case QuestionScreenLayout.TWO_COLUMNS_IMAGES:
      return "TWO_COLUMNS_IMAGES";
    case QuestionScreenLayout.TWO_COLUMNS_IMAGES_TRANSPARENT:
      return "TWO_COLUMNS_IMAGES_TRANSPARENT";
    case QuestionScreenLayout.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SubscriptionStatus {
  UNKNOWN = "UNKNOWN",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function subscriptionStatusFromJSON(object: any): SubscriptionStatus {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return SubscriptionStatus.UNKNOWN;
    case 1:
    case "ACTIVE":
      return SubscriptionStatus.ACTIVE;
    case 2:
    case "INACTIVE":
      return SubscriptionStatus.INACTIVE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SubscriptionStatus.UNRECOGNIZED;
  }
}

export function subscriptionStatusToJSON(object: SubscriptionStatus): string {
  switch (object) {
    case SubscriptionStatus.UNKNOWN:
      return "UNKNOWN";
    case SubscriptionStatus.ACTIVE:
      return "ACTIVE";
    case SubscriptionStatus.INACTIVE:
      return "INACTIVE";
    case SubscriptionStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WellnessProfileScreen {
  level: WellnessProfileScreen_Level;
  properties: { [key: string]: string };
  age_image: WellnessProfileScreen_AgeImage | undefined;
}

export enum WellnessProfileScreen_Level {
  UNKNOWN = "UNKNOWN",
  LOW = "LOW",
  MODERATE = "MODERATE",
  GOOD = "GOOD",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function wellnessProfileScreen_LevelFromJSON(object: any): WellnessProfileScreen_Level {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return WellnessProfileScreen_Level.UNKNOWN;
    case 1:
    case "LOW":
      return WellnessProfileScreen_Level.LOW;
    case 2:
    case "MODERATE":
      return WellnessProfileScreen_Level.MODERATE;
    case 3:
    case "GOOD":
      return WellnessProfileScreen_Level.GOOD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WellnessProfileScreen_Level.UNRECOGNIZED;
  }
}

export function wellnessProfileScreen_LevelToJSON(object: WellnessProfileScreen_Level): string {
  switch (object) {
    case WellnessProfileScreen_Level.UNKNOWN:
      return "UNKNOWN";
    case WellnessProfileScreen_Level.LOW:
      return "LOW";
    case WellnessProfileScreen_Level.MODERATE:
      return "MODERATE";
    case WellnessProfileScreen_Level.GOOD:
      return "GOOD";
    case WellnessProfileScreen_Level.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface WellnessProfileScreen_PropertiesEntry {
  key: string;
  value: string;
}

export interface WellnessProfileScreen_AgeImage {
  image: Image | undefined;
  title: string;
}

export interface QuestionV2 {
  parameters: QuestionScreenParameters | undefined;
  question_screen?:
    | { $case: "date_screen"; date_screen: DateQuestionScreen }
    | { $case: "time_screen"; time_screen: TimeQuestionScreen }
    | { $case: "privacy_screen"; privacy_screen: PrivacyQuestionScreen }
    | { $case: "message_screen"; message_screen: MessageQuestionScreen }
    | { $case: "int_value_screen"; int_value_screen: IntValueQuestionScreen }
    | { $case: "single_variant_screen"; single_variant_screen: SingleVariantQuestionScreen }
    | { $case: "multiple_variants_screen"; multiple_variants_screen: MultipleVariantsQuestionScreen }
    | { $case: "scanning_instruction_screen"; scanning_instruction_screen: ScanningInstructionQuestionScreen }
    | { $case: "welcome_main_screen"; welcome_main_screen: WelcomeMainQuestionScreen }
    | { $case: "welcome_deeplink_screen"; welcome_deeplink_screen: WelcomeDeeplinkQuestionScreen }
    | { $case: "scan_screen"; scan_screen: ScanQuestionScreen }
    | { $case: "string_screen"; string_screen: StringValueQuestionScreen }
    | { $case: "summary_screen"; summary_screen: SummaryScreen }
    | { $case: "products_screen"; products_screen: ProductsScreen }
    | { $case: "buy_products_screen"; buy_products_screen: BuyProductsScreen }
    | { $case: "card_messages_loader_screen"; card_messages_loader_screen: CardMessagesLoaderScreen }
    | { $case: "titles_loader_screen"; titles_loader_screen: TitlesLoaderScreen }
    | { $case: "progress_bars_screen"; progress_bars_screen: ProgressBarsScreen }
    | { $case: "scan_report_screen"; scan_report_screen: ScanReportScreen }
    | { $case: "welcome_ios_screen"; welcome_ios_screen: WelcomeIosScreen }
    | { $case: "email_screen"; email_screen: EmailScreen }
    | { $case: "feedback_screen"; feedback_screen: FeedbackScreen }
    | { $case: "quote_screen"; quote_screen: MessageQuoteScreen }
    | { $case: "face_area_select_screen"; face_area_select_screen: FaceAreaSelectQuestionScreen }
    | { $case: "ios_paywall_screen"; ios_paywall_screen: IoSPaywallScreen }
    | { $case: "message_with_background_screen"; message_with_background_screen: MessageWithBackgroundScreen }
    | { $case: "product_list_screen"; product_list_screen: ProductsListScreen }
    | { $case: "products_search_screen"; products_search_screen: ProductsSearchScreen }
    | {
      $case: "push_notifications_permission_screen";
      push_notifications_permission_screen: PushNotificationsPermissionsScreen;
    }
    | { $case: "face_camera_screen"; face_camera_screen: FaceCameraScreen }
    | { $case: "skin_id_screen"; skin_id_screen: SkinIDScreen }
    | { $case: "message_list_screen"; message_list_screen: MessageListScreen }
    | { $case: "video_player_with_chapters"; video_player_with_chapters: VideoPlayerWithChapters }
    | { $case: "video_instruction"; video_instruction: VideoInstructionScreen }
    | { $case: "slide_loader_screen"; slide_loader_screen: SlideLoaderScreen }
    | { $case: "pre_paywall_summary_screen"; pre_paywall_summary_screen: PrePaywallSummaryScreen }
    | { $case: "product_fit_screen"; product_fit_screen: ProductFitScreen }
    | { $case: "product_fit_result_screen"; product_fit_result_screen: ProductFitResultScreen }
    | { $case: "discrete_slider_screen"; discrete_slider_screen: DiscreteSliderScreen }
    | { $case: "welcome_loading_screen"; welcome_loading_screen: WelcomeLoadingScreen }
    | { $case: "graph_page_gt_screen"; graph_page_gt_screen: GraphPageGTScreen }
    | { $case: "graph_page_gt_new_date_screen"; graph_page_gt_new_date_screen: GraphPageGTNewDateScreen }
    | { $case: "pre_paywall_message_screen"; pre_paywall_message_screen: PrePaywallMessageScreen }
    | { $case: "num_range_input_screen"; num_range_input_screen: NumRangeInputScreen }
    | { $case: "review_screen"; review_screen: ReviewScreen }
    | { $case: "welcome_select_screen"; welcome_select_screen: WelcomeSelectScreen }
    | { $case: "product_fit_2_screen"; product_fit_2_screen: ProductFit2Screen }
    | { $case: "static_about_pora_program_screen"; static_about_pora_program_screen: StaticScreen }
    | { $case: "static_cosmetologist_vs_pora_screen"; static_cosmetologist_vs_pora_screen: StaticScreen }
    | { $case: "loading_screen"; loading_screen: LoadingScreen }
    | { $case: "one_subscription_paywall_screen"; one_subscription_paywall_screen: OneSubscriptionPaywallScreen }
    | { $case: "welcome_slider_screen"; welcome_slider_screen: WelcomeSliderScreen }
    | { $case: "progress_bars_with_reviews_screen"; progress_bars_with_reviews_screen: ProgressBarsScreen }
    | { $case: "wellness_profile_screen"; wellness_profile_screen: WellnessProfileScreen }
    | { $case: "product_fit_3_screen"; product_fit_3_screen: ProductFit3Screen }
    | { $case: "static_self_care_vs_pora_screen"; static_self_care_vs_pora_screen: StaticScreen }
    | { $case: "subscription_status_screen"; subscription_status_screen: SubscriptionStatusScreen }
    | { $case: "personalization_screen"; personalization_screen: PersonalizationScreen }
    | { $case: "message_with_answers_screen"; message_with_answers_screen: MessageWithAnswersScreen }
    | { $case: "sub_offer_screen"; sub_offer_screen: SubOfferScreen }
    | { $case: "if_you_cancel_screen"; if_you_cancel_screen: IfYouCancelScreen }
    | { $case: "message_with_image_screen"; message_with_image_screen: MessageWithImageScreen }
    | {
      $case: "message_with_image_and_progressbar_screen";
      message_with_image_and_progressbar_screen: MessageWithImageScreen;
    }
    | { $case: "welcome_select_2_screen"; welcome_select_2_screen: WelcomeSelectScreen }
    | { $case: "scan_results_screen"; scan_results_screen: ScanResultScreen }
    | { $case: "static_esthetician_vs_lovi"; static_esthetician_vs_lovi: StaticScreen }
    | { $case: "product_scanner"; product_scanner: ActionOpenProductScanner }
    | { $case: "static_what_is_face_yoga"; static_what_is_face_yoga: StaticScreen }
    | { $case: "static_trust_doctors"; static_trust_doctors: StaticScreen }
    | { $case: "static_cosmetologist_vs_lovi_screen"; static_cosmetologist_vs_lovi_screen: StaticScreen }
    | { $case: "progress_graph_screen"; progress_graph_screen: ProgressGraphScreen }
    | { $case: "progress_weeks_screen"; progress_weeks_screen: ProgressWeeksScreen }
    | { $case: "progress_bars_with_questions_screen"; progress_bars_with_questions_screen: ProgressBarsScreen }
    | { $case: "static_science_research"; static_science_research: StaticScreen }
    | { $case: "welcome_select_luvly_screen"; welcome_select_luvly_screen: WelcomeSelectScreen }
    | { $case: "static_about_program_2"; static_about_program_2: StaticScreen }
    | { $case: "welcome_select_3_screen"; welcome_select_3_screen: WelcomeSelectScreen }
    | { $case: "product_fit_result_2_screen"; product_fit_result_2_screen: ProductFitResultScreen }
    | { $case: "about_program_screen"; about_program_screen: AboutProgramScreen }
    | { $case: "welcome_select_4_screen"; welcome_select_4_screen: WelcomeSelectScreen }
    | { $case: "web_scan_skin_id_screen"; web_scan_skin_id_screen: WebScanSkinIDScreen }
    | { $case: "web_features_screen"; web_features_screen: WebFeaturesScreen }
    | { $case: "scan_2_screen"; scan_2_screen: ScanQuestionScreen }
    | { $case: "welcome_select_5_screen"; welcome_select_5_screen: WelcomeSelectScreen }
    | {
      $case: "wellness_profile_with_facescan_result_screen";
      wellness_profile_with_facescan_result_screen: WellnessProfileScreen;
    }
    | { $case: "scan_3_screen"; scan_3_screen: ScanQuestionScreen };
}

export interface IfYouCancelScreen {
  title: AttributedString | undefined;
  variants: string[];
  image: Image | undefined;
}

export interface SubOfferScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  rows: SubOfferScreen_Row[];
  help_texts: string[];
  primary_subtitle: string;
  variants: string[];
}

export interface SubOfferScreen_InfoBlock {
  title: string;
  value: string;
  primary: boolean;
}

export interface SubOfferScreen_Divider {
}

export interface SubOfferScreen_Row {
  row?: { $case: "description"; description: SubOfferScreen_InfoBlock } | {
    $case: "divider";
    divider: SubOfferScreen_Divider;
  };
}

export interface MessageWithAnswersScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: Image | undefined;
  variants: string[];
}

export interface SubscriptionStatusInfo {
  subscription_status: SubscriptionStatus;
  action_data:
    | ActionData
    | undefined;
  /** RFC 3339 */
  crated_at: string;
  /** RFC 3339 */
  next_charge_at: string;
  /** ISO 4217 */
  currency_code: string;
  subscription_price_per_unit_cents: number;
}

export interface SubscriptionsInfo {
  subscriptions: SubscriptionStatusInfo[];
}

export interface SubscriptionStatusScreen {
  title: AttributedString | undefined;
  description:
    | AttributedString
    | undefined;
  /** @deprecated */
  subscription_status: SubscriptionStatus;
  subscriptions_list: SubscriptionStatusInfo[];
  email: string;
}

export interface AnalyticsEventParameters {
  analytics_id: string;
  targets: AnalyticsEventParameters_Target[];
  parameters: { [key: string]: ParameterValues };
}

export enum AnalyticsEventParameters_Target {
  PRODUCT = "PRODUCT",
  MARKETING = "MARKETING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function analyticsEventParameters_TargetFromJSON(object: any): AnalyticsEventParameters_Target {
  switch (object) {
    case 0:
    case "PRODUCT":
      return AnalyticsEventParameters_Target.PRODUCT;
    case 1:
    case "MARKETING":
      return AnalyticsEventParameters_Target.MARKETING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AnalyticsEventParameters_Target.UNRECOGNIZED;
  }
}

export function analyticsEventParameters_TargetToJSON(object: AnalyticsEventParameters_Target): string {
  switch (object) {
    case AnalyticsEventParameters_Target.PRODUCT:
      return "PRODUCT";
    case AnalyticsEventParameters_Target.MARKETING:
      return "MARKETING";
    case AnalyticsEventParameters_Target.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface AnalyticsEventParameters_ParametersEntry {
  key: string;
  value: ParameterValues | undefined;
}

export interface QuestionScreenParameters {
  id: string;
  page_num: number;
  design_id: string;
  show_progress: boolean;
  onboarding_id: string;
  background_id: string;
  custom_string: string;
  button_text: string;
  section_name: string;
  hide_answers: boolean;
  analytics_event_parameters: AnalyticsEventParameters | undefined;
  progress_bar_theme: string;
  always_hides_nav_bar: boolean;
  progress_indicator: QuestionScreenParameters_ProgressIndicator | undefined;
  type: string;
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
  answers: string[];
  description_2: AttributedString | undefined;
  view_variant: string;
  should_make_screenshot: boolean;
}

export interface QuestionScreenParameters_ProgressIndicator {
  sections_count: number;
  active_section_index: number;
  pages_in_active_section_count: number;
  active_page_num: number;
  active_section_progress: number;
}

export interface DateQuestionScreen {
  title: string;
  description: string;
}

export interface TimeQuestionScreen {
  title: string;
  description: string;
}

export interface IntValueQuestionScreen {
  title: string;
  description: string;
  min: number;
  max: number;
}

export interface StringValueQuestionScreen {
  title: string;
  description: string;
  text_field: IOSTextField | undefined;
}

export interface PrivacyQuestionScreen {
  privacy_url: string;
  terms_url: string;
}

export interface MessageQuestionScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  description: string;
  image: string;
  more_link: LinkData | undefined;
  image_width: number;
  image_height: number;
  video: string;
  buttons_with_linked_quiz_ids: MessageQuestionScreen_ButtonWithLinkedQuizID[];
}

export interface MessageQuestionScreen_ButtonWithLinkedQuizID {
  button: FilledButton | undefined;
  quiz_id: string;
}

export interface MessageQuoteScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  description: string;
  image: string;
  more_link: LinkData | undefined;
  image_width: number;
  image_height: number;
  author: string;
}

export interface MessageWithBackgroundScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  description: string;
  attributed_description: AttributedString | undefined;
  background_image: string;
  more_link: LinkData | undefined;
}

export interface LoaderOptions {
  DisplaySecondsPerScreen: number;
}

export interface CardMessagesLoaderScreen {
  loader_options: LoaderOptions | undefined;
  messages: string[];
  process_title: string;
}

export interface TitlesLoaderScreen {
  loader_options: LoaderOptions | undefined;
  messages: string[];
  final_message: string;
}

export interface SingleVariantQuestionScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  up_title: AttributedString | undefined;
  description: string;
  variants: string[];
  variant_properties: { [key: string]: VariantProperties };
  image: string;
  layout: QuestionScreenLayout;
  preselected_variant: string;
}

export interface SingleVariantQuestionScreen_VariantPropertiesEntry {
  key: string;
  value: VariantProperties | undefined;
}

export interface VariantProperties {
  deselect_other_variants: boolean;
  ask_push_permissions: boolean;
  image_link: string;
  description: string;
  text: string;
  attributed_description: AttributedString | undefined;
  select_all_variants: boolean;
}

export interface MultipleVariantsQuestionScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  description: string;
  variants: string[];
  variant_properties: { [key: string]: VariantProperties };
  image: string;
  layout: QuestionScreenLayout;
  preselected_variants: string[];
}

export interface MultipleVariantsQuestionScreen_VariantPropertiesEntry {
  key: string;
  value: VariantProperties | undefined;
}

export interface FaceAreaSelectQuestionScreen {
  title: AttributedString | undefined;
  description: string;
  variant: FaceAreaSelectQuestionScreen_FaceAreaSelectVariant;
}

export enum FaceAreaSelectQuestionScreen_FaceAreaSelectVariant {
  FACEAREA_SELECT_VARIANT_UNSPECIFIED = "FACEAREA_SELECT_VARIANT_UNSPECIFIED",
  FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS = "FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS",
  FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES = "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES",
  FACEAREA_SELECT_VARIANT_MALE_SWELLNESS = "FACEAREA_SELECT_VARIANT_MALE_SWELLNESS",
  FACEAREA_SELECT_VARIANT_MALE_WRINKLES = "FACEAREA_SELECT_VARIANT_MALE_WRINKLES",
  FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2 = "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2",
  FACEAREA_SELECT_VARIANT_FEMALE_REDNESS = "FACEAREA_SELECT_VARIANT_FEMALE_REDNESS",
  FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION = "FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function faceAreaSelectQuestionScreen_FaceAreaSelectVariantFromJSON(
  object: any,
): FaceAreaSelectQuestionScreen_FaceAreaSelectVariant {
  switch (object) {
    case 0:
    case "FACEAREA_SELECT_VARIANT_UNSPECIFIED":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_UNSPECIFIED;
    case 1:
    case "FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS;
    case 2:
    case "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES;
    case 3:
    case "FACEAREA_SELECT_VARIANT_MALE_SWELLNESS":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_SWELLNESS;
    case 4:
    case "FACEAREA_SELECT_VARIANT_MALE_WRINKLES":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_WRINKLES;
    case 5:
    case "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2;
    case 6:
    case "FACEAREA_SELECT_VARIANT_FEMALE_REDNESS":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_REDNESS;
    case 7:
    case "FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION":
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.UNRECOGNIZED;
  }
}

export function faceAreaSelectQuestionScreen_FaceAreaSelectVariantToJSON(
  object: FaceAreaSelectQuestionScreen_FaceAreaSelectVariant,
): string {
  switch (object) {
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_UNSPECIFIED:
      return "FACEAREA_SELECT_VARIANT_UNSPECIFIED";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS:
      return "FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES:
      return "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_SWELLNESS:
      return "FACEAREA_SELECT_VARIANT_MALE_SWELLNESS";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_WRINKLES:
      return "FACEAREA_SELECT_VARIANT_MALE_WRINKLES";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2:
      return "FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_REDNESS:
      return "FACEAREA_SELECT_VARIANT_FEMALE_REDNESS";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION:
      return "FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION";
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ScanningInstructionQuestionScreen {
  title: string;
  description: string;
}

export interface WelcomeMainQuestionScreen {
  title: string;
  description: string;
  custom_string: string;
  variants: string[];
  image: string;
}

export interface WelcomeDeeplinkQuestionScreen {
  title: string;
  description: string;
  privacy_url: string;
  terms_url: string;
}

export interface EmailScreen {
  title: string;
  description: string;
  additional_info: string;
  links: LinkData[];
}

export interface ProgressScreenSingleSelectQuestion {
  title: string;
  variants: string[];
  progress_completion_trigger_percent: number;
}

export interface ProgressBarsScreenQuestion {
  id: string;
  question?: { $case: "single_select"; single_select: ProgressScreenSingleSelectQuestion };
}

export interface ProgressBar {
  title: string;
  completion_title: string;
  seconds_to_complete: number;
  questions: ProgressBarsScreenQuestion[];
}

export interface ProgressBarsScreen {
  title: string;
  attributed_title: AttributedString | undefined;
  progress_bars: ProgressBar[];
}

export interface ScanQuestionScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
  variants: VariantProperties[];
}

export interface ScanResultScreen {
  last_face_scan_report_id: string;
  issues: SkinIssues[];
}

export interface PushNotificationsPermissionsScreen {
  title: AttributedString | undefined;
  subtitle: string;
  /** This field can be empty. In that case the default image is used */
  image: string;
  allow_button_text: string;
  skip_button_text: string;
}

export interface WebFeaturesScreen {
  title: AttributedString | undefined;
  cards: WebFeaturesScreen_Card[];
  footer_button_title: string;
}

export interface WebFeaturesScreen_Card {
  card_number: AttributedString | undefined;
  title: AttributedString | undefined;
  image: string;
}

export interface LinkData {
  title: string;
  url: string;
}

export interface QuizActionRequestNewTest {
  test_id: string;
  interview_id: string;
}

export interface QuizActionRequestNextQuizWithClientProvidedID {
}

export interface QuizActionSignIn {
  next_quiz_id: string;
}

export interface QuizActionNothing {
}

export interface QuizActionSetOnboardingCompleted {
}

export interface QuizActionSignInAndSetOnboardingCompleted {
}

export interface QuizActionClosePaywallWithIncompletion {
}

export interface QuizActionGenerateUserID {
  /** Optional. If not set, the interview will be finished. */
  next_quiz_id: string;
}

export interface QuizActionPullPostEndpoint {
  endpoint: string;
  params: { [key: string]: string };
}

export interface QuizActionPullPostEndpoint_ParamsEntry {
  key: string;
  value: string;
}

export interface QuizActionPullGetEndpoint {
  endpoint: string;
  params: { [key: string]: string };
}

export interface QuizActionPullGetEndpoint_ParamsEntry {
  key: string;
  value: string;
}

export interface QuizActionRedirect {
  endpoint: string;
  params: { [key: string]: string };
}

export interface QuizActionRedirect_ParamsEntry {
  key: string;
  value: string;
}

export interface QuizInfo {
  type: string;
  count: number;
}

export interface Quiz {
  id: string;
  skippable: boolean;
  questions_v2: QuestionV2[];
  on_completion_action?:
    | { $case: "new_test_request"; new_test_request: QuizActionRequestNewTest }
    | { $case: "set_onboarding_completed"; set_onboarding_completed: QuizActionSetOnboardingCompleted }
    | { $case: "nothing"; nothing: QuizActionNothing }
    | { $case: "pull_post_endpoint"; pull_post_endpoint: QuizActionPullPostEndpoint }
    | { $case: "pull_get_endpoint"; pull_get_endpoint: QuizActionPullGetEndpoint }
    | { $case: "redirect"; redirect: QuizActionRedirect }
    | { $case: "sign_in"; sign_in: QuizActionSignIn }
    | {
      $case: "sign_in_and_set_onboarding_completed";
      sign_in_and_set_onboarding_completed: QuizActionSignInAndSetOnboardingCompleted;
    }
    | { $case: "generate_user_id"; generate_user_id: QuizActionGenerateUserID }
    | {
      $case: "next_quiz_with_client_provided_id";
      next_quiz_with_client_provided_id: QuizActionRequestNextQuizWithClientProvidedID;
    }
    | {
      $case: "close_paywall_with_incompletion";
      close_paywall_with_incompletion: QuizActionClosePaywallWithIncompletion;
    };
  progress_bar_data: QuizInfo[];
  section_name: string;
  prefetch_images: string[];
  post_completion_action: ActionType | undefined;
}

export interface ActionData {
  id: string;
  title: string;
  profile_field: string;
  profile_field_value: string;
}

export interface Answer {
  question_id: string;
  values: string[];
  values2: ActionData[];
}

export interface InterviewServiceSaveAnswersRequest {
  request: Request | undefined;
  test_id: string;
  next_quiz_id: string;
  answers: Answer[];
  utm_source: string;
  interview_id: string;
  smp_link: string;
  product_page_report_screenshot: Image | undefined;
}

export interface InterviewServiceSaveAnswersResponse {
  response: Response | undefined;
  quiz: Quiz | undefined;
}

export interface InterviewServiceGetQuizRequest {
  request: Request | undefined;
  quiz_id: string;
  utm_source: string;
  interview_id: string;
  smp_link: string;
}

export interface InterviewServiceGetQuizResponse {
  response: Response | undefined;
  quiz: Quiz | undefined;
}

export interface InterviewServiceSetPlanIDRequest {
  request: Request | undefined;
}

export interface InterviewServiceSetPlanIDResponse {
  response: Response | undefined;
  plan_id: string;
  user_id: string;
}

export interface PushRegisterRequest {
  request: Request | undefined;
  firebase_token: string;
  device_id: string;
}

export interface PaywallRequest {
  request: Request | undefined;
  interview_variant: string;
}

export interface UpdateUserIDRequest {
  request: Request | undefined;
  oldUserId: string;
}

export interface Table {
  id: string;
  fields: string[];
}

export interface PaymentStatusContent {
  payment_in_progress_title: string;
  payment_in_progress_image_tag: string;
  payment_in_progress_thanks_text: string;
  payment_in_progress_email_prefix_text: string;
  payment_success_title: string;
  payment_success_image_tag: string;
  payment_success_text: string;
  oops_title: string;
  oops_image_tag: string;
  oops_text: string;
}

export interface ComplianceURLsData {
  privacy_policy_title: string;
  privacy_policy_url: string;
  terms_and_conditions_title: string;
  terms_and_conditions_url: string;
}

export interface ProgressPageData {
  title: string;
  subtitle: string;
  status_messages: string[];
  analysis_duration_seconds: number;
  success_redirect_url: string;
  error_redirect_url: string;
}

/** Deprecated */
export interface WebInterviewStyleConfig {
  color_icon_primary: string;
  color_text_primary: string;
  color_text_inverted: string;
  border_radius_button: string;
  color_action_primary: string;
  color_text_secondary: string;
  color_action_secondary: string;
  color_border_secondary: string;
  border_radius_progress_bar: string;
  color_background_secondary: string;
  color_action_secondary_pressed: string;
  color_action_secondary_disabled: string;
}

export interface WebInterviewEndpoints {
  get_user_id: string;
  get_quiz: string;
  save_answers: string;
  validate_images: string;
  visual_analysis: string;
  progress_page_data: string;
  update_user_id: string;
  log: string;
}

export interface WebInterviewConfig {
  /** @deprecated */
  style: WebInterviewStyleConfig | undefined;
  endpoints: WebInterviewEndpoints | undefined;
  lovi_redirect_url: string;
}

export interface FeedbackAirtableConfig {
  base_id: string;
  popups_table_id: string;
  answers_table_id: string;
  actions_table_id: string;
  quiz_screens_table_id: string;
  quiz_answers_table_id: string;
  appreciations_table_id: string;
}

export interface IoSWelcomeScreenConfig {
  base_name: string;
  subscreens_table_name: string;
  screens_table_name: string;
}

export interface OnboardingConfig {
  airtable_base_id: string;
  questions_table_id: string;
  answers_table_id: string;
  answer_points_table_id: string;
  interpretation_points_table_id: string;
  interpretation_table_id: string;
  filter_table_id: string;
  skin_conditions_table_id: string;
  progress_bars_screen_table_id: string;
  paywall_table_id: string;
  client: string;
  bayes_fraction: number;
  price_point_bayes_fraction: number;
  quiz_bayes_fraction: number;
  paywall_blocks_bayes_fraction: number;
  payment_form_bayes_fraction: number;
  interview_rituals: string;
  interview_ritual_videos: string;
  message_list_screen_table_id: string;
  paywall_variants_table_id: string;
  paywall_prices_table_id: string;
  slide_loader_screen_table_id: string;
  video_instructions_table_id: string;
  video_instruction_items_table_id: string;
  ios_paywall_table_id: string;
  ios_paywall_subscriptions_table_id: string;
  ios_paywall_header_items_table_id: string;
  interview_variants_table_id: string;
  interview_variants_questions_table_id: string;
  ios_welcome_screens_config: IoSWelcomeScreenConfig | undefined;
  app_id: string;
  paywall_properties_table_id: string;
}

export interface InterviewServiceConfig {
  web_onboarding_config: OnboardingConfig | undefined;
  lovi_ios_onboarding_config: OnboardingConfig | undefined;
  environment: string;
  first_quiz_id: string;
  airtable_base_id: string;
  airtable_intro_base_id: string;
  intro_table_id: string;
  screens_table_id: string;
  jars_base_id: string;
  jars_table_id: string;
  jars_applying_table_id: string;
  daily_quiz_questions_table_id: string;
  daily_quiz_answers_table_id: string;
  payment_status_content_table_id: string;
  web_interview_style_config_table_id: string;
  emails_airtable_base_id: string;
  emails_table_id: string;
  rename_features_table_id: string;
  content_base_id: string;
  journey_table_id: string;
  personalized_content_table_id: string;
  rituals_table_id: string;
  stories_table_id: string;
  chapters_table_id: string;
  base_api_url: string;
  auth0_url: string;
  auth0_client_id: string;
  auth0_client_secret: string;
  feedback_config: FeedbackAirtableConfig | undefined;
  progress_page_data: ProgressPageData | undefined;
  compliance_urls_data: ComplianceURLsData | undefined;
  fault_rate: number;
  visualization_colors_table: string;
  web_interview_endpoints: WebInterviewEndpoints | undefined;
  lovi_redirect_url: string;
  subscription_check_url: string;
  product_page_tutorials_table_id: string;
  handpicked_jars_base_id: string;
  handpicked_jars_products_table_id: string;
  experts_review_id: string;
  didnt_find_my_product_table_id: string;
  banners_table_id: string;
  subscription_redirect_url: string;
  paltaPaymentsSubscriptionUrl: string;
  paltaPaymentsCheckFreePeriodUsageUrl: string;
  paltaPaymentsApplyFreePeriodUsageUrl: string;
  paltaPaymentsCancelSubscriptionUrl: string;
  app_paywall_table_id: string;
  paltaPaymentsSubscriptionListUrl: string;
}

export interface BuyProduct {
  product: Product | undefined;
  popup: PopupWithLinks | undefined;
}

export interface PopupWithLinks {
  title: string;
  links: LinkData[];
}

export interface Product {
  image: string;
  steps: string[];
  title: string;
  sub_title: string;
  tags: string[];
}

export interface Point {
  title: string;
  values: string[];
}

export interface SummaryScreen {
  title: string;
  sub_title: string;
  points: Point[];
}

export interface PrePaywallSummaryScreen {
  title: AttributedString | undefined;
  summary_list: SummaryListItem[];
  graph_img: string;
  graph_title: AttributedString | undefined;
  button_text: string;
}

export interface ProductFitScreen {
  product_images: string[];
  title: AttributedString | undefined;
  view_variant: ProductFitScreen_ViewVariant;
}

export enum ProductFitScreen_ViewVariant {
  unknown = "unknown",
  bad = "bad",
  mild = "mild",
  green = "green",
  success = "success",
  top = "top",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productFitScreen_ViewVariantFromJSON(object: any): ProductFitScreen_ViewVariant {
  switch (object) {
    case 0:
    case "unknown":
      return ProductFitScreen_ViewVariant.unknown;
    case 1:
    case "bad":
      return ProductFitScreen_ViewVariant.bad;
    case 2:
    case "mild":
      return ProductFitScreen_ViewVariant.mild;
    case 3:
    case "green":
      return ProductFitScreen_ViewVariant.green;
    case 4:
    case "success":
      return ProductFitScreen_ViewVariant.success;
    case 5:
    case "top":
      return ProductFitScreen_ViewVariant.top;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductFitScreen_ViewVariant.UNRECOGNIZED;
  }
}

export function productFitScreen_ViewVariantToJSON(object: ProductFitScreen_ViewVariant): string {
  switch (object) {
    case ProductFitScreen_ViewVariant.unknown:
      return "unknown";
    case ProductFitScreen_ViewVariant.bad:
      return "bad";
    case ProductFitScreen_ViewVariant.mild:
      return "mild";
    case ProductFitScreen_ViewVariant.green:
      return "green";
    case ProductFitScreen_ViewVariant.success:
      return "success";
    case ProductFitScreen_ViewVariant.top:
      return "top";
    case ProductFitScreen_ViewVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductFit2Screen {
  product_images: string[];
  title: AttributedString | undefined;
  view_variant: ProductFit2Screen_ViewVariant;
  description: AttributedString | undefined;
  description2: AttributedString | undefined;
}

export enum ProductFit2Screen_ViewVariant {
  unknown = "unknown",
  mild = "mild",
  success = "success",
  top = "top",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productFit2Screen_ViewVariantFromJSON(object: any): ProductFit2Screen_ViewVariant {
  switch (object) {
    case 0:
    case "unknown":
      return ProductFit2Screen_ViewVariant.unknown;
    case 1:
    case "mild":
      return ProductFit2Screen_ViewVariant.mild;
    case 2:
    case "success":
      return ProductFit2Screen_ViewVariant.success;
    case 3:
    case "top":
      return ProductFit2Screen_ViewVariant.top;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductFit2Screen_ViewVariant.UNRECOGNIZED;
  }
}

export function productFit2Screen_ViewVariantToJSON(object: ProductFit2Screen_ViewVariant): string {
  switch (object) {
    case ProductFit2Screen_ViewVariant.unknown:
      return "unknown";
    case ProductFit2Screen_ViewVariant.mild:
      return "mild";
    case ProductFit2Screen_ViewVariant.success:
      return "success";
    case ProductFit2Screen_ViewVariant.top:
      return "top";
    case ProductFit2Screen_ViewVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductFit3Screen {
  product_images: string[];
  title: AttributedString | undefined;
  view_variant: ProductFit3Screen_ViewVariant;
  description: AttributedString | undefined;
  description2: AttributedString | undefined;
}

export enum ProductFit3Screen_ViewVariant {
  unknown = "unknown",
  bad = "bad",
  mild = "mild",
  success = "success",
  success2 = "success2",
  top = "top",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productFit3Screen_ViewVariantFromJSON(object: any): ProductFit3Screen_ViewVariant {
  switch (object) {
    case 0:
    case "unknown":
      return ProductFit3Screen_ViewVariant.unknown;
    case 1:
    case "bad":
      return ProductFit3Screen_ViewVariant.bad;
    case 2:
    case "mild":
      return ProductFit3Screen_ViewVariant.mild;
    case 3:
    case "success":
      return ProductFit3Screen_ViewVariant.success;
    case 4:
    case "success2":
      return ProductFit3Screen_ViewVariant.success2;
    case 5:
    case "top":
      return ProductFit3Screen_ViewVariant.top;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductFit3Screen_ViewVariant.UNRECOGNIZED;
  }
}

export function productFit3Screen_ViewVariantToJSON(object: ProductFit3Screen_ViewVariant): string {
  switch (object) {
    case ProductFit3Screen_ViewVariant.unknown:
      return "unknown";
    case ProductFit3Screen_ViewVariant.bad:
      return "bad";
    case ProductFit3Screen_ViewVariant.mild:
      return "mild";
    case ProductFit3Screen_ViewVariant.success:
      return "success";
    case ProductFit3Screen_ViewVariant.success2:
      return "success2";
    case ProductFit3Screen_ViewVariant.top:
      return "top";
    case ProductFit3Screen_ViewVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductFitResultScreen {
  fit_products: ProductFitResultScreen_ProductFitData | undefined;
  not_fit_products: ProductFitResultScreen_ProductFitData | undefined;
  title: AttributedString | undefined;
}

export interface ProductFitResultScreen_ProductListItem {
  fit_percentage: number;
  description: string;
  image: string;
  category: string;
}

export interface ProductFitResultScreen_ProductFitData {
  total_products: number;
  products_list: ProductFitResultScreen_ProductListItem[];
}

export interface DiscreteSliderScreen {
  title: AttributedString | undefined;
  up_title: AttributedString | undefined;
  answers: string[];
}

export interface MessageWithImageScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
}

export interface WelcomeLoadingScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
}

export interface WelcomeSelectScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
  select_title: AttributedString | undefined;
  variants: VariantProperties[];
}

export interface WelcomeSliderScreen {
  slides: WelcomeSliderScreen_Slide[];
  skippable: boolean;
}

export interface WelcomeSliderScreen_Slide {
  title: AttributedString | undefined;
  image: string;
}

export interface GraphPageGTScreen {
  description: AttributedString | undefined;
  image: string;
}

export interface GraphPageGTNewDateScreen {
  description: AttributedString | undefined;
  image: string;
}

export interface PrePaywallMessageScreen {
  title: AttributedString | undefined;
  image: string;
}

export interface NumRangeInputScreen {
  title: AttributedString | undefined;
  up_title: AttributedString | undefined;
  answers: string[];
}

export interface ReviewScreen {
  title: AttributedString | undefined;
  image: string;
  author: AttributedString | undefined;
  date: AttributedString | undefined;
  text: AttributedString | undefined;
}

export interface ProductsScreen {
  title: string;
  sub_title: string;
  products: Product[];
}

export interface BuyProductsScreen {
  title: string;
  sub_title: string;
  button_text: string;
  buy_products: BuyProduct[];
}

export interface CosmeticJar {
  ages: string[];
  amazon_link: string;
  bottle_post_applying: string[];
  bottle_pre_applying: string[];
  brand: string;
  breastfeeding_safe: boolean;
  description: string;
  inci: string;
  jar_image: string;
  key_ingredients: string[];
  link: string;
  pregnancy_safe: boolean;
  price_range: string;
  sephora_link: string;
  short_description: string;
  skin_conditions_additional: string[];
  skin_conditions_main: string[];
  skin_types: string[];
  steps: string[];
  texture: string[];
  title: string;
  when_to_apply: string;
  id: string;
  quality: number;
  rating: string;
  price: string;
  amazon_rating: string;
  sephora_rating: string;
  amazon_reviews: string;
  sephora_reviews: string;
  amazon_price: string;
  sephora_price: string;
}

export interface SkinCareRitualStep {
  step: string;
  jar: CosmeticJar | undefined;
  quality: number;
  jars: CosmeticJar[];
}

export interface SkinCareRitual {
  when: string;
  steps: SkinCareRitualStep[];
}

export interface ProductSearchStepStatistics {
  property: string;
  count: number;
}

export interface CheckoutProduct {
  title: string;
  brand: string;
  image: string;
  step_number: number;
  step_name: string;
  checkout_link_parameters: string;
  price: number;
  raw_skincare_step: string;
}

export interface CheckoutData {
  products: CheckoutProduct[];
  price: number;
  price_currency: string;
  price_currency_sign: string;
  subscription_term: string;
  checkout_link_prefix: string;
  checkout_link_suffix: string;
}

export interface SkinCareProgramParams {
  primary_skin_issue: string;
  additional_skin_issues: string[];
  sensitivity: string;
  skin_type: string;
  target_price: number;
  target_steps_count: number;
  pregnancy_safe: boolean;
  mask_preference: string;
  moisturizing_treatment: string;
  avoid_drying_ingredients: string;
  keep_eye_treatments: string;
  delivery_region: string;
  target_price_low: number;
  target_price_high: number;
}

export interface SkinCareProgram {
  program_params: SkinCareProgramParams | undefined;
  program_id: string;
  generation_datetime: string;
  program_type: string;
  main_condition: string;
  additional_conditions: string[];
  skin_type: string;
  pregnancy_safety: boolean;
  breastfeeding_safety: boolean;
  price_range: string;
  rituals: SkinCareRitual[];
  jar_links: string[];
  scanning_results_used: boolean;
  ts: number;
  product_search_statistics: ProductSearchStepStatistics[];
  checkout_link: string;
  step_costs: { [key: string]: number };
  step_product_ids: { [key: string]: number };
  checkout_data: CheckoutData | undefined;
  version: number;
  recommender_url: string;
}

export interface SkinCareProgram_StepCostsEntry {
  key: string;
  value: number;
}

export interface SkinCareProgram_StepProductIdsEntry {
  key: string;
  value: number;
}

export interface ConditionsResponse {
  main_condition: string;
  additional_conditions: string[];
  program: SkinCareProgram | undefined;
  program_quality: number;
}

export interface SplitProductsResponse {
  fit_products: CosmeticJar[];
  non_fit_products: CosmeticJar[];
}

export interface Button {
  title: string;
  action: Button_Action | undefined;
}

export interface Button_Action {
  action?: { $case: "nothing"; nothing: QuizActionNothing } | {
    $case: "new_test_request";
    new_test_request: QuizActionRequestNewTest;
  } | { $case: "sign_in"; sign_in: QuizActionSignIn };
}

export interface WelcomeIosScreen {
  sub_screens: WelcomeIosScreen_SubScreen[];
  terms_url: string;
  privacy_url: string;
  primary_button: Button | undefined;
  secondary_button: Button | undefined;
  disabled_logo: boolean;
  disabled_shading_gradient: boolean;
  screens_auto_scroll_delay: number;
}

export interface WelcomeIosScreen_SubScreen {
  title: string;
  sub_title: string;
  button_text: string;
  media: WelcomeIosScreen_SubScreen_Media | undefined;
  primary_button: Button | undefined;
  secondary_button: Button | undefined;
  analytics_event_parameters: AnalyticsEventParameters | undefined;
}

export interface WelcomeIosScreen_SubScreen_Media {
  type: WelcomeIosScreen_SubScreen_Media_Type;
  file_name: string;
}

export enum WelcomeIosScreen_SubScreen_Media_Type {
  NONE = "NONE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function welcomeIosScreen_SubScreen_Media_TypeFromJSON(object: any): WelcomeIosScreen_SubScreen_Media_Type {
  switch (object) {
    case 0:
    case "NONE":
      return WelcomeIosScreen_SubScreen_Media_Type.NONE;
    case 1:
    case "IMAGE":
      return WelcomeIosScreen_SubScreen_Media_Type.IMAGE;
    case 2:
    case "VIDEO":
      return WelcomeIosScreen_SubScreen_Media_Type.VIDEO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return WelcomeIosScreen_SubScreen_Media_Type.UNRECOGNIZED;
  }
}

export function welcomeIosScreen_SubScreen_Media_TypeToJSON(object: WelcomeIosScreen_SubScreen_Media_Type): string {
  switch (object) {
    case WelcomeIosScreen_SubScreen_Media_Type.NONE:
      return "NONE";
    case WelcomeIosScreen_SubScreen_Media_Type.IMAGE:
      return "IMAGE";
    case WelcomeIosScreen_SubScreen_Media_Type.VIDEO:
      return "VIDEO";
    case WelcomeIosScreen_SubScreen_Media_Type.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface MultilineTextField {
  placeholder?: string | undefined;
  default_text?: string | undefined;
  caption?: string | undefined;
}

export interface FeedbackScreen {
  title: string;
  text_field: MultilineTextField | undefined;
  skip_button_text?: string | undefined;
}

export interface SlideLoaderScreen {
  process_title: string;
  slides: SlideLoaderScreen_Slide[];
}

export interface SlideLoaderScreen_Slide {
  title: AttributedString | undefined;
  image: Image | undefined;
  duration_ms: number;
}

export interface MessageListScreen {
  title: AttributedString | undefined;
  items: MessageListScreen_ListItem[];
}

export interface MessageListScreen_Explanation {
  explanation?: { $case: "title"; title: AttributedString } | { $case: "text"; text: AttributedString } | {
    $case: "image";
    image: Image;
  } | { $case: "notice"; notice: MessageListScreen_Explanation_NoticeText };
}

export interface MessageListScreen_Explanation_NoticeText {
  image: Image | undefined;
  text: string;
}

export interface MessageListScreen_ListItem {
  symbol?: MessageListScreen_ListItem_BulletSymbol | undefined;
  title: AttributedString | undefined;
  explanations: MessageListScreen_Explanation[];
  /** @deprecated */
  required_answer_text: string;
  allowed_answers_texts: string[];
}

export enum MessageListScreen_ListItem_BulletSymbol {
  /** BULLET_SYMBOL_UNSPECIFIED - no bullet */
  BULLET_SYMBOL_UNSPECIFIED = "BULLET_SYMBOL_UNSPECIFIED",
  BULLET_SYMBOL_GREEN_MARK = "BULLET_SYMBOL_GREEN_MARK",
  BULLET_SYMBOL_PROCESSING = "BULLET_SYMBOL_PROCESSING",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function messageListScreen_ListItem_BulletSymbolFromJSON(object: any): MessageListScreen_ListItem_BulletSymbol {
  switch (object) {
    case 0:
    case "BULLET_SYMBOL_UNSPECIFIED":
      return MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_UNSPECIFIED;
    case 1:
    case "BULLET_SYMBOL_GREEN_MARK":
      return MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_GREEN_MARK;
    case 2:
    case "BULLET_SYMBOL_PROCESSING":
      return MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_PROCESSING;
    case -1:
    case "UNRECOGNIZED":
    default:
      return MessageListScreen_ListItem_BulletSymbol.UNRECOGNIZED;
  }
}

export function messageListScreen_ListItem_BulletSymbolToJSON(object: MessageListScreen_ListItem_BulletSymbol): string {
  switch (object) {
    case MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_UNSPECIFIED:
      return "BULLET_SYMBOL_UNSPECIFIED";
    case MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_GREEN_MARK:
      return "BULLET_SYMBOL_GREEN_MARK";
    case MessageListScreen_ListItem_BulletSymbol.BULLET_SYMBOL_PROCESSING:
      return "BULLET_SYMBOL_PROCESSING";
    case MessageListScreen_ListItem_BulletSymbol.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface StaticScreen {
  title: AttributedString | undefined;
}

export interface ProgressGraphScreen {
  image: string;
}

export interface ProgressWeeksScreen {
  image: string;
  view_variant: string;
  main_issue: string;
  title: string;
}

export interface FaceScanIdData {
  scan_id: string;
  when: string;
}

export interface FaceScansHistoryResponse {
  scan_ids: FaceScanIdData[];
}

export interface AboutProgramScreen {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  image: string;
  subtitle: string;
}

export interface EnhancePhotoRequest {
  photo_url: string;
}

export interface EnhancePhotoResponse {
  photo_url: string;
}

/** This scheme is for /get_home_tasks_actions endpoint in interview service */
export interface GetHomeTasksActionsResponse {
  actions: { [key: string]: ActionType };
  task_contents: { [key: string]: GetHomeTasksActionsResponse_TaskContent };
}

export interface GetHomeTasksActionsResponse_TaskContent {
  title: string;
  subtitle: string;
}

export interface GetHomeTasksActionsResponse_ActionsEntry {
  key: string;
  value: ActionType | undefined;
}

export interface GetHomeTasksActionsResponse_TaskContentsEntry {
  key: string;
  value: GetHomeTasksActionsResponse_TaskContent | undefined;
}

function createBaseWellnessProfileScreen(): WellnessProfileScreen {
  return { level: WellnessProfileScreen_Level.UNKNOWN, properties: {}, age_image: undefined };
}

export const WellnessProfileScreen = {
  fromJSON(object: any): WellnessProfileScreen {
    return {
      level: isSet(object.level)
        ? wellnessProfileScreen_LevelFromJSON(object.level)
        : WellnessProfileScreen_Level.UNKNOWN,
      properties: isObject(object.properties)
        ? Object.entries(object.properties).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      age_image: isSet(object.age_image) ? WellnessProfileScreen_AgeImage.fromJSON(object.age_image) : undefined,
    };
  },

  toJSON(message: WellnessProfileScreen): unknown {
    const obj: any = {};
    message.level !== undefined && (obj.level = wellnessProfileScreen_LevelToJSON(message.level));
    obj.properties = {};
    if (message.properties) {
      Object.entries(message.properties).forEach(([k, v]) => {
        obj.properties[k] = v;
      });
    }
    message.age_image !== undefined &&
      (obj.age_image = message.age_image ? WellnessProfileScreen_AgeImage.toJSON(message.age_image) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WellnessProfileScreen>, I>>(base?: I): WellnessProfileScreen {
    return WellnessProfileScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WellnessProfileScreen>, I>>(object: I): WellnessProfileScreen {
    const message = createBaseWellnessProfileScreen();
    message.level = object.level ?? WellnessProfileScreen_Level.UNKNOWN;
    message.properties = Object.entries(object.properties ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.age_image = (object.age_image !== undefined && object.age_image !== null)
      ? WellnessProfileScreen_AgeImage.fromPartial(object.age_image)
      : undefined;
    return message;
  },
};

function createBaseWellnessProfileScreen_PropertiesEntry(): WellnessProfileScreen_PropertiesEntry {
  return { key: "", value: "" };
}

export const WellnessProfileScreen_PropertiesEntry = {
  fromJSON(object: any): WellnessProfileScreen_PropertiesEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: WellnessProfileScreen_PropertiesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<WellnessProfileScreen_PropertiesEntry>, I>>(
    base?: I,
  ): WellnessProfileScreen_PropertiesEntry {
    return WellnessProfileScreen_PropertiesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WellnessProfileScreen_PropertiesEntry>, I>>(
    object: I,
  ): WellnessProfileScreen_PropertiesEntry {
    const message = createBaseWellnessProfileScreen_PropertiesEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseWellnessProfileScreen_AgeImage(): WellnessProfileScreen_AgeImage {
  return { image: undefined, title: "" };
}

export const WellnessProfileScreen_AgeImage = {
  fromJSON(object: any): WellnessProfileScreen_AgeImage {
    return {
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: WellnessProfileScreen_AgeImage): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image ? Image.toJSON(message.image) : undefined);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<WellnessProfileScreen_AgeImage>, I>>(base?: I): WellnessProfileScreen_AgeImage {
    return WellnessProfileScreen_AgeImage.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WellnessProfileScreen_AgeImage>, I>>(
    object: I,
  ): WellnessProfileScreen_AgeImage {
    const message = createBaseWellnessProfileScreen_AgeImage();
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseQuestionV2(): QuestionV2 {
  return { parameters: undefined, question_screen: undefined };
}

export const QuestionV2 = {
  fromJSON(object: any): QuestionV2 {
    return {
      parameters: isSet(object.parameters) ? QuestionScreenParameters.fromJSON(object.parameters) : undefined,
      question_screen: isSet(object.date_screen)
        ? { $case: "date_screen", date_screen: DateQuestionScreen.fromJSON(object.date_screen) }
        : isSet(object.time_screen)
        ? { $case: "time_screen", time_screen: TimeQuestionScreen.fromJSON(object.time_screen) }
        : isSet(object.privacy_screen)
        ? { $case: "privacy_screen", privacy_screen: PrivacyQuestionScreen.fromJSON(object.privacy_screen) }
        : isSet(object.message_screen)
        ? { $case: "message_screen", message_screen: MessageQuestionScreen.fromJSON(object.message_screen) }
        : isSet(object.int_value_screen)
        ? { $case: "int_value_screen", int_value_screen: IntValueQuestionScreen.fromJSON(object.int_value_screen) }
        : isSet(object.single_variant_screen)
        ? {
          $case: "single_variant_screen",
          single_variant_screen: SingleVariantQuestionScreen.fromJSON(object.single_variant_screen),
        }
        : isSet(object.multiple_variants_screen)
        ? {
          $case: "multiple_variants_screen",
          multiple_variants_screen: MultipleVariantsQuestionScreen.fromJSON(object.multiple_variants_screen),
        }
        : isSet(object.scanning_instruction_screen)
        ? {
          $case: "scanning_instruction_screen",
          scanning_instruction_screen: ScanningInstructionQuestionScreen.fromJSON(object.scanning_instruction_screen),
        }
        : isSet(object.welcome_main_screen)
        ? {
          $case: "welcome_main_screen",
          welcome_main_screen: WelcomeMainQuestionScreen.fromJSON(object.welcome_main_screen),
        }
        : isSet(object.welcome_deeplink_screen)
        ? {
          $case: "welcome_deeplink_screen",
          welcome_deeplink_screen: WelcomeDeeplinkQuestionScreen.fromJSON(object.welcome_deeplink_screen),
        }
        : isSet(object.scan_screen)
        ? { $case: "scan_screen", scan_screen: ScanQuestionScreen.fromJSON(object.scan_screen) }
        : isSet(object.string_screen)
        ? { $case: "string_screen", string_screen: StringValueQuestionScreen.fromJSON(object.string_screen) }
        : isSet(object.summary_screen)
        ? { $case: "summary_screen", summary_screen: SummaryScreen.fromJSON(object.summary_screen) }
        : isSet(object.products_screen)
        ? { $case: "products_screen", products_screen: ProductsScreen.fromJSON(object.products_screen) }
        : isSet(object.buy_products_screen)
        ? { $case: "buy_products_screen", buy_products_screen: BuyProductsScreen.fromJSON(object.buy_products_screen) }
        : isSet(object.card_messages_loader_screen)
        ? {
          $case: "card_messages_loader_screen",
          card_messages_loader_screen: CardMessagesLoaderScreen.fromJSON(object.card_messages_loader_screen),
        }
        : isSet(object.titles_loader_screen)
        ? {
          $case: "titles_loader_screen",
          titles_loader_screen: TitlesLoaderScreen.fromJSON(object.titles_loader_screen),
        }
        : isSet(object.progress_bars_screen)
        ? {
          $case: "progress_bars_screen",
          progress_bars_screen: ProgressBarsScreen.fromJSON(object.progress_bars_screen),
        }
        : isSet(object.scan_report_screen)
        ? { $case: "scan_report_screen", scan_report_screen: ScanReportScreen.fromJSON(object.scan_report_screen) }
        : isSet(object.welcome_ios_screen)
        ? { $case: "welcome_ios_screen", welcome_ios_screen: WelcomeIosScreen.fromJSON(object.welcome_ios_screen) }
        : isSet(object.email_screen)
        ? { $case: "email_screen", email_screen: EmailScreen.fromJSON(object.email_screen) }
        : isSet(object.feedback_screen)
        ? { $case: "feedback_screen", feedback_screen: FeedbackScreen.fromJSON(object.feedback_screen) }
        : isSet(object.quote_screen)
        ? { $case: "quote_screen", quote_screen: MessageQuoteScreen.fromJSON(object.quote_screen) }
        : isSet(object.face_area_select_screen)
        ? {
          $case: "face_area_select_screen",
          face_area_select_screen: FaceAreaSelectQuestionScreen.fromJSON(object.face_area_select_screen),
        }
        : isSet(object.ios_paywall_screen)
        ? { $case: "ios_paywall_screen", ios_paywall_screen: IoSPaywallScreen.fromJSON(object.ios_paywall_screen) }
        : isSet(object.message_with_background_screen)
        ? {
          $case: "message_with_background_screen",
          message_with_background_screen: MessageWithBackgroundScreen.fromJSON(object.message_with_background_screen),
        }
        : isSet(object.product_list_screen)
        ? { $case: "product_list_screen", product_list_screen: ProductsListScreen.fromJSON(object.product_list_screen) }
        : isSet(object.products_search_screen)
        ? {
          $case: "products_search_screen",
          products_search_screen: ProductsSearchScreen.fromJSON(object.products_search_screen),
        }
        : isSet(object.push_notifications_permission_screen)
        ? {
          $case: "push_notifications_permission_screen",
          push_notifications_permission_screen: PushNotificationsPermissionsScreen.fromJSON(
            object.push_notifications_permission_screen,
          ),
        }
        : isSet(object.face_camera_screen)
        ? { $case: "face_camera_screen", face_camera_screen: FaceCameraScreen.fromJSON(object.face_camera_screen) }
        : isSet(object.skin_id_screen)
        ? { $case: "skin_id_screen", skin_id_screen: SkinIDScreen.fromJSON(object.skin_id_screen) }
        : isSet(object.message_list_screen)
        ? { $case: "message_list_screen", message_list_screen: MessageListScreen.fromJSON(object.message_list_screen) }
        : isSet(object.video_player_with_chapters)
        ? {
          $case: "video_player_with_chapters",
          video_player_with_chapters: VideoPlayerWithChapters.fromJSON(object.video_player_with_chapters),
        }
        : isSet(object.video_instruction)
        ? { $case: "video_instruction", video_instruction: VideoInstructionScreen.fromJSON(object.video_instruction) }
        : isSet(object.slide_loader_screen)
        ? { $case: "slide_loader_screen", slide_loader_screen: SlideLoaderScreen.fromJSON(object.slide_loader_screen) }
        : isSet(object.pre_paywall_summary_screen)
        ? {
          $case: "pre_paywall_summary_screen",
          pre_paywall_summary_screen: PrePaywallSummaryScreen.fromJSON(object.pre_paywall_summary_screen),
        }
        : isSet(object.product_fit_screen)
        ? { $case: "product_fit_screen", product_fit_screen: ProductFitScreen.fromJSON(object.product_fit_screen) }
        : isSet(object.product_fit_result_screen)
        ? {
          $case: "product_fit_result_screen",
          product_fit_result_screen: ProductFitResultScreen.fromJSON(object.product_fit_result_screen),
        }
        : isSet(object.discrete_slider_screen)
        ? {
          $case: "discrete_slider_screen",
          discrete_slider_screen: DiscreteSliderScreen.fromJSON(object.discrete_slider_screen),
        }
        : isSet(object.welcome_loading_screen)
        ? {
          $case: "welcome_loading_screen",
          welcome_loading_screen: WelcomeLoadingScreen.fromJSON(object.welcome_loading_screen),
        }
        : isSet(object.graph_page_gt_screen)
        ? {
          $case: "graph_page_gt_screen",
          graph_page_gt_screen: GraphPageGTScreen.fromJSON(object.graph_page_gt_screen),
        }
        : isSet(object.graph_page_gt_new_date_screen)
        ? {
          $case: "graph_page_gt_new_date_screen",
          graph_page_gt_new_date_screen: GraphPageGTNewDateScreen.fromJSON(object.graph_page_gt_new_date_screen),
        }
        : isSet(object.pre_paywall_message_screen)
        ? {
          $case: "pre_paywall_message_screen",
          pre_paywall_message_screen: PrePaywallMessageScreen.fromJSON(object.pre_paywall_message_screen),
        }
        : isSet(object.num_range_input_screen)
        ? {
          $case: "num_range_input_screen",
          num_range_input_screen: NumRangeInputScreen.fromJSON(object.num_range_input_screen),
        }
        : isSet(object.review_screen)
        ? { $case: "review_screen", review_screen: ReviewScreen.fromJSON(object.review_screen) }
        : isSet(object.welcome_select_screen)
        ? {
          $case: "welcome_select_screen",
          welcome_select_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_screen),
        }
        : isSet(object.product_fit_2_screen)
        ? {
          $case: "product_fit_2_screen",
          product_fit_2_screen: ProductFit2Screen.fromJSON(object.product_fit_2_screen),
        }
        : isSet(object.static_about_pora_program_screen)
        ? {
          $case: "static_about_pora_program_screen",
          static_about_pora_program_screen: StaticScreen.fromJSON(object.static_about_pora_program_screen),
        }
        : isSet(object.static_cosmetologist_vs_pora_screen)
        ? {
          $case: "static_cosmetologist_vs_pora_screen",
          static_cosmetologist_vs_pora_screen: StaticScreen.fromJSON(object.static_cosmetologist_vs_pora_screen),
        }
        : isSet(object.loading_screen)
        ? { $case: "loading_screen", loading_screen: LoadingScreen.fromJSON(object.loading_screen) }
        : isSet(object.one_subscription_paywall_screen)
        ? {
          $case: "one_subscription_paywall_screen",
          one_subscription_paywall_screen: OneSubscriptionPaywallScreen.fromJSON(
            object.one_subscription_paywall_screen,
          ),
        }
        : isSet(object.welcome_slider_screen)
        ? {
          $case: "welcome_slider_screen",
          welcome_slider_screen: WelcomeSliderScreen.fromJSON(object.welcome_slider_screen),
        }
        : isSet(object.progress_bars_with_reviews_screen)
        ? {
          $case: "progress_bars_with_reviews_screen",
          progress_bars_with_reviews_screen: ProgressBarsScreen.fromJSON(object.progress_bars_with_reviews_screen),
        }
        : isSet(object.wellness_profile_screen)
        ? {
          $case: "wellness_profile_screen",
          wellness_profile_screen: WellnessProfileScreen.fromJSON(object.wellness_profile_screen),
        }
        : isSet(object.product_fit_3_screen)
        ? {
          $case: "product_fit_3_screen",
          product_fit_3_screen: ProductFit3Screen.fromJSON(object.product_fit_3_screen),
        }
        : isSet(object.static_self_care_vs_pora_screen)
        ? {
          $case: "static_self_care_vs_pora_screen",
          static_self_care_vs_pora_screen: StaticScreen.fromJSON(object.static_self_care_vs_pora_screen),
        }
        : isSet(object.subscription_status_screen)
        ? {
          $case: "subscription_status_screen",
          subscription_status_screen: SubscriptionStatusScreen.fromJSON(object.subscription_status_screen),
        }
        : isSet(object.personalization_screen)
        ? {
          $case: "personalization_screen",
          personalization_screen: PersonalizationScreen.fromJSON(object.personalization_screen),
        }
        : isSet(object.message_with_answers_screen)
        ? {
          $case: "message_with_answers_screen",
          message_with_answers_screen: MessageWithAnswersScreen.fromJSON(object.message_with_answers_screen),
        }
        : isSet(object.sub_offer_screen)
        ? { $case: "sub_offer_screen", sub_offer_screen: SubOfferScreen.fromJSON(object.sub_offer_screen) }
        : isSet(object.if_you_cancel_screen)
        ? {
          $case: "if_you_cancel_screen",
          if_you_cancel_screen: IfYouCancelScreen.fromJSON(object.if_you_cancel_screen),
        }
        : isSet(object.message_with_image_screen)
        ? {
          $case: "message_with_image_screen",
          message_with_image_screen: MessageWithImageScreen.fromJSON(object.message_with_image_screen),
        }
        : isSet(object.message_with_image_and_progressbar_screen)
        ? {
          $case: "message_with_image_and_progressbar_screen",
          message_with_image_and_progressbar_screen: MessageWithImageScreen.fromJSON(
            object.message_with_image_and_progressbar_screen,
          ),
        }
        : isSet(object.welcome_select_2_screen)
        ? {
          $case: "welcome_select_2_screen",
          welcome_select_2_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_2_screen),
        }
        : isSet(object.scan_results_screen)
        ? { $case: "scan_results_screen", scan_results_screen: ScanResultScreen.fromJSON(object.scan_results_screen) }
        : isSet(object.static_esthetician_vs_lovi)
        ? {
          $case: "static_esthetician_vs_lovi",
          static_esthetician_vs_lovi: StaticScreen.fromJSON(object.static_esthetician_vs_lovi),
        }
        : isSet(object.product_scanner)
        ? { $case: "product_scanner", product_scanner: ActionOpenProductScanner.fromJSON(object.product_scanner) }
        : isSet(object.static_what_is_face_yoga)
        ? {
          $case: "static_what_is_face_yoga",
          static_what_is_face_yoga: StaticScreen.fromJSON(object.static_what_is_face_yoga),
        }
        : isSet(object.static_trust_doctors)
        ? { $case: "static_trust_doctors", static_trust_doctors: StaticScreen.fromJSON(object.static_trust_doctors) }
        : isSet(object.static_cosmetologist_vs_lovi_screen)
        ? {
          $case: "static_cosmetologist_vs_lovi_screen",
          static_cosmetologist_vs_lovi_screen: StaticScreen.fromJSON(object.static_cosmetologist_vs_lovi_screen),
        }
        : isSet(object.progress_graph_screen)
        ? {
          $case: "progress_graph_screen",
          progress_graph_screen: ProgressGraphScreen.fromJSON(object.progress_graph_screen),
        }
        : isSet(object.progress_weeks_screen)
        ? {
          $case: "progress_weeks_screen",
          progress_weeks_screen: ProgressWeeksScreen.fromJSON(object.progress_weeks_screen),
        }
        : isSet(object.progress_bars_with_questions_screen)
        ? {
          $case: "progress_bars_with_questions_screen",
          progress_bars_with_questions_screen: ProgressBarsScreen.fromJSON(object.progress_bars_with_questions_screen),
        }
        : isSet(object.static_science_research)
        ? {
          $case: "static_science_research",
          static_science_research: StaticScreen.fromJSON(object.static_science_research),
        }
        : isSet(object.welcome_select_luvly_screen)
        ? {
          $case: "welcome_select_luvly_screen",
          welcome_select_luvly_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_luvly_screen),
        }
        : isSet(object.static_about_program_2)
        ? {
          $case: "static_about_program_2",
          static_about_program_2: StaticScreen.fromJSON(object.static_about_program_2),
        }
        : isSet(object.welcome_select_3_screen)
        ? {
          $case: "welcome_select_3_screen",
          welcome_select_3_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_3_screen),
        }
        : isSet(object.product_fit_result_2_screen)
        ? {
          $case: "product_fit_result_2_screen",
          product_fit_result_2_screen: ProductFitResultScreen.fromJSON(object.product_fit_result_2_screen),
        }
        : isSet(object.about_program_screen)
        ? {
          $case: "about_program_screen",
          about_program_screen: AboutProgramScreen.fromJSON(object.about_program_screen),
        }
        : isSet(object.welcome_select_4_screen)
        ? {
          $case: "welcome_select_4_screen",
          welcome_select_4_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_4_screen),
        }
        : isSet(object.web_scan_skin_id_screen)
        ? {
          $case: "web_scan_skin_id_screen",
          web_scan_skin_id_screen: WebScanSkinIDScreen.fromJSON(object.web_scan_skin_id_screen),
        }
        : isSet(object.web_features_screen)
        ? { $case: "web_features_screen", web_features_screen: WebFeaturesScreen.fromJSON(object.web_features_screen) }
        : isSet(object.scan_2_screen)
        ? { $case: "scan_2_screen", scan_2_screen: ScanQuestionScreen.fromJSON(object.scan_2_screen) }
        : isSet(object.welcome_select_5_screen)
        ? {
          $case: "welcome_select_5_screen",
          welcome_select_5_screen: WelcomeSelectScreen.fromJSON(object.welcome_select_5_screen),
        }
        : isSet(object.wellness_profile_with_facescan_result_screen)
        ? {
          $case: "wellness_profile_with_facescan_result_screen",
          wellness_profile_with_facescan_result_screen: WellnessProfileScreen.fromJSON(
            object.wellness_profile_with_facescan_result_screen,
          ),
        }
        : isSet(object.scan_3_screen)
        ? { $case: "scan_3_screen", scan_3_screen: ScanQuestionScreen.fromJSON(object.scan_3_screen) }
        : undefined,
    };
  },

  toJSON(message: QuestionV2): unknown {
    const obj: any = {};
    message.parameters !== undefined &&
      (obj.parameters = message.parameters ? QuestionScreenParameters.toJSON(message.parameters) : undefined);
    message.question_screen?.$case === "date_screen" && (obj.date_screen = message.question_screen?.date_screen
      ? DateQuestionScreen.toJSON(message.question_screen?.date_screen)
      : undefined);
    message.question_screen?.$case === "time_screen" && (obj.time_screen = message.question_screen?.time_screen
      ? TimeQuestionScreen.toJSON(message.question_screen?.time_screen)
      : undefined);
    message.question_screen?.$case === "privacy_screen" && (obj.privacy_screen = message.question_screen?.privacy_screen
      ? PrivacyQuestionScreen.toJSON(message.question_screen?.privacy_screen)
      : undefined);
    message.question_screen?.$case === "message_screen" && (obj.message_screen = message.question_screen?.message_screen
      ? MessageQuestionScreen.toJSON(message.question_screen?.message_screen)
      : undefined);
    message.question_screen?.$case === "int_value_screen" &&
      (obj.int_value_screen = message.question_screen?.int_value_screen
        ? IntValueQuestionScreen.toJSON(message.question_screen?.int_value_screen)
        : undefined);
    message.question_screen?.$case === "single_variant_screen" &&
      (obj.single_variant_screen = message.question_screen?.single_variant_screen
        ? SingleVariantQuestionScreen.toJSON(message.question_screen?.single_variant_screen)
        : undefined);
    message.question_screen?.$case === "multiple_variants_screen" &&
      (obj.multiple_variants_screen = message.question_screen?.multiple_variants_screen
        ? MultipleVariantsQuestionScreen.toJSON(message.question_screen?.multiple_variants_screen)
        : undefined);
    message.question_screen?.$case === "scanning_instruction_screen" &&
      (obj.scanning_instruction_screen = message.question_screen?.scanning_instruction_screen
        ? ScanningInstructionQuestionScreen.toJSON(message.question_screen?.scanning_instruction_screen)
        : undefined);
    message.question_screen?.$case === "welcome_main_screen" &&
      (obj.welcome_main_screen = message.question_screen?.welcome_main_screen
        ? WelcomeMainQuestionScreen.toJSON(message.question_screen?.welcome_main_screen)
        : undefined);
    message.question_screen?.$case === "welcome_deeplink_screen" &&
      (obj.welcome_deeplink_screen = message.question_screen?.welcome_deeplink_screen
        ? WelcomeDeeplinkQuestionScreen.toJSON(message.question_screen?.welcome_deeplink_screen)
        : undefined);
    message.question_screen?.$case === "scan_screen" && (obj.scan_screen = message.question_screen?.scan_screen
      ? ScanQuestionScreen.toJSON(message.question_screen?.scan_screen)
      : undefined);
    message.question_screen?.$case === "string_screen" && (obj.string_screen = message.question_screen?.string_screen
      ? StringValueQuestionScreen.toJSON(message.question_screen?.string_screen)
      : undefined);
    message.question_screen?.$case === "summary_screen" && (obj.summary_screen = message.question_screen?.summary_screen
      ? SummaryScreen.toJSON(message.question_screen?.summary_screen)
      : undefined);
    message.question_screen?.$case === "products_screen" &&
      (obj.products_screen = message.question_screen?.products_screen
        ? ProductsScreen.toJSON(message.question_screen?.products_screen)
        : undefined);
    message.question_screen?.$case === "buy_products_screen" &&
      (obj.buy_products_screen = message.question_screen?.buy_products_screen
        ? BuyProductsScreen.toJSON(message.question_screen?.buy_products_screen)
        : undefined);
    message.question_screen?.$case === "card_messages_loader_screen" &&
      (obj.card_messages_loader_screen = message.question_screen?.card_messages_loader_screen
        ? CardMessagesLoaderScreen.toJSON(message.question_screen?.card_messages_loader_screen)
        : undefined);
    message.question_screen?.$case === "titles_loader_screen" &&
      (obj.titles_loader_screen = message.question_screen?.titles_loader_screen
        ? TitlesLoaderScreen.toJSON(message.question_screen?.titles_loader_screen)
        : undefined);
    message.question_screen?.$case === "progress_bars_screen" &&
      (obj.progress_bars_screen = message.question_screen?.progress_bars_screen
        ? ProgressBarsScreen.toJSON(message.question_screen?.progress_bars_screen)
        : undefined);
    message.question_screen?.$case === "scan_report_screen" &&
      (obj.scan_report_screen = message.question_screen?.scan_report_screen
        ? ScanReportScreen.toJSON(message.question_screen?.scan_report_screen)
        : undefined);
    message.question_screen?.$case === "welcome_ios_screen" &&
      (obj.welcome_ios_screen = message.question_screen?.welcome_ios_screen
        ? WelcomeIosScreen.toJSON(message.question_screen?.welcome_ios_screen)
        : undefined);
    message.question_screen?.$case === "email_screen" && (obj.email_screen = message.question_screen?.email_screen
      ? EmailScreen.toJSON(message.question_screen?.email_screen)
      : undefined);
    message.question_screen?.$case === "feedback_screen" &&
      (obj.feedback_screen = message.question_screen?.feedback_screen
        ? FeedbackScreen.toJSON(message.question_screen?.feedback_screen)
        : undefined);
    message.question_screen?.$case === "quote_screen" && (obj.quote_screen = message.question_screen?.quote_screen
      ? MessageQuoteScreen.toJSON(message.question_screen?.quote_screen)
      : undefined);
    message.question_screen?.$case === "face_area_select_screen" &&
      (obj.face_area_select_screen = message.question_screen?.face_area_select_screen
        ? FaceAreaSelectQuestionScreen.toJSON(message.question_screen?.face_area_select_screen)
        : undefined);
    message.question_screen?.$case === "ios_paywall_screen" &&
      (obj.ios_paywall_screen = message.question_screen?.ios_paywall_screen
        ? IoSPaywallScreen.toJSON(message.question_screen?.ios_paywall_screen)
        : undefined);
    message.question_screen?.$case === "message_with_background_screen" &&
      (obj.message_with_background_screen = message.question_screen?.message_with_background_screen
        ? MessageWithBackgroundScreen.toJSON(message.question_screen?.message_with_background_screen)
        : undefined);
    message.question_screen?.$case === "product_list_screen" &&
      (obj.product_list_screen = message.question_screen?.product_list_screen
        ? ProductsListScreen.toJSON(message.question_screen?.product_list_screen)
        : undefined);
    message.question_screen?.$case === "products_search_screen" &&
      (obj.products_search_screen = message.question_screen?.products_search_screen
        ? ProductsSearchScreen.toJSON(message.question_screen?.products_search_screen)
        : undefined);
    message.question_screen?.$case === "push_notifications_permission_screen" &&
      (obj.push_notifications_permission_screen = message.question_screen?.push_notifications_permission_screen
        ? PushNotificationsPermissionsScreen.toJSON(message.question_screen?.push_notifications_permission_screen)
        : undefined);
    message.question_screen?.$case === "face_camera_screen" &&
      (obj.face_camera_screen = message.question_screen?.face_camera_screen
        ? FaceCameraScreen.toJSON(message.question_screen?.face_camera_screen)
        : undefined);
    message.question_screen?.$case === "skin_id_screen" && (obj.skin_id_screen = message.question_screen?.skin_id_screen
      ? SkinIDScreen.toJSON(message.question_screen?.skin_id_screen)
      : undefined);
    message.question_screen?.$case === "message_list_screen" &&
      (obj.message_list_screen = message.question_screen?.message_list_screen
        ? MessageListScreen.toJSON(message.question_screen?.message_list_screen)
        : undefined);
    message.question_screen?.$case === "video_player_with_chapters" &&
      (obj.video_player_with_chapters = message.question_screen?.video_player_with_chapters
        ? VideoPlayerWithChapters.toJSON(message.question_screen?.video_player_with_chapters)
        : undefined);
    message.question_screen?.$case === "video_instruction" &&
      (obj.video_instruction = message.question_screen?.video_instruction
        ? VideoInstructionScreen.toJSON(message.question_screen?.video_instruction)
        : undefined);
    message.question_screen?.$case === "slide_loader_screen" &&
      (obj.slide_loader_screen = message.question_screen?.slide_loader_screen
        ? SlideLoaderScreen.toJSON(message.question_screen?.slide_loader_screen)
        : undefined);
    message.question_screen?.$case === "pre_paywall_summary_screen" &&
      (obj.pre_paywall_summary_screen = message.question_screen?.pre_paywall_summary_screen
        ? PrePaywallSummaryScreen.toJSON(message.question_screen?.pre_paywall_summary_screen)
        : undefined);
    message.question_screen?.$case === "product_fit_screen" &&
      (obj.product_fit_screen = message.question_screen?.product_fit_screen
        ? ProductFitScreen.toJSON(message.question_screen?.product_fit_screen)
        : undefined);
    message.question_screen?.$case === "product_fit_result_screen" &&
      (obj.product_fit_result_screen = message.question_screen?.product_fit_result_screen
        ? ProductFitResultScreen.toJSON(message.question_screen?.product_fit_result_screen)
        : undefined);
    message.question_screen?.$case === "discrete_slider_screen" &&
      (obj.discrete_slider_screen = message.question_screen?.discrete_slider_screen
        ? DiscreteSliderScreen.toJSON(message.question_screen?.discrete_slider_screen)
        : undefined);
    message.question_screen?.$case === "welcome_loading_screen" &&
      (obj.welcome_loading_screen = message.question_screen?.welcome_loading_screen
        ? WelcomeLoadingScreen.toJSON(message.question_screen?.welcome_loading_screen)
        : undefined);
    message.question_screen?.$case === "graph_page_gt_screen" &&
      (obj.graph_page_gt_screen = message.question_screen?.graph_page_gt_screen
        ? GraphPageGTScreen.toJSON(message.question_screen?.graph_page_gt_screen)
        : undefined);
    message.question_screen?.$case === "graph_page_gt_new_date_screen" &&
      (obj.graph_page_gt_new_date_screen = message.question_screen?.graph_page_gt_new_date_screen
        ? GraphPageGTNewDateScreen.toJSON(message.question_screen?.graph_page_gt_new_date_screen)
        : undefined);
    message.question_screen?.$case === "pre_paywall_message_screen" &&
      (obj.pre_paywall_message_screen = message.question_screen?.pre_paywall_message_screen
        ? PrePaywallMessageScreen.toJSON(message.question_screen?.pre_paywall_message_screen)
        : undefined);
    message.question_screen?.$case === "num_range_input_screen" &&
      (obj.num_range_input_screen = message.question_screen?.num_range_input_screen
        ? NumRangeInputScreen.toJSON(message.question_screen?.num_range_input_screen)
        : undefined);
    message.question_screen?.$case === "review_screen" && (obj.review_screen = message.question_screen?.review_screen
      ? ReviewScreen.toJSON(message.question_screen?.review_screen)
      : undefined);
    message.question_screen?.$case === "welcome_select_screen" &&
      (obj.welcome_select_screen = message.question_screen?.welcome_select_screen
        ? WelcomeSelectScreen.toJSON(message.question_screen?.welcome_select_screen)
        : undefined);
    message.question_screen?.$case === "product_fit_2_screen" &&
      (obj.product_fit_2_screen = message.question_screen?.product_fit_2_screen
        ? ProductFit2Screen.toJSON(message.question_screen?.product_fit_2_screen)
        : undefined);
    message.question_screen?.$case === "static_about_pora_program_screen" &&
      (obj.static_about_pora_program_screen = message.question_screen?.static_about_pora_program_screen
        ? StaticScreen.toJSON(message.question_screen?.static_about_pora_program_screen)
        : undefined);
    message.question_screen?.$case === "static_cosmetologist_vs_pora_screen" &&
      (obj.static_cosmetologist_vs_pora_screen = message.question_screen?.static_cosmetologist_vs_pora_screen
        ? StaticScreen.toJSON(message.question_screen?.static_cosmetologist_vs_pora_screen)
        : undefined);
    message.question_screen?.$case === "loading_screen" && (obj.loading_screen = message.question_screen?.loading_screen
      ? LoadingScreen.toJSON(message.question_screen?.loading_screen)
      : undefined);
    message.question_screen?.$case === "one_subscription_paywall_screen" &&
      (obj.one_subscription_paywall_screen = message.question_screen?.one_subscription_paywall_screen
        ? OneSubscriptionPaywallScreen.toJSON(message.question_screen?.one_subscription_paywall_screen)
        : undefined);
    message.question_screen?.$case === "welcome_slider_screen" &&
      (obj.welcome_slider_screen = message.question_screen?.welcome_slider_screen
        ? WelcomeSliderScreen.toJSON(message.question_screen?.welcome_slider_screen)
        : undefined);
    message.question_screen?.$case === "progress_bars_with_reviews_screen" &&
      (obj.progress_bars_with_reviews_screen = message.question_screen?.progress_bars_with_reviews_screen
        ? ProgressBarsScreen.toJSON(message.question_screen?.progress_bars_with_reviews_screen)
        : undefined);
    message.question_screen?.$case === "wellness_profile_screen" &&
      (obj.wellness_profile_screen = message.question_screen?.wellness_profile_screen
        ? WellnessProfileScreen.toJSON(message.question_screen?.wellness_profile_screen)
        : undefined);
    message.question_screen?.$case === "product_fit_3_screen" &&
      (obj.product_fit_3_screen = message.question_screen?.product_fit_3_screen
        ? ProductFit3Screen.toJSON(message.question_screen?.product_fit_3_screen)
        : undefined);
    message.question_screen?.$case === "static_self_care_vs_pora_screen" &&
      (obj.static_self_care_vs_pora_screen = message.question_screen?.static_self_care_vs_pora_screen
        ? StaticScreen.toJSON(message.question_screen?.static_self_care_vs_pora_screen)
        : undefined);
    message.question_screen?.$case === "subscription_status_screen" &&
      (obj.subscription_status_screen = message.question_screen?.subscription_status_screen
        ? SubscriptionStatusScreen.toJSON(message.question_screen?.subscription_status_screen)
        : undefined);
    message.question_screen?.$case === "personalization_screen" &&
      (obj.personalization_screen = message.question_screen?.personalization_screen
        ? PersonalizationScreen.toJSON(message.question_screen?.personalization_screen)
        : undefined);
    message.question_screen?.$case === "message_with_answers_screen" &&
      (obj.message_with_answers_screen = message.question_screen?.message_with_answers_screen
        ? MessageWithAnswersScreen.toJSON(message.question_screen?.message_with_answers_screen)
        : undefined);
    message.question_screen?.$case === "sub_offer_screen" &&
      (obj.sub_offer_screen = message.question_screen?.sub_offer_screen
        ? SubOfferScreen.toJSON(message.question_screen?.sub_offer_screen)
        : undefined);
    message.question_screen?.$case === "if_you_cancel_screen" &&
      (obj.if_you_cancel_screen = message.question_screen?.if_you_cancel_screen
        ? IfYouCancelScreen.toJSON(message.question_screen?.if_you_cancel_screen)
        : undefined);
    message.question_screen?.$case === "message_with_image_screen" &&
      (obj.message_with_image_screen = message.question_screen?.message_with_image_screen
        ? MessageWithImageScreen.toJSON(message.question_screen?.message_with_image_screen)
        : undefined);
    message.question_screen?.$case === "message_with_image_and_progressbar_screen" &&
      (obj.message_with_image_and_progressbar_screen =
        message.question_screen?.message_with_image_and_progressbar_screen
          ? MessageWithImageScreen.toJSON(message.question_screen?.message_with_image_and_progressbar_screen)
          : undefined);
    message.question_screen?.$case === "welcome_select_2_screen" &&
      (obj.welcome_select_2_screen = message.question_screen?.welcome_select_2_screen
        ? WelcomeSelectScreen.toJSON(message.question_screen?.welcome_select_2_screen)
        : undefined);
    message.question_screen?.$case === "scan_results_screen" &&
      (obj.scan_results_screen = message.question_screen?.scan_results_screen
        ? ScanResultScreen.toJSON(message.question_screen?.scan_results_screen)
        : undefined);
    message.question_screen?.$case === "static_esthetician_vs_lovi" &&
      (obj.static_esthetician_vs_lovi = message.question_screen?.static_esthetician_vs_lovi
        ? StaticScreen.toJSON(message.question_screen?.static_esthetician_vs_lovi)
        : undefined);
    message.question_screen?.$case === "product_scanner" &&
      (obj.product_scanner = message.question_screen?.product_scanner
        ? ActionOpenProductScanner.toJSON(message.question_screen?.product_scanner)
        : undefined);
    message.question_screen?.$case === "static_what_is_face_yoga" &&
      (obj.static_what_is_face_yoga = message.question_screen?.static_what_is_face_yoga
        ? StaticScreen.toJSON(message.question_screen?.static_what_is_face_yoga)
        : undefined);
    message.question_screen?.$case === "static_trust_doctors" &&
      (obj.static_trust_doctors = message.question_screen?.static_trust_doctors
        ? StaticScreen.toJSON(message.question_screen?.static_trust_doctors)
        : undefined);
    message.question_screen?.$case === "static_cosmetologist_vs_lovi_screen" &&
      (obj.static_cosmetologist_vs_lovi_screen = message.question_screen?.static_cosmetologist_vs_lovi_screen
        ? StaticScreen.toJSON(message.question_screen?.static_cosmetologist_vs_lovi_screen)
        : undefined);
    message.question_screen?.$case === "progress_graph_screen" &&
      (obj.progress_graph_screen = message.question_screen?.progress_graph_screen
        ? ProgressGraphScreen.toJSON(message.question_screen?.progress_graph_screen)
        : undefined);
    message.question_screen?.$case === "progress_weeks_screen" &&
      (obj.progress_weeks_screen = message.question_screen?.progress_weeks_screen
        ? ProgressWeeksScreen.toJSON(message.question_screen?.progress_weeks_screen)
        : undefined);
    message.question_screen?.$case === "progress_bars_with_questions_screen" &&
      (obj.progress_bars_with_questions_screen = message.question_screen?.progress_bars_with_questions_screen
        ? ProgressBarsScreen.toJSON(message.question_screen?.progress_bars_with_questions_screen)
        : undefined);
    message.question_screen?.$case === "static_science_research" &&
      (obj.static_science_research = message.question_screen?.static_science_research
        ? StaticScreen.toJSON(message.question_screen?.static_science_research)
        : undefined);
    message.question_screen?.$case === "welcome_select_luvly_screen" &&
      (obj.welcome_select_luvly_screen = message.question_screen?.welcome_select_luvly_screen
        ? WelcomeSelectScreen.toJSON(message.question_screen?.welcome_select_luvly_screen)
        : undefined);
    message.question_screen?.$case === "static_about_program_2" &&
      (obj.static_about_program_2 = message.question_screen?.static_about_program_2
        ? StaticScreen.toJSON(message.question_screen?.static_about_program_2)
        : undefined);
    message.question_screen?.$case === "welcome_select_3_screen" &&
      (obj.welcome_select_3_screen = message.question_screen?.welcome_select_3_screen
        ? WelcomeSelectScreen.toJSON(message.question_screen?.welcome_select_3_screen)
        : undefined);
    message.question_screen?.$case === "product_fit_result_2_screen" &&
      (obj.product_fit_result_2_screen = message.question_screen?.product_fit_result_2_screen
        ? ProductFitResultScreen.toJSON(message.question_screen?.product_fit_result_2_screen)
        : undefined);
    message.question_screen?.$case === "about_program_screen" &&
      (obj.about_program_screen = message.question_screen?.about_program_screen
        ? AboutProgramScreen.toJSON(message.question_screen?.about_program_screen)
        : undefined);
    message.question_screen?.$case === "welcome_select_4_screen" &&
      (obj.welcome_select_4_screen = message.question_screen?.welcome_select_4_screen
        ? WelcomeSelectScreen.toJSON(message.question_screen?.welcome_select_4_screen)
        : undefined);
    message.question_screen?.$case === "web_scan_skin_id_screen" &&
      (obj.web_scan_skin_id_screen = message.question_screen?.web_scan_skin_id_screen
        ? WebScanSkinIDScreen.toJSON(message.question_screen?.web_scan_skin_id_screen)
        : undefined);
    message.question_screen?.$case === "web_features_screen" &&
      (obj.web_features_screen = message.question_screen?.web_features_screen
        ? WebFeaturesScreen.toJSON(message.question_screen?.web_features_screen)
        : undefined);
    message.question_screen?.$case === "scan_2_screen" && (obj.scan_2_screen = message.question_screen?.scan_2_screen
      ? ScanQuestionScreen.toJSON(message.question_screen?.scan_2_screen)
      : undefined);
    message.question_screen?.$case === "welcome_select_5_screen" &&
      (obj.welcome_select_5_screen = message.question_screen?.welcome_select_5_screen
        ? WelcomeSelectScreen.toJSON(message.question_screen?.welcome_select_5_screen)
        : undefined);
    message.question_screen?.$case === "wellness_profile_with_facescan_result_screen" &&
      (obj.wellness_profile_with_facescan_result_screen =
        message.question_screen?.wellness_profile_with_facescan_result_screen
          ? WellnessProfileScreen.toJSON(message.question_screen?.wellness_profile_with_facescan_result_screen)
          : undefined);
    message.question_screen?.$case === "scan_3_screen" && (obj.scan_3_screen = message.question_screen?.scan_3_screen
      ? ScanQuestionScreen.toJSON(message.question_screen?.scan_3_screen)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuestionV2>, I>>(base?: I): QuestionV2 {
    return QuestionV2.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuestionV2>, I>>(object: I): QuestionV2 {
    const message = createBaseQuestionV2();
    message.parameters = (object.parameters !== undefined && object.parameters !== null)
      ? QuestionScreenParameters.fromPartial(object.parameters)
      : undefined;
    if (
      object.question_screen?.$case === "date_screen" &&
      object.question_screen?.date_screen !== undefined &&
      object.question_screen?.date_screen !== null
    ) {
      message.question_screen = {
        $case: "date_screen",
        date_screen: DateQuestionScreen.fromPartial(object.question_screen.date_screen),
      };
    }
    if (
      object.question_screen?.$case === "time_screen" &&
      object.question_screen?.time_screen !== undefined &&
      object.question_screen?.time_screen !== null
    ) {
      message.question_screen = {
        $case: "time_screen",
        time_screen: TimeQuestionScreen.fromPartial(object.question_screen.time_screen),
      };
    }
    if (
      object.question_screen?.$case === "privacy_screen" &&
      object.question_screen?.privacy_screen !== undefined &&
      object.question_screen?.privacy_screen !== null
    ) {
      message.question_screen = {
        $case: "privacy_screen",
        privacy_screen: PrivacyQuestionScreen.fromPartial(object.question_screen.privacy_screen),
      };
    }
    if (
      object.question_screen?.$case === "message_screen" &&
      object.question_screen?.message_screen !== undefined &&
      object.question_screen?.message_screen !== null
    ) {
      message.question_screen = {
        $case: "message_screen",
        message_screen: MessageQuestionScreen.fromPartial(object.question_screen.message_screen),
      };
    }
    if (
      object.question_screen?.$case === "int_value_screen" &&
      object.question_screen?.int_value_screen !== undefined &&
      object.question_screen?.int_value_screen !== null
    ) {
      message.question_screen = {
        $case: "int_value_screen",
        int_value_screen: IntValueQuestionScreen.fromPartial(object.question_screen.int_value_screen),
      };
    }
    if (
      object.question_screen?.$case === "single_variant_screen" &&
      object.question_screen?.single_variant_screen !== undefined &&
      object.question_screen?.single_variant_screen !== null
    ) {
      message.question_screen = {
        $case: "single_variant_screen",
        single_variant_screen: SingleVariantQuestionScreen.fromPartial(object.question_screen.single_variant_screen),
      };
    }
    if (
      object.question_screen?.$case === "multiple_variants_screen" &&
      object.question_screen?.multiple_variants_screen !== undefined &&
      object.question_screen?.multiple_variants_screen !== null
    ) {
      message.question_screen = {
        $case: "multiple_variants_screen",
        multiple_variants_screen: MultipleVariantsQuestionScreen.fromPartial(
          object.question_screen.multiple_variants_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "scanning_instruction_screen" &&
      object.question_screen?.scanning_instruction_screen !== undefined &&
      object.question_screen?.scanning_instruction_screen !== null
    ) {
      message.question_screen = {
        $case: "scanning_instruction_screen",
        scanning_instruction_screen: ScanningInstructionQuestionScreen.fromPartial(
          object.question_screen.scanning_instruction_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "welcome_main_screen" &&
      object.question_screen?.welcome_main_screen !== undefined &&
      object.question_screen?.welcome_main_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_main_screen",
        welcome_main_screen: WelcomeMainQuestionScreen.fromPartial(object.question_screen.welcome_main_screen),
      };
    }
    if (
      object.question_screen?.$case === "welcome_deeplink_screen" &&
      object.question_screen?.welcome_deeplink_screen !== undefined &&
      object.question_screen?.welcome_deeplink_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_deeplink_screen",
        welcome_deeplink_screen: WelcomeDeeplinkQuestionScreen.fromPartial(
          object.question_screen.welcome_deeplink_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "scan_screen" &&
      object.question_screen?.scan_screen !== undefined &&
      object.question_screen?.scan_screen !== null
    ) {
      message.question_screen = {
        $case: "scan_screen",
        scan_screen: ScanQuestionScreen.fromPartial(object.question_screen.scan_screen),
      };
    }
    if (
      object.question_screen?.$case === "string_screen" &&
      object.question_screen?.string_screen !== undefined &&
      object.question_screen?.string_screen !== null
    ) {
      message.question_screen = {
        $case: "string_screen",
        string_screen: StringValueQuestionScreen.fromPartial(object.question_screen.string_screen),
      };
    }
    if (
      object.question_screen?.$case === "summary_screen" &&
      object.question_screen?.summary_screen !== undefined &&
      object.question_screen?.summary_screen !== null
    ) {
      message.question_screen = {
        $case: "summary_screen",
        summary_screen: SummaryScreen.fromPartial(object.question_screen.summary_screen),
      };
    }
    if (
      object.question_screen?.$case === "products_screen" &&
      object.question_screen?.products_screen !== undefined &&
      object.question_screen?.products_screen !== null
    ) {
      message.question_screen = {
        $case: "products_screen",
        products_screen: ProductsScreen.fromPartial(object.question_screen.products_screen),
      };
    }
    if (
      object.question_screen?.$case === "buy_products_screen" &&
      object.question_screen?.buy_products_screen !== undefined &&
      object.question_screen?.buy_products_screen !== null
    ) {
      message.question_screen = {
        $case: "buy_products_screen",
        buy_products_screen: BuyProductsScreen.fromPartial(object.question_screen.buy_products_screen),
      };
    }
    if (
      object.question_screen?.$case === "card_messages_loader_screen" &&
      object.question_screen?.card_messages_loader_screen !== undefined &&
      object.question_screen?.card_messages_loader_screen !== null
    ) {
      message.question_screen = {
        $case: "card_messages_loader_screen",
        card_messages_loader_screen: CardMessagesLoaderScreen.fromPartial(
          object.question_screen.card_messages_loader_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "titles_loader_screen" &&
      object.question_screen?.titles_loader_screen !== undefined &&
      object.question_screen?.titles_loader_screen !== null
    ) {
      message.question_screen = {
        $case: "titles_loader_screen",
        titles_loader_screen: TitlesLoaderScreen.fromPartial(object.question_screen.titles_loader_screen),
      };
    }
    if (
      object.question_screen?.$case === "progress_bars_screen" &&
      object.question_screen?.progress_bars_screen !== undefined &&
      object.question_screen?.progress_bars_screen !== null
    ) {
      message.question_screen = {
        $case: "progress_bars_screen",
        progress_bars_screen: ProgressBarsScreen.fromPartial(object.question_screen.progress_bars_screen),
      };
    }
    if (
      object.question_screen?.$case === "scan_report_screen" &&
      object.question_screen?.scan_report_screen !== undefined &&
      object.question_screen?.scan_report_screen !== null
    ) {
      message.question_screen = {
        $case: "scan_report_screen",
        scan_report_screen: ScanReportScreen.fromPartial(object.question_screen.scan_report_screen),
      };
    }
    if (
      object.question_screen?.$case === "welcome_ios_screen" &&
      object.question_screen?.welcome_ios_screen !== undefined &&
      object.question_screen?.welcome_ios_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_ios_screen",
        welcome_ios_screen: WelcomeIosScreen.fromPartial(object.question_screen.welcome_ios_screen),
      };
    }
    if (
      object.question_screen?.$case === "email_screen" &&
      object.question_screen?.email_screen !== undefined &&
      object.question_screen?.email_screen !== null
    ) {
      message.question_screen = {
        $case: "email_screen",
        email_screen: EmailScreen.fromPartial(object.question_screen.email_screen),
      };
    }
    if (
      object.question_screen?.$case === "feedback_screen" &&
      object.question_screen?.feedback_screen !== undefined &&
      object.question_screen?.feedback_screen !== null
    ) {
      message.question_screen = {
        $case: "feedback_screen",
        feedback_screen: FeedbackScreen.fromPartial(object.question_screen.feedback_screen),
      };
    }
    if (
      object.question_screen?.$case === "quote_screen" &&
      object.question_screen?.quote_screen !== undefined &&
      object.question_screen?.quote_screen !== null
    ) {
      message.question_screen = {
        $case: "quote_screen",
        quote_screen: MessageQuoteScreen.fromPartial(object.question_screen.quote_screen),
      };
    }
    if (
      object.question_screen?.$case === "face_area_select_screen" &&
      object.question_screen?.face_area_select_screen !== undefined &&
      object.question_screen?.face_area_select_screen !== null
    ) {
      message.question_screen = {
        $case: "face_area_select_screen",
        face_area_select_screen: FaceAreaSelectQuestionScreen.fromPartial(
          object.question_screen.face_area_select_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "ios_paywall_screen" &&
      object.question_screen?.ios_paywall_screen !== undefined &&
      object.question_screen?.ios_paywall_screen !== null
    ) {
      message.question_screen = {
        $case: "ios_paywall_screen",
        ios_paywall_screen: IoSPaywallScreen.fromPartial(object.question_screen.ios_paywall_screen),
      };
    }
    if (
      object.question_screen?.$case === "message_with_background_screen" &&
      object.question_screen?.message_with_background_screen !== undefined &&
      object.question_screen?.message_with_background_screen !== null
    ) {
      message.question_screen = {
        $case: "message_with_background_screen",
        message_with_background_screen: MessageWithBackgroundScreen.fromPartial(
          object.question_screen.message_with_background_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "product_list_screen" &&
      object.question_screen?.product_list_screen !== undefined &&
      object.question_screen?.product_list_screen !== null
    ) {
      message.question_screen = {
        $case: "product_list_screen",
        product_list_screen: ProductsListScreen.fromPartial(object.question_screen.product_list_screen),
      };
    }
    if (
      object.question_screen?.$case === "products_search_screen" &&
      object.question_screen?.products_search_screen !== undefined &&
      object.question_screen?.products_search_screen !== null
    ) {
      message.question_screen = {
        $case: "products_search_screen",
        products_search_screen: ProductsSearchScreen.fromPartial(object.question_screen.products_search_screen),
      };
    }
    if (
      object.question_screen?.$case === "push_notifications_permission_screen" &&
      object.question_screen?.push_notifications_permission_screen !== undefined &&
      object.question_screen?.push_notifications_permission_screen !== null
    ) {
      message.question_screen = {
        $case: "push_notifications_permission_screen",
        push_notifications_permission_screen: PushNotificationsPermissionsScreen.fromPartial(
          object.question_screen.push_notifications_permission_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "face_camera_screen" &&
      object.question_screen?.face_camera_screen !== undefined &&
      object.question_screen?.face_camera_screen !== null
    ) {
      message.question_screen = {
        $case: "face_camera_screen",
        face_camera_screen: FaceCameraScreen.fromPartial(object.question_screen.face_camera_screen),
      };
    }
    if (
      object.question_screen?.$case === "skin_id_screen" &&
      object.question_screen?.skin_id_screen !== undefined &&
      object.question_screen?.skin_id_screen !== null
    ) {
      message.question_screen = {
        $case: "skin_id_screen",
        skin_id_screen: SkinIDScreen.fromPartial(object.question_screen.skin_id_screen),
      };
    }
    if (
      object.question_screen?.$case === "message_list_screen" &&
      object.question_screen?.message_list_screen !== undefined &&
      object.question_screen?.message_list_screen !== null
    ) {
      message.question_screen = {
        $case: "message_list_screen",
        message_list_screen: MessageListScreen.fromPartial(object.question_screen.message_list_screen),
      };
    }
    if (
      object.question_screen?.$case === "video_player_with_chapters" &&
      object.question_screen?.video_player_with_chapters !== undefined &&
      object.question_screen?.video_player_with_chapters !== null
    ) {
      message.question_screen = {
        $case: "video_player_with_chapters",
        video_player_with_chapters: VideoPlayerWithChapters.fromPartial(
          object.question_screen.video_player_with_chapters,
        ),
      };
    }
    if (
      object.question_screen?.$case === "video_instruction" &&
      object.question_screen?.video_instruction !== undefined &&
      object.question_screen?.video_instruction !== null
    ) {
      message.question_screen = {
        $case: "video_instruction",
        video_instruction: VideoInstructionScreen.fromPartial(object.question_screen.video_instruction),
      };
    }
    if (
      object.question_screen?.$case === "slide_loader_screen" &&
      object.question_screen?.slide_loader_screen !== undefined &&
      object.question_screen?.slide_loader_screen !== null
    ) {
      message.question_screen = {
        $case: "slide_loader_screen",
        slide_loader_screen: SlideLoaderScreen.fromPartial(object.question_screen.slide_loader_screen),
      };
    }
    if (
      object.question_screen?.$case === "pre_paywall_summary_screen" &&
      object.question_screen?.pre_paywall_summary_screen !== undefined &&
      object.question_screen?.pre_paywall_summary_screen !== null
    ) {
      message.question_screen = {
        $case: "pre_paywall_summary_screen",
        pre_paywall_summary_screen: PrePaywallSummaryScreen.fromPartial(
          object.question_screen.pre_paywall_summary_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "product_fit_screen" &&
      object.question_screen?.product_fit_screen !== undefined &&
      object.question_screen?.product_fit_screen !== null
    ) {
      message.question_screen = {
        $case: "product_fit_screen",
        product_fit_screen: ProductFitScreen.fromPartial(object.question_screen.product_fit_screen),
      };
    }
    if (
      object.question_screen?.$case === "product_fit_result_screen" &&
      object.question_screen?.product_fit_result_screen !== undefined &&
      object.question_screen?.product_fit_result_screen !== null
    ) {
      message.question_screen = {
        $case: "product_fit_result_screen",
        product_fit_result_screen: ProductFitResultScreen.fromPartial(object.question_screen.product_fit_result_screen),
      };
    }
    if (
      object.question_screen?.$case === "discrete_slider_screen" &&
      object.question_screen?.discrete_slider_screen !== undefined &&
      object.question_screen?.discrete_slider_screen !== null
    ) {
      message.question_screen = {
        $case: "discrete_slider_screen",
        discrete_slider_screen: DiscreteSliderScreen.fromPartial(object.question_screen.discrete_slider_screen),
      };
    }
    if (
      object.question_screen?.$case === "welcome_loading_screen" &&
      object.question_screen?.welcome_loading_screen !== undefined &&
      object.question_screen?.welcome_loading_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_loading_screen",
        welcome_loading_screen: WelcomeLoadingScreen.fromPartial(object.question_screen.welcome_loading_screen),
      };
    }
    if (
      object.question_screen?.$case === "graph_page_gt_screen" &&
      object.question_screen?.graph_page_gt_screen !== undefined &&
      object.question_screen?.graph_page_gt_screen !== null
    ) {
      message.question_screen = {
        $case: "graph_page_gt_screen",
        graph_page_gt_screen: GraphPageGTScreen.fromPartial(object.question_screen.graph_page_gt_screen),
      };
    }
    if (
      object.question_screen?.$case === "graph_page_gt_new_date_screen" &&
      object.question_screen?.graph_page_gt_new_date_screen !== undefined &&
      object.question_screen?.graph_page_gt_new_date_screen !== null
    ) {
      message.question_screen = {
        $case: "graph_page_gt_new_date_screen",
        graph_page_gt_new_date_screen: GraphPageGTNewDateScreen.fromPartial(
          object.question_screen.graph_page_gt_new_date_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "pre_paywall_message_screen" &&
      object.question_screen?.pre_paywall_message_screen !== undefined &&
      object.question_screen?.pre_paywall_message_screen !== null
    ) {
      message.question_screen = {
        $case: "pre_paywall_message_screen",
        pre_paywall_message_screen: PrePaywallMessageScreen.fromPartial(
          object.question_screen.pre_paywall_message_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "num_range_input_screen" &&
      object.question_screen?.num_range_input_screen !== undefined &&
      object.question_screen?.num_range_input_screen !== null
    ) {
      message.question_screen = {
        $case: "num_range_input_screen",
        num_range_input_screen: NumRangeInputScreen.fromPartial(object.question_screen.num_range_input_screen),
      };
    }
    if (
      object.question_screen?.$case === "review_screen" &&
      object.question_screen?.review_screen !== undefined &&
      object.question_screen?.review_screen !== null
    ) {
      message.question_screen = {
        $case: "review_screen",
        review_screen: ReviewScreen.fromPartial(object.question_screen.review_screen),
      };
    }
    if (
      object.question_screen?.$case === "welcome_select_screen" &&
      object.question_screen?.welcome_select_screen !== undefined &&
      object.question_screen?.welcome_select_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_select_screen",
        welcome_select_screen: WelcomeSelectScreen.fromPartial(object.question_screen.welcome_select_screen),
      };
    }
    if (
      object.question_screen?.$case === "product_fit_2_screen" &&
      object.question_screen?.product_fit_2_screen !== undefined &&
      object.question_screen?.product_fit_2_screen !== null
    ) {
      message.question_screen = {
        $case: "product_fit_2_screen",
        product_fit_2_screen: ProductFit2Screen.fromPartial(object.question_screen.product_fit_2_screen),
      };
    }
    if (
      object.question_screen?.$case === "static_about_pora_program_screen" &&
      object.question_screen?.static_about_pora_program_screen !== undefined &&
      object.question_screen?.static_about_pora_program_screen !== null
    ) {
      message.question_screen = {
        $case: "static_about_pora_program_screen",
        static_about_pora_program_screen: StaticScreen.fromPartial(
          object.question_screen.static_about_pora_program_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "static_cosmetologist_vs_pora_screen" &&
      object.question_screen?.static_cosmetologist_vs_pora_screen !== undefined &&
      object.question_screen?.static_cosmetologist_vs_pora_screen !== null
    ) {
      message.question_screen = {
        $case: "static_cosmetologist_vs_pora_screen",
        static_cosmetologist_vs_pora_screen: StaticScreen.fromPartial(
          object.question_screen.static_cosmetologist_vs_pora_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "loading_screen" &&
      object.question_screen?.loading_screen !== undefined &&
      object.question_screen?.loading_screen !== null
    ) {
      message.question_screen = {
        $case: "loading_screen",
        loading_screen: LoadingScreen.fromPartial(object.question_screen.loading_screen),
      };
    }
    if (
      object.question_screen?.$case === "one_subscription_paywall_screen" &&
      object.question_screen?.one_subscription_paywall_screen !== undefined &&
      object.question_screen?.one_subscription_paywall_screen !== null
    ) {
      message.question_screen = {
        $case: "one_subscription_paywall_screen",
        one_subscription_paywall_screen: OneSubscriptionPaywallScreen.fromPartial(
          object.question_screen.one_subscription_paywall_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "welcome_slider_screen" &&
      object.question_screen?.welcome_slider_screen !== undefined &&
      object.question_screen?.welcome_slider_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_slider_screen",
        welcome_slider_screen: WelcomeSliderScreen.fromPartial(object.question_screen.welcome_slider_screen),
      };
    }
    if (
      object.question_screen?.$case === "progress_bars_with_reviews_screen" &&
      object.question_screen?.progress_bars_with_reviews_screen !== undefined &&
      object.question_screen?.progress_bars_with_reviews_screen !== null
    ) {
      message.question_screen = {
        $case: "progress_bars_with_reviews_screen",
        progress_bars_with_reviews_screen: ProgressBarsScreen.fromPartial(
          object.question_screen.progress_bars_with_reviews_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "wellness_profile_screen" &&
      object.question_screen?.wellness_profile_screen !== undefined &&
      object.question_screen?.wellness_profile_screen !== null
    ) {
      message.question_screen = {
        $case: "wellness_profile_screen",
        wellness_profile_screen: WellnessProfileScreen.fromPartial(object.question_screen.wellness_profile_screen),
      };
    }
    if (
      object.question_screen?.$case === "product_fit_3_screen" &&
      object.question_screen?.product_fit_3_screen !== undefined &&
      object.question_screen?.product_fit_3_screen !== null
    ) {
      message.question_screen = {
        $case: "product_fit_3_screen",
        product_fit_3_screen: ProductFit3Screen.fromPartial(object.question_screen.product_fit_3_screen),
      };
    }
    if (
      object.question_screen?.$case === "static_self_care_vs_pora_screen" &&
      object.question_screen?.static_self_care_vs_pora_screen !== undefined &&
      object.question_screen?.static_self_care_vs_pora_screen !== null
    ) {
      message.question_screen = {
        $case: "static_self_care_vs_pora_screen",
        static_self_care_vs_pora_screen: StaticScreen.fromPartial(
          object.question_screen.static_self_care_vs_pora_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "subscription_status_screen" &&
      object.question_screen?.subscription_status_screen !== undefined &&
      object.question_screen?.subscription_status_screen !== null
    ) {
      message.question_screen = {
        $case: "subscription_status_screen",
        subscription_status_screen: SubscriptionStatusScreen.fromPartial(
          object.question_screen.subscription_status_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "personalization_screen" &&
      object.question_screen?.personalization_screen !== undefined &&
      object.question_screen?.personalization_screen !== null
    ) {
      message.question_screen = {
        $case: "personalization_screen",
        personalization_screen: PersonalizationScreen.fromPartial(object.question_screen.personalization_screen),
      };
    }
    if (
      object.question_screen?.$case === "message_with_answers_screen" &&
      object.question_screen?.message_with_answers_screen !== undefined &&
      object.question_screen?.message_with_answers_screen !== null
    ) {
      message.question_screen = {
        $case: "message_with_answers_screen",
        message_with_answers_screen: MessageWithAnswersScreen.fromPartial(
          object.question_screen.message_with_answers_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "sub_offer_screen" &&
      object.question_screen?.sub_offer_screen !== undefined &&
      object.question_screen?.sub_offer_screen !== null
    ) {
      message.question_screen = {
        $case: "sub_offer_screen",
        sub_offer_screen: SubOfferScreen.fromPartial(object.question_screen.sub_offer_screen),
      };
    }
    if (
      object.question_screen?.$case === "if_you_cancel_screen" &&
      object.question_screen?.if_you_cancel_screen !== undefined &&
      object.question_screen?.if_you_cancel_screen !== null
    ) {
      message.question_screen = {
        $case: "if_you_cancel_screen",
        if_you_cancel_screen: IfYouCancelScreen.fromPartial(object.question_screen.if_you_cancel_screen),
      };
    }
    if (
      object.question_screen?.$case === "message_with_image_screen" &&
      object.question_screen?.message_with_image_screen !== undefined &&
      object.question_screen?.message_with_image_screen !== null
    ) {
      message.question_screen = {
        $case: "message_with_image_screen",
        message_with_image_screen: MessageWithImageScreen.fromPartial(object.question_screen.message_with_image_screen),
      };
    }
    if (
      object.question_screen?.$case === "message_with_image_and_progressbar_screen" &&
      object.question_screen?.message_with_image_and_progressbar_screen !== undefined &&
      object.question_screen?.message_with_image_and_progressbar_screen !== null
    ) {
      message.question_screen = {
        $case: "message_with_image_and_progressbar_screen",
        message_with_image_and_progressbar_screen: MessageWithImageScreen.fromPartial(
          object.question_screen.message_with_image_and_progressbar_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "welcome_select_2_screen" &&
      object.question_screen?.welcome_select_2_screen !== undefined &&
      object.question_screen?.welcome_select_2_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_select_2_screen",
        welcome_select_2_screen: WelcomeSelectScreen.fromPartial(object.question_screen.welcome_select_2_screen),
      };
    }
    if (
      object.question_screen?.$case === "scan_results_screen" &&
      object.question_screen?.scan_results_screen !== undefined &&
      object.question_screen?.scan_results_screen !== null
    ) {
      message.question_screen = {
        $case: "scan_results_screen",
        scan_results_screen: ScanResultScreen.fromPartial(object.question_screen.scan_results_screen),
      };
    }
    if (
      object.question_screen?.$case === "static_esthetician_vs_lovi" &&
      object.question_screen?.static_esthetician_vs_lovi !== undefined &&
      object.question_screen?.static_esthetician_vs_lovi !== null
    ) {
      message.question_screen = {
        $case: "static_esthetician_vs_lovi",
        static_esthetician_vs_lovi: StaticScreen.fromPartial(object.question_screen.static_esthetician_vs_lovi),
      };
    }
    if (
      object.question_screen?.$case === "product_scanner" &&
      object.question_screen?.product_scanner !== undefined &&
      object.question_screen?.product_scanner !== null
    ) {
      message.question_screen = {
        $case: "product_scanner",
        product_scanner: ActionOpenProductScanner.fromPartial(object.question_screen.product_scanner),
      };
    }
    if (
      object.question_screen?.$case === "static_what_is_face_yoga" &&
      object.question_screen?.static_what_is_face_yoga !== undefined &&
      object.question_screen?.static_what_is_face_yoga !== null
    ) {
      message.question_screen = {
        $case: "static_what_is_face_yoga",
        static_what_is_face_yoga: StaticScreen.fromPartial(object.question_screen.static_what_is_face_yoga),
      };
    }
    if (
      object.question_screen?.$case === "static_trust_doctors" &&
      object.question_screen?.static_trust_doctors !== undefined &&
      object.question_screen?.static_trust_doctors !== null
    ) {
      message.question_screen = {
        $case: "static_trust_doctors",
        static_trust_doctors: StaticScreen.fromPartial(object.question_screen.static_trust_doctors),
      };
    }
    if (
      object.question_screen?.$case === "static_cosmetologist_vs_lovi_screen" &&
      object.question_screen?.static_cosmetologist_vs_lovi_screen !== undefined &&
      object.question_screen?.static_cosmetologist_vs_lovi_screen !== null
    ) {
      message.question_screen = {
        $case: "static_cosmetologist_vs_lovi_screen",
        static_cosmetologist_vs_lovi_screen: StaticScreen.fromPartial(
          object.question_screen.static_cosmetologist_vs_lovi_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "progress_graph_screen" &&
      object.question_screen?.progress_graph_screen !== undefined &&
      object.question_screen?.progress_graph_screen !== null
    ) {
      message.question_screen = {
        $case: "progress_graph_screen",
        progress_graph_screen: ProgressGraphScreen.fromPartial(object.question_screen.progress_graph_screen),
      };
    }
    if (
      object.question_screen?.$case === "progress_weeks_screen" &&
      object.question_screen?.progress_weeks_screen !== undefined &&
      object.question_screen?.progress_weeks_screen !== null
    ) {
      message.question_screen = {
        $case: "progress_weeks_screen",
        progress_weeks_screen: ProgressWeeksScreen.fromPartial(object.question_screen.progress_weeks_screen),
      };
    }
    if (
      object.question_screen?.$case === "progress_bars_with_questions_screen" &&
      object.question_screen?.progress_bars_with_questions_screen !== undefined &&
      object.question_screen?.progress_bars_with_questions_screen !== null
    ) {
      message.question_screen = {
        $case: "progress_bars_with_questions_screen",
        progress_bars_with_questions_screen: ProgressBarsScreen.fromPartial(
          object.question_screen.progress_bars_with_questions_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "static_science_research" &&
      object.question_screen?.static_science_research !== undefined &&
      object.question_screen?.static_science_research !== null
    ) {
      message.question_screen = {
        $case: "static_science_research",
        static_science_research: StaticScreen.fromPartial(object.question_screen.static_science_research),
      };
    }
    if (
      object.question_screen?.$case === "welcome_select_luvly_screen" &&
      object.question_screen?.welcome_select_luvly_screen !== undefined &&
      object.question_screen?.welcome_select_luvly_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_select_luvly_screen",
        welcome_select_luvly_screen: WelcomeSelectScreen.fromPartial(
          object.question_screen.welcome_select_luvly_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "static_about_program_2" &&
      object.question_screen?.static_about_program_2 !== undefined &&
      object.question_screen?.static_about_program_2 !== null
    ) {
      message.question_screen = {
        $case: "static_about_program_2",
        static_about_program_2: StaticScreen.fromPartial(object.question_screen.static_about_program_2),
      };
    }
    if (
      object.question_screen?.$case === "welcome_select_3_screen" &&
      object.question_screen?.welcome_select_3_screen !== undefined &&
      object.question_screen?.welcome_select_3_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_select_3_screen",
        welcome_select_3_screen: WelcomeSelectScreen.fromPartial(object.question_screen.welcome_select_3_screen),
      };
    }
    if (
      object.question_screen?.$case === "product_fit_result_2_screen" &&
      object.question_screen?.product_fit_result_2_screen !== undefined &&
      object.question_screen?.product_fit_result_2_screen !== null
    ) {
      message.question_screen = {
        $case: "product_fit_result_2_screen",
        product_fit_result_2_screen: ProductFitResultScreen.fromPartial(
          object.question_screen.product_fit_result_2_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "about_program_screen" &&
      object.question_screen?.about_program_screen !== undefined &&
      object.question_screen?.about_program_screen !== null
    ) {
      message.question_screen = {
        $case: "about_program_screen",
        about_program_screen: AboutProgramScreen.fromPartial(object.question_screen.about_program_screen),
      };
    }
    if (
      object.question_screen?.$case === "welcome_select_4_screen" &&
      object.question_screen?.welcome_select_4_screen !== undefined &&
      object.question_screen?.welcome_select_4_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_select_4_screen",
        welcome_select_4_screen: WelcomeSelectScreen.fromPartial(object.question_screen.welcome_select_4_screen),
      };
    }
    if (
      object.question_screen?.$case === "web_scan_skin_id_screen" &&
      object.question_screen?.web_scan_skin_id_screen !== undefined &&
      object.question_screen?.web_scan_skin_id_screen !== null
    ) {
      message.question_screen = {
        $case: "web_scan_skin_id_screen",
        web_scan_skin_id_screen: WebScanSkinIDScreen.fromPartial(object.question_screen.web_scan_skin_id_screen),
      };
    }
    if (
      object.question_screen?.$case === "web_features_screen" &&
      object.question_screen?.web_features_screen !== undefined &&
      object.question_screen?.web_features_screen !== null
    ) {
      message.question_screen = {
        $case: "web_features_screen",
        web_features_screen: WebFeaturesScreen.fromPartial(object.question_screen.web_features_screen),
      };
    }
    if (
      object.question_screen?.$case === "scan_2_screen" &&
      object.question_screen?.scan_2_screen !== undefined &&
      object.question_screen?.scan_2_screen !== null
    ) {
      message.question_screen = {
        $case: "scan_2_screen",
        scan_2_screen: ScanQuestionScreen.fromPartial(object.question_screen.scan_2_screen),
      };
    }
    if (
      object.question_screen?.$case === "welcome_select_5_screen" &&
      object.question_screen?.welcome_select_5_screen !== undefined &&
      object.question_screen?.welcome_select_5_screen !== null
    ) {
      message.question_screen = {
        $case: "welcome_select_5_screen",
        welcome_select_5_screen: WelcomeSelectScreen.fromPartial(object.question_screen.welcome_select_5_screen),
      };
    }
    if (
      object.question_screen?.$case === "wellness_profile_with_facescan_result_screen" &&
      object.question_screen?.wellness_profile_with_facescan_result_screen !== undefined &&
      object.question_screen?.wellness_profile_with_facescan_result_screen !== null
    ) {
      message.question_screen = {
        $case: "wellness_profile_with_facescan_result_screen",
        wellness_profile_with_facescan_result_screen: WellnessProfileScreen.fromPartial(
          object.question_screen.wellness_profile_with_facescan_result_screen,
        ),
      };
    }
    if (
      object.question_screen?.$case === "scan_3_screen" &&
      object.question_screen?.scan_3_screen !== undefined &&
      object.question_screen?.scan_3_screen !== null
    ) {
      message.question_screen = {
        $case: "scan_3_screen",
        scan_3_screen: ScanQuestionScreen.fromPartial(object.question_screen.scan_3_screen),
      };
    }
    return message;
  },
};

function createBaseIfYouCancelScreen(): IfYouCancelScreen {
  return { title: undefined, variants: [], image: undefined };
}

export const IfYouCancelScreen = {
  fromJSON(object: any): IfYouCancelScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      variants: Array.isArray(object?.variants) ? object.variants.map((e: any) => String(e)) : [],
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
    };
  },

  toJSON(message: IfYouCancelScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.variants) {
      obj.variants = message.variants.map((e) => e);
    } else {
      obj.variants = [];
    }
    message.image !== undefined && (obj.image = message.image ? Image.toJSON(message.image) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<IfYouCancelScreen>, I>>(base?: I): IfYouCancelScreen {
    return IfYouCancelScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IfYouCancelScreen>, I>>(object: I): IfYouCancelScreen {
    const message = createBaseIfYouCancelScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.variants = object.variants?.map((e) => e) || [];
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    return message;
  },
};

function createBaseSubOfferScreen(): SubOfferScreen {
  return { title: undefined, description: undefined, rows: [], help_texts: [], primary_subtitle: "", variants: [] };
}

export const SubOfferScreen = {
  fromJSON(object: any): SubOfferScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      rows: Array.isArray(object?.rows) ? object.rows.map((e: any) => SubOfferScreen_Row.fromJSON(e)) : [],
      help_texts: Array.isArray(object?.help_texts) ? object.help_texts.map((e: any) => String(e)) : [],
      primary_subtitle: isSet(object.primary_subtitle) ? String(object.primary_subtitle) : "",
      variants: Array.isArray(object?.variants) ? object.variants.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SubOfferScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    if (message.rows) {
      obj.rows = message.rows.map((e) => e ? SubOfferScreen_Row.toJSON(e) : undefined);
    } else {
      obj.rows = [];
    }
    if (message.help_texts) {
      obj.help_texts = message.help_texts.map((e) => e);
    } else {
      obj.help_texts = [];
    }
    message.primary_subtitle !== undefined && (obj.primary_subtitle = message.primary_subtitle);
    if (message.variants) {
      obj.variants = message.variants.map((e) => e);
    } else {
      obj.variants = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubOfferScreen>, I>>(base?: I): SubOfferScreen {
    return SubOfferScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubOfferScreen>, I>>(object: I): SubOfferScreen {
    const message = createBaseSubOfferScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.rows = object.rows?.map((e) => SubOfferScreen_Row.fromPartial(e)) || [];
    message.help_texts = object.help_texts?.map((e) => e) || [];
    message.primary_subtitle = object.primary_subtitle ?? "";
    message.variants = object.variants?.map((e) => e) || [];
    return message;
  },
};

function createBaseSubOfferScreen_InfoBlock(): SubOfferScreen_InfoBlock {
  return { title: "", value: "", primary: false };
}

export const SubOfferScreen_InfoBlock = {
  fromJSON(object: any): SubOfferScreen_InfoBlock {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      value: isSet(object.value) ? String(object.value) : "",
      primary: isSet(object.primary) ? Boolean(object.primary) : false,
    };
  },

  toJSON(message: SubOfferScreen_InfoBlock): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.value !== undefined && (obj.value = message.value);
    message.primary !== undefined && (obj.primary = message.primary);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubOfferScreen_InfoBlock>, I>>(base?: I): SubOfferScreen_InfoBlock {
    return SubOfferScreen_InfoBlock.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubOfferScreen_InfoBlock>, I>>(object: I): SubOfferScreen_InfoBlock {
    const message = createBaseSubOfferScreen_InfoBlock();
    message.title = object.title ?? "";
    message.value = object.value ?? "";
    message.primary = object.primary ?? false;
    return message;
  },
};

function createBaseSubOfferScreen_Divider(): SubOfferScreen_Divider {
  return {};
}

export const SubOfferScreen_Divider = {
  fromJSON(_: any): SubOfferScreen_Divider {
    return {};
  },

  toJSON(_: SubOfferScreen_Divider): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SubOfferScreen_Divider>, I>>(base?: I): SubOfferScreen_Divider {
    return SubOfferScreen_Divider.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubOfferScreen_Divider>, I>>(_: I): SubOfferScreen_Divider {
    const message = createBaseSubOfferScreen_Divider();
    return message;
  },
};

function createBaseSubOfferScreen_Row(): SubOfferScreen_Row {
  return { row: undefined };
}

export const SubOfferScreen_Row = {
  fromJSON(object: any): SubOfferScreen_Row {
    return {
      row: isSet(object.description)
        ? { $case: "description", description: SubOfferScreen_InfoBlock.fromJSON(object.description) }
        : isSet(object.divider)
        ? { $case: "divider", divider: SubOfferScreen_Divider.fromJSON(object.divider) }
        : undefined,
    };
  },

  toJSON(message: SubOfferScreen_Row): unknown {
    const obj: any = {};
    message.row?.$case === "description" && (obj.description = message.row?.description
      ? SubOfferScreen_InfoBlock.toJSON(message.row?.description)
      : undefined);
    message.row?.$case === "divider" &&
      (obj.divider = message.row?.divider ? SubOfferScreen_Divider.toJSON(message.row?.divider) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubOfferScreen_Row>, I>>(base?: I): SubOfferScreen_Row {
    return SubOfferScreen_Row.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubOfferScreen_Row>, I>>(object: I): SubOfferScreen_Row {
    const message = createBaseSubOfferScreen_Row();
    if (
      object.row?.$case === "description" && object.row?.description !== undefined && object.row?.description !== null
    ) {
      message.row = { $case: "description", description: SubOfferScreen_InfoBlock.fromPartial(object.row.description) };
    }
    if (object.row?.$case === "divider" && object.row?.divider !== undefined && object.row?.divider !== null) {
      message.row = { $case: "divider", divider: SubOfferScreen_Divider.fromPartial(object.row.divider) };
    }
    return message;
  },
};

function createBaseMessageWithAnswersScreen(): MessageWithAnswersScreen {
  return { title: undefined, description: undefined, image: undefined, variants: [] };
}

export const MessageWithAnswersScreen = {
  fromJSON(object: any): MessageWithAnswersScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      variants: Array.isArray(object?.variants) ? object.variants.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: MessageWithAnswersScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.image !== undefined && (obj.image = message.image ? Image.toJSON(message.image) : undefined);
    if (message.variants) {
      obj.variants = message.variants.map((e) => e);
    } else {
      obj.variants = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageWithAnswersScreen>, I>>(base?: I): MessageWithAnswersScreen {
    return MessageWithAnswersScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageWithAnswersScreen>, I>>(object: I): MessageWithAnswersScreen {
    const message = createBaseMessageWithAnswersScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.variants = object.variants?.map((e) => e) || [];
    return message;
  },
};

function createBaseSubscriptionStatusInfo(): SubscriptionStatusInfo {
  return {
    subscription_status: SubscriptionStatus.UNKNOWN,
    action_data: undefined,
    crated_at: "",
    next_charge_at: "",
    currency_code: "",
    subscription_price_per_unit_cents: 0,
  };
}

export const SubscriptionStatusInfo = {
  fromJSON(object: any): SubscriptionStatusInfo {
    return {
      subscription_status: isSet(object.subscription_status)
        ? subscriptionStatusFromJSON(object.subscription_status)
        : SubscriptionStatus.UNKNOWN,
      action_data: isSet(object.action_data) ? ActionData.fromJSON(object.action_data) : undefined,
      crated_at: isSet(object.crated_at) ? String(object.crated_at) : "",
      next_charge_at: isSet(object.next_charge_at) ? String(object.next_charge_at) : "",
      currency_code: isSet(object.currency_code) ? String(object.currency_code) : "",
      subscription_price_per_unit_cents: isSet(object.subscription_price_per_unit_cents)
        ? Number(object.subscription_price_per_unit_cents)
        : 0,
    };
  },

  toJSON(message: SubscriptionStatusInfo): unknown {
    const obj: any = {};
    message.subscription_status !== undefined &&
      (obj.subscription_status = subscriptionStatusToJSON(message.subscription_status));
    message.action_data !== undefined &&
      (obj.action_data = message.action_data ? ActionData.toJSON(message.action_data) : undefined);
    message.crated_at !== undefined && (obj.crated_at = message.crated_at);
    message.next_charge_at !== undefined && (obj.next_charge_at = message.next_charge_at);
    message.currency_code !== undefined && (obj.currency_code = message.currency_code);
    message.subscription_price_per_unit_cents !== undefined &&
      (obj.subscription_price_per_unit_cents = Math.round(message.subscription_price_per_unit_cents));
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionStatusInfo>, I>>(base?: I): SubscriptionStatusInfo {
    return SubscriptionStatusInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionStatusInfo>, I>>(object: I): SubscriptionStatusInfo {
    const message = createBaseSubscriptionStatusInfo();
    message.subscription_status = object.subscription_status ?? SubscriptionStatus.UNKNOWN;
    message.action_data = (object.action_data !== undefined && object.action_data !== null)
      ? ActionData.fromPartial(object.action_data)
      : undefined;
    message.crated_at = object.crated_at ?? "";
    message.next_charge_at = object.next_charge_at ?? "";
    message.currency_code = object.currency_code ?? "";
    message.subscription_price_per_unit_cents = object.subscription_price_per_unit_cents ?? 0;
    return message;
  },
};

function createBaseSubscriptionsInfo(): SubscriptionsInfo {
  return { subscriptions: [] };
}

export const SubscriptionsInfo = {
  fromJSON(object: any): SubscriptionsInfo {
    return {
      subscriptions: Array.isArray(object?.subscriptions)
        ? object.subscriptions.map((e: any) => SubscriptionStatusInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SubscriptionsInfo): unknown {
    const obj: any = {};
    if (message.subscriptions) {
      obj.subscriptions = message.subscriptions.map((e) => e ? SubscriptionStatusInfo.toJSON(e) : undefined);
    } else {
      obj.subscriptions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionsInfo>, I>>(base?: I): SubscriptionsInfo {
    return SubscriptionsInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionsInfo>, I>>(object: I): SubscriptionsInfo {
    const message = createBaseSubscriptionsInfo();
    message.subscriptions = object.subscriptions?.map((e) => SubscriptionStatusInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSubscriptionStatusScreen(): SubscriptionStatusScreen {
  return {
    title: undefined,
    description: undefined,
    subscription_status: SubscriptionStatus.UNKNOWN,
    subscriptions_list: [],
    email: "",
  };
}

export const SubscriptionStatusScreen = {
  fromJSON(object: any): SubscriptionStatusScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      subscription_status: isSet(object.subscription_status)
        ? subscriptionStatusFromJSON(object.subscription_status)
        : SubscriptionStatus.UNKNOWN,
      subscriptions_list: Array.isArray(object?.subscriptions_list)
        ? object.subscriptions_list.map((e: any) => SubscriptionStatusInfo.fromJSON(e))
        : [],
      email: isSet(object.email) ? String(object.email) : "",
    };
  },

  toJSON(message: SubscriptionStatusScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.subscription_status !== undefined &&
      (obj.subscription_status = subscriptionStatusToJSON(message.subscription_status));
    if (message.subscriptions_list) {
      obj.subscriptions_list = message.subscriptions_list.map((e) => e ? SubscriptionStatusInfo.toJSON(e) : undefined);
    } else {
      obj.subscriptions_list = [];
    }
    message.email !== undefined && (obj.email = message.email);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionStatusScreen>, I>>(base?: I): SubscriptionStatusScreen {
    return SubscriptionStatusScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionStatusScreen>, I>>(object: I): SubscriptionStatusScreen {
    const message = createBaseSubscriptionStatusScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.subscription_status = object.subscription_status ?? SubscriptionStatus.UNKNOWN;
    message.subscriptions_list = object.subscriptions_list?.map((e) => SubscriptionStatusInfo.fromPartial(e)) || [];
    message.email = object.email ?? "";
    return message;
  },
};

function createBaseAnalyticsEventParameters(): AnalyticsEventParameters {
  return { analytics_id: "", targets: [], parameters: {} };
}

export const AnalyticsEventParameters = {
  fromJSON(object: any): AnalyticsEventParameters {
    return {
      analytics_id: isSet(object.analytics_id) ? String(object.analytics_id) : "",
      targets: Array.isArray(object?.targets)
        ? object.targets.map((e: any) => analyticsEventParameters_TargetFromJSON(e))
        : [],
      parameters: isObject(object.parameters)
        ? Object.entries(object.parameters).reduce<{ [key: string]: ParameterValues }>((acc, [key, value]) => {
          acc[key] = ParameterValues.fromJSON(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: AnalyticsEventParameters): unknown {
    const obj: any = {};
    message.analytics_id !== undefined && (obj.analytics_id = message.analytics_id);
    if (message.targets) {
      obj.targets = message.targets.map((e) => analyticsEventParameters_TargetToJSON(e));
    } else {
      obj.targets = [];
    }
    obj.parameters = {};
    if (message.parameters) {
      Object.entries(message.parameters).forEach(([k, v]) => {
        obj.parameters[k] = ParameterValues.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AnalyticsEventParameters>, I>>(base?: I): AnalyticsEventParameters {
    return AnalyticsEventParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AnalyticsEventParameters>, I>>(object: I): AnalyticsEventParameters {
    const message = createBaseAnalyticsEventParameters();
    message.analytics_id = object.analytics_id ?? "";
    message.targets = object.targets?.map((e) => e) || [];
    message.parameters = Object.entries(object.parameters ?? {}).reduce<{ [key: string]: ParameterValues }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ParameterValues.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseAnalyticsEventParameters_ParametersEntry(): AnalyticsEventParameters_ParametersEntry {
  return { key: "", value: undefined };
}

export const AnalyticsEventParameters_ParametersEntry = {
  fromJSON(object: any): AnalyticsEventParameters_ParametersEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ParameterValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: AnalyticsEventParameters_ParametersEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ParameterValues.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<AnalyticsEventParameters_ParametersEntry>, I>>(
    base?: I,
  ): AnalyticsEventParameters_ParametersEntry {
    return AnalyticsEventParameters_ParametersEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AnalyticsEventParameters_ParametersEntry>, I>>(
    object: I,
  ): AnalyticsEventParameters_ParametersEntry {
    const message = createBaseAnalyticsEventParameters_ParametersEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ParameterValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseQuestionScreenParameters(): QuestionScreenParameters {
  return {
    id: "",
    page_num: 0,
    design_id: "",
    show_progress: false,
    onboarding_id: "",
    background_id: "",
    custom_string: "",
    button_text: "",
    section_name: "",
    hide_answers: false,
    analytics_event_parameters: undefined,
    progress_bar_theme: "",
    always_hides_nav_bar: false,
    progress_indicator: undefined,
    type: "",
    title: undefined,
    description: undefined,
    image: "",
    answers: [],
    description_2: undefined,
    view_variant: "",
    should_make_screenshot: false,
  };
}

export const QuestionScreenParameters = {
  fromJSON(object: any): QuestionScreenParameters {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      page_num: isSet(object.page_num) ? Number(object.page_num) : 0,
      design_id: isSet(object.design_id) ? String(object.design_id) : "",
      show_progress: isSet(object.show_progress) ? Boolean(object.show_progress) : false,
      onboarding_id: isSet(object.onboarding_id) ? String(object.onboarding_id) : "",
      background_id: isSet(object.background_id) ? String(object.background_id) : "",
      custom_string: isSet(object.custom_string) ? String(object.custom_string) : "",
      button_text: isSet(object.button_text) ? String(object.button_text) : "",
      section_name: isSet(object.section_name) ? String(object.section_name) : "",
      hide_answers: isSet(object.hide_answers) ? Boolean(object.hide_answers) : false,
      analytics_event_parameters: isSet(object.analytics_event_parameters)
        ? AnalyticsEventParameters.fromJSON(object.analytics_event_parameters)
        : undefined,
      progress_bar_theme: isSet(object.progress_bar_theme) ? String(object.progress_bar_theme) : "",
      always_hides_nav_bar: isSet(object.always_hides_nav_bar) ? Boolean(object.always_hides_nav_bar) : false,
      progress_indicator: isSet(object.progress_indicator)
        ? QuestionScreenParameters_ProgressIndicator.fromJSON(object.progress_indicator)
        : undefined,
      type: isSet(object.type) ? String(object.type) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
      answers: Array.isArray(object?.answers)
        ? object.answers.map((e: any) => String(e))
        : [],
      description_2: isSet(object.description_2) ? AttributedString.fromJSON(object.description_2) : undefined,
      view_variant: isSet(object.view_variant) ? String(object.view_variant) : "",
      should_make_screenshot: isSet(object.should_make_screenshot) ? Boolean(object.should_make_screenshot) : false,
    };
  },

  toJSON(message: QuestionScreenParameters): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.page_num !== undefined && (obj.page_num = Math.round(message.page_num));
    message.design_id !== undefined && (obj.design_id = message.design_id);
    message.show_progress !== undefined && (obj.show_progress = message.show_progress);
    message.onboarding_id !== undefined && (obj.onboarding_id = message.onboarding_id);
    message.background_id !== undefined && (obj.background_id = message.background_id);
    message.custom_string !== undefined && (obj.custom_string = message.custom_string);
    message.button_text !== undefined && (obj.button_text = message.button_text);
    message.section_name !== undefined && (obj.section_name = message.section_name);
    message.hide_answers !== undefined && (obj.hide_answers = message.hide_answers);
    message.analytics_event_parameters !== undefined &&
      (obj.analytics_event_parameters = message.analytics_event_parameters
        ? AnalyticsEventParameters.toJSON(message.analytics_event_parameters)
        : undefined);
    message.progress_bar_theme !== undefined && (obj.progress_bar_theme = message.progress_bar_theme);
    message.always_hides_nav_bar !== undefined && (obj.always_hides_nav_bar = message.always_hides_nav_bar);
    message.progress_indicator !== undefined && (obj.progress_indicator = message.progress_indicator
      ? QuestionScreenParameters_ProgressIndicator.toJSON(message.progress_indicator)
      : undefined);
    message.type !== undefined && (obj.type = message.type);
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.image !== undefined && (obj.image = message.image);
    if (message.answers) {
      obj.answers = message.answers.map((e) => e);
    } else {
      obj.answers = [];
    }
    message.description_2 !== undefined &&
      (obj.description_2 = message.description_2 ? AttributedString.toJSON(message.description_2) : undefined);
    message.view_variant !== undefined && (obj.view_variant = message.view_variant);
    message.should_make_screenshot !== undefined && (obj.should_make_screenshot = message.should_make_screenshot);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuestionScreenParameters>, I>>(base?: I): QuestionScreenParameters {
    return QuestionScreenParameters.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuestionScreenParameters>, I>>(object: I): QuestionScreenParameters {
    const message = createBaseQuestionScreenParameters();
    message.id = object.id ?? "";
    message.page_num = object.page_num ?? 0;
    message.design_id = object.design_id ?? "";
    message.show_progress = object.show_progress ?? false;
    message.onboarding_id = object.onboarding_id ?? "";
    message.background_id = object.background_id ?? "";
    message.custom_string = object.custom_string ?? "";
    message.button_text = object.button_text ?? "";
    message.section_name = object.section_name ?? "";
    message.hide_answers = object.hide_answers ?? false;
    message.analytics_event_parameters =
      (object.analytics_event_parameters !== undefined && object.analytics_event_parameters !== null)
        ? AnalyticsEventParameters.fromPartial(object.analytics_event_parameters)
        : undefined;
    message.progress_bar_theme = object.progress_bar_theme ?? "";
    message.always_hides_nav_bar = object.always_hides_nav_bar ?? false;
    message.progress_indicator = (object.progress_indicator !== undefined && object.progress_indicator !== null)
      ? QuestionScreenParameters_ProgressIndicator.fromPartial(object.progress_indicator)
      : undefined;
    message.type = object.type ?? "";
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.image = object.image ?? "";
    message.answers = object.answers?.map((e) => e) || [];
    message.description_2 = (object.description_2 !== undefined && object.description_2 !== null)
      ? AttributedString.fromPartial(object.description_2)
      : undefined;
    message.view_variant = object.view_variant ?? "";
    message.should_make_screenshot = object.should_make_screenshot ?? false;
    return message;
  },
};

function createBaseQuestionScreenParameters_ProgressIndicator(): QuestionScreenParameters_ProgressIndicator {
  return {
    sections_count: 0,
    active_section_index: 0,
    pages_in_active_section_count: 0,
    active_page_num: 0,
    active_section_progress: 0,
  };
}

export const QuestionScreenParameters_ProgressIndicator = {
  fromJSON(object: any): QuestionScreenParameters_ProgressIndicator {
    return {
      sections_count: isSet(object.sections_count) ? Number(object.sections_count) : 0,
      active_section_index: isSet(object.active_section_index) ? Number(object.active_section_index) : 0,
      pages_in_active_section_count: isSet(object.pages_in_active_section_count)
        ? Number(object.pages_in_active_section_count)
        : 0,
      active_page_num: isSet(object.active_page_num) ? Number(object.active_page_num) : 0,
      active_section_progress: isSet(object.active_section_progress) ? Number(object.active_section_progress) : 0,
    };
  },

  toJSON(message: QuestionScreenParameters_ProgressIndicator): unknown {
    const obj: any = {};
    message.sections_count !== undefined && (obj.sections_count = Math.round(message.sections_count));
    message.active_section_index !== undefined && (obj.active_section_index = Math.round(message.active_section_index));
    message.pages_in_active_section_count !== undefined &&
      (obj.pages_in_active_section_count = Math.round(message.pages_in_active_section_count));
    message.active_page_num !== undefined && (obj.active_page_num = Math.round(message.active_page_num));
    message.active_section_progress !== undefined && (obj.active_section_progress = message.active_section_progress);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuestionScreenParameters_ProgressIndicator>, I>>(
    base?: I,
  ): QuestionScreenParameters_ProgressIndicator {
    return QuestionScreenParameters_ProgressIndicator.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuestionScreenParameters_ProgressIndicator>, I>>(
    object: I,
  ): QuestionScreenParameters_ProgressIndicator {
    const message = createBaseQuestionScreenParameters_ProgressIndicator();
    message.sections_count = object.sections_count ?? 0;
    message.active_section_index = object.active_section_index ?? 0;
    message.pages_in_active_section_count = object.pages_in_active_section_count ?? 0;
    message.active_page_num = object.active_page_num ?? 0;
    message.active_section_progress = object.active_section_progress ?? 0;
    return message;
  },
};

function createBaseDateQuestionScreen(): DateQuestionScreen {
  return { title: "", description: "" };
}

export const DateQuestionScreen = {
  fromJSON(object: any): DateQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: DateQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<DateQuestionScreen>, I>>(base?: I): DateQuestionScreen {
    return DateQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DateQuestionScreen>, I>>(object: I): DateQuestionScreen {
    const message = createBaseDateQuestionScreen();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseTimeQuestionScreen(): TimeQuestionScreen {
  return { title: "", description: "" };
}

export const TimeQuestionScreen = {
  fromJSON(object: any): TimeQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: TimeQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<TimeQuestionScreen>, I>>(base?: I): TimeQuestionScreen {
    return TimeQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TimeQuestionScreen>, I>>(object: I): TimeQuestionScreen {
    const message = createBaseTimeQuestionScreen();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseIntValueQuestionScreen(): IntValueQuestionScreen {
  return { title: "", description: "", min: 0, max: 0 };
}

export const IntValueQuestionScreen = {
  fromJSON(object: any): IntValueQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      min: isSet(object.min) ? Number(object.min) : 0,
      max: isSet(object.max) ? Number(object.max) : 0,
    };
  },

  toJSON(message: IntValueQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    message.min !== undefined && (obj.min = Math.round(message.min));
    message.max !== undefined && (obj.max = Math.round(message.max));
    return obj;
  },

  create<I extends Exact<DeepPartial<IntValueQuestionScreen>, I>>(base?: I): IntValueQuestionScreen {
    return IntValueQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IntValueQuestionScreen>, I>>(object: I): IntValueQuestionScreen {
    const message = createBaseIntValueQuestionScreen();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.min = object.min ?? 0;
    message.max = object.max ?? 0;
    return message;
  },
};

function createBaseStringValueQuestionScreen(): StringValueQuestionScreen {
  return { title: "", description: "", text_field: undefined };
}

export const StringValueQuestionScreen = {
  fromJSON(object: any): StringValueQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      text_field: isSet(object.text_field) ? IOSTextField.fromJSON(object.text_field) : undefined,
    };
  },

  toJSON(message: StringValueQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    message.text_field !== undefined &&
      (obj.text_field = message.text_field ? IOSTextField.toJSON(message.text_field) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<StringValueQuestionScreen>, I>>(base?: I): StringValueQuestionScreen {
    return StringValueQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StringValueQuestionScreen>, I>>(object: I): StringValueQuestionScreen {
    const message = createBaseStringValueQuestionScreen();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.text_field = (object.text_field !== undefined && object.text_field !== null)
      ? IOSTextField.fromPartial(object.text_field)
      : undefined;
    return message;
  },
};

function createBasePrivacyQuestionScreen(): PrivacyQuestionScreen {
  return { privacy_url: "", terms_url: "" };
}

export const PrivacyQuestionScreen = {
  fromJSON(object: any): PrivacyQuestionScreen {
    return {
      privacy_url: isSet(object.privacy_url) ? String(object.privacy_url) : "",
      terms_url: isSet(object.terms_url) ? String(object.terms_url) : "",
    };
  },

  toJSON(message: PrivacyQuestionScreen): unknown {
    const obj: any = {};
    message.privacy_url !== undefined && (obj.privacy_url = message.privacy_url);
    message.terms_url !== undefined && (obj.terms_url = message.terms_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<PrivacyQuestionScreen>, I>>(base?: I): PrivacyQuestionScreen {
    return PrivacyQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrivacyQuestionScreen>, I>>(object: I): PrivacyQuestionScreen {
    const message = createBasePrivacyQuestionScreen();
    message.privacy_url = object.privacy_url ?? "";
    message.terms_url = object.terms_url ?? "";
    return message;
  },
};

function createBaseMessageQuestionScreen(): MessageQuestionScreen {
  return {
    title: "",
    attributed_title: undefined,
    description: "",
    image: "",
    more_link: undefined,
    image_width: 0,
    image_height: 0,
    video: "",
    buttons_with_linked_quiz_ids: [],
  };
}

export const MessageQuestionScreen = {
  fromJSON(object: any): MessageQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      description: isSet(object.description) ? String(object.description) : "",
      image: isSet(object.image) ? String(object.image) : "",
      more_link: isSet(object.more_link) ? LinkData.fromJSON(object.more_link) : undefined,
      image_width: isSet(object.image_width) ? Number(object.image_width) : 0,
      image_height: isSet(object.image_height) ? Number(object.image_height) : 0,
      video: isSet(object.video) ? String(object.video) : "",
      buttons_with_linked_quiz_ids: Array.isArray(object?.buttons_with_linked_quiz_ids)
        ? object.buttons_with_linked_quiz_ids.map((e: any) => MessageQuestionScreen_ButtonWithLinkedQuizID.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MessageQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.attributed_title !== undefined &&
      (obj.attributed_title = message.attributed_title ? AttributedString.toJSON(message.attributed_title) : undefined);
    message.description !== undefined && (obj.description = message.description);
    message.image !== undefined && (obj.image = message.image);
    message.more_link !== undefined &&
      (obj.more_link = message.more_link ? LinkData.toJSON(message.more_link) : undefined);
    message.image_width !== undefined && (obj.image_width = Math.round(message.image_width));
    message.image_height !== undefined && (obj.image_height = Math.round(message.image_height));
    message.video !== undefined && (obj.video = message.video);
    if (message.buttons_with_linked_quiz_ids) {
      obj.buttons_with_linked_quiz_ids = message.buttons_with_linked_quiz_ids.map((e) =>
        e ? MessageQuestionScreen_ButtonWithLinkedQuizID.toJSON(e) : undefined
      );
    } else {
      obj.buttons_with_linked_quiz_ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageQuestionScreen>, I>>(base?: I): MessageQuestionScreen {
    return MessageQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageQuestionScreen>, I>>(object: I): MessageQuestionScreen {
    const message = createBaseMessageQuestionScreen();
    message.title = object.title ?? "";
    message.attributed_title = (object.attributed_title !== undefined && object.attributed_title !== null)
      ? AttributedString.fromPartial(object.attributed_title)
      : undefined;
    message.description = object.description ?? "";
    message.image = object.image ?? "";
    message.more_link = (object.more_link !== undefined && object.more_link !== null)
      ? LinkData.fromPartial(object.more_link)
      : undefined;
    message.image_width = object.image_width ?? 0;
    message.image_height = object.image_height ?? 0;
    message.video = object.video ?? "";
    message.buttons_with_linked_quiz_ids =
      object.buttons_with_linked_quiz_ids?.map((e) => MessageQuestionScreen_ButtonWithLinkedQuizID.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseMessageQuestionScreen_ButtonWithLinkedQuizID(): MessageQuestionScreen_ButtonWithLinkedQuizID {
  return { button: undefined, quiz_id: "" };
}

export const MessageQuestionScreen_ButtonWithLinkedQuizID = {
  fromJSON(object: any): MessageQuestionScreen_ButtonWithLinkedQuizID {
    return {
      button: isSet(object.button) ? FilledButton.fromJSON(object.button) : undefined,
      quiz_id: isSet(object.quiz_id) ? String(object.quiz_id) : "",
    };
  },

  toJSON(message: MessageQuestionScreen_ButtonWithLinkedQuizID): unknown {
    const obj: any = {};
    message.button !== undefined && (obj.button = message.button ? FilledButton.toJSON(message.button) : undefined);
    message.quiz_id !== undefined && (obj.quiz_id = message.quiz_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageQuestionScreen_ButtonWithLinkedQuizID>, I>>(
    base?: I,
  ): MessageQuestionScreen_ButtonWithLinkedQuizID {
    return MessageQuestionScreen_ButtonWithLinkedQuizID.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageQuestionScreen_ButtonWithLinkedQuizID>, I>>(
    object: I,
  ): MessageQuestionScreen_ButtonWithLinkedQuizID {
    const message = createBaseMessageQuestionScreen_ButtonWithLinkedQuizID();
    message.button = (object.button !== undefined && object.button !== null)
      ? FilledButton.fromPartial(object.button)
      : undefined;
    message.quiz_id = object.quiz_id ?? "";
    return message;
  },
};

function createBaseMessageQuoteScreen(): MessageQuoteScreen {
  return {
    title: "",
    attributed_title: undefined,
    description: "",
    image: "",
    more_link: undefined,
    image_width: 0,
    image_height: 0,
    author: "",
  };
}

export const MessageQuoteScreen = {
  fromJSON(object: any): MessageQuoteScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      description: isSet(object.description) ? String(object.description) : "",
      image: isSet(object.image) ? String(object.image) : "",
      more_link: isSet(object.more_link) ? LinkData.fromJSON(object.more_link) : undefined,
      image_width: isSet(object.image_width) ? Number(object.image_width) : 0,
      image_height: isSet(object.image_height) ? Number(object.image_height) : 0,
      author: isSet(object.author) ? String(object.author) : "",
    };
  },

  toJSON(message: MessageQuoteScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.attributed_title !== undefined &&
      (obj.attributed_title = message.attributed_title ? AttributedString.toJSON(message.attributed_title) : undefined);
    message.description !== undefined && (obj.description = message.description);
    message.image !== undefined && (obj.image = message.image);
    message.more_link !== undefined &&
      (obj.more_link = message.more_link ? LinkData.toJSON(message.more_link) : undefined);
    message.image_width !== undefined && (obj.image_width = Math.round(message.image_width));
    message.image_height !== undefined && (obj.image_height = Math.round(message.image_height));
    message.author !== undefined && (obj.author = message.author);
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageQuoteScreen>, I>>(base?: I): MessageQuoteScreen {
    return MessageQuoteScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageQuoteScreen>, I>>(object: I): MessageQuoteScreen {
    const message = createBaseMessageQuoteScreen();
    message.title = object.title ?? "";
    message.attributed_title = (object.attributed_title !== undefined && object.attributed_title !== null)
      ? AttributedString.fromPartial(object.attributed_title)
      : undefined;
    message.description = object.description ?? "";
    message.image = object.image ?? "";
    message.more_link = (object.more_link !== undefined && object.more_link !== null)
      ? LinkData.fromPartial(object.more_link)
      : undefined;
    message.image_width = object.image_width ?? 0;
    message.image_height = object.image_height ?? 0;
    message.author = object.author ?? "";
    return message;
  },
};

function createBaseMessageWithBackgroundScreen(): MessageWithBackgroundScreen {
  return {
    title: "",
    attributed_title: undefined,
    description: "",
    attributed_description: undefined,
    background_image: "",
    more_link: undefined,
  };
}

export const MessageWithBackgroundScreen = {
  fromJSON(object: any): MessageWithBackgroundScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      description: isSet(object.description) ? String(object.description) : "",
      attributed_description: isSet(object.attributed_description)
        ? AttributedString.fromJSON(object.attributed_description)
        : undefined,
      background_image: isSet(object.background_image) ? String(object.background_image) : "",
      more_link: isSet(object.more_link) ? LinkData.fromJSON(object.more_link) : undefined,
    };
  },

  toJSON(message: MessageWithBackgroundScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.attributed_title !== undefined &&
      (obj.attributed_title = message.attributed_title ? AttributedString.toJSON(message.attributed_title) : undefined);
    message.description !== undefined && (obj.description = message.description);
    message.attributed_description !== undefined && (obj.attributed_description = message.attributed_description
      ? AttributedString.toJSON(message.attributed_description)
      : undefined);
    message.background_image !== undefined && (obj.background_image = message.background_image);
    message.more_link !== undefined &&
      (obj.more_link = message.more_link ? LinkData.toJSON(message.more_link) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageWithBackgroundScreen>, I>>(base?: I): MessageWithBackgroundScreen {
    return MessageWithBackgroundScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageWithBackgroundScreen>, I>>(object: I): MessageWithBackgroundScreen {
    const message = createBaseMessageWithBackgroundScreen();
    message.title = object.title ?? "";
    message.attributed_title = (object.attributed_title !== undefined && object.attributed_title !== null)
      ? AttributedString.fromPartial(object.attributed_title)
      : undefined;
    message.description = object.description ?? "";
    message.attributed_description =
      (object.attributed_description !== undefined && object.attributed_description !== null)
        ? AttributedString.fromPartial(object.attributed_description)
        : undefined;
    message.background_image = object.background_image ?? "";
    message.more_link = (object.more_link !== undefined && object.more_link !== null)
      ? LinkData.fromPartial(object.more_link)
      : undefined;
    return message;
  },
};

function createBaseLoaderOptions(): LoaderOptions {
  return { DisplaySecondsPerScreen: 0 };
}

export const LoaderOptions = {
  fromJSON(object: any): LoaderOptions {
    return {
      DisplaySecondsPerScreen: isSet(object.DisplaySecondsPerScreen) ? Number(object.DisplaySecondsPerScreen) : 0,
    };
  },

  toJSON(message: LoaderOptions): unknown {
    const obj: any = {};
    message.DisplaySecondsPerScreen !== undefined && (obj.DisplaySecondsPerScreen = message.DisplaySecondsPerScreen);
    return obj;
  },

  create<I extends Exact<DeepPartial<LoaderOptions>, I>>(base?: I): LoaderOptions {
    return LoaderOptions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LoaderOptions>, I>>(object: I): LoaderOptions {
    const message = createBaseLoaderOptions();
    message.DisplaySecondsPerScreen = object.DisplaySecondsPerScreen ?? 0;
    return message;
  },
};

function createBaseCardMessagesLoaderScreen(): CardMessagesLoaderScreen {
  return { loader_options: undefined, messages: [], process_title: "" };
}

export const CardMessagesLoaderScreen = {
  fromJSON(object: any): CardMessagesLoaderScreen {
    return {
      loader_options: isSet(object.loader_options) ? LoaderOptions.fromJSON(object.loader_options) : undefined,
      messages: Array.isArray(object?.messages) ? object.messages.map((e: any) => String(e)) : [],
      process_title: isSet(object.process_title) ? String(object.process_title) : "",
    };
  },

  toJSON(message: CardMessagesLoaderScreen): unknown {
    const obj: any = {};
    message.loader_options !== undefined &&
      (obj.loader_options = message.loader_options ? LoaderOptions.toJSON(message.loader_options) : undefined);
    if (message.messages) {
      obj.messages = message.messages.map((e) => e);
    } else {
      obj.messages = [];
    }
    message.process_title !== undefined && (obj.process_title = message.process_title);
    return obj;
  },

  create<I extends Exact<DeepPartial<CardMessagesLoaderScreen>, I>>(base?: I): CardMessagesLoaderScreen {
    return CardMessagesLoaderScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CardMessagesLoaderScreen>, I>>(object: I): CardMessagesLoaderScreen {
    const message = createBaseCardMessagesLoaderScreen();
    message.loader_options = (object.loader_options !== undefined && object.loader_options !== null)
      ? LoaderOptions.fromPartial(object.loader_options)
      : undefined;
    message.messages = object.messages?.map((e) => e) || [];
    message.process_title = object.process_title ?? "";
    return message;
  },
};

function createBaseTitlesLoaderScreen(): TitlesLoaderScreen {
  return { loader_options: undefined, messages: [], final_message: "" };
}

export const TitlesLoaderScreen = {
  fromJSON(object: any): TitlesLoaderScreen {
    return {
      loader_options: isSet(object.loader_options) ? LoaderOptions.fromJSON(object.loader_options) : undefined,
      messages: Array.isArray(object?.messages) ? object.messages.map((e: any) => String(e)) : [],
      final_message: isSet(object.final_message) ? String(object.final_message) : "",
    };
  },

  toJSON(message: TitlesLoaderScreen): unknown {
    const obj: any = {};
    message.loader_options !== undefined &&
      (obj.loader_options = message.loader_options ? LoaderOptions.toJSON(message.loader_options) : undefined);
    if (message.messages) {
      obj.messages = message.messages.map((e) => e);
    } else {
      obj.messages = [];
    }
    message.final_message !== undefined && (obj.final_message = message.final_message);
    return obj;
  },

  create<I extends Exact<DeepPartial<TitlesLoaderScreen>, I>>(base?: I): TitlesLoaderScreen {
    return TitlesLoaderScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TitlesLoaderScreen>, I>>(object: I): TitlesLoaderScreen {
    const message = createBaseTitlesLoaderScreen();
    message.loader_options = (object.loader_options !== undefined && object.loader_options !== null)
      ? LoaderOptions.fromPartial(object.loader_options)
      : undefined;
    message.messages = object.messages?.map((e) => e) || [];
    message.final_message = object.final_message ?? "";
    return message;
  },
};

function createBaseSingleVariantQuestionScreen(): SingleVariantQuestionScreen {
  return {
    title: "",
    attributed_title: undefined,
    up_title: undefined,
    description: "",
    variants: [],
    variant_properties: {},
    image: "",
    layout: QuestionScreenLayout.SCREEN_LAYOUT_NONE,
    preselected_variant: "",
  };
}

export const SingleVariantQuestionScreen = {
  fromJSON(object: any): SingleVariantQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      up_title: isSet(object.up_title) ? AttributedString.fromJSON(object.up_title) : undefined,
      description: isSet(object.description) ? String(object.description) : "",
      variants: Array.isArray(object?.variants) ? object.variants.map((e: any) => String(e)) : [],
      variant_properties: isObject(object.variant_properties)
        ? Object.entries(object.variant_properties).reduce<{ [key: string]: VariantProperties }>(
          (acc, [key, value]) => {
            acc[key] = VariantProperties.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      image: isSet(object.image) ? String(object.image) : "",
      layout: isSet(object.layout)
        ? questionScreenLayoutFromJSON(object.layout)
        : QuestionScreenLayout.SCREEN_LAYOUT_NONE,
      preselected_variant: isSet(object.preselected_variant) ? String(object.preselected_variant) : "",
    };
  },

  toJSON(message: SingleVariantQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.attributed_title !== undefined &&
      (obj.attributed_title = message.attributed_title ? AttributedString.toJSON(message.attributed_title) : undefined);
    message.up_title !== undefined &&
      (obj.up_title = message.up_title ? AttributedString.toJSON(message.up_title) : undefined);
    message.description !== undefined && (obj.description = message.description);
    if (message.variants) {
      obj.variants = message.variants.map((e) => e);
    } else {
      obj.variants = [];
    }
    obj.variant_properties = {};
    if (message.variant_properties) {
      Object.entries(message.variant_properties).forEach(([k, v]) => {
        obj.variant_properties[k] = VariantProperties.toJSON(v);
      });
    }
    message.image !== undefined && (obj.image = message.image);
    message.layout !== undefined && (obj.layout = questionScreenLayoutToJSON(message.layout));
    message.preselected_variant !== undefined && (obj.preselected_variant = message.preselected_variant);
    return obj;
  },

  create<I extends Exact<DeepPartial<SingleVariantQuestionScreen>, I>>(base?: I): SingleVariantQuestionScreen {
    return SingleVariantQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SingleVariantQuestionScreen>, I>>(object: I): SingleVariantQuestionScreen {
    const message = createBaseSingleVariantQuestionScreen();
    message.title = object.title ?? "";
    message.attributed_title = (object.attributed_title !== undefined && object.attributed_title !== null)
      ? AttributedString.fromPartial(object.attributed_title)
      : undefined;
    message.up_title = (object.up_title !== undefined && object.up_title !== null)
      ? AttributedString.fromPartial(object.up_title)
      : undefined;
    message.description = object.description ?? "";
    message.variants = object.variants?.map((e) => e) || [];
    message.variant_properties = Object.entries(object.variant_properties ?? {}).reduce<
      { [key: string]: VariantProperties }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = VariantProperties.fromPartial(value);
      }
      return acc;
    }, {});
    message.image = object.image ?? "";
    message.layout = object.layout ?? QuestionScreenLayout.SCREEN_LAYOUT_NONE;
    message.preselected_variant = object.preselected_variant ?? "";
    return message;
  },
};

function createBaseSingleVariantQuestionScreen_VariantPropertiesEntry(): SingleVariantQuestionScreen_VariantPropertiesEntry {
  return { key: "", value: undefined };
}

export const SingleVariantQuestionScreen_VariantPropertiesEntry = {
  fromJSON(object: any): SingleVariantQuestionScreen_VariantPropertiesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? VariantProperties.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: SingleVariantQuestionScreen_VariantPropertiesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? VariantProperties.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SingleVariantQuestionScreen_VariantPropertiesEntry>, I>>(
    base?: I,
  ): SingleVariantQuestionScreen_VariantPropertiesEntry {
    return SingleVariantQuestionScreen_VariantPropertiesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SingleVariantQuestionScreen_VariantPropertiesEntry>, I>>(
    object: I,
  ): SingleVariantQuestionScreen_VariantPropertiesEntry {
    const message = createBaseSingleVariantQuestionScreen_VariantPropertiesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? VariantProperties.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseVariantProperties(): VariantProperties {
  return {
    deselect_other_variants: false,
    ask_push_permissions: false,
    image_link: "",
    description: "",
    text: "",
    attributed_description: undefined,
    select_all_variants: false,
  };
}

export const VariantProperties = {
  fromJSON(object: any): VariantProperties {
    return {
      deselect_other_variants: isSet(object.deselect_other_variants) ? Boolean(object.deselect_other_variants) : false,
      ask_push_permissions: isSet(object.ask_push_permissions) ? Boolean(object.ask_push_permissions) : false,
      image_link: isSet(object.image_link) ? String(object.image_link) : "",
      description: isSet(object.description) ? String(object.description) : "",
      text: isSet(object.text) ? String(object.text) : "",
      attributed_description: isSet(object.attributed_description)
        ? AttributedString.fromJSON(object.attributed_description)
        : undefined,
      select_all_variants: isSet(object.select_all_variants) ? Boolean(object.select_all_variants) : false,
    };
  },

  toJSON(message: VariantProperties): unknown {
    const obj: any = {};
    message.deselect_other_variants !== undefined && (obj.deselect_other_variants = message.deselect_other_variants);
    message.ask_push_permissions !== undefined && (obj.ask_push_permissions = message.ask_push_permissions);
    message.image_link !== undefined && (obj.image_link = message.image_link);
    message.description !== undefined && (obj.description = message.description);
    message.text !== undefined && (obj.text = message.text);
    message.attributed_description !== undefined && (obj.attributed_description = message.attributed_description
      ? AttributedString.toJSON(message.attributed_description)
      : undefined);
    message.select_all_variants !== undefined && (obj.select_all_variants = message.select_all_variants);
    return obj;
  },

  create<I extends Exact<DeepPartial<VariantProperties>, I>>(base?: I): VariantProperties {
    return VariantProperties.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VariantProperties>, I>>(object: I): VariantProperties {
    const message = createBaseVariantProperties();
    message.deselect_other_variants = object.deselect_other_variants ?? false;
    message.ask_push_permissions = object.ask_push_permissions ?? false;
    message.image_link = object.image_link ?? "";
    message.description = object.description ?? "";
    message.text = object.text ?? "";
    message.attributed_description =
      (object.attributed_description !== undefined && object.attributed_description !== null)
        ? AttributedString.fromPartial(object.attributed_description)
        : undefined;
    message.select_all_variants = object.select_all_variants ?? false;
    return message;
  },
};

function createBaseMultipleVariantsQuestionScreen(): MultipleVariantsQuestionScreen {
  return {
    title: "",
    attributed_title: undefined,
    description: "",
    variants: [],
    variant_properties: {},
    image: "",
    layout: QuestionScreenLayout.SCREEN_LAYOUT_NONE,
    preselected_variants: [],
  };
}

export const MultipleVariantsQuestionScreen = {
  fromJSON(object: any): MultipleVariantsQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      description: isSet(object.description) ? String(object.description) : "",
      variants: Array.isArray(object?.variants) ? object.variants.map((e: any) => String(e)) : [],
      variant_properties: isObject(object.variant_properties)
        ? Object.entries(object.variant_properties).reduce<{ [key: string]: VariantProperties }>(
          (acc, [key, value]) => {
            acc[key] = VariantProperties.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      image: isSet(object.image) ? String(object.image) : "",
      layout: isSet(object.layout)
        ? questionScreenLayoutFromJSON(object.layout)
        : QuestionScreenLayout.SCREEN_LAYOUT_NONE,
      preselected_variants: Array.isArray(object?.preselected_variants)
        ? object.preselected_variants.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: MultipleVariantsQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.attributed_title !== undefined &&
      (obj.attributed_title = message.attributed_title ? AttributedString.toJSON(message.attributed_title) : undefined);
    message.description !== undefined && (obj.description = message.description);
    if (message.variants) {
      obj.variants = message.variants.map((e) => e);
    } else {
      obj.variants = [];
    }
    obj.variant_properties = {};
    if (message.variant_properties) {
      Object.entries(message.variant_properties).forEach(([k, v]) => {
        obj.variant_properties[k] = VariantProperties.toJSON(v);
      });
    }
    message.image !== undefined && (obj.image = message.image);
    message.layout !== undefined && (obj.layout = questionScreenLayoutToJSON(message.layout));
    if (message.preselected_variants) {
      obj.preselected_variants = message.preselected_variants.map((e) => e);
    } else {
      obj.preselected_variants = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MultipleVariantsQuestionScreen>, I>>(base?: I): MultipleVariantsQuestionScreen {
    return MultipleVariantsQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MultipleVariantsQuestionScreen>, I>>(
    object: I,
  ): MultipleVariantsQuestionScreen {
    const message = createBaseMultipleVariantsQuestionScreen();
    message.title = object.title ?? "";
    message.attributed_title = (object.attributed_title !== undefined && object.attributed_title !== null)
      ? AttributedString.fromPartial(object.attributed_title)
      : undefined;
    message.description = object.description ?? "";
    message.variants = object.variants?.map((e) => e) || [];
    message.variant_properties = Object.entries(object.variant_properties ?? {}).reduce<
      { [key: string]: VariantProperties }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = VariantProperties.fromPartial(value);
      }
      return acc;
    }, {});
    message.image = object.image ?? "";
    message.layout = object.layout ?? QuestionScreenLayout.SCREEN_LAYOUT_NONE;
    message.preselected_variants = object.preselected_variants?.map((e) => e) || [];
    return message;
  },
};

function createBaseMultipleVariantsQuestionScreen_VariantPropertiesEntry(): MultipleVariantsQuestionScreen_VariantPropertiesEntry {
  return { key: "", value: undefined };
}

export const MultipleVariantsQuestionScreen_VariantPropertiesEntry = {
  fromJSON(object: any): MultipleVariantsQuestionScreen_VariantPropertiesEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? VariantProperties.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: MultipleVariantsQuestionScreen_VariantPropertiesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? VariantProperties.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MultipleVariantsQuestionScreen_VariantPropertiesEntry>, I>>(
    base?: I,
  ): MultipleVariantsQuestionScreen_VariantPropertiesEntry {
    return MultipleVariantsQuestionScreen_VariantPropertiesEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MultipleVariantsQuestionScreen_VariantPropertiesEntry>, I>>(
    object: I,
  ): MultipleVariantsQuestionScreen_VariantPropertiesEntry {
    const message = createBaseMultipleVariantsQuestionScreen_VariantPropertiesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? VariantProperties.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseFaceAreaSelectQuestionScreen(): FaceAreaSelectQuestionScreen {
  return {
    title: undefined,
    description: "",
    variant: FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_UNSPECIFIED,
  };
}

export const FaceAreaSelectQuestionScreen = {
  fromJSON(object: any): FaceAreaSelectQuestionScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? String(object.description) : "",
      variant: isSet(object.variant)
        ? faceAreaSelectQuestionScreen_FaceAreaSelectVariantFromJSON(object.variant)
        : FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_UNSPECIFIED,
    };
  },

  toJSON(message: FaceAreaSelectQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined && (obj.description = message.description);
    message.variant !== undefined &&
      (obj.variant = faceAreaSelectQuestionScreen_FaceAreaSelectVariantToJSON(message.variant));
    return obj;
  },

  create<I extends Exact<DeepPartial<FaceAreaSelectQuestionScreen>, I>>(base?: I): FaceAreaSelectQuestionScreen {
    return FaceAreaSelectQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FaceAreaSelectQuestionScreen>, I>>(object: I): FaceAreaSelectQuestionScreen {
    const message = createBaseFaceAreaSelectQuestionScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = object.description ?? "";
    message.variant = object.variant ??
      FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_UNSPECIFIED;
    return message;
  },
};

function createBaseScanningInstructionQuestionScreen(): ScanningInstructionQuestionScreen {
  return { title: "", description: "" };
}

export const ScanningInstructionQuestionScreen = {
  fromJSON(object: any): ScanningInstructionQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: ScanningInstructionQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanningInstructionQuestionScreen>, I>>(
    base?: I,
  ): ScanningInstructionQuestionScreen {
    return ScanningInstructionQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanningInstructionQuestionScreen>, I>>(
    object: I,
  ): ScanningInstructionQuestionScreen {
    const message = createBaseScanningInstructionQuestionScreen();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseWelcomeMainQuestionScreen(): WelcomeMainQuestionScreen {
  return { title: "", description: "", custom_string: "", variants: [], image: "" };
}

export const WelcomeMainQuestionScreen = {
  fromJSON(object: any): WelcomeMainQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      custom_string: isSet(object.custom_string) ? String(object.custom_string) : "",
      variants: Array.isArray(object?.variants) ? object.variants.map((e: any) => String(e)) : [],
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: WelcomeMainQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    message.custom_string !== undefined && (obj.custom_string = message.custom_string);
    if (message.variants) {
      obj.variants = message.variants.map((e) => e);
    } else {
      obj.variants = [];
    }
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<WelcomeMainQuestionScreen>, I>>(base?: I): WelcomeMainQuestionScreen {
    return WelcomeMainQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WelcomeMainQuestionScreen>, I>>(object: I): WelcomeMainQuestionScreen {
    const message = createBaseWelcomeMainQuestionScreen();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.custom_string = object.custom_string ?? "";
    message.variants = object.variants?.map((e) => e) || [];
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseWelcomeDeeplinkQuestionScreen(): WelcomeDeeplinkQuestionScreen {
  return { title: "", description: "", privacy_url: "", terms_url: "" };
}

export const WelcomeDeeplinkQuestionScreen = {
  fromJSON(object: any): WelcomeDeeplinkQuestionScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      privacy_url: isSet(object.privacy_url) ? String(object.privacy_url) : "",
      terms_url: isSet(object.terms_url) ? String(object.terms_url) : "",
    };
  },

  toJSON(message: WelcomeDeeplinkQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    message.privacy_url !== undefined && (obj.privacy_url = message.privacy_url);
    message.terms_url !== undefined && (obj.terms_url = message.terms_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<WelcomeDeeplinkQuestionScreen>, I>>(base?: I): WelcomeDeeplinkQuestionScreen {
    return WelcomeDeeplinkQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WelcomeDeeplinkQuestionScreen>, I>>(
    object: I,
  ): WelcomeDeeplinkQuestionScreen {
    const message = createBaseWelcomeDeeplinkQuestionScreen();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.privacy_url = object.privacy_url ?? "";
    message.terms_url = object.terms_url ?? "";
    return message;
  },
};

function createBaseEmailScreen(): EmailScreen {
  return { title: "", description: "", additional_info: "", links: [] };
}

export const EmailScreen = {
  fromJSON(object: any): EmailScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      additional_info: isSet(object.additional_info) ? String(object.additional_info) : "",
      links: Array.isArray(object?.links) ? object.links.map((e: any) => LinkData.fromJSON(e)) : [],
    };
  },

  toJSON(message: EmailScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined && (obj.description = message.description);
    message.additional_info !== undefined && (obj.additional_info = message.additional_info);
    if (message.links) {
      obj.links = message.links.map((e) => e ? LinkData.toJSON(e) : undefined);
    } else {
      obj.links = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EmailScreen>, I>>(base?: I): EmailScreen {
    return EmailScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EmailScreen>, I>>(object: I): EmailScreen {
    const message = createBaseEmailScreen();
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.additional_info = object.additional_info ?? "";
    message.links = object.links?.map((e) => LinkData.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProgressScreenSingleSelectQuestion(): ProgressScreenSingleSelectQuestion {
  return { title: "", variants: [], progress_completion_trigger_percent: 0 };
}

export const ProgressScreenSingleSelectQuestion = {
  fromJSON(object: any): ProgressScreenSingleSelectQuestion {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      variants: Array.isArray(object?.variants) ? object.variants.map((e: any) => String(e)) : [],
      progress_completion_trigger_percent: isSet(object.progress_completion_trigger_percent)
        ? Number(object.progress_completion_trigger_percent)
        : 0,
    };
  },

  toJSON(message: ProgressScreenSingleSelectQuestion): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.variants) {
      obj.variants = message.variants.map((e) => e);
    } else {
      obj.variants = [];
    }
    message.progress_completion_trigger_percent !== undefined &&
      (obj.progress_completion_trigger_percent = message.progress_completion_trigger_percent);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProgressScreenSingleSelectQuestion>, I>>(
    base?: I,
  ): ProgressScreenSingleSelectQuestion {
    return ProgressScreenSingleSelectQuestion.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProgressScreenSingleSelectQuestion>, I>>(
    object: I,
  ): ProgressScreenSingleSelectQuestion {
    const message = createBaseProgressScreenSingleSelectQuestion();
    message.title = object.title ?? "";
    message.variants = object.variants?.map((e) => e) || [];
    message.progress_completion_trigger_percent = object.progress_completion_trigger_percent ?? 0;
    return message;
  },
};

function createBaseProgressBarsScreenQuestion(): ProgressBarsScreenQuestion {
  return { id: "", question: undefined };
}

export const ProgressBarsScreenQuestion = {
  fromJSON(object: any): ProgressBarsScreenQuestion {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      question: isSet(object.single_select)
        ? { $case: "single_select", single_select: ProgressScreenSingleSelectQuestion.fromJSON(object.single_select) }
        : undefined,
    };
  },

  toJSON(message: ProgressBarsScreenQuestion): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.question?.$case === "single_select" && (obj.single_select = message.question?.single_select
      ? ProgressScreenSingleSelectQuestion.toJSON(message.question?.single_select)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProgressBarsScreenQuestion>, I>>(base?: I): ProgressBarsScreenQuestion {
    return ProgressBarsScreenQuestion.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProgressBarsScreenQuestion>, I>>(object: I): ProgressBarsScreenQuestion {
    const message = createBaseProgressBarsScreenQuestion();
    message.id = object.id ?? "";
    if (
      object.question?.$case === "single_select" &&
      object.question?.single_select !== undefined &&
      object.question?.single_select !== null
    ) {
      message.question = {
        $case: "single_select",
        single_select: ProgressScreenSingleSelectQuestion.fromPartial(object.question.single_select),
      };
    }
    return message;
  },
};

function createBaseProgressBar(): ProgressBar {
  return { title: "", completion_title: "", seconds_to_complete: 0, questions: [] };
}

export const ProgressBar = {
  fromJSON(object: any): ProgressBar {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      completion_title: isSet(object.completion_title) ? String(object.completion_title) : "",
      seconds_to_complete: isSet(object.seconds_to_complete) ? Number(object.seconds_to_complete) : 0,
      questions: Array.isArray(object?.questions)
        ? object.questions.map((e: any) => ProgressBarsScreenQuestion.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProgressBar): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.completion_title !== undefined && (obj.completion_title = message.completion_title);
    message.seconds_to_complete !== undefined && (obj.seconds_to_complete = message.seconds_to_complete);
    if (message.questions) {
      obj.questions = message.questions.map((e) => e ? ProgressBarsScreenQuestion.toJSON(e) : undefined);
    } else {
      obj.questions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProgressBar>, I>>(base?: I): ProgressBar {
    return ProgressBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProgressBar>, I>>(object: I): ProgressBar {
    const message = createBaseProgressBar();
    message.title = object.title ?? "";
    message.completion_title = object.completion_title ?? "";
    message.seconds_to_complete = object.seconds_to_complete ?? 0;
    message.questions = object.questions?.map((e) => ProgressBarsScreenQuestion.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProgressBarsScreen(): ProgressBarsScreen {
  return { title: "", attributed_title: undefined, progress_bars: [] };
}

export const ProgressBarsScreen = {
  fromJSON(object: any): ProgressBarsScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
      progress_bars: Array.isArray(object?.progress_bars)
        ? object.progress_bars.map((e: any) => ProgressBar.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProgressBarsScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.attributed_title !== undefined &&
      (obj.attributed_title = message.attributed_title ? AttributedString.toJSON(message.attributed_title) : undefined);
    if (message.progress_bars) {
      obj.progress_bars = message.progress_bars.map((e) => e ? ProgressBar.toJSON(e) : undefined);
    } else {
      obj.progress_bars = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProgressBarsScreen>, I>>(base?: I): ProgressBarsScreen {
    return ProgressBarsScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProgressBarsScreen>, I>>(object: I): ProgressBarsScreen {
    const message = createBaseProgressBarsScreen();
    message.title = object.title ?? "";
    message.attributed_title = (object.attributed_title !== undefined && object.attributed_title !== null)
      ? AttributedString.fromPartial(object.attributed_title)
      : undefined;
    message.progress_bars = object.progress_bars?.map((e) => ProgressBar.fromPartial(e)) || [];
    return message;
  },
};

function createBaseScanQuestionScreen(): ScanQuestionScreen {
  return { title: undefined, description: undefined, image: "", variants: [] };
}

export const ScanQuestionScreen = {
  fromJSON(object: any): ScanQuestionScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
      variants: Array.isArray(object?.variants) ? object.variants.map((e: any) => VariantProperties.fromJSON(e)) : [],
    };
  },

  toJSON(message: ScanQuestionScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.image !== undefined && (obj.image = message.image);
    if (message.variants) {
      obj.variants = message.variants.map((e) => e ? VariantProperties.toJSON(e) : undefined);
    } else {
      obj.variants = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanQuestionScreen>, I>>(base?: I): ScanQuestionScreen {
    return ScanQuestionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanQuestionScreen>, I>>(object: I): ScanQuestionScreen {
    const message = createBaseScanQuestionScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.image = object.image ?? "";
    message.variants = object.variants?.map((e) => VariantProperties.fromPartial(e)) || [];
    return message;
  },
};

function createBaseScanResultScreen(): ScanResultScreen {
  return { last_face_scan_report_id: "", issues: [] };
}

export const ScanResultScreen = {
  fromJSON(object: any): ScanResultScreen {
    return {
      last_face_scan_report_id: isSet(object.last_face_scan_report_id) ? String(object.last_face_scan_report_id) : "",
      issues: Array.isArray(object?.issues) ? object.issues.map((e: any) => skinIssuesFromJSON(e)) : [],
    };
  },

  toJSON(message: ScanResultScreen): unknown {
    const obj: any = {};
    message.last_face_scan_report_id !== undefined && (obj.last_face_scan_report_id = message.last_face_scan_report_id);
    if (message.issues) {
      obj.issues = message.issues.map((e) => skinIssuesToJSON(e));
    } else {
      obj.issues = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ScanResultScreen>, I>>(base?: I): ScanResultScreen {
    return ScanResultScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ScanResultScreen>, I>>(object: I): ScanResultScreen {
    const message = createBaseScanResultScreen();
    message.last_face_scan_report_id = object.last_face_scan_report_id ?? "";
    message.issues = object.issues?.map((e) => e) || [];
    return message;
  },
};

function createBasePushNotificationsPermissionsScreen(): PushNotificationsPermissionsScreen {
  return { title: undefined, subtitle: "", image: "", allow_button_text: "", skip_button_text: "" };
}

export const PushNotificationsPermissionsScreen = {
  fromJSON(object: any): PushNotificationsPermissionsScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      image: isSet(object.image) ? String(object.image) : "",
      allow_button_text: isSet(object.allow_button_text) ? String(object.allow_button_text) : "",
      skip_button_text: isSet(object.skip_button_text) ? String(object.skip_button_text) : "",
    };
  },

  toJSON(message: PushNotificationsPermissionsScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.image !== undefined && (obj.image = message.image);
    message.allow_button_text !== undefined && (obj.allow_button_text = message.allow_button_text);
    message.skip_button_text !== undefined && (obj.skip_button_text = message.skip_button_text);
    return obj;
  },

  create<I extends Exact<DeepPartial<PushNotificationsPermissionsScreen>, I>>(
    base?: I,
  ): PushNotificationsPermissionsScreen {
    return PushNotificationsPermissionsScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PushNotificationsPermissionsScreen>, I>>(
    object: I,
  ): PushNotificationsPermissionsScreen {
    const message = createBasePushNotificationsPermissionsScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.subtitle = object.subtitle ?? "";
    message.image = object.image ?? "";
    message.allow_button_text = object.allow_button_text ?? "";
    message.skip_button_text = object.skip_button_text ?? "";
    return message;
  },
};

function createBaseWebFeaturesScreen(): WebFeaturesScreen {
  return { title: undefined, cards: [], footer_button_title: "" };
}

export const WebFeaturesScreen = {
  fromJSON(object: any): WebFeaturesScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      cards: Array.isArray(object?.cards) ? object.cards.map((e: any) => WebFeaturesScreen_Card.fromJSON(e)) : [],
      footer_button_title: isSet(object.footer_button_title) ? String(object.footer_button_title) : "",
    };
  },

  toJSON(message: WebFeaturesScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.cards) {
      obj.cards = message.cards.map((e) => e ? WebFeaturesScreen_Card.toJSON(e) : undefined);
    } else {
      obj.cards = [];
    }
    message.footer_button_title !== undefined && (obj.footer_button_title = message.footer_button_title);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebFeaturesScreen>, I>>(base?: I): WebFeaturesScreen {
    return WebFeaturesScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebFeaturesScreen>, I>>(object: I): WebFeaturesScreen {
    const message = createBaseWebFeaturesScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.cards = object.cards?.map((e) => WebFeaturesScreen_Card.fromPartial(e)) || [];
    message.footer_button_title = object.footer_button_title ?? "";
    return message;
  },
};

function createBaseWebFeaturesScreen_Card(): WebFeaturesScreen_Card {
  return { card_number: undefined, title: undefined, image: "" };
}

export const WebFeaturesScreen_Card = {
  fromJSON(object: any): WebFeaturesScreen_Card {
    return {
      card_number: isSet(object.card_number) ? AttributedString.fromJSON(object.card_number) : undefined,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: WebFeaturesScreen_Card): unknown {
    const obj: any = {};
    message.card_number !== undefined &&
      (obj.card_number = message.card_number ? AttributedString.toJSON(message.card_number) : undefined);
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebFeaturesScreen_Card>, I>>(base?: I): WebFeaturesScreen_Card {
    return WebFeaturesScreen_Card.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebFeaturesScreen_Card>, I>>(object: I): WebFeaturesScreen_Card {
    const message = createBaseWebFeaturesScreen_Card();
    message.card_number = (object.card_number !== undefined && object.card_number !== null)
      ? AttributedString.fromPartial(object.card_number)
      : undefined;
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseLinkData(): LinkData {
  return { title: "", url: "" };
}

export const LinkData = {
  fromJSON(object: any): LinkData {
    return { title: isSet(object.title) ? String(object.title) : "", url: isSet(object.url) ? String(object.url) : "" };
  },

  toJSON(message: LinkData): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<LinkData>, I>>(base?: I): LinkData {
    return LinkData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LinkData>, I>>(object: I): LinkData {
    const message = createBaseLinkData();
    message.title = object.title ?? "";
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseQuizActionRequestNewTest(): QuizActionRequestNewTest {
  return { test_id: "", interview_id: "" };
}

export const QuizActionRequestNewTest = {
  fromJSON(object: any): QuizActionRequestNewTest {
    return {
      test_id: isSet(object.test_id) ? String(object.test_id) : "",
      interview_id: isSet(object.interview_id) ? String(object.interview_id) : "",
    };
  },

  toJSON(message: QuizActionRequestNewTest): unknown {
    const obj: any = {};
    message.test_id !== undefined && (obj.test_id = message.test_id);
    message.interview_id !== undefined && (obj.interview_id = message.interview_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionRequestNewTest>, I>>(base?: I): QuizActionRequestNewTest {
    return QuizActionRequestNewTest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionRequestNewTest>, I>>(object: I): QuizActionRequestNewTest {
    const message = createBaseQuizActionRequestNewTest();
    message.test_id = object.test_id ?? "";
    message.interview_id = object.interview_id ?? "";
    return message;
  },
};

function createBaseQuizActionRequestNextQuizWithClientProvidedID(): QuizActionRequestNextQuizWithClientProvidedID {
  return {};
}

export const QuizActionRequestNextQuizWithClientProvidedID = {
  fromJSON(_: any): QuizActionRequestNextQuizWithClientProvidedID {
    return {};
  },

  toJSON(_: QuizActionRequestNextQuizWithClientProvidedID): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionRequestNextQuizWithClientProvidedID>, I>>(
    base?: I,
  ): QuizActionRequestNextQuizWithClientProvidedID {
    return QuizActionRequestNextQuizWithClientProvidedID.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionRequestNextQuizWithClientProvidedID>, I>>(
    _: I,
  ): QuizActionRequestNextQuizWithClientProvidedID {
    const message = createBaseQuizActionRequestNextQuizWithClientProvidedID();
    return message;
  },
};

function createBaseQuizActionSignIn(): QuizActionSignIn {
  return { next_quiz_id: "" };
}

export const QuizActionSignIn = {
  fromJSON(object: any): QuizActionSignIn {
    return { next_quiz_id: isSet(object.next_quiz_id) ? String(object.next_quiz_id) : "" };
  },

  toJSON(message: QuizActionSignIn): unknown {
    const obj: any = {};
    message.next_quiz_id !== undefined && (obj.next_quiz_id = message.next_quiz_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionSignIn>, I>>(base?: I): QuizActionSignIn {
    return QuizActionSignIn.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionSignIn>, I>>(object: I): QuizActionSignIn {
    const message = createBaseQuizActionSignIn();
    message.next_quiz_id = object.next_quiz_id ?? "";
    return message;
  },
};

function createBaseQuizActionNothing(): QuizActionNothing {
  return {};
}

export const QuizActionNothing = {
  fromJSON(_: any): QuizActionNothing {
    return {};
  },

  toJSON(_: QuizActionNothing): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionNothing>, I>>(base?: I): QuizActionNothing {
    return QuizActionNothing.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionNothing>, I>>(_: I): QuizActionNothing {
    const message = createBaseQuizActionNothing();
    return message;
  },
};

function createBaseQuizActionSetOnboardingCompleted(): QuizActionSetOnboardingCompleted {
  return {};
}

export const QuizActionSetOnboardingCompleted = {
  fromJSON(_: any): QuizActionSetOnboardingCompleted {
    return {};
  },

  toJSON(_: QuizActionSetOnboardingCompleted): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionSetOnboardingCompleted>, I>>(
    base?: I,
  ): QuizActionSetOnboardingCompleted {
    return QuizActionSetOnboardingCompleted.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionSetOnboardingCompleted>, I>>(
    _: I,
  ): QuizActionSetOnboardingCompleted {
    const message = createBaseQuizActionSetOnboardingCompleted();
    return message;
  },
};

function createBaseQuizActionSignInAndSetOnboardingCompleted(): QuizActionSignInAndSetOnboardingCompleted {
  return {};
}

export const QuizActionSignInAndSetOnboardingCompleted = {
  fromJSON(_: any): QuizActionSignInAndSetOnboardingCompleted {
    return {};
  },

  toJSON(_: QuizActionSignInAndSetOnboardingCompleted): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionSignInAndSetOnboardingCompleted>, I>>(
    base?: I,
  ): QuizActionSignInAndSetOnboardingCompleted {
    return QuizActionSignInAndSetOnboardingCompleted.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionSignInAndSetOnboardingCompleted>, I>>(
    _: I,
  ): QuizActionSignInAndSetOnboardingCompleted {
    const message = createBaseQuizActionSignInAndSetOnboardingCompleted();
    return message;
  },
};

function createBaseQuizActionClosePaywallWithIncompletion(): QuizActionClosePaywallWithIncompletion {
  return {};
}

export const QuizActionClosePaywallWithIncompletion = {
  fromJSON(_: any): QuizActionClosePaywallWithIncompletion {
    return {};
  },

  toJSON(_: QuizActionClosePaywallWithIncompletion): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionClosePaywallWithIncompletion>, I>>(
    base?: I,
  ): QuizActionClosePaywallWithIncompletion {
    return QuizActionClosePaywallWithIncompletion.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionClosePaywallWithIncompletion>, I>>(
    _: I,
  ): QuizActionClosePaywallWithIncompletion {
    const message = createBaseQuizActionClosePaywallWithIncompletion();
    return message;
  },
};

function createBaseQuizActionGenerateUserID(): QuizActionGenerateUserID {
  return { next_quiz_id: "" };
}

export const QuizActionGenerateUserID = {
  fromJSON(object: any): QuizActionGenerateUserID {
    return { next_quiz_id: isSet(object.next_quiz_id) ? String(object.next_quiz_id) : "" };
  },

  toJSON(message: QuizActionGenerateUserID): unknown {
    const obj: any = {};
    message.next_quiz_id !== undefined && (obj.next_quiz_id = message.next_quiz_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionGenerateUserID>, I>>(base?: I): QuizActionGenerateUserID {
    return QuizActionGenerateUserID.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionGenerateUserID>, I>>(object: I): QuizActionGenerateUserID {
    const message = createBaseQuizActionGenerateUserID();
    message.next_quiz_id = object.next_quiz_id ?? "";
    return message;
  },
};

function createBaseQuizActionPullPostEndpoint(): QuizActionPullPostEndpoint {
  return { endpoint: "", params: {} };
}

export const QuizActionPullPostEndpoint = {
  fromJSON(object: any): QuizActionPullPostEndpoint {
    return {
      endpoint: isSet(object.endpoint) ? String(object.endpoint) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QuizActionPullPostEndpoint): unknown {
    const obj: any = {};
    message.endpoint !== undefined && (obj.endpoint = message.endpoint);
    obj.params = {};
    if (message.params) {
      Object.entries(message.params).forEach(([k, v]) => {
        obj.params[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionPullPostEndpoint>, I>>(base?: I): QuizActionPullPostEndpoint {
    return QuizActionPullPostEndpoint.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionPullPostEndpoint>, I>>(object: I): QuizActionPullPostEndpoint {
    const message = createBaseQuizActionPullPostEndpoint();
    message.endpoint = object.endpoint ?? "";
    message.params = Object.entries(object.params ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseQuizActionPullPostEndpoint_ParamsEntry(): QuizActionPullPostEndpoint_ParamsEntry {
  return { key: "", value: "" };
}

export const QuizActionPullPostEndpoint_ParamsEntry = {
  fromJSON(object: any): QuizActionPullPostEndpoint_ParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: QuizActionPullPostEndpoint_ParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionPullPostEndpoint_ParamsEntry>, I>>(
    base?: I,
  ): QuizActionPullPostEndpoint_ParamsEntry {
    return QuizActionPullPostEndpoint_ParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionPullPostEndpoint_ParamsEntry>, I>>(
    object: I,
  ): QuizActionPullPostEndpoint_ParamsEntry {
    const message = createBaseQuizActionPullPostEndpoint_ParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseQuizActionPullGetEndpoint(): QuizActionPullGetEndpoint {
  return { endpoint: "", params: {} };
}

export const QuizActionPullGetEndpoint = {
  fromJSON(object: any): QuizActionPullGetEndpoint {
    return {
      endpoint: isSet(object.endpoint) ? String(object.endpoint) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QuizActionPullGetEndpoint): unknown {
    const obj: any = {};
    message.endpoint !== undefined && (obj.endpoint = message.endpoint);
    obj.params = {};
    if (message.params) {
      Object.entries(message.params).forEach(([k, v]) => {
        obj.params[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionPullGetEndpoint>, I>>(base?: I): QuizActionPullGetEndpoint {
    return QuizActionPullGetEndpoint.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionPullGetEndpoint>, I>>(object: I): QuizActionPullGetEndpoint {
    const message = createBaseQuizActionPullGetEndpoint();
    message.endpoint = object.endpoint ?? "";
    message.params = Object.entries(object.params ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseQuizActionPullGetEndpoint_ParamsEntry(): QuizActionPullGetEndpoint_ParamsEntry {
  return { key: "", value: "" };
}

export const QuizActionPullGetEndpoint_ParamsEntry = {
  fromJSON(object: any): QuizActionPullGetEndpoint_ParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: QuizActionPullGetEndpoint_ParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionPullGetEndpoint_ParamsEntry>, I>>(
    base?: I,
  ): QuizActionPullGetEndpoint_ParamsEntry {
    return QuizActionPullGetEndpoint_ParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionPullGetEndpoint_ParamsEntry>, I>>(
    object: I,
  ): QuizActionPullGetEndpoint_ParamsEntry {
    const message = createBaseQuizActionPullGetEndpoint_ParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseQuizActionRedirect(): QuizActionRedirect {
  return { endpoint: "", params: {} };
}

export const QuizActionRedirect = {
  fromJSON(object: any): QuizActionRedirect {
    return {
      endpoint: isSet(object.endpoint) ? String(object.endpoint) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: QuizActionRedirect): unknown {
    const obj: any = {};
    message.endpoint !== undefined && (obj.endpoint = message.endpoint);
    obj.params = {};
    if (message.params) {
      Object.entries(message.params).forEach(([k, v]) => {
        obj.params[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionRedirect>, I>>(base?: I): QuizActionRedirect {
    return QuizActionRedirect.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionRedirect>, I>>(object: I): QuizActionRedirect {
    const message = createBaseQuizActionRedirect();
    message.endpoint = object.endpoint ?? "";
    message.params = Object.entries(object.params ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseQuizActionRedirect_ParamsEntry(): QuizActionRedirect_ParamsEntry {
  return { key: "", value: "" };
}

export const QuizActionRedirect_ParamsEntry = {
  fromJSON(object: any): QuizActionRedirect_ParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: QuizActionRedirect_ParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizActionRedirect_ParamsEntry>, I>>(base?: I): QuizActionRedirect_ParamsEntry {
    return QuizActionRedirect_ParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizActionRedirect_ParamsEntry>, I>>(
    object: I,
  ): QuizActionRedirect_ParamsEntry {
    const message = createBaseQuizActionRedirect_ParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseQuizInfo(): QuizInfo {
  return { type: "", count: 0 };
}

export const QuizInfo = {
  fromJSON(object: any): QuizInfo {
    return {
      type: isSet(object.type) ? String(object.type) : "",
      count: isSet(object.count) ? Number(object.count) : 0,
    };
  },

  toJSON(message: QuizInfo): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = message.type);
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  create<I extends Exact<DeepPartial<QuizInfo>, I>>(base?: I): QuizInfo {
    return QuizInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<QuizInfo>, I>>(object: I): QuizInfo {
    const message = createBaseQuizInfo();
    message.type = object.type ?? "";
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseQuiz(): Quiz {
  return {
    id: "",
    skippable: false,
    questions_v2: [],
    on_completion_action: undefined,
    progress_bar_data: [],
    section_name: "",
    prefetch_images: [],
    post_completion_action: undefined,
  };
}

export const Quiz = {
  fromJSON(object: any): Quiz {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      skippable: isSet(object.skippable) ? Boolean(object.skippable) : false,
      questions_v2: Array.isArray(object?.questions_v2)
        ? object.questions_v2.map((e: any) => QuestionV2.fromJSON(e))
        : [],
      on_completion_action: isSet(object.new_test_request)
        ? { $case: "new_test_request", new_test_request: QuizActionRequestNewTest.fromJSON(object.new_test_request) }
        : isSet(object.set_onboarding_completed)
        ? {
          $case: "set_onboarding_completed",
          set_onboarding_completed: QuizActionSetOnboardingCompleted.fromJSON(object.set_onboarding_completed),
        }
        : isSet(object.nothing)
        ? { $case: "nothing", nothing: QuizActionNothing.fromJSON(object.nothing) }
        : isSet(object.pull_post_endpoint)
        ? {
          $case: "pull_post_endpoint",
          pull_post_endpoint: QuizActionPullPostEndpoint.fromJSON(object.pull_post_endpoint),
        }
        : isSet(object.pull_get_endpoint)
        ? {
          $case: "pull_get_endpoint",
          pull_get_endpoint: QuizActionPullGetEndpoint.fromJSON(object.pull_get_endpoint),
        }
        : isSet(object.redirect)
        ? { $case: "redirect", redirect: QuizActionRedirect.fromJSON(object.redirect) }
        : isSet(object.sign_in)
        ? { $case: "sign_in", sign_in: QuizActionSignIn.fromJSON(object.sign_in) }
        : isSet(object.sign_in_and_set_onboarding_completed)
        ? {
          $case: "sign_in_and_set_onboarding_completed",
          sign_in_and_set_onboarding_completed: QuizActionSignInAndSetOnboardingCompleted.fromJSON(
            object.sign_in_and_set_onboarding_completed,
          ),
        }
        : isSet(object.generate_user_id)
        ? { $case: "generate_user_id", generate_user_id: QuizActionGenerateUserID.fromJSON(object.generate_user_id) }
        : isSet(object.next_quiz_with_client_provided_id)
        ? {
          $case: "next_quiz_with_client_provided_id",
          next_quiz_with_client_provided_id: QuizActionRequestNextQuizWithClientProvidedID.fromJSON(
            object.next_quiz_with_client_provided_id,
          ),
        }
        : isSet(object.close_paywall_with_incompletion)
        ? {
          $case: "close_paywall_with_incompletion",
          close_paywall_with_incompletion: QuizActionClosePaywallWithIncompletion.fromJSON(
            object.close_paywall_with_incompletion,
          ),
        }
        : undefined,
      progress_bar_data: Array.isArray(object?.progress_bar_data)
        ? object.progress_bar_data.map((e: any) => QuizInfo.fromJSON(e))
        : [],
      section_name: isSet(object.section_name) ? String(object.section_name) : "",
      prefetch_images: Array.isArray(object?.prefetch_images) ? object.prefetch_images.map((e: any) => String(e)) : [],
      post_completion_action: isSet(object.post_completion_action)
        ? ActionType.fromJSON(object.post_completion_action)
        : undefined,
    };
  },

  toJSON(message: Quiz): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.skippable !== undefined && (obj.skippable = message.skippable);
    if (message.questions_v2) {
      obj.questions_v2 = message.questions_v2.map((e) => e ? QuestionV2.toJSON(e) : undefined);
    } else {
      obj.questions_v2 = [];
    }
    message.on_completion_action?.$case === "new_test_request" &&
      (obj.new_test_request = message.on_completion_action?.new_test_request
        ? QuizActionRequestNewTest.toJSON(message.on_completion_action?.new_test_request)
        : undefined);
    message.on_completion_action?.$case === "set_onboarding_completed" &&
      (obj.set_onboarding_completed = message.on_completion_action?.set_onboarding_completed
        ? QuizActionSetOnboardingCompleted.toJSON(message.on_completion_action?.set_onboarding_completed)
        : undefined);
    message.on_completion_action?.$case === "nothing" && (obj.nothing = message.on_completion_action?.nothing
      ? QuizActionNothing.toJSON(message.on_completion_action?.nothing)
      : undefined);
    message.on_completion_action?.$case === "pull_post_endpoint" &&
      (obj.pull_post_endpoint = message.on_completion_action?.pull_post_endpoint
        ? QuizActionPullPostEndpoint.toJSON(message.on_completion_action?.pull_post_endpoint)
        : undefined);
    message.on_completion_action?.$case === "pull_get_endpoint" &&
      (obj.pull_get_endpoint = message.on_completion_action?.pull_get_endpoint
        ? QuizActionPullGetEndpoint.toJSON(message.on_completion_action?.pull_get_endpoint)
        : undefined);
    message.on_completion_action?.$case === "redirect" && (obj.redirect = message.on_completion_action?.redirect
      ? QuizActionRedirect.toJSON(message.on_completion_action?.redirect)
      : undefined);
    message.on_completion_action?.$case === "sign_in" && (obj.sign_in = message.on_completion_action?.sign_in
      ? QuizActionSignIn.toJSON(message.on_completion_action?.sign_in)
      : undefined);
    message.on_completion_action?.$case === "sign_in_and_set_onboarding_completed" &&
      (obj.sign_in_and_set_onboarding_completed = message.on_completion_action?.sign_in_and_set_onboarding_completed
        ? QuizActionSignInAndSetOnboardingCompleted.toJSON(
          message.on_completion_action?.sign_in_and_set_onboarding_completed,
        )
        : undefined);
    message.on_completion_action?.$case === "generate_user_id" &&
      (obj.generate_user_id = message.on_completion_action?.generate_user_id
        ? QuizActionGenerateUserID.toJSON(message.on_completion_action?.generate_user_id)
        : undefined);
    message.on_completion_action?.$case === "next_quiz_with_client_provided_id" &&
      (obj.next_quiz_with_client_provided_id = message.on_completion_action?.next_quiz_with_client_provided_id
        ? QuizActionRequestNextQuizWithClientProvidedID.toJSON(
          message.on_completion_action?.next_quiz_with_client_provided_id,
        )
        : undefined);
    message.on_completion_action?.$case === "close_paywall_with_incompletion" &&
      (obj.close_paywall_with_incompletion = message.on_completion_action?.close_paywall_with_incompletion
        ? QuizActionClosePaywallWithIncompletion.toJSON(message.on_completion_action?.close_paywall_with_incompletion)
        : undefined);
    if (message.progress_bar_data) {
      obj.progress_bar_data = message.progress_bar_data.map((e) => e ? QuizInfo.toJSON(e) : undefined);
    } else {
      obj.progress_bar_data = [];
    }
    message.section_name !== undefined && (obj.section_name = message.section_name);
    if (message.prefetch_images) {
      obj.prefetch_images = message.prefetch_images.map((e) => e);
    } else {
      obj.prefetch_images = [];
    }
    message.post_completion_action !== undefined && (obj.post_completion_action = message.post_completion_action
      ? ActionType.toJSON(message.post_completion_action)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Quiz>, I>>(base?: I): Quiz {
    return Quiz.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Quiz>, I>>(object: I): Quiz {
    const message = createBaseQuiz();
    message.id = object.id ?? "";
    message.skippable = object.skippable ?? false;
    message.questions_v2 = object.questions_v2?.map((e) => QuestionV2.fromPartial(e)) || [];
    if (
      object.on_completion_action?.$case === "new_test_request" &&
      object.on_completion_action?.new_test_request !== undefined &&
      object.on_completion_action?.new_test_request !== null
    ) {
      message.on_completion_action = {
        $case: "new_test_request",
        new_test_request: QuizActionRequestNewTest.fromPartial(object.on_completion_action.new_test_request),
      };
    }
    if (
      object.on_completion_action?.$case === "set_onboarding_completed" &&
      object.on_completion_action?.set_onboarding_completed !== undefined &&
      object.on_completion_action?.set_onboarding_completed !== null
    ) {
      message.on_completion_action = {
        $case: "set_onboarding_completed",
        set_onboarding_completed: QuizActionSetOnboardingCompleted.fromPartial(
          object.on_completion_action.set_onboarding_completed,
        ),
      };
    }
    if (
      object.on_completion_action?.$case === "nothing" &&
      object.on_completion_action?.nothing !== undefined &&
      object.on_completion_action?.nothing !== null
    ) {
      message.on_completion_action = {
        $case: "nothing",
        nothing: QuizActionNothing.fromPartial(object.on_completion_action.nothing),
      };
    }
    if (
      object.on_completion_action?.$case === "pull_post_endpoint" &&
      object.on_completion_action?.pull_post_endpoint !== undefined &&
      object.on_completion_action?.pull_post_endpoint !== null
    ) {
      message.on_completion_action = {
        $case: "pull_post_endpoint",
        pull_post_endpoint: QuizActionPullPostEndpoint.fromPartial(object.on_completion_action.pull_post_endpoint),
      };
    }
    if (
      object.on_completion_action?.$case === "pull_get_endpoint" &&
      object.on_completion_action?.pull_get_endpoint !== undefined &&
      object.on_completion_action?.pull_get_endpoint !== null
    ) {
      message.on_completion_action = {
        $case: "pull_get_endpoint",
        pull_get_endpoint: QuizActionPullGetEndpoint.fromPartial(object.on_completion_action.pull_get_endpoint),
      };
    }
    if (
      object.on_completion_action?.$case === "redirect" &&
      object.on_completion_action?.redirect !== undefined &&
      object.on_completion_action?.redirect !== null
    ) {
      message.on_completion_action = {
        $case: "redirect",
        redirect: QuizActionRedirect.fromPartial(object.on_completion_action.redirect),
      };
    }
    if (
      object.on_completion_action?.$case === "sign_in" &&
      object.on_completion_action?.sign_in !== undefined &&
      object.on_completion_action?.sign_in !== null
    ) {
      message.on_completion_action = {
        $case: "sign_in",
        sign_in: QuizActionSignIn.fromPartial(object.on_completion_action.sign_in),
      };
    }
    if (
      object.on_completion_action?.$case === "sign_in_and_set_onboarding_completed" &&
      object.on_completion_action?.sign_in_and_set_onboarding_completed !== undefined &&
      object.on_completion_action?.sign_in_and_set_onboarding_completed !== null
    ) {
      message.on_completion_action = {
        $case: "sign_in_and_set_onboarding_completed",
        sign_in_and_set_onboarding_completed: QuizActionSignInAndSetOnboardingCompleted.fromPartial(
          object.on_completion_action.sign_in_and_set_onboarding_completed,
        ),
      };
    }
    if (
      object.on_completion_action?.$case === "generate_user_id" &&
      object.on_completion_action?.generate_user_id !== undefined &&
      object.on_completion_action?.generate_user_id !== null
    ) {
      message.on_completion_action = {
        $case: "generate_user_id",
        generate_user_id: QuizActionGenerateUserID.fromPartial(object.on_completion_action.generate_user_id),
      };
    }
    if (
      object.on_completion_action?.$case === "next_quiz_with_client_provided_id" &&
      object.on_completion_action?.next_quiz_with_client_provided_id !== undefined &&
      object.on_completion_action?.next_quiz_with_client_provided_id !== null
    ) {
      message.on_completion_action = {
        $case: "next_quiz_with_client_provided_id",
        next_quiz_with_client_provided_id: QuizActionRequestNextQuizWithClientProvidedID.fromPartial(
          object.on_completion_action.next_quiz_with_client_provided_id,
        ),
      };
    }
    if (
      object.on_completion_action?.$case === "close_paywall_with_incompletion" &&
      object.on_completion_action?.close_paywall_with_incompletion !== undefined &&
      object.on_completion_action?.close_paywall_with_incompletion !== null
    ) {
      message.on_completion_action = {
        $case: "close_paywall_with_incompletion",
        close_paywall_with_incompletion: QuizActionClosePaywallWithIncompletion.fromPartial(
          object.on_completion_action.close_paywall_with_incompletion,
        ),
      };
    }
    message.progress_bar_data = object.progress_bar_data?.map((e) => QuizInfo.fromPartial(e)) || [];
    message.section_name = object.section_name ?? "";
    message.prefetch_images = object.prefetch_images?.map((e) => e) || [];
    message.post_completion_action =
      (object.post_completion_action !== undefined && object.post_completion_action !== null)
        ? ActionType.fromPartial(object.post_completion_action)
        : undefined;
    return message;
  },
};

function createBaseActionData(): ActionData {
  return { id: "", title: "", profile_field: "", profile_field_value: "" };
}

export const ActionData = {
  fromJSON(object: any): ActionData {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      profile_field: isSet(object.profile_field) ? String(object.profile_field) : "",
      profile_field_value: isSet(object.profile_field_value) ? String(object.profile_field_value) : "",
    };
  },

  toJSON(message: ActionData): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.profile_field !== undefined && (obj.profile_field = message.profile_field);
    message.profile_field_value !== undefined && (obj.profile_field_value = message.profile_field_value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionData>, I>>(base?: I): ActionData {
    return ActionData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionData>, I>>(object: I): ActionData {
    const message = createBaseActionData();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.profile_field = object.profile_field ?? "";
    message.profile_field_value = object.profile_field_value ?? "";
    return message;
  },
};

function createBaseAnswer(): Answer {
  return { question_id: "", values: [], values2: [] };
}

export const Answer = {
  fromJSON(object: any): Answer {
    return {
      question_id: isSet(object.question_id) ? String(object.question_id) : "",
      values: Array.isArray(object?.values) ? object.values.map((e: any) => String(e)) : [],
      values2: Array.isArray(object?.values2) ? object.values2.map((e: any) => ActionData.fromJSON(e)) : [],
    };
  },

  toJSON(message: Answer): unknown {
    const obj: any = {};
    message.question_id !== undefined && (obj.question_id = message.question_id);
    if (message.values) {
      obj.values = message.values.map((e) => e);
    } else {
      obj.values = [];
    }
    if (message.values2) {
      obj.values2 = message.values2.map((e) => e ? ActionData.toJSON(e) : undefined);
    } else {
      obj.values2 = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Answer>, I>>(base?: I): Answer {
    return Answer.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Answer>, I>>(object: I): Answer {
    const message = createBaseAnswer();
    message.question_id = object.question_id ?? "";
    message.values = object.values?.map((e) => e) || [];
    message.values2 = object.values2?.map((e) => ActionData.fromPartial(e)) || [];
    return message;
  },
};

function createBaseInterviewServiceSaveAnswersRequest(): InterviewServiceSaveAnswersRequest {
  return {
    request: undefined,
    test_id: "",
    next_quiz_id: "",
    answers: [],
    utm_source: "",
    interview_id: "",
    smp_link: "",
    product_page_report_screenshot: undefined,
  };
}

export const InterviewServiceSaveAnswersRequest = {
  fromJSON(object: any): InterviewServiceSaveAnswersRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      test_id: isSet(object.test_id) ? String(object.test_id) : "",
      next_quiz_id: isSet(object.next_quiz_id) ? String(object.next_quiz_id) : "",
      answers: Array.isArray(object?.answers) ? object.answers.map((e: any) => Answer.fromJSON(e)) : [],
      utm_source: isSet(object.utm_source) ? String(object.utm_source) : "",
      interview_id: isSet(object.interview_id) ? String(object.interview_id) : "",
      smp_link: isSet(object.smp_link) ? String(object.smp_link) : "",
      product_page_report_screenshot: isSet(object.product_page_report_screenshot)
        ? Image.fromJSON(object.product_page_report_screenshot)
        : undefined,
    };
  },

  toJSON(message: InterviewServiceSaveAnswersRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.test_id !== undefined && (obj.test_id = message.test_id);
    message.next_quiz_id !== undefined && (obj.next_quiz_id = message.next_quiz_id);
    if (message.answers) {
      obj.answers = message.answers.map((e) => e ? Answer.toJSON(e) : undefined);
    } else {
      obj.answers = [];
    }
    message.utm_source !== undefined && (obj.utm_source = message.utm_source);
    message.interview_id !== undefined && (obj.interview_id = message.interview_id);
    message.smp_link !== undefined && (obj.smp_link = message.smp_link);
    message.product_page_report_screenshot !== undefined &&
      (obj.product_page_report_screenshot = message.product_page_report_screenshot
        ? Image.toJSON(message.product_page_report_screenshot)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<InterviewServiceSaveAnswersRequest>, I>>(
    base?: I,
  ): InterviewServiceSaveAnswersRequest {
    return InterviewServiceSaveAnswersRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InterviewServiceSaveAnswersRequest>, I>>(
    object: I,
  ): InterviewServiceSaveAnswersRequest {
    const message = createBaseInterviewServiceSaveAnswersRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.test_id = object.test_id ?? "";
    message.next_quiz_id = object.next_quiz_id ?? "";
    message.answers = object.answers?.map((e) => Answer.fromPartial(e)) || [];
    message.utm_source = object.utm_source ?? "";
    message.interview_id = object.interview_id ?? "";
    message.smp_link = object.smp_link ?? "";
    message.product_page_report_screenshot =
      (object.product_page_report_screenshot !== undefined && object.product_page_report_screenshot !== null)
        ? Image.fromPartial(object.product_page_report_screenshot)
        : undefined;
    return message;
  },
};

function createBaseInterviewServiceSaveAnswersResponse(): InterviewServiceSaveAnswersResponse {
  return { response: undefined, quiz: undefined };
}

export const InterviewServiceSaveAnswersResponse = {
  fromJSON(object: any): InterviewServiceSaveAnswersResponse {
    return {
      response: isSet(object.response) ? Response.fromJSON(object.response) : undefined,
      quiz: isSet(object.quiz) ? Quiz.fromJSON(object.quiz) : undefined,
    };
  },

  toJSON(message: InterviewServiceSaveAnswersResponse): unknown {
    const obj: any = {};
    message.response !== undefined && (obj.response = message.response ? Response.toJSON(message.response) : undefined);
    message.quiz !== undefined && (obj.quiz = message.quiz ? Quiz.toJSON(message.quiz) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<InterviewServiceSaveAnswersResponse>, I>>(
    base?: I,
  ): InterviewServiceSaveAnswersResponse {
    return InterviewServiceSaveAnswersResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InterviewServiceSaveAnswersResponse>, I>>(
    object: I,
  ): InterviewServiceSaveAnswersResponse {
    const message = createBaseInterviewServiceSaveAnswersResponse();
    message.response = (object.response !== undefined && object.response !== null)
      ? Response.fromPartial(object.response)
      : undefined;
    message.quiz = (object.quiz !== undefined && object.quiz !== null) ? Quiz.fromPartial(object.quiz) : undefined;
    return message;
  },
};

function createBaseInterviewServiceGetQuizRequest(): InterviewServiceGetQuizRequest {
  return { request: undefined, quiz_id: "", utm_source: "", interview_id: "", smp_link: "" };
}

export const InterviewServiceGetQuizRequest = {
  fromJSON(object: any): InterviewServiceGetQuizRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      quiz_id: isSet(object.quiz_id) ? String(object.quiz_id) : "",
      utm_source: isSet(object.utm_source) ? String(object.utm_source) : "",
      interview_id: isSet(object.interview_id) ? String(object.interview_id) : "",
      smp_link: isSet(object.smp_link) ? String(object.smp_link) : "",
    };
  },

  toJSON(message: InterviewServiceGetQuizRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.quiz_id !== undefined && (obj.quiz_id = message.quiz_id);
    message.utm_source !== undefined && (obj.utm_source = message.utm_source);
    message.interview_id !== undefined && (obj.interview_id = message.interview_id);
    message.smp_link !== undefined && (obj.smp_link = message.smp_link);
    return obj;
  },

  create<I extends Exact<DeepPartial<InterviewServiceGetQuizRequest>, I>>(base?: I): InterviewServiceGetQuizRequest {
    return InterviewServiceGetQuizRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InterviewServiceGetQuizRequest>, I>>(
    object: I,
  ): InterviewServiceGetQuizRequest {
    const message = createBaseInterviewServiceGetQuizRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.quiz_id = object.quiz_id ?? "";
    message.utm_source = object.utm_source ?? "";
    message.interview_id = object.interview_id ?? "";
    message.smp_link = object.smp_link ?? "";
    return message;
  },
};

function createBaseInterviewServiceGetQuizResponse(): InterviewServiceGetQuizResponse {
  return { response: undefined, quiz: undefined };
}

export const InterviewServiceGetQuizResponse = {
  fromJSON(object: any): InterviewServiceGetQuizResponse {
    return {
      response: isSet(object.response) ? Response.fromJSON(object.response) : undefined,
      quiz: isSet(object.quiz) ? Quiz.fromJSON(object.quiz) : undefined,
    };
  },

  toJSON(message: InterviewServiceGetQuizResponse): unknown {
    const obj: any = {};
    message.response !== undefined && (obj.response = message.response ? Response.toJSON(message.response) : undefined);
    message.quiz !== undefined && (obj.quiz = message.quiz ? Quiz.toJSON(message.quiz) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<InterviewServiceGetQuizResponse>, I>>(base?: I): InterviewServiceGetQuizResponse {
    return InterviewServiceGetQuizResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InterviewServiceGetQuizResponse>, I>>(
    object: I,
  ): InterviewServiceGetQuizResponse {
    const message = createBaseInterviewServiceGetQuizResponse();
    message.response = (object.response !== undefined && object.response !== null)
      ? Response.fromPartial(object.response)
      : undefined;
    message.quiz = (object.quiz !== undefined && object.quiz !== null) ? Quiz.fromPartial(object.quiz) : undefined;
    return message;
  },
};

function createBaseInterviewServiceSetPlanIDRequest(): InterviewServiceSetPlanIDRequest {
  return { request: undefined };
}

export const InterviewServiceSetPlanIDRequest = {
  fromJSON(object: any): InterviewServiceSetPlanIDRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: InterviewServiceSetPlanIDRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<InterviewServiceSetPlanIDRequest>, I>>(
    base?: I,
  ): InterviewServiceSetPlanIDRequest {
    return InterviewServiceSetPlanIDRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InterviewServiceSetPlanIDRequest>, I>>(
    object: I,
  ): InterviewServiceSetPlanIDRequest {
    const message = createBaseInterviewServiceSetPlanIDRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseInterviewServiceSetPlanIDResponse(): InterviewServiceSetPlanIDResponse {
  return { response: undefined, plan_id: "", user_id: "" };
}

export const InterviewServiceSetPlanIDResponse = {
  fromJSON(object: any): InterviewServiceSetPlanIDResponse {
    return {
      response: isSet(object.response) ? Response.fromJSON(object.response) : undefined,
      plan_id: isSet(object.plan_id) ? String(object.plan_id) : "",
      user_id: isSet(object.user_id) ? String(object.user_id) : "",
    };
  },

  toJSON(message: InterviewServiceSetPlanIDResponse): unknown {
    const obj: any = {};
    message.response !== undefined && (obj.response = message.response ? Response.toJSON(message.response) : undefined);
    message.plan_id !== undefined && (obj.plan_id = message.plan_id);
    message.user_id !== undefined && (obj.user_id = message.user_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<InterviewServiceSetPlanIDResponse>, I>>(
    base?: I,
  ): InterviewServiceSetPlanIDResponse {
    return InterviewServiceSetPlanIDResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InterviewServiceSetPlanIDResponse>, I>>(
    object: I,
  ): InterviewServiceSetPlanIDResponse {
    const message = createBaseInterviewServiceSetPlanIDResponse();
    message.response = (object.response !== undefined && object.response !== null)
      ? Response.fromPartial(object.response)
      : undefined;
    message.plan_id = object.plan_id ?? "";
    message.user_id = object.user_id ?? "";
    return message;
  },
};

function createBasePushRegisterRequest(): PushRegisterRequest {
  return { request: undefined, firebase_token: "", device_id: "" };
}

export const PushRegisterRequest = {
  fromJSON(object: any): PushRegisterRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      firebase_token: isSet(object.firebase_token) ? String(object.firebase_token) : "",
      device_id: isSet(object.device_id) ? String(object.device_id) : "",
    };
  },

  toJSON(message: PushRegisterRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.firebase_token !== undefined && (obj.firebase_token = message.firebase_token);
    message.device_id !== undefined && (obj.device_id = message.device_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<PushRegisterRequest>, I>>(base?: I): PushRegisterRequest {
    return PushRegisterRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PushRegisterRequest>, I>>(object: I): PushRegisterRequest {
    const message = createBasePushRegisterRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.firebase_token = object.firebase_token ?? "";
    message.device_id = object.device_id ?? "";
    return message;
  },
};

function createBasePaywallRequest(): PaywallRequest {
  return { request: undefined, interview_variant: "" };
}

export const PaywallRequest = {
  fromJSON(object: any): PaywallRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      interview_variant: isSet(object.interview_variant) ? String(object.interview_variant) : "",
    };
  },

  toJSON(message: PaywallRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.interview_variant !== undefined && (obj.interview_variant = message.interview_variant);
    return obj;
  },

  create<I extends Exact<DeepPartial<PaywallRequest>, I>>(base?: I): PaywallRequest {
    return PaywallRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PaywallRequest>, I>>(object: I): PaywallRequest {
    const message = createBasePaywallRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.interview_variant = object.interview_variant ?? "";
    return message;
  },
};

function createBaseUpdateUserIDRequest(): UpdateUserIDRequest {
  return { request: undefined, oldUserId: "" };
}

export const UpdateUserIDRequest = {
  fromJSON(object: any): UpdateUserIDRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      oldUserId: isSet(object.oldUserId) ? String(object.oldUserId) : "",
    };
  },

  toJSON(message: UpdateUserIDRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.oldUserId !== undefined && (obj.oldUserId = message.oldUserId);
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateUserIDRequest>, I>>(base?: I): UpdateUserIDRequest {
    return UpdateUserIDRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateUserIDRequest>, I>>(object: I): UpdateUserIDRequest {
    const message = createBaseUpdateUserIDRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.oldUserId = object.oldUserId ?? "";
    return message;
  },
};

function createBaseTable(): Table {
  return { id: "", fields: [] };
}

export const Table = {
  fromJSON(object: any): Table {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      fields: Array.isArray(object?.fields) ? object.fields.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: Table): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    if (message.fields) {
      obj.fields = message.fields.map((e) => e);
    } else {
      obj.fields = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Table>, I>>(base?: I): Table {
    return Table.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Table>, I>>(object: I): Table {
    const message = createBaseTable();
    message.id = object.id ?? "";
    message.fields = object.fields?.map((e) => e) || [];
    return message;
  },
};

function createBasePaymentStatusContent(): PaymentStatusContent {
  return {
    payment_in_progress_title: "",
    payment_in_progress_image_tag: "",
    payment_in_progress_thanks_text: "",
    payment_in_progress_email_prefix_text: "",
    payment_success_title: "",
    payment_success_image_tag: "",
    payment_success_text: "",
    oops_title: "",
    oops_image_tag: "",
    oops_text: "",
  };
}

export const PaymentStatusContent = {
  fromJSON(object: any): PaymentStatusContent {
    return {
      payment_in_progress_title: isSet(object.payment_in_progress_title)
        ? String(object.payment_in_progress_title)
        : "",
      payment_in_progress_image_tag: isSet(object.payment_in_progress_image_tag)
        ? String(object.payment_in_progress_image_tag)
        : "",
      payment_in_progress_thanks_text: isSet(object.payment_in_progress_thanks_text)
        ? String(object.payment_in_progress_thanks_text)
        : "",
      payment_in_progress_email_prefix_text: isSet(object.payment_in_progress_email_prefix_text)
        ? String(object.payment_in_progress_email_prefix_text)
        : "",
      payment_success_title: isSet(object.payment_success_title) ? String(object.payment_success_title) : "",
      payment_success_image_tag: isSet(object.payment_success_image_tag)
        ? String(object.payment_success_image_tag)
        : "",
      payment_success_text: isSet(object.payment_success_text) ? String(object.payment_success_text) : "",
      oops_title: isSet(object.oops_title) ? String(object.oops_title) : "",
      oops_image_tag: isSet(object.oops_image_tag) ? String(object.oops_image_tag) : "",
      oops_text: isSet(object.oops_text) ? String(object.oops_text) : "",
    };
  },

  toJSON(message: PaymentStatusContent): unknown {
    const obj: any = {};
    message.payment_in_progress_title !== undefined &&
      (obj.payment_in_progress_title = message.payment_in_progress_title);
    message.payment_in_progress_image_tag !== undefined &&
      (obj.payment_in_progress_image_tag = message.payment_in_progress_image_tag);
    message.payment_in_progress_thanks_text !== undefined &&
      (obj.payment_in_progress_thanks_text = message.payment_in_progress_thanks_text);
    message.payment_in_progress_email_prefix_text !== undefined &&
      (obj.payment_in_progress_email_prefix_text = message.payment_in_progress_email_prefix_text);
    message.payment_success_title !== undefined && (obj.payment_success_title = message.payment_success_title);
    message.payment_success_image_tag !== undefined &&
      (obj.payment_success_image_tag = message.payment_success_image_tag);
    message.payment_success_text !== undefined && (obj.payment_success_text = message.payment_success_text);
    message.oops_title !== undefined && (obj.oops_title = message.oops_title);
    message.oops_image_tag !== undefined && (obj.oops_image_tag = message.oops_image_tag);
    message.oops_text !== undefined && (obj.oops_text = message.oops_text);
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentStatusContent>, I>>(base?: I): PaymentStatusContent {
    return PaymentStatusContent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PaymentStatusContent>, I>>(object: I): PaymentStatusContent {
    const message = createBasePaymentStatusContent();
    message.payment_in_progress_title = object.payment_in_progress_title ?? "";
    message.payment_in_progress_image_tag = object.payment_in_progress_image_tag ?? "";
    message.payment_in_progress_thanks_text = object.payment_in_progress_thanks_text ?? "";
    message.payment_in_progress_email_prefix_text = object.payment_in_progress_email_prefix_text ?? "";
    message.payment_success_title = object.payment_success_title ?? "";
    message.payment_success_image_tag = object.payment_success_image_tag ?? "";
    message.payment_success_text = object.payment_success_text ?? "";
    message.oops_title = object.oops_title ?? "";
    message.oops_image_tag = object.oops_image_tag ?? "";
    message.oops_text = object.oops_text ?? "";
    return message;
  },
};

function createBaseComplianceURLsData(): ComplianceURLsData {
  return {
    privacy_policy_title: "",
    privacy_policy_url: "",
    terms_and_conditions_title: "",
    terms_and_conditions_url: "",
  };
}

export const ComplianceURLsData = {
  fromJSON(object: any): ComplianceURLsData {
    return {
      privacy_policy_title: isSet(object.privacy_policy_title) ? String(object.privacy_policy_title) : "",
      privacy_policy_url: isSet(object.privacy_policy_url) ? String(object.privacy_policy_url) : "",
      terms_and_conditions_title: isSet(object.terms_and_conditions_title)
        ? String(object.terms_and_conditions_title)
        : "",
      terms_and_conditions_url: isSet(object.terms_and_conditions_url) ? String(object.terms_and_conditions_url) : "",
    };
  },

  toJSON(message: ComplianceURLsData): unknown {
    const obj: any = {};
    message.privacy_policy_title !== undefined && (obj.privacy_policy_title = message.privacy_policy_title);
    message.privacy_policy_url !== undefined && (obj.privacy_policy_url = message.privacy_policy_url);
    message.terms_and_conditions_title !== undefined &&
      (obj.terms_and_conditions_title = message.terms_and_conditions_title);
    message.terms_and_conditions_url !== undefined && (obj.terms_and_conditions_url = message.terms_and_conditions_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<ComplianceURLsData>, I>>(base?: I): ComplianceURLsData {
    return ComplianceURLsData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ComplianceURLsData>, I>>(object: I): ComplianceURLsData {
    const message = createBaseComplianceURLsData();
    message.privacy_policy_title = object.privacy_policy_title ?? "";
    message.privacy_policy_url = object.privacy_policy_url ?? "";
    message.terms_and_conditions_title = object.terms_and_conditions_title ?? "";
    message.terms_and_conditions_url = object.terms_and_conditions_url ?? "";
    return message;
  },
};

function createBaseProgressPageData(): ProgressPageData {
  return {
    title: "",
    subtitle: "",
    status_messages: [],
    analysis_duration_seconds: 0,
    success_redirect_url: "",
    error_redirect_url: "",
  };
}

export const ProgressPageData = {
  fromJSON(object: any): ProgressPageData {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      status_messages: Array.isArray(object?.status_messages) ? object.status_messages.map((e: any) => String(e)) : [],
      analysis_duration_seconds: isSet(object.analysis_duration_seconds) ? Number(object.analysis_duration_seconds) : 0,
      success_redirect_url: isSet(object.success_redirect_url) ? String(object.success_redirect_url) : "",
      error_redirect_url: isSet(object.error_redirect_url) ? String(object.error_redirect_url) : "",
    };
  },

  toJSON(message: ProgressPageData): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    if (message.status_messages) {
      obj.status_messages = message.status_messages.map((e) => e);
    } else {
      obj.status_messages = [];
    }
    message.analysis_duration_seconds !== undefined &&
      (obj.analysis_duration_seconds = Math.round(message.analysis_duration_seconds));
    message.success_redirect_url !== undefined && (obj.success_redirect_url = message.success_redirect_url);
    message.error_redirect_url !== undefined && (obj.error_redirect_url = message.error_redirect_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProgressPageData>, I>>(base?: I): ProgressPageData {
    return ProgressPageData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProgressPageData>, I>>(object: I): ProgressPageData {
    const message = createBaseProgressPageData();
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    message.status_messages = object.status_messages?.map((e) => e) || [];
    message.analysis_duration_seconds = object.analysis_duration_seconds ?? 0;
    message.success_redirect_url = object.success_redirect_url ?? "";
    message.error_redirect_url = object.error_redirect_url ?? "";
    return message;
  },
};

function createBaseWebInterviewStyleConfig(): WebInterviewStyleConfig {
  return {
    color_icon_primary: "",
    color_text_primary: "",
    color_text_inverted: "",
    border_radius_button: "",
    color_action_primary: "",
    color_text_secondary: "",
    color_action_secondary: "",
    color_border_secondary: "",
    border_radius_progress_bar: "",
    color_background_secondary: "",
    color_action_secondary_pressed: "",
    color_action_secondary_disabled: "",
  };
}

export const WebInterviewStyleConfig = {
  fromJSON(object: any): WebInterviewStyleConfig {
    return {
      color_icon_primary: isSet(object.color_icon_primary) ? String(object.color_icon_primary) : "",
      color_text_primary: isSet(object.color_text_primary) ? String(object.color_text_primary) : "",
      color_text_inverted: isSet(object.color_text_inverted) ? String(object.color_text_inverted) : "",
      border_radius_button: isSet(object.border_radius_button) ? String(object.border_radius_button) : "",
      color_action_primary: isSet(object.color_action_primary) ? String(object.color_action_primary) : "",
      color_text_secondary: isSet(object.color_text_secondary) ? String(object.color_text_secondary) : "",
      color_action_secondary: isSet(object.color_action_secondary) ? String(object.color_action_secondary) : "",
      color_border_secondary: isSet(object.color_border_secondary) ? String(object.color_border_secondary) : "",
      border_radius_progress_bar: isSet(object.border_radius_progress_bar)
        ? String(object.border_radius_progress_bar)
        : "",
      color_background_secondary: isSet(object.color_background_secondary)
        ? String(object.color_background_secondary)
        : "",
      color_action_secondary_pressed: isSet(object.color_action_secondary_pressed)
        ? String(object.color_action_secondary_pressed)
        : "",
      color_action_secondary_disabled: isSet(object.color_action_secondary_disabled)
        ? String(object.color_action_secondary_disabled)
        : "",
    };
  },

  toJSON(message: WebInterviewStyleConfig): unknown {
    const obj: any = {};
    message.color_icon_primary !== undefined && (obj.color_icon_primary = message.color_icon_primary);
    message.color_text_primary !== undefined && (obj.color_text_primary = message.color_text_primary);
    message.color_text_inverted !== undefined && (obj.color_text_inverted = message.color_text_inverted);
    message.border_radius_button !== undefined && (obj.border_radius_button = message.border_radius_button);
    message.color_action_primary !== undefined && (obj.color_action_primary = message.color_action_primary);
    message.color_text_secondary !== undefined && (obj.color_text_secondary = message.color_text_secondary);
    message.color_action_secondary !== undefined && (obj.color_action_secondary = message.color_action_secondary);
    message.color_border_secondary !== undefined && (obj.color_border_secondary = message.color_border_secondary);
    message.border_radius_progress_bar !== undefined &&
      (obj.border_radius_progress_bar = message.border_radius_progress_bar);
    message.color_background_secondary !== undefined &&
      (obj.color_background_secondary = message.color_background_secondary);
    message.color_action_secondary_pressed !== undefined &&
      (obj.color_action_secondary_pressed = message.color_action_secondary_pressed);
    message.color_action_secondary_disabled !== undefined &&
      (obj.color_action_secondary_disabled = message.color_action_secondary_disabled);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebInterviewStyleConfig>, I>>(base?: I): WebInterviewStyleConfig {
    return WebInterviewStyleConfig.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebInterviewStyleConfig>, I>>(object: I): WebInterviewStyleConfig {
    const message = createBaseWebInterviewStyleConfig();
    message.color_icon_primary = object.color_icon_primary ?? "";
    message.color_text_primary = object.color_text_primary ?? "";
    message.color_text_inverted = object.color_text_inverted ?? "";
    message.border_radius_button = object.border_radius_button ?? "";
    message.color_action_primary = object.color_action_primary ?? "";
    message.color_text_secondary = object.color_text_secondary ?? "";
    message.color_action_secondary = object.color_action_secondary ?? "";
    message.color_border_secondary = object.color_border_secondary ?? "";
    message.border_radius_progress_bar = object.border_radius_progress_bar ?? "";
    message.color_background_secondary = object.color_background_secondary ?? "";
    message.color_action_secondary_pressed = object.color_action_secondary_pressed ?? "";
    message.color_action_secondary_disabled = object.color_action_secondary_disabled ?? "";
    return message;
  },
};

function createBaseWebInterviewEndpoints(): WebInterviewEndpoints {
  return {
    get_user_id: "",
    get_quiz: "",
    save_answers: "",
    validate_images: "",
    visual_analysis: "",
    progress_page_data: "",
    update_user_id: "",
    log: "",
  };
}

export const WebInterviewEndpoints = {
  fromJSON(object: any): WebInterviewEndpoints {
    return {
      get_user_id: isSet(object.get_user_id) ? String(object.get_user_id) : "",
      get_quiz: isSet(object.get_quiz) ? String(object.get_quiz) : "",
      save_answers: isSet(object.save_answers) ? String(object.save_answers) : "",
      validate_images: isSet(object.validate_images) ? String(object.validate_images) : "",
      visual_analysis: isSet(object.visual_analysis) ? String(object.visual_analysis) : "",
      progress_page_data: isSet(object.progress_page_data) ? String(object.progress_page_data) : "",
      update_user_id: isSet(object.update_user_id) ? String(object.update_user_id) : "",
      log: isSet(object.log) ? String(object.log) : "",
    };
  },

  toJSON(message: WebInterviewEndpoints): unknown {
    const obj: any = {};
    message.get_user_id !== undefined && (obj.get_user_id = message.get_user_id);
    message.get_quiz !== undefined && (obj.get_quiz = message.get_quiz);
    message.save_answers !== undefined && (obj.save_answers = message.save_answers);
    message.validate_images !== undefined && (obj.validate_images = message.validate_images);
    message.visual_analysis !== undefined && (obj.visual_analysis = message.visual_analysis);
    message.progress_page_data !== undefined && (obj.progress_page_data = message.progress_page_data);
    message.update_user_id !== undefined && (obj.update_user_id = message.update_user_id);
    message.log !== undefined && (obj.log = message.log);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebInterviewEndpoints>, I>>(base?: I): WebInterviewEndpoints {
    return WebInterviewEndpoints.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebInterviewEndpoints>, I>>(object: I): WebInterviewEndpoints {
    const message = createBaseWebInterviewEndpoints();
    message.get_user_id = object.get_user_id ?? "";
    message.get_quiz = object.get_quiz ?? "";
    message.save_answers = object.save_answers ?? "";
    message.validate_images = object.validate_images ?? "";
    message.visual_analysis = object.visual_analysis ?? "";
    message.progress_page_data = object.progress_page_data ?? "";
    message.update_user_id = object.update_user_id ?? "";
    message.log = object.log ?? "";
    return message;
  },
};

function createBaseWebInterviewConfig(): WebInterviewConfig {
  return { style: undefined, endpoints: undefined, lovi_redirect_url: "" };
}

export const WebInterviewConfig = {
  fromJSON(object: any): WebInterviewConfig {
    return {
      style: isSet(object.style) ? WebInterviewStyleConfig.fromJSON(object.style) : undefined,
      endpoints: isSet(object.endpoints) ? WebInterviewEndpoints.fromJSON(object.endpoints) : undefined,
      lovi_redirect_url: isSet(object.lovi_redirect_url) ? String(object.lovi_redirect_url) : "",
    };
  },

  toJSON(message: WebInterviewConfig): unknown {
    const obj: any = {};
    message.style !== undefined &&
      (obj.style = message.style ? WebInterviewStyleConfig.toJSON(message.style) : undefined);
    message.endpoints !== undefined &&
      (obj.endpoints = message.endpoints ? WebInterviewEndpoints.toJSON(message.endpoints) : undefined);
    message.lovi_redirect_url !== undefined && (obj.lovi_redirect_url = message.lovi_redirect_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<WebInterviewConfig>, I>>(base?: I): WebInterviewConfig {
    return WebInterviewConfig.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WebInterviewConfig>, I>>(object: I): WebInterviewConfig {
    const message = createBaseWebInterviewConfig();
    message.style = (object.style !== undefined && object.style !== null)
      ? WebInterviewStyleConfig.fromPartial(object.style)
      : undefined;
    message.endpoints = (object.endpoints !== undefined && object.endpoints !== null)
      ? WebInterviewEndpoints.fromPartial(object.endpoints)
      : undefined;
    message.lovi_redirect_url = object.lovi_redirect_url ?? "";
    return message;
  },
};

function createBaseFeedbackAirtableConfig(): FeedbackAirtableConfig {
  return {
    base_id: "",
    popups_table_id: "",
    answers_table_id: "",
    actions_table_id: "",
    quiz_screens_table_id: "",
    quiz_answers_table_id: "",
    appreciations_table_id: "",
  };
}

export const FeedbackAirtableConfig = {
  fromJSON(object: any): FeedbackAirtableConfig {
    return {
      base_id: isSet(object.base_id) ? String(object.base_id) : "",
      popups_table_id: isSet(object.popups_table_id) ? String(object.popups_table_id) : "",
      answers_table_id: isSet(object.answers_table_id) ? String(object.answers_table_id) : "",
      actions_table_id: isSet(object.actions_table_id) ? String(object.actions_table_id) : "",
      quiz_screens_table_id: isSet(object.quiz_screens_table_id) ? String(object.quiz_screens_table_id) : "",
      quiz_answers_table_id: isSet(object.quiz_answers_table_id) ? String(object.quiz_answers_table_id) : "",
      appreciations_table_id: isSet(object.appreciations_table_id) ? String(object.appreciations_table_id) : "",
    };
  },

  toJSON(message: FeedbackAirtableConfig): unknown {
    const obj: any = {};
    message.base_id !== undefined && (obj.base_id = message.base_id);
    message.popups_table_id !== undefined && (obj.popups_table_id = message.popups_table_id);
    message.answers_table_id !== undefined && (obj.answers_table_id = message.answers_table_id);
    message.actions_table_id !== undefined && (obj.actions_table_id = message.actions_table_id);
    message.quiz_screens_table_id !== undefined && (obj.quiz_screens_table_id = message.quiz_screens_table_id);
    message.quiz_answers_table_id !== undefined && (obj.quiz_answers_table_id = message.quiz_answers_table_id);
    message.appreciations_table_id !== undefined && (obj.appreciations_table_id = message.appreciations_table_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<FeedbackAirtableConfig>, I>>(base?: I): FeedbackAirtableConfig {
    return FeedbackAirtableConfig.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FeedbackAirtableConfig>, I>>(object: I): FeedbackAirtableConfig {
    const message = createBaseFeedbackAirtableConfig();
    message.base_id = object.base_id ?? "";
    message.popups_table_id = object.popups_table_id ?? "";
    message.answers_table_id = object.answers_table_id ?? "";
    message.actions_table_id = object.actions_table_id ?? "";
    message.quiz_screens_table_id = object.quiz_screens_table_id ?? "";
    message.quiz_answers_table_id = object.quiz_answers_table_id ?? "";
    message.appreciations_table_id = object.appreciations_table_id ?? "";
    return message;
  },
};

function createBaseIoSWelcomeScreenConfig(): IoSWelcomeScreenConfig {
  return { base_name: "", subscreens_table_name: "", screens_table_name: "" };
}

export const IoSWelcomeScreenConfig = {
  fromJSON(object: any): IoSWelcomeScreenConfig {
    return {
      base_name: isSet(object.base_name) ? String(object.base_name) : "",
      subscreens_table_name: isSet(object.subscreens_table_name) ? String(object.subscreens_table_name) : "",
      screens_table_name: isSet(object.screens_table_name) ? String(object.screens_table_name) : "",
    };
  },

  toJSON(message: IoSWelcomeScreenConfig): unknown {
    const obj: any = {};
    message.base_name !== undefined && (obj.base_name = message.base_name);
    message.subscreens_table_name !== undefined && (obj.subscreens_table_name = message.subscreens_table_name);
    message.screens_table_name !== undefined && (obj.screens_table_name = message.screens_table_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<IoSWelcomeScreenConfig>, I>>(base?: I): IoSWelcomeScreenConfig {
    return IoSWelcomeScreenConfig.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<IoSWelcomeScreenConfig>, I>>(object: I): IoSWelcomeScreenConfig {
    const message = createBaseIoSWelcomeScreenConfig();
    message.base_name = object.base_name ?? "";
    message.subscreens_table_name = object.subscreens_table_name ?? "";
    message.screens_table_name = object.screens_table_name ?? "";
    return message;
  },
};

function createBaseOnboardingConfig(): OnboardingConfig {
  return {
    airtable_base_id: "",
    questions_table_id: "",
    answers_table_id: "",
    answer_points_table_id: "",
    interpretation_points_table_id: "",
    interpretation_table_id: "",
    filter_table_id: "",
    skin_conditions_table_id: "",
    progress_bars_screen_table_id: "",
    paywall_table_id: "",
    client: "",
    bayes_fraction: 0,
    price_point_bayes_fraction: 0,
    quiz_bayes_fraction: 0,
    paywall_blocks_bayes_fraction: 0,
    payment_form_bayes_fraction: 0,
    interview_rituals: "",
    interview_ritual_videos: "",
    message_list_screen_table_id: "",
    paywall_variants_table_id: "",
    paywall_prices_table_id: "",
    slide_loader_screen_table_id: "",
    video_instructions_table_id: "",
    video_instruction_items_table_id: "",
    ios_paywall_table_id: "",
    ios_paywall_subscriptions_table_id: "",
    ios_paywall_header_items_table_id: "",
    interview_variants_table_id: "",
    interview_variants_questions_table_id: "",
    ios_welcome_screens_config: undefined,
    app_id: "",
    paywall_properties_table_id: "",
  };
}

export const OnboardingConfig = {
  fromJSON(object: any): OnboardingConfig {
    return {
      airtable_base_id: isSet(object.airtable_base_id) ? String(object.airtable_base_id) : "",
      questions_table_id: isSet(object.questions_table_id) ? String(object.questions_table_id) : "",
      answers_table_id: isSet(object.answers_table_id) ? String(object.answers_table_id) : "",
      answer_points_table_id: isSet(object.answer_points_table_id) ? String(object.answer_points_table_id) : "",
      interpretation_points_table_id: isSet(object.interpretation_points_table_id)
        ? String(object.interpretation_points_table_id)
        : "",
      interpretation_table_id: isSet(object.interpretation_table_id) ? String(object.interpretation_table_id) : "",
      filter_table_id: isSet(object.filter_table_id) ? String(object.filter_table_id) : "",
      skin_conditions_table_id: isSet(object.skin_conditions_table_id) ? String(object.skin_conditions_table_id) : "",
      progress_bars_screen_table_id: isSet(object.progress_bars_screen_table_id)
        ? String(object.progress_bars_screen_table_id)
        : "",
      paywall_table_id: isSet(object.paywall_table_id) ? String(object.paywall_table_id) : "",
      client: isSet(object.client) ? String(object.client) : "",
      bayes_fraction: isSet(object.bayes_fraction) ? Number(object.bayes_fraction) : 0,
      price_point_bayes_fraction: isSet(object.price_point_bayes_fraction)
        ? Number(object.price_point_bayes_fraction)
        : 0,
      quiz_bayes_fraction: isSet(object.quiz_bayes_fraction) ? Number(object.quiz_bayes_fraction) : 0,
      paywall_blocks_bayes_fraction: isSet(object.paywall_blocks_bayes_fraction)
        ? Number(object.paywall_blocks_bayes_fraction)
        : 0,
      payment_form_bayes_fraction: isSet(object.payment_form_bayes_fraction)
        ? Number(object.payment_form_bayes_fraction)
        : 0,
      interview_rituals: isSet(object.interview_rituals) ? String(object.interview_rituals) : "",
      interview_ritual_videos: isSet(object.interview_ritual_videos) ? String(object.interview_ritual_videos) : "",
      message_list_screen_table_id: isSet(object.message_list_screen_table_id)
        ? String(object.message_list_screen_table_id)
        : "",
      paywall_variants_table_id: isSet(object.paywall_variants_table_id)
        ? String(object.paywall_variants_table_id)
        : "",
      paywall_prices_table_id: isSet(object.paywall_prices_table_id) ? String(object.paywall_prices_table_id) : "",
      slide_loader_screen_table_id: isSet(object.slide_loader_screen_table_id)
        ? String(object.slide_loader_screen_table_id)
        : "",
      video_instructions_table_id: isSet(object.video_instructions_table_id)
        ? String(object.video_instructions_table_id)
        : "",
      video_instruction_items_table_id: isSet(object.video_instruction_items_table_id)
        ? String(object.video_instruction_items_table_id)
        : "",
      ios_paywall_table_id: isSet(object.ios_paywall_table_id) ? String(object.ios_paywall_table_id) : "",
      ios_paywall_subscriptions_table_id: isSet(object.ios_paywall_subscriptions_table_id)
        ? String(object.ios_paywall_subscriptions_table_id)
        : "",
      ios_paywall_header_items_table_id: isSet(object.ios_paywall_header_items_table_id)
        ? String(object.ios_paywall_header_items_table_id)
        : "",
      interview_variants_table_id: isSet(object.interview_variants_table_id)
        ? String(object.interview_variants_table_id)
        : "",
      interview_variants_questions_table_id: isSet(object.interview_variants_questions_table_id)
        ? String(object.interview_variants_questions_table_id)
        : "",
      ios_welcome_screens_config: isSet(object.ios_welcome_screens_config)
        ? IoSWelcomeScreenConfig.fromJSON(object.ios_welcome_screens_config)
        : undefined,
      app_id: isSet(object.app_id) ? String(object.app_id) : "",
      paywall_properties_table_id: isSet(object.paywall_properties_table_id)
        ? String(object.paywall_properties_table_id)
        : "",
    };
  },

  toJSON(message: OnboardingConfig): unknown {
    const obj: any = {};
    message.airtable_base_id !== undefined && (obj.airtable_base_id = message.airtable_base_id);
    message.questions_table_id !== undefined && (obj.questions_table_id = message.questions_table_id);
    message.answers_table_id !== undefined && (obj.answers_table_id = message.answers_table_id);
    message.answer_points_table_id !== undefined && (obj.answer_points_table_id = message.answer_points_table_id);
    message.interpretation_points_table_id !== undefined &&
      (obj.interpretation_points_table_id = message.interpretation_points_table_id);
    message.interpretation_table_id !== undefined && (obj.interpretation_table_id = message.interpretation_table_id);
    message.filter_table_id !== undefined && (obj.filter_table_id = message.filter_table_id);
    message.skin_conditions_table_id !== undefined && (obj.skin_conditions_table_id = message.skin_conditions_table_id);
    message.progress_bars_screen_table_id !== undefined &&
      (obj.progress_bars_screen_table_id = message.progress_bars_screen_table_id);
    message.paywall_table_id !== undefined && (obj.paywall_table_id = message.paywall_table_id);
    message.client !== undefined && (obj.client = message.client);
    message.bayes_fraction !== undefined && (obj.bayes_fraction = message.bayes_fraction);
    message.price_point_bayes_fraction !== undefined &&
      (obj.price_point_bayes_fraction = message.price_point_bayes_fraction);
    message.quiz_bayes_fraction !== undefined && (obj.quiz_bayes_fraction = message.quiz_bayes_fraction);
    message.paywall_blocks_bayes_fraction !== undefined &&
      (obj.paywall_blocks_bayes_fraction = message.paywall_blocks_bayes_fraction);
    message.payment_form_bayes_fraction !== undefined &&
      (obj.payment_form_bayes_fraction = message.payment_form_bayes_fraction);
    message.interview_rituals !== undefined && (obj.interview_rituals = message.interview_rituals);
    message.interview_ritual_videos !== undefined && (obj.interview_ritual_videos = message.interview_ritual_videos);
    message.message_list_screen_table_id !== undefined &&
      (obj.message_list_screen_table_id = message.message_list_screen_table_id);
    message.paywall_variants_table_id !== undefined &&
      (obj.paywall_variants_table_id = message.paywall_variants_table_id);
    message.paywall_prices_table_id !== undefined && (obj.paywall_prices_table_id = message.paywall_prices_table_id);
    message.slide_loader_screen_table_id !== undefined &&
      (obj.slide_loader_screen_table_id = message.slide_loader_screen_table_id);
    message.video_instructions_table_id !== undefined &&
      (obj.video_instructions_table_id = message.video_instructions_table_id);
    message.video_instruction_items_table_id !== undefined &&
      (obj.video_instruction_items_table_id = message.video_instruction_items_table_id);
    message.ios_paywall_table_id !== undefined && (obj.ios_paywall_table_id = message.ios_paywall_table_id);
    message.ios_paywall_subscriptions_table_id !== undefined &&
      (obj.ios_paywall_subscriptions_table_id = message.ios_paywall_subscriptions_table_id);
    message.ios_paywall_header_items_table_id !== undefined &&
      (obj.ios_paywall_header_items_table_id = message.ios_paywall_header_items_table_id);
    message.interview_variants_table_id !== undefined &&
      (obj.interview_variants_table_id = message.interview_variants_table_id);
    message.interview_variants_questions_table_id !== undefined &&
      (obj.interview_variants_questions_table_id = message.interview_variants_questions_table_id);
    message.ios_welcome_screens_config !== undefined &&
      (obj.ios_welcome_screens_config = message.ios_welcome_screens_config
        ? IoSWelcomeScreenConfig.toJSON(message.ios_welcome_screens_config)
        : undefined);
    message.app_id !== undefined && (obj.app_id = message.app_id);
    message.paywall_properties_table_id !== undefined &&
      (obj.paywall_properties_table_id = message.paywall_properties_table_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<OnboardingConfig>, I>>(base?: I): OnboardingConfig {
    return OnboardingConfig.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OnboardingConfig>, I>>(object: I): OnboardingConfig {
    const message = createBaseOnboardingConfig();
    message.airtable_base_id = object.airtable_base_id ?? "";
    message.questions_table_id = object.questions_table_id ?? "";
    message.answers_table_id = object.answers_table_id ?? "";
    message.answer_points_table_id = object.answer_points_table_id ?? "";
    message.interpretation_points_table_id = object.interpretation_points_table_id ?? "";
    message.interpretation_table_id = object.interpretation_table_id ?? "";
    message.filter_table_id = object.filter_table_id ?? "";
    message.skin_conditions_table_id = object.skin_conditions_table_id ?? "";
    message.progress_bars_screen_table_id = object.progress_bars_screen_table_id ?? "";
    message.paywall_table_id = object.paywall_table_id ?? "";
    message.client = object.client ?? "";
    message.bayes_fraction = object.bayes_fraction ?? 0;
    message.price_point_bayes_fraction = object.price_point_bayes_fraction ?? 0;
    message.quiz_bayes_fraction = object.quiz_bayes_fraction ?? 0;
    message.paywall_blocks_bayes_fraction = object.paywall_blocks_bayes_fraction ?? 0;
    message.payment_form_bayes_fraction = object.payment_form_bayes_fraction ?? 0;
    message.interview_rituals = object.interview_rituals ?? "";
    message.interview_ritual_videos = object.interview_ritual_videos ?? "";
    message.message_list_screen_table_id = object.message_list_screen_table_id ?? "";
    message.paywall_variants_table_id = object.paywall_variants_table_id ?? "";
    message.paywall_prices_table_id = object.paywall_prices_table_id ?? "";
    message.slide_loader_screen_table_id = object.slide_loader_screen_table_id ?? "";
    message.video_instructions_table_id = object.video_instructions_table_id ?? "";
    message.video_instruction_items_table_id = object.video_instruction_items_table_id ?? "";
    message.ios_paywall_table_id = object.ios_paywall_table_id ?? "";
    message.ios_paywall_subscriptions_table_id = object.ios_paywall_subscriptions_table_id ?? "";
    message.ios_paywall_header_items_table_id = object.ios_paywall_header_items_table_id ?? "";
    message.interview_variants_table_id = object.interview_variants_table_id ?? "";
    message.interview_variants_questions_table_id = object.interview_variants_questions_table_id ?? "";
    message.ios_welcome_screens_config =
      (object.ios_welcome_screens_config !== undefined && object.ios_welcome_screens_config !== null)
        ? IoSWelcomeScreenConfig.fromPartial(object.ios_welcome_screens_config)
        : undefined;
    message.app_id = object.app_id ?? "";
    message.paywall_properties_table_id = object.paywall_properties_table_id ?? "";
    return message;
  },
};

function createBaseInterviewServiceConfig(): InterviewServiceConfig {
  return {
    web_onboarding_config: undefined,
    lovi_ios_onboarding_config: undefined,
    environment: "",
    first_quiz_id: "",
    airtable_base_id: "",
    airtable_intro_base_id: "",
    intro_table_id: "",
    screens_table_id: "",
    jars_base_id: "",
    jars_table_id: "",
    jars_applying_table_id: "",
    daily_quiz_questions_table_id: "",
    daily_quiz_answers_table_id: "",
    payment_status_content_table_id: "",
    web_interview_style_config_table_id: "",
    emails_airtable_base_id: "",
    emails_table_id: "",
    rename_features_table_id: "",
    content_base_id: "",
    journey_table_id: "",
    personalized_content_table_id: "",
    rituals_table_id: "",
    stories_table_id: "",
    chapters_table_id: "",
    base_api_url: "",
    auth0_url: "",
    auth0_client_id: "",
    auth0_client_secret: "",
    feedback_config: undefined,
    progress_page_data: undefined,
    compliance_urls_data: undefined,
    fault_rate: 0,
    visualization_colors_table: "",
    web_interview_endpoints: undefined,
    lovi_redirect_url: "",
    subscription_check_url: "",
    product_page_tutorials_table_id: "",
    handpicked_jars_base_id: "",
    handpicked_jars_products_table_id: "",
    experts_review_id: "",
    didnt_find_my_product_table_id: "",
    banners_table_id: "",
    subscription_redirect_url: "",
    paltaPaymentsSubscriptionUrl: "",
    paltaPaymentsCheckFreePeriodUsageUrl: "",
    paltaPaymentsApplyFreePeriodUsageUrl: "",
    paltaPaymentsCancelSubscriptionUrl: "",
    app_paywall_table_id: "",
    paltaPaymentsSubscriptionListUrl: "",
  };
}

export const InterviewServiceConfig = {
  fromJSON(object: any): InterviewServiceConfig {
    return {
      web_onboarding_config: isSet(object.web_onboarding_config)
        ? OnboardingConfig.fromJSON(object.web_onboarding_config)
        : undefined,
      lovi_ios_onboarding_config: isSet(object.lovi_ios_onboarding_config)
        ? OnboardingConfig.fromJSON(object.lovi_ios_onboarding_config)
        : undefined,
      environment: isSet(object.environment) ? String(object.environment) : "",
      first_quiz_id: isSet(object.first_quiz_id) ? String(object.first_quiz_id) : "",
      airtable_base_id: isSet(object.airtable_base_id) ? String(object.airtable_base_id) : "",
      airtable_intro_base_id: isSet(object.airtable_intro_base_id) ? String(object.airtable_intro_base_id) : "",
      intro_table_id: isSet(object.intro_table_id) ? String(object.intro_table_id) : "",
      screens_table_id: isSet(object.screens_table_id) ? String(object.screens_table_id) : "",
      jars_base_id: isSet(object.jars_base_id) ? String(object.jars_base_id) : "",
      jars_table_id: isSet(object.jars_table_id) ? String(object.jars_table_id) : "",
      jars_applying_table_id: isSet(object.jars_applying_table_id) ? String(object.jars_applying_table_id) : "",
      daily_quiz_questions_table_id: isSet(object.daily_quiz_questions_table_id)
        ? String(object.daily_quiz_questions_table_id)
        : "",
      daily_quiz_answers_table_id: isSet(object.daily_quiz_answers_table_id)
        ? String(object.daily_quiz_answers_table_id)
        : "",
      payment_status_content_table_id: isSet(object.payment_status_content_table_id)
        ? String(object.payment_status_content_table_id)
        : "",
      web_interview_style_config_table_id: isSet(object.web_interview_style_config_table_id)
        ? String(object.web_interview_style_config_table_id)
        : "",
      emails_airtable_base_id: isSet(object.emails_airtable_base_id) ? String(object.emails_airtable_base_id) : "",
      emails_table_id: isSet(object.emails_table_id) ? String(object.emails_table_id) : "",
      rename_features_table_id: isSet(object.rename_features_table_id) ? String(object.rename_features_table_id) : "",
      content_base_id: isSet(object.content_base_id) ? String(object.content_base_id) : "",
      journey_table_id: isSet(object.journey_table_id) ? String(object.journey_table_id) : "",
      personalized_content_table_id: isSet(object.personalized_content_table_id)
        ? String(object.personalized_content_table_id)
        : "",
      rituals_table_id: isSet(object.rituals_table_id) ? String(object.rituals_table_id) : "",
      stories_table_id: isSet(object.stories_table_id) ? String(object.stories_table_id) : "",
      chapters_table_id: isSet(object.chapters_table_id) ? String(object.chapters_table_id) : "",
      base_api_url: isSet(object.base_api_url) ? String(object.base_api_url) : "",
      auth0_url: isSet(object.auth0_url) ? String(object.auth0_url) : "",
      auth0_client_id: isSet(object.auth0_client_id) ? String(object.auth0_client_id) : "",
      auth0_client_secret: isSet(object.auth0_client_secret) ? String(object.auth0_client_secret) : "",
      feedback_config: isSet(object.feedback_config)
        ? FeedbackAirtableConfig.fromJSON(object.feedback_config)
        : undefined,
      progress_page_data: isSet(object.progress_page_data)
        ? ProgressPageData.fromJSON(object.progress_page_data)
        : undefined,
      compliance_urls_data: isSet(object.compliance_urls_data)
        ? ComplianceURLsData.fromJSON(object.compliance_urls_data)
        : undefined,
      fault_rate: isSet(object.fault_rate) ? Number(object.fault_rate) : 0,
      visualization_colors_table: isSet(object.visualization_colors_table)
        ? String(object.visualization_colors_table)
        : "",
      web_interview_endpoints: isSet(object.web_interview_endpoints)
        ? WebInterviewEndpoints.fromJSON(object.web_interview_endpoints)
        : undefined,
      lovi_redirect_url: isSet(object.lovi_redirect_url) ? String(object.lovi_redirect_url) : "",
      subscription_check_url: isSet(object.subscription_check_url) ? String(object.subscription_check_url) : "",
      product_page_tutorials_table_id: isSet(object.product_page_tutorials_table_id)
        ? String(object.product_page_tutorials_table_id)
        : "",
      handpicked_jars_base_id: isSet(object.handpicked_jars_base_id) ? String(object.handpicked_jars_base_id) : "",
      handpicked_jars_products_table_id: isSet(object.handpicked_jars_products_table_id)
        ? String(object.handpicked_jars_products_table_id)
        : "",
      experts_review_id: isSet(object.experts_review_id) ? String(object.experts_review_id) : "",
      didnt_find_my_product_table_id: isSet(object.didnt_find_my_product_table_id)
        ? String(object.didnt_find_my_product_table_id)
        : "",
      banners_table_id: isSet(object.banners_table_id) ? String(object.banners_table_id) : "",
      subscription_redirect_url: isSet(object.subscription_redirect_url)
        ? String(object.subscription_redirect_url)
        : "",
      paltaPaymentsSubscriptionUrl: isSet(object.paltaPaymentsSubscriptionUrl)
        ? String(object.paltaPaymentsSubscriptionUrl)
        : "",
      paltaPaymentsCheckFreePeriodUsageUrl: isSet(object.paltaPaymentsCheckFreePeriodUsageUrl)
        ? String(object.paltaPaymentsCheckFreePeriodUsageUrl)
        : "",
      paltaPaymentsApplyFreePeriodUsageUrl: isSet(object.paltaPaymentsApplyFreePeriodUsageUrl)
        ? String(object.paltaPaymentsApplyFreePeriodUsageUrl)
        : "",
      paltaPaymentsCancelSubscriptionUrl: isSet(object.paltaPaymentsCancelSubscriptionUrl)
        ? String(object.paltaPaymentsCancelSubscriptionUrl)
        : "",
      app_paywall_table_id: isSet(object.app_paywall_table_id) ? String(object.app_paywall_table_id) : "",
      paltaPaymentsSubscriptionListUrl: isSet(object.paltaPaymentsSubscriptionListUrl)
        ? String(object.paltaPaymentsSubscriptionListUrl)
        : "",
    };
  },

  toJSON(message: InterviewServiceConfig): unknown {
    const obj: any = {};
    message.web_onboarding_config !== undefined && (obj.web_onboarding_config = message.web_onboarding_config
      ? OnboardingConfig.toJSON(message.web_onboarding_config)
      : undefined);
    message.lovi_ios_onboarding_config !== undefined &&
      (obj.lovi_ios_onboarding_config = message.lovi_ios_onboarding_config
        ? OnboardingConfig.toJSON(message.lovi_ios_onboarding_config)
        : undefined);
    message.environment !== undefined && (obj.environment = message.environment);
    message.first_quiz_id !== undefined && (obj.first_quiz_id = message.first_quiz_id);
    message.airtable_base_id !== undefined && (obj.airtable_base_id = message.airtable_base_id);
    message.airtable_intro_base_id !== undefined && (obj.airtable_intro_base_id = message.airtable_intro_base_id);
    message.intro_table_id !== undefined && (obj.intro_table_id = message.intro_table_id);
    message.screens_table_id !== undefined && (obj.screens_table_id = message.screens_table_id);
    message.jars_base_id !== undefined && (obj.jars_base_id = message.jars_base_id);
    message.jars_table_id !== undefined && (obj.jars_table_id = message.jars_table_id);
    message.jars_applying_table_id !== undefined && (obj.jars_applying_table_id = message.jars_applying_table_id);
    message.daily_quiz_questions_table_id !== undefined &&
      (obj.daily_quiz_questions_table_id = message.daily_quiz_questions_table_id);
    message.daily_quiz_answers_table_id !== undefined &&
      (obj.daily_quiz_answers_table_id = message.daily_quiz_answers_table_id);
    message.payment_status_content_table_id !== undefined &&
      (obj.payment_status_content_table_id = message.payment_status_content_table_id);
    message.web_interview_style_config_table_id !== undefined &&
      (obj.web_interview_style_config_table_id = message.web_interview_style_config_table_id);
    message.emails_airtable_base_id !== undefined && (obj.emails_airtable_base_id = message.emails_airtable_base_id);
    message.emails_table_id !== undefined && (obj.emails_table_id = message.emails_table_id);
    message.rename_features_table_id !== undefined && (obj.rename_features_table_id = message.rename_features_table_id);
    message.content_base_id !== undefined && (obj.content_base_id = message.content_base_id);
    message.journey_table_id !== undefined && (obj.journey_table_id = message.journey_table_id);
    message.personalized_content_table_id !== undefined &&
      (obj.personalized_content_table_id = message.personalized_content_table_id);
    message.rituals_table_id !== undefined && (obj.rituals_table_id = message.rituals_table_id);
    message.stories_table_id !== undefined && (obj.stories_table_id = message.stories_table_id);
    message.chapters_table_id !== undefined && (obj.chapters_table_id = message.chapters_table_id);
    message.base_api_url !== undefined && (obj.base_api_url = message.base_api_url);
    message.auth0_url !== undefined && (obj.auth0_url = message.auth0_url);
    message.auth0_client_id !== undefined && (obj.auth0_client_id = message.auth0_client_id);
    message.auth0_client_secret !== undefined && (obj.auth0_client_secret = message.auth0_client_secret);
    message.feedback_config !== undefined && (obj.feedback_config = message.feedback_config
      ? FeedbackAirtableConfig.toJSON(message.feedback_config)
      : undefined);
    message.progress_page_data !== undefined && (obj.progress_page_data = message.progress_page_data
      ? ProgressPageData.toJSON(message.progress_page_data)
      : undefined);
    message.compliance_urls_data !== undefined && (obj.compliance_urls_data = message.compliance_urls_data
      ? ComplianceURLsData.toJSON(message.compliance_urls_data)
      : undefined);
    message.fault_rate !== undefined && (obj.fault_rate = message.fault_rate);
    message.visualization_colors_table !== undefined &&
      (obj.visualization_colors_table = message.visualization_colors_table);
    message.web_interview_endpoints !== undefined && (obj.web_interview_endpoints = message.web_interview_endpoints
      ? WebInterviewEndpoints.toJSON(message.web_interview_endpoints)
      : undefined);
    message.lovi_redirect_url !== undefined && (obj.lovi_redirect_url = message.lovi_redirect_url);
    message.subscription_check_url !== undefined && (obj.subscription_check_url = message.subscription_check_url);
    message.product_page_tutorials_table_id !== undefined &&
      (obj.product_page_tutorials_table_id = message.product_page_tutorials_table_id);
    message.handpicked_jars_base_id !== undefined && (obj.handpicked_jars_base_id = message.handpicked_jars_base_id);
    message.handpicked_jars_products_table_id !== undefined &&
      (obj.handpicked_jars_products_table_id = message.handpicked_jars_products_table_id);
    message.experts_review_id !== undefined && (obj.experts_review_id = message.experts_review_id);
    message.didnt_find_my_product_table_id !== undefined &&
      (obj.didnt_find_my_product_table_id = message.didnt_find_my_product_table_id);
    message.banners_table_id !== undefined && (obj.banners_table_id = message.banners_table_id);
    message.subscription_redirect_url !== undefined &&
      (obj.subscription_redirect_url = message.subscription_redirect_url);
    message.paltaPaymentsSubscriptionUrl !== undefined &&
      (obj.paltaPaymentsSubscriptionUrl = message.paltaPaymentsSubscriptionUrl);
    message.paltaPaymentsCheckFreePeriodUsageUrl !== undefined &&
      (obj.paltaPaymentsCheckFreePeriodUsageUrl = message.paltaPaymentsCheckFreePeriodUsageUrl);
    message.paltaPaymentsApplyFreePeriodUsageUrl !== undefined &&
      (obj.paltaPaymentsApplyFreePeriodUsageUrl = message.paltaPaymentsApplyFreePeriodUsageUrl);
    message.paltaPaymentsCancelSubscriptionUrl !== undefined &&
      (obj.paltaPaymentsCancelSubscriptionUrl = message.paltaPaymentsCancelSubscriptionUrl);
    message.app_paywall_table_id !== undefined && (obj.app_paywall_table_id = message.app_paywall_table_id);
    message.paltaPaymentsSubscriptionListUrl !== undefined &&
      (obj.paltaPaymentsSubscriptionListUrl = message.paltaPaymentsSubscriptionListUrl);
    return obj;
  },

  create<I extends Exact<DeepPartial<InterviewServiceConfig>, I>>(base?: I): InterviewServiceConfig {
    return InterviewServiceConfig.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<InterviewServiceConfig>, I>>(object: I): InterviewServiceConfig {
    const message = createBaseInterviewServiceConfig();
    message.web_onboarding_config =
      (object.web_onboarding_config !== undefined && object.web_onboarding_config !== null)
        ? OnboardingConfig.fromPartial(object.web_onboarding_config)
        : undefined;
    message.lovi_ios_onboarding_config =
      (object.lovi_ios_onboarding_config !== undefined && object.lovi_ios_onboarding_config !== null)
        ? OnboardingConfig.fromPartial(object.lovi_ios_onboarding_config)
        : undefined;
    message.environment = object.environment ?? "";
    message.first_quiz_id = object.first_quiz_id ?? "";
    message.airtable_base_id = object.airtable_base_id ?? "";
    message.airtable_intro_base_id = object.airtable_intro_base_id ?? "";
    message.intro_table_id = object.intro_table_id ?? "";
    message.screens_table_id = object.screens_table_id ?? "";
    message.jars_base_id = object.jars_base_id ?? "";
    message.jars_table_id = object.jars_table_id ?? "";
    message.jars_applying_table_id = object.jars_applying_table_id ?? "";
    message.daily_quiz_questions_table_id = object.daily_quiz_questions_table_id ?? "";
    message.daily_quiz_answers_table_id = object.daily_quiz_answers_table_id ?? "";
    message.payment_status_content_table_id = object.payment_status_content_table_id ?? "";
    message.web_interview_style_config_table_id = object.web_interview_style_config_table_id ?? "";
    message.emails_airtable_base_id = object.emails_airtable_base_id ?? "";
    message.emails_table_id = object.emails_table_id ?? "";
    message.rename_features_table_id = object.rename_features_table_id ?? "";
    message.content_base_id = object.content_base_id ?? "";
    message.journey_table_id = object.journey_table_id ?? "";
    message.personalized_content_table_id = object.personalized_content_table_id ?? "";
    message.rituals_table_id = object.rituals_table_id ?? "";
    message.stories_table_id = object.stories_table_id ?? "";
    message.chapters_table_id = object.chapters_table_id ?? "";
    message.base_api_url = object.base_api_url ?? "";
    message.auth0_url = object.auth0_url ?? "";
    message.auth0_client_id = object.auth0_client_id ?? "";
    message.auth0_client_secret = object.auth0_client_secret ?? "";
    message.feedback_config = (object.feedback_config !== undefined && object.feedback_config !== null)
      ? FeedbackAirtableConfig.fromPartial(object.feedback_config)
      : undefined;
    message.progress_page_data = (object.progress_page_data !== undefined && object.progress_page_data !== null)
      ? ProgressPageData.fromPartial(object.progress_page_data)
      : undefined;
    message.compliance_urls_data = (object.compliance_urls_data !== undefined && object.compliance_urls_data !== null)
      ? ComplianceURLsData.fromPartial(object.compliance_urls_data)
      : undefined;
    message.fault_rate = object.fault_rate ?? 0;
    message.visualization_colors_table = object.visualization_colors_table ?? "";
    message.web_interview_endpoints =
      (object.web_interview_endpoints !== undefined && object.web_interview_endpoints !== null)
        ? WebInterviewEndpoints.fromPartial(object.web_interview_endpoints)
        : undefined;
    message.lovi_redirect_url = object.lovi_redirect_url ?? "";
    message.subscription_check_url = object.subscription_check_url ?? "";
    message.product_page_tutorials_table_id = object.product_page_tutorials_table_id ?? "";
    message.handpicked_jars_base_id = object.handpicked_jars_base_id ?? "";
    message.handpicked_jars_products_table_id = object.handpicked_jars_products_table_id ?? "";
    message.experts_review_id = object.experts_review_id ?? "";
    message.didnt_find_my_product_table_id = object.didnt_find_my_product_table_id ?? "";
    message.banners_table_id = object.banners_table_id ?? "";
    message.subscription_redirect_url = object.subscription_redirect_url ?? "";
    message.paltaPaymentsSubscriptionUrl = object.paltaPaymentsSubscriptionUrl ?? "";
    message.paltaPaymentsCheckFreePeriodUsageUrl = object.paltaPaymentsCheckFreePeriodUsageUrl ?? "";
    message.paltaPaymentsApplyFreePeriodUsageUrl = object.paltaPaymentsApplyFreePeriodUsageUrl ?? "";
    message.paltaPaymentsCancelSubscriptionUrl = object.paltaPaymentsCancelSubscriptionUrl ?? "";
    message.app_paywall_table_id = object.app_paywall_table_id ?? "";
    message.paltaPaymentsSubscriptionListUrl = object.paltaPaymentsSubscriptionListUrl ?? "";
    return message;
  },
};

function createBaseBuyProduct(): BuyProduct {
  return { product: undefined, popup: undefined };
}

export const BuyProduct = {
  fromJSON(object: any): BuyProduct {
    return {
      product: isSet(object.product) ? Product.fromJSON(object.product) : undefined,
      popup: isSet(object.popup) ? PopupWithLinks.fromJSON(object.popup) : undefined,
    };
  },

  toJSON(message: BuyProduct): unknown {
    const obj: any = {};
    message.product !== undefined && (obj.product = message.product ? Product.toJSON(message.product) : undefined);
    message.popup !== undefined && (obj.popup = message.popup ? PopupWithLinks.toJSON(message.popup) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<BuyProduct>, I>>(base?: I): BuyProduct {
    return BuyProduct.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BuyProduct>, I>>(object: I): BuyProduct {
    const message = createBaseBuyProduct();
    message.product = (object.product !== undefined && object.product !== null)
      ? Product.fromPartial(object.product)
      : undefined;
    message.popup = (object.popup !== undefined && object.popup !== null)
      ? PopupWithLinks.fromPartial(object.popup)
      : undefined;
    return message;
  },
};

function createBasePopupWithLinks(): PopupWithLinks {
  return { title: "", links: [] };
}

export const PopupWithLinks = {
  fromJSON(object: any): PopupWithLinks {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      links: Array.isArray(object?.links) ? object.links.map((e: any) => LinkData.fromJSON(e)) : [],
    };
  },

  toJSON(message: PopupWithLinks): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.links) {
      obj.links = message.links.map((e) => e ? LinkData.toJSON(e) : undefined);
    } else {
      obj.links = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PopupWithLinks>, I>>(base?: I): PopupWithLinks {
    return PopupWithLinks.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PopupWithLinks>, I>>(object: I): PopupWithLinks {
    const message = createBasePopupWithLinks();
    message.title = object.title ?? "";
    message.links = object.links?.map((e) => LinkData.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProduct(): Product {
  return { image: "", steps: [], title: "", sub_title: "", tags: [] };
}

export const Product = {
  fromJSON(object: any): Product {
    return {
      image: isSet(object.image) ? String(object.image) : "",
      steps: Array.isArray(object?.steps) ? object.steps.map((e: any) => String(e)) : [],
      title: isSet(object.title) ? String(object.title) : "",
      sub_title: isSet(object.sub_title) ? String(object.sub_title) : "",
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: Product): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    if (message.steps) {
      obj.steps = message.steps.map((e) => e);
    } else {
      obj.steps = [];
    }
    message.title !== undefined && (obj.title = message.title);
    message.sub_title !== undefined && (obj.sub_title = message.sub_title);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Product>, I>>(base?: I): Product {
    return Product.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Product>, I>>(object: I): Product {
    const message = createBaseProduct();
    message.image = object.image ?? "";
    message.steps = object.steps?.map((e) => e) || [];
    message.title = object.title ?? "";
    message.sub_title = object.sub_title ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    return message;
  },
};

function createBasePoint(): Point {
  return { title: "", values: [] };
}

export const Point = {
  fromJSON(object: any): Point {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      values: Array.isArray(object?.values) ? object.values.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: Point): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.values) {
      obj.values = message.values.map((e) => e);
    } else {
      obj.values = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Point>, I>>(base?: I): Point {
    return Point.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Point>, I>>(object: I): Point {
    const message = createBasePoint();
    message.title = object.title ?? "";
    message.values = object.values?.map((e) => e) || [];
    return message;
  },
};

function createBaseSummaryScreen(): SummaryScreen {
  return { title: "", sub_title: "", points: [] };
}

export const SummaryScreen = {
  fromJSON(object: any): SummaryScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      sub_title: isSet(object.sub_title) ? String(object.sub_title) : "",
      points: Array.isArray(object?.points) ? object.points.map((e: any) => Point.fromJSON(e)) : [],
    };
  },

  toJSON(message: SummaryScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.sub_title !== undefined && (obj.sub_title = message.sub_title);
    if (message.points) {
      obj.points = message.points.map((e) => e ? Point.toJSON(e) : undefined);
    } else {
      obj.points = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SummaryScreen>, I>>(base?: I): SummaryScreen {
    return SummaryScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SummaryScreen>, I>>(object: I): SummaryScreen {
    const message = createBaseSummaryScreen();
    message.title = object.title ?? "";
    message.sub_title = object.sub_title ?? "";
    message.points = object.points?.map((e) => Point.fromPartial(e)) || [];
    return message;
  },
};

function createBasePrePaywallSummaryScreen(): PrePaywallSummaryScreen {
  return { title: undefined, summary_list: [], graph_img: "", graph_title: undefined, button_text: "" };
}

export const PrePaywallSummaryScreen = {
  fromJSON(object: any): PrePaywallSummaryScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      summary_list: Array.isArray(object?.summary_list)
        ? object.summary_list.map((e: any) => SummaryListItem.fromJSON(e))
        : [],
      graph_img: isSet(object.graph_img) ? String(object.graph_img) : "",
      graph_title: isSet(object.graph_title) ? AttributedString.fromJSON(object.graph_title) : undefined,
      button_text: isSet(object.button_text) ? String(object.button_text) : "",
    };
  },

  toJSON(message: PrePaywallSummaryScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.summary_list) {
      obj.summary_list = message.summary_list.map((e) => e ? SummaryListItem.toJSON(e) : undefined);
    } else {
      obj.summary_list = [];
    }
    message.graph_img !== undefined && (obj.graph_img = message.graph_img);
    message.graph_title !== undefined &&
      (obj.graph_title = message.graph_title ? AttributedString.toJSON(message.graph_title) : undefined);
    message.button_text !== undefined && (obj.button_text = message.button_text);
    return obj;
  },

  create<I extends Exact<DeepPartial<PrePaywallSummaryScreen>, I>>(base?: I): PrePaywallSummaryScreen {
    return PrePaywallSummaryScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrePaywallSummaryScreen>, I>>(object: I): PrePaywallSummaryScreen {
    const message = createBasePrePaywallSummaryScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.summary_list = object.summary_list?.map((e) => SummaryListItem.fromPartial(e)) || [];
    message.graph_img = object.graph_img ?? "";
    message.graph_title = (object.graph_title !== undefined && object.graph_title !== null)
      ? AttributedString.fromPartial(object.graph_title)
      : undefined;
    message.button_text = object.button_text ?? "";
    return message;
  },
};

function createBaseProductFitScreen(): ProductFitScreen {
  return { product_images: [], title: undefined, view_variant: ProductFitScreen_ViewVariant.unknown };
}

export const ProductFitScreen = {
  fromJSON(object: any): ProductFitScreen {
    return {
      product_images: Array.isArray(object?.product_images) ? object.product_images.map((e: any) => String(e)) : [],
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      view_variant: isSet(object.view_variant)
        ? productFitScreen_ViewVariantFromJSON(object.view_variant)
        : ProductFitScreen_ViewVariant.unknown,
    };
  },

  toJSON(message: ProductFitScreen): unknown {
    const obj: any = {};
    if (message.product_images) {
      obj.product_images = message.product_images.map((e) => e);
    } else {
      obj.product_images = [];
    }
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.view_variant !== undefined && (obj.view_variant = productFitScreen_ViewVariantToJSON(message.view_variant));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScreen>, I>>(base?: I): ProductFitScreen {
    return ProductFitScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScreen>, I>>(object: I): ProductFitScreen {
    const message = createBaseProductFitScreen();
    message.product_images = object.product_images?.map((e) => e) || [];
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.view_variant = object.view_variant ?? ProductFitScreen_ViewVariant.unknown;
    return message;
  },
};

function createBaseProductFit2Screen(): ProductFit2Screen {
  return {
    product_images: [],
    title: undefined,
    view_variant: ProductFit2Screen_ViewVariant.unknown,
    description: undefined,
    description2: undefined,
  };
}

export const ProductFit2Screen = {
  fromJSON(object: any): ProductFit2Screen {
    return {
      product_images: Array.isArray(object?.product_images) ? object.product_images.map((e: any) => String(e)) : [],
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      view_variant: isSet(object.view_variant)
        ? productFit2Screen_ViewVariantFromJSON(object.view_variant)
        : ProductFit2Screen_ViewVariant.unknown,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      description2: isSet(object.description2) ? AttributedString.fromJSON(object.description2) : undefined,
    };
  },

  toJSON(message: ProductFit2Screen): unknown {
    const obj: any = {};
    if (message.product_images) {
      obj.product_images = message.product_images.map((e) => e);
    } else {
      obj.product_images = [];
    }
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.view_variant !== undefined &&
      (obj.view_variant = productFit2Screen_ViewVariantToJSON(message.view_variant));
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.description2 !== undefined &&
      (obj.description2 = message.description2 ? AttributedString.toJSON(message.description2) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFit2Screen>, I>>(base?: I): ProductFit2Screen {
    return ProductFit2Screen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFit2Screen>, I>>(object: I): ProductFit2Screen {
    const message = createBaseProductFit2Screen();
    message.product_images = object.product_images?.map((e) => e) || [];
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.view_variant = object.view_variant ?? ProductFit2Screen_ViewVariant.unknown;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.description2 = (object.description2 !== undefined && object.description2 !== null)
      ? AttributedString.fromPartial(object.description2)
      : undefined;
    return message;
  },
};

function createBaseProductFit3Screen(): ProductFit3Screen {
  return {
    product_images: [],
    title: undefined,
    view_variant: ProductFit3Screen_ViewVariant.unknown,
    description: undefined,
    description2: undefined,
  };
}

export const ProductFit3Screen = {
  fromJSON(object: any): ProductFit3Screen {
    return {
      product_images: Array.isArray(object?.product_images) ? object.product_images.map((e: any) => String(e)) : [],
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      view_variant: isSet(object.view_variant)
        ? productFit3Screen_ViewVariantFromJSON(object.view_variant)
        : ProductFit3Screen_ViewVariant.unknown,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      description2: isSet(object.description2) ? AttributedString.fromJSON(object.description2) : undefined,
    };
  },

  toJSON(message: ProductFit3Screen): unknown {
    const obj: any = {};
    if (message.product_images) {
      obj.product_images = message.product_images.map((e) => e);
    } else {
      obj.product_images = [];
    }
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.view_variant !== undefined &&
      (obj.view_variant = productFit3Screen_ViewVariantToJSON(message.view_variant));
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.description2 !== undefined &&
      (obj.description2 = message.description2 ? AttributedString.toJSON(message.description2) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFit3Screen>, I>>(base?: I): ProductFit3Screen {
    return ProductFit3Screen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFit3Screen>, I>>(object: I): ProductFit3Screen {
    const message = createBaseProductFit3Screen();
    message.product_images = object.product_images?.map((e) => e) || [];
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.view_variant = object.view_variant ?? ProductFit3Screen_ViewVariant.unknown;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.description2 = (object.description2 !== undefined && object.description2 !== null)
      ? AttributedString.fromPartial(object.description2)
      : undefined;
    return message;
  },
};

function createBaseProductFitResultScreen(): ProductFitResultScreen {
  return { fit_products: undefined, not_fit_products: undefined, title: undefined };
}

export const ProductFitResultScreen = {
  fromJSON(object: any): ProductFitResultScreen {
    return {
      fit_products: isSet(object.fit_products)
        ? ProductFitResultScreen_ProductFitData.fromJSON(object.fit_products)
        : undefined,
      not_fit_products: isSet(object.not_fit_products)
        ? ProductFitResultScreen_ProductFitData.fromJSON(object.not_fit_products)
        : undefined,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
    };
  },

  toJSON(message: ProductFitResultScreen): unknown {
    const obj: any = {};
    message.fit_products !== undefined && (obj.fit_products = message.fit_products
      ? ProductFitResultScreen_ProductFitData.toJSON(message.fit_products)
      : undefined);
    message.not_fit_products !== undefined && (obj.not_fit_products = message.not_fit_products
      ? ProductFitResultScreen_ProductFitData.toJSON(message.not_fit_products)
      : undefined);
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitResultScreen>, I>>(base?: I): ProductFitResultScreen {
    return ProductFitResultScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitResultScreen>, I>>(object: I): ProductFitResultScreen {
    const message = createBaseProductFitResultScreen();
    message.fit_products = (object.fit_products !== undefined && object.fit_products !== null)
      ? ProductFitResultScreen_ProductFitData.fromPartial(object.fit_products)
      : undefined;
    message.not_fit_products = (object.not_fit_products !== undefined && object.not_fit_products !== null)
      ? ProductFitResultScreen_ProductFitData.fromPartial(object.not_fit_products)
      : undefined;
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    return message;
  },
};

function createBaseProductFitResultScreen_ProductListItem(): ProductFitResultScreen_ProductListItem {
  return { fit_percentage: 0, description: "", image: "", category: "" };
}

export const ProductFitResultScreen_ProductListItem = {
  fromJSON(object: any): ProductFitResultScreen_ProductListItem {
    return {
      fit_percentage: isSet(object.fit_percentage) ? Number(object.fit_percentage) : 0,
      description: isSet(object.description) ? String(object.description) : "",
      image: isSet(object.image) ? String(object.image) : "",
      category: isSet(object.category) ? String(object.category) : "",
    };
  },

  toJSON(message: ProductFitResultScreen_ProductListItem): unknown {
    const obj: any = {};
    message.fit_percentage !== undefined && (obj.fit_percentage = Math.round(message.fit_percentage));
    message.description !== undefined && (obj.description = message.description);
    message.image !== undefined && (obj.image = message.image);
    message.category !== undefined && (obj.category = message.category);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitResultScreen_ProductListItem>, I>>(
    base?: I,
  ): ProductFitResultScreen_ProductListItem {
    return ProductFitResultScreen_ProductListItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitResultScreen_ProductListItem>, I>>(
    object: I,
  ): ProductFitResultScreen_ProductListItem {
    const message = createBaseProductFitResultScreen_ProductListItem();
    message.fit_percentage = object.fit_percentage ?? 0;
    message.description = object.description ?? "";
    message.image = object.image ?? "";
    message.category = object.category ?? "";
    return message;
  },
};

function createBaseProductFitResultScreen_ProductFitData(): ProductFitResultScreen_ProductFitData {
  return { total_products: 0, products_list: [] };
}

export const ProductFitResultScreen_ProductFitData = {
  fromJSON(object: any): ProductFitResultScreen_ProductFitData {
    return {
      total_products: isSet(object.total_products) ? Number(object.total_products) : 0,
      products_list: Array.isArray(object?.products_list)
        ? object.products_list.map((e: any) => ProductFitResultScreen_ProductListItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductFitResultScreen_ProductFitData): unknown {
    const obj: any = {};
    message.total_products !== undefined && (obj.total_products = Math.round(message.total_products));
    if (message.products_list) {
      obj.products_list = message.products_list.map((e) =>
        e ? ProductFitResultScreen_ProductListItem.toJSON(e) : undefined
      );
    } else {
      obj.products_list = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitResultScreen_ProductFitData>, I>>(
    base?: I,
  ): ProductFitResultScreen_ProductFitData {
    return ProductFitResultScreen_ProductFitData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitResultScreen_ProductFitData>, I>>(
    object: I,
  ): ProductFitResultScreen_ProductFitData {
    const message = createBaseProductFitResultScreen_ProductFitData();
    message.total_products = object.total_products ?? 0;
    message.products_list = object.products_list?.map((e) => ProductFitResultScreen_ProductListItem.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseDiscreteSliderScreen(): DiscreteSliderScreen {
  return { title: undefined, up_title: undefined, answers: [] };
}

export const DiscreteSliderScreen = {
  fromJSON(object: any): DiscreteSliderScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      up_title: isSet(object.up_title) ? AttributedString.fromJSON(object.up_title) : undefined,
      answers: Array.isArray(object?.answers) ? object.answers.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: DiscreteSliderScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.up_title !== undefined &&
      (obj.up_title = message.up_title ? AttributedString.toJSON(message.up_title) : undefined);
    if (message.answers) {
      obj.answers = message.answers.map((e) => e);
    } else {
      obj.answers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DiscreteSliderScreen>, I>>(base?: I): DiscreteSliderScreen {
    return DiscreteSliderScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiscreteSliderScreen>, I>>(object: I): DiscreteSliderScreen {
    const message = createBaseDiscreteSliderScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.up_title = (object.up_title !== undefined && object.up_title !== null)
      ? AttributedString.fromPartial(object.up_title)
      : undefined;
    message.answers = object.answers?.map((e) => e) || [];
    return message;
  },
};

function createBaseMessageWithImageScreen(): MessageWithImageScreen {
  return { title: undefined, description: undefined, image: "" };
}

export const MessageWithImageScreen = {
  fromJSON(object: any): MessageWithImageScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: MessageWithImageScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageWithImageScreen>, I>>(base?: I): MessageWithImageScreen {
    return MessageWithImageScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageWithImageScreen>, I>>(object: I): MessageWithImageScreen {
    const message = createBaseMessageWithImageScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseWelcomeLoadingScreen(): WelcomeLoadingScreen {
  return { title: undefined, description: undefined, image: "" };
}

export const WelcomeLoadingScreen = {
  fromJSON(object: any): WelcomeLoadingScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: WelcomeLoadingScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<WelcomeLoadingScreen>, I>>(base?: I): WelcomeLoadingScreen {
    return WelcomeLoadingScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WelcomeLoadingScreen>, I>>(object: I): WelcomeLoadingScreen {
    const message = createBaseWelcomeLoadingScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseWelcomeSelectScreen(): WelcomeSelectScreen {
  return { title: undefined, description: undefined, image: "", select_title: undefined, variants: [] };
}

export const WelcomeSelectScreen = {
  fromJSON(object: any): WelcomeSelectScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
      select_title: isSet(object.select_title) ? AttributedString.fromJSON(object.select_title) : undefined,
      variants: Array.isArray(object?.variants) ? object.variants.map((e: any) => VariantProperties.fromJSON(e)) : [],
    };
  },

  toJSON(message: WelcomeSelectScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.image !== undefined && (obj.image = message.image);
    message.select_title !== undefined &&
      (obj.select_title = message.select_title ? AttributedString.toJSON(message.select_title) : undefined);
    if (message.variants) {
      obj.variants = message.variants.map((e) => e ? VariantProperties.toJSON(e) : undefined);
    } else {
      obj.variants = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<WelcomeSelectScreen>, I>>(base?: I): WelcomeSelectScreen {
    return WelcomeSelectScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WelcomeSelectScreen>, I>>(object: I): WelcomeSelectScreen {
    const message = createBaseWelcomeSelectScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.image = object.image ?? "";
    message.select_title = (object.select_title !== undefined && object.select_title !== null)
      ? AttributedString.fromPartial(object.select_title)
      : undefined;
    message.variants = object.variants?.map((e) => VariantProperties.fromPartial(e)) || [];
    return message;
  },
};

function createBaseWelcomeSliderScreen(): WelcomeSliderScreen {
  return { slides: [], skippable: false };
}

export const WelcomeSliderScreen = {
  fromJSON(object: any): WelcomeSliderScreen {
    return {
      slides: Array.isArray(object?.slides) ? object.slides.map((e: any) => WelcomeSliderScreen_Slide.fromJSON(e)) : [],
      skippable: isSet(object.skippable) ? Boolean(object.skippable) : false,
    };
  },

  toJSON(message: WelcomeSliderScreen): unknown {
    const obj: any = {};
    if (message.slides) {
      obj.slides = message.slides.map((e) => e ? WelcomeSliderScreen_Slide.toJSON(e) : undefined);
    } else {
      obj.slides = [];
    }
    message.skippable !== undefined && (obj.skippable = message.skippable);
    return obj;
  },

  create<I extends Exact<DeepPartial<WelcomeSliderScreen>, I>>(base?: I): WelcomeSliderScreen {
    return WelcomeSliderScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WelcomeSliderScreen>, I>>(object: I): WelcomeSliderScreen {
    const message = createBaseWelcomeSliderScreen();
    message.slides = object.slides?.map((e) => WelcomeSliderScreen_Slide.fromPartial(e)) || [];
    message.skippable = object.skippable ?? false;
    return message;
  },
};

function createBaseWelcomeSliderScreen_Slide(): WelcomeSliderScreen_Slide {
  return { title: undefined, image: "" };
}

export const WelcomeSliderScreen_Slide = {
  fromJSON(object: any): WelcomeSliderScreen_Slide {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: WelcomeSliderScreen_Slide): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<WelcomeSliderScreen_Slide>, I>>(base?: I): WelcomeSliderScreen_Slide {
    return WelcomeSliderScreen_Slide.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WelcomeSliderScreen_Slide>, I>>(object: I): WelcomeSliderScreen_Slide {
    const message = createBaseWelcomeSliderScreen_Slide();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseGraphPageGTScreen(): GraphPageGTScreen {
  return { description: undefined, image: "" };
}

export const GraphPageGTScreen = {
  fromJSON(object: any): GraphPageGTScreen {
    return {
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: GraphPageGTScreen): unknown {
    const obj: any = {};
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<GraphPageGTScreen>, I>>(base?: I): GraphPageGTScreen {
    return GraphPageGTScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GraphPageGTScreen>, I>>(object: I): GraphPageGTScreen {
    const message = createBaseGraphPageGTScreen();
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseGraphPageGTNewDateScreen(): GraphPageGTNewDateScreen {
  return { description: undefined, image: "" };
}

export const GraphPageGTNewDateScreen = {
  fromJSON(object: any): GraphPageGTNewDateScreen {
    return {
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: GraphPageGTNewDateScreen): unknown {
    const obj: any = {};
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<GraphPageGTNewDateScreen>, I>>(base?: I): GraphPageGTNewDateScreen {
    return GraphPageGTNewDateScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GraphPageGTNewDateScreen>, I>>(object: I): GraphPageGTNewDateScreen {
    const message = createBaseGraphPageGTNewDateScreen();
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.image = object.image ?? "";
    return message;
  },
};

function createBasePrePaywallMessageScreen(): PrePaywallMessageScreen {
  return { title: undefined, image: "" };
}

export const PrePaywallMessageScreen = {
  fromJSON(object: any): PrePaywallMessageScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: PrePaywallMessageScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<PrePaywallMessageScreen>, I>>(base?: I): PrePaywallMessageScreen {
    return PrePaywallMessageScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PrePaywallMessageScreen>, I>>(object: I): PrePaywallMessageScreen {
    const message = createBasePrePaywallMessageScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseNumRangeInputScreen(): NumRangeInputScreen {
  return { title: undefined, up_title: undefined, answers: [] };
}

export const NumRangeInputScreen = {
  fromJSON(object: any): NumRangeInputScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      up_title: isSet(object.up_title) ? AttributedString.fromJSON(object.up_title) : undefined,
      answers: Array.isArray(object?.answers) ? object.answers.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: NumRangeInputScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.up_title !== undefined &&
      (obj.up_title = message.up_title ? AttributedString.toJSON(message.up_title) : undefined);
    if (message.answers) {
      obj.answers = message.answers.map((e) => e);
    } else {
      obj.answers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NumRangeInputScreen>, I>>(base?: I): NumRangeInputScreen {
    return NumRangeInputScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<NumRangeInputScreen>, I>>(object: I): NumRangeInputScreen {
    const message = createBaseNumRangeInputScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.up_title = (object.up_title !== undefined && object.up_title !== null)
      ? AttributedString.fromPartial(object.up_title)
      : undefined;
    message.answers = object.answers?.map((e) => e) || [];
    return message;
  },
};

function createBaseReviewScreen(): ReviewScreen {
  return { title: undefined, image: "", author: undefined, date: undefined, text: undefined };
}

export const ReviewScreen = {
  fromJSON(object: any): ReviewScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
      author: isSet(object.author) ? AttributedString.fromJSON(object.author) : undefined,
      date: isSet(object.date) ? AttributedString.fromJSON(object.date) : undefined,
      text: isSet(object.text) ? AttributedString.fromJSON(object.text) : undefined,
    };
  },

  toJSON(message: ReviewScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.image !== undefined && (obj.image = message.image);
    message.author !== undefined && (obj.author = message.author ? AttributedString.toJSON(message.author) : undefined);
    message.date !== undefined && (obj.date = message.date ? AttributedString.toJSON(message.date) : undefined);
    message.text !== undefined && (obj.text = message.text ? AttributedString.toJSON(message.text) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ReviewScreen>, I>>(base?: I): ReviewScreen {
    return ReviewScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ReviewScreen>, I>>(object: I): ReviewScreen {
    const message = createBaseReviewScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.image = object.image ?? "";
    message.author = (object.author !== undefined && object.author !== null)
      ? AttributedString.fromPartial(object.author)
      : undefined;
    message.date = (object.date !== undefined && object.date !== null)
      ? AttributedString.fromPartial(object.date)
      : undefined;
    message.text = (object.text !== undefined && object.text !== null)
      ? AttributedString.fromPartial(object.text)
      : undefined;
    return message;
  },
};

function createBaseProductsScreen(): ProductsScreen {
  return { title: "", sub_title: "", products: [] };
}

export const ProductsScreen = {
  fromJSON(object: any): ProductsScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      sub_title: isSet(object.sub_title) ? String(object.sub_title) : "",
      products: Array.isArray(object?.products) ? object.products.map((e: any) => Product.fromJSON(e)) : [],
    };
  },

  toJSON(message: ProductsScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.sub_title !== undefined && (obj.sub_title = message.sub_title);
    if (message.products) {
      obj.products = message.products.map((e) => e ? Product.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsScreen>, I>>(base?: I): ProductsScreen {
    return ProductsScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsScreen>, I>>(object: I): ProductsScreen {
    const message = createBaseProductsScreen();
    message.title = object.title ?? "";
    message.sub_title = object.sub_title ?? "";
    message.products = object.products?.map((e) => Product.fromPartial(e)) || [];
    return message;
  },
};

function createBaseBuyProductsScreen(): BuyProductsScreen {
  return { title: "", sub_title: "", button_text: "", buy_products: [] };
}

export const BuyProductsScreen = {
  fromJSON(object: any): BuyProductsScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      sub_title: isSet(object.sub_title) ? String(object.sub_title) : "",
      button_text: isSet(object.button_text) ? String(object.button_text) : "",
      buy_products: Array.isArray(object?.buy_products)
        ? object.buy_products.map((e: any) => BuyProduct.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BuyProductsScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.sub_title !== undefined && (obj.sub_title = message.sub_title);
    message.button_text !== undefined && (obj.button_text = message.button_text);
    if (message.buy_products) {
      obj.buy_products = message.buy_products.map((e) => e ? BuyProduct.toJSON(e) : undefined);
    } else {
      obj.buy_products = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BuyProductsScreen>, I>>(base?: I): BuyProductsScreen {
    return BuyProductsScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BuyProductsScreen>, I>>(object: I): BuyProductsScreen {
    const message = createBaseBuyProductsScreen();
    message.title = object.title ?? "";
    message.sub_title = object.sub_title ?? "";
    message.button_text = object.button_text ?? "";
    message.buy_products = object.buy_products?.map((e) => BuyProduct.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCosmeticJar(): CosmeticJar {
  return {
    ages: [],
    amazon_link: "",
    bottle_post_applying: [],
    bottle_pre_applying: [],
    brand: "",
    breastfeeding_safe: false,
    description: "",
    inci: "",
    jar_image: "",
    key_ingredients: [],
    link: "",
    pregnancy_safe: false,
    price_range: "",
    sephora_link: "",
    short_description: "",
    skin_conditions_additional: [],
    skin_conditions_main: [],
    skin_types: [],
    steps: [],
    texture: [],
    title: "",
    when_to_apply: "",
    id: "",
    quality: 0,
    rating: "",
    price: "",
    amazon_rating: "",
    sephora_rating: "",
    amazon_reviews: "",
    sephora_reviews: "",
    amazon_price: "",
    sephora_price: "",
  };
}

export const CosmeticJar = {
  fromJSON(object: any): CosmeticJar {
    return {
      ages: Array.isArray(object?.ages) ? object.ages.map((e: any) => String(e)) : [],
      amazon_link: isSet(object.amazon_link) ? String(object.amazon_link) : "",
      bottle_post_applying: Array.isArray(object?.bottle_post_applying)
        ? object.bottle_post_applying.map((e: any) => String(e))
        : [],
      bottle_pre_applying: Array.isArray(object?.bottle_pre_applying)
        ? object.bottle_pre_applying.map((e: any) => String(e))
        : [],
      brand: isSet(object.brand) ? String(object.brand) : "",
      breastfeeding_safe: isSet(object.breastfeeding_safe) ? Boolean(object.breastfeeding_safe) : false,
      description: isSet(object.description) ? String(object.description) : "",
      inci: isSet(object.inci) ? String(object.inci) : "",
      jar_image: isSet(object.jar_image) ? String(object.jar_image) : "",
      key_ingredients: Array.isArray(object?.key_ingredients) ? object.key_ingredients.map((e: any) => String(e)) : [],
      link: isSet(object.link) ? String(object.link) : "",
      pregnancy_safe: isSet(object.pregnancy_safe) ? Boolean(object.pregnancy_safe) : false,
      price_range: isSet(object.price_range) ? String(object.price_range) : "",
      sephora_link: isSet(object.sephora_link) ? String(object.sephora_link) : "",
      short_description: isSet(object.short_description) ? String(object.short_description) : "",
      skin_conditions_additional: Array.isArray(object?.skin_conditions_additional)
        ? object.skin_conditions_additional.map((e: any) => String(e))
        : [],
      skin_conditions_main: Array.isArray(object?.skin_conditions_main)
        ? object.skin_conditions_main.map((e: any) => String(e))
        : [],
      skin_types: Array.isArray(object?.skin_types) ? object.skin_types.map((e: any) => String(e)) : [],
      steps: Array.isArray(object?.steps) ? object.steps.map((e: any) => String(e)) : [],
      texture: Array.isArray(object?.texture) ? object.texture.map((e: any) => String(e)) : [],
      title: isSet(object.title) ? String(object.title) : "",
      when_to_apply: isSet(object.when_to_apply) ? String(object.when_to_apply) : "",
      id: isSet(object.id) ? String(object.id) : "",
      quality: isSet(object.quality) ? Number(object.quality) : 0,
      rating: isSet(object.rating) ? String(object.rating) : "",
      price: isSet(object.price) ? String(object.price) : "",
      amazon_rating: isSet(object.amazon_rating) ? String(object.amazon_rating) : "",
      sephora_rating: isSet(object.sephora_rating) ? String(object.sephora_rating) : "",
      amazon_reviews: isSet(object.amazon_reviews) ? String(object.amazon_reviews) : "",
      sephora_reviews: isSet(object.sephora_reviews) ? String(object.sephora_reviews) : "",
      amazon_price: isSet(object.amazon_price) ? String(object.amazon_price) : "",
      sephora_price: isSet(object.sephora_price) ? String(object.sephora_price) : "",
    };
  },

  toJSON(message: CosmeticJar): unknown {
    const obj: any = {};
    if (message.ages) {
      obj.ages = message.ages.map((e) => e);
    } else {
      obj.ages = [];
    }
    message.amazon_link !== undefined && (obj.amazon_link = message.amazon_link);
    if (message.bottle_post_applying) {
      obj.bottle_post_applying = message.bottle_post_applying.map((e) => e);
    } else {
      obj.bottle_post_applying = [];
    }
    if (message.bottle_pre_applying) {
      obj.bottle_pre_applying = message.bottle_pre_applying.map((e) => e);
    } else {
      obj.bottle_pre_applying = [];
    }
    message.brand !== undefined && (obj.brand = message.brand);
    message.breastfeeding_safe !== undefined && (obj.breastfeeding_safe = message.breastfeeding_safe);
    message.description !== undefined && (obj.description = message.description);
    message.inci !== undefined && (obj.inci = message.inci);
    message.jar_image !== undefined && (obj.jar_image = message.jar_image);
    if (message.key_ingredients) {
      obj.key_ingredients = message.key_ingredients.map((e) => e);
    } else {
      obj.key_ingredients = [];
    }
    message.link !== undefined && (obj.link = message.link);
    message.pregnancy_safe !== undefined && (obj.pregnancy_safe = message.pregnancy_safe);
    message.price_range !== undefined && (obj.price_range = message.price_range);
    message.sephora_link !== undefined && (obj.sephora_link = message.sephora_link);
    message.short_description !== undefined && (obj.short_description = message.short_description);
    if (message.skin_conditions_additional) {
      obj.skin_conditions_additional = message.skin_conditions_additional.map((e) => e);
    } else {
      obj.skin_conditions_additional = [];
    }
    if (message.skin_conditions_main) {
      obj.skin_conditions_main = message.skin_conditions_main.map((e) => e);
    } else {
      obj.skin_conditions_main = [];
    }
    if (message.skin_types) {
      obj.skin_types = message.skin_types.map((e) => e);
    } else {
      obj.skin_types = [];
    }
    if (message.steps) {
      obj.steps = message.steps.map((e) => e);
    } else {
      obj.steps = [];
    }
    if (message.texture) {
      obj.texture = message.texture.map((e) => e);
    } else {
      obj.texture = [];
    }
    message.title !== undefined && (obj.title = message.title);
    message.when_to_apply !== undefined && (obj.when_to_apply = message.when_to_apply);
    message.id !== undefined && (obj.id = message.id);
    message.quality !== undefined && (obj.quality = message.quality);
    message.rating !== undefined && (obj.rating = message.rating);
    message.price !== undefined && (obj.price = message.price);
    message.amazon_rating !== undefined && (obj.amazon_rating = message.amazon_rating);
    message.sephora_rating !== undefined && (obj.sephora_rating = message.sephora_rating);
    message.amazon_reviews !== undefined && (obj.amazon_reviews = message.amazon_reviews);
    message.sephora_reviews !== undefined && (obj.sephora_reviews = message.sephora_reviews);
    message.amazon_price !== undefined && (obj.amazon_price = message.amazon_price);
    message.sephora_price !== undefined && (obj.sephora_price = message.sephora_price);
    return obj;
  },

  create<I extends Exact<DeepPartial<CosmeticJar>, I>>(base?: I): CosmeticJar {
    return CosmeticJar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CosmeticJar>, I>>(object: I): CosmeticJar {
    const message = createBaseCosmeticJar();
    message.ages = object.ages?.map((e) => e) || [];
    message.amazon_link = object.amazon_link ?? "";
    message.bottle_post_applying = object.bottle_post_applying?.map((e) => e) || [];
    message.bottle_pre_applying = object.bottle_pre_applying?.map((e) => e) || [];
    message.brand = object.brand ?? "";
    message.breastfeeding_safe = object.breastfeeding_safe ?? false;
    message.description = object.description ?? "";
    message.inci = object.inci ?? "";
    message.jar_image = object.jar_image ?? "";
    message.key_ingredients = object.key_ingredients?.map((e) => e) || [];
    message.link = object.link ?? "";
    message.pregnancy_safe = object.pregnancy_safe ?? false;
    message.price_range = object.price_range ?? "";
    message.sephora_link = object.sephora_link ?? "";
    message.short_description = object.short_description ?? "";
    message.skin_conditions_additional = object.skin_conditions_additional?.map((e) => e) || [];
    message.skin_conditions_main = object.skin_conditions_main?.map((e) => e) || [];
    message.skin_types = object.skin_types?.map((e) => e) || [];
    message.steps = object.steps?.map((e) => e) || [];
    message.texture = object.texture?.map((e) => e) || [];
    message.title = object.title ?? "";
    message.when_to_apply = object.when_to_apply ?? "";
    message.id = object.id ?? "";
    message.quality = object.quality ?? 0;
    message.rating = object.rating ?? "";
    message.price = object.price ?? "";
    message.amazon_rating = object.amazon_rating ?? "";
    message.sephora_rating = object.sephora_rating ?? "";
    message.amazon_reviews = object.amazon_reviews ?? "";
    message.sephora_reviews = object.sephora_reviews ?? "";
    message.amazon_price = object.amazon_price ?? "";
    message.sephora_price = object.sephora_price ?? "";
    return message;
  },
};

function createBaseSkinCareRitualStep(): SkinCareRitualStep {
  return { step: "", jar: undefined, quality: 0, jars: [] };
}

export const SkinCareRitualStep = {
  fromJSON(object: any): SkinCareRitualStep {
    return {
      step: isSet(object.step) ? String(object.step) : "",
      jar: isSet(object.jar) ? CosmeticJar.fromJSON(object.jar) : undefined,
      quality: isSet(object.quality) ? Number(object.quality) : 0,
      jars: Array.isArray(object?.jars) ? object.jars.map((e: any) => CosmeticJar.fromJSON(e)) : [],
    };
  },

  toJSON(message: SkinCareRitualStep): unknown {
    const obj: any = {};
    message.step !== undefined && (obj.step = message.step);
    message.jar !== undefined && (obj.jar = message.jar ? CosmeticJar.toJSON(message.jar) : undefined);
    message.quality !== undefined && (obj.quality = message.quality);
    if (message.jars) {
      obj.jars = message.jars.map((e) => e ? CosmeticJar.toJSON(e) : undefined);
    } else {
      obj.jars = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinCareRitualStep>, I>>(base?: I): SkinCareRitualStep {
    return SkinCareRitualStep.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinCareRitualStep>, I>>(object: I): SkinCareRitualStep {
    const message = createBaseSkinCareRitualStep();
    message.step = object.step ?? "";
    message.jar = (object.jar !== undefined && object.jar !== null) ? CosmeticJar.fromPartial(object.jar) : undefined;
    message.quality = object.quality ?? 0;
    message.jars = object.jars?.map((e) => CosmeticJar.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSkinCareRitual(): SkinCareRitual {
  return { when: "", steps: [] };
}

export const SkinCareRitual = {
  fromJSON(object: any): SkinCareRitual {
    return {
      when: isSet(object.when) ? String(object.when) : "",
      steps: Array.isArray(object?.steps) ? object.steps.map((e: any) => SkinCareRitualStep.fromJSON(e)) : [],
    };
  },

  toJSON(message: SkinCareRitual): unknown {
    const obj: any = {};
    message.when !== undefined && (obj.when = message.when);
    if (message.steps) {
      obj.steps = message.steps.map((e) => e ? SkinCareRitualStep.toJSON(e) : undefined);
    } else {
      obj.steps = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinCareRitual>, I>>(base?: I): SkinCareRitual {
    return SkinCareRitual.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinCareRitual>, I>>(object: I): SkinCareRitual {
    const message = createBaseSkinCareRitual();
    message.when = object.when ?? "";
    message.steps = object.steps?.map((e) => SkinCareRitualStep.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductSearchStepStatistics(): ProductSearchStepStatistics {
  return { property: "", count: 0 };
}

export const ProductSearchStepStatistics = {
  fromJSON(object: any): ProductSearchStepStatistics {
    return {
      property: isSet(object.property) ? String(object.property) : "",
      count: isSet(object.count) ? Number(object.count) : 0,
    };
  },

  toJSON(message: ProductSearchStepStatistics): unknown {
    const obj: any = {};
    message.property !== undefined && (obj.property = message.property);
    message.count !== undefined && (obj.count = Math.round(message.count));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductSearchStepStatistics>, I>>(base?: I): ProductSearchStepStatistics {
    return ProductSearchStepStatistics.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductSearchStepStatistics>, I>>(object: I): ProductSearchStepStatistics {
    const message = createBaseProductSearchStepStatistics();
    message.property = object.property ?? "";
    message.count = object.count ?? 0;
    return message;
  },
};

function createBaseCheckoutProduct(): CheckoutProduct {
  return {
    title: "",
    brand: "",
    image: "",
    step_number: 0,
    step_name: "",
    checkout_link_parameters: "",
    price: 0,
    raw_skincare_step: "",
  };
}

export const CheckoutProduct = {
  fromJSON(object: any): CheckoutProduct {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      brand: isSet(object.brand) ? String(object.brand) : "",
      image: isSet(object.image) ? String(object.image) : "",
      step_number: isSet(object.step_number) ? Number(object.step_number) : 0,
      step_name: isSet(object.step_name) ? String(object.step_name) : "",
      checkout_link_parameters: isSet(object.checkout_link_parameters) ? String(object.checkout_link_parameters) : "",
      price: isSet(object.price) ? Number(object.price) : 0,
      raw_skincare_step: isSet(object.raw_skincare_step) ? String(object.raw_skincare_step) : "",
    };
  },

  toJSON(message: CheckoutProduct): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.brand !== undefined && (obj.brand = message.brand);
    message.image !== undefined && (obj.image = message.image);
    message.step_number !== undefined && (obj.step_number = Math.round(message.step_number));
    message.step_name !== undefined && (obj.step_name = message.step_name);
    message.checkout_link_parameters !== undefined && (obj.checkout_link_parameters = message.checkout_link_parameters);
    message.price !== undefined && (obj.price = message.price);
    message.raw_skincare_step !== undefined && (obj.raw_skincare_step = message.raw_skincare_step);
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckoutProduct>, I>>(base?: I): CheckoutProduct {
    return CheckoutProduct.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CheckoutProduct>, I>>(object: I): CheckoutProduct {
    const message = createBaseCheckoutProduct();
    message.title = object.title ?? "";
    message.brand = object.brand ?? "";
    message.image = object.image ?? "";
    message.step_number = object.step_number ?? 0;
    message.step_name = object.step_name ?? "";
    message.checkout_link_parameters = object.checkout_link_parameters ?? "";
    message.price = object.price ?? 0;
    message.raw_skincare_step = object.raw_skincare_step ?? "";
    return message;
  },
};

function createBaseCheckoutData(): CheckoutData {
  return {
    products: [],
    price: 0,
    price_currency: "",
    price_currency_sign: "",
    subscription_term: "",
    checkout_link_prefix: "",
    checkout_link_suffix: "",
  };
}

export const CheckoutData = {
  fromJSON(object: any): CheckoutData {
    return {
      products: Array.isArray(object?.products) ? object.products.map((e: any) => CheckoutProduct.fromJSON(e)) : [],
      price: isSet(object.price) ? Number(object.price) : 0,
      price_currency: isSet(object.price_currency) ? String(object.price_currency) : "",
      price_currency_sign: isSet(object.price_currency_sign) ? String(object.price_currency_sign) : "",
      subscription_term: isSet(object.subscription_term) ? String(object.subscription_term) : "",
      checkout_link_prefix: isSet(object.checkout_link_prefix) ? String(object.checkout_link_prefix) : "",
      checkout_link_suffix: isSet(object.checkout_link_suffix) ? String(object.checkout_link_suffix) : "",
    };
  },

  toJSON(message: CheckoutData): unknown {
    const obj: any = {};
    if (message.products) {
      obj.products = message.products.map((e) => e ? CheckoutProduct.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    message.price !== undefined && (obj.price = message.price);
    message.price_currency !== undefined && (obj.price_currency = message.price_currency);
    message.price_currency_sign !== undefined && (obj.price_currency_sign = message.price_currency_sign);
    message.subscription_term !== undefined && (obj.subscription_term = message.subscription_term);
    message.checkout_link_prefix !== undefined && (obj.checkout_link_prefix = message.checkout_link_prefix);
    message.checkout_link_suffix !== undefined && (obj.checkout_link_suffix = message.checkout_link_suffix);
    return obj;
  },

  create<I extends Exact<DeepPartial<CheckoutData>, I>>(base?: I): CheckoutData {
    return CheckoutData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CheckoutData>, I>>(object: I): CheckoutData {
    const message = createBaseCheckoutData();
    message.products = object.products?.map((e) => CheckoutProduct.fromPartial(e)) || [];
    message.price = object.price ?? 0;
    message.price_currency = object.price_currency ?? "";
    message.price_currency_sign = object.price_currency_sign ?? "";
    message.subscription_term = object.subscription_term ?? "";
    message.checkout_link_prefix = object.checkout_link_prefix ?? "";
    message.checkout_link_suffix = object.checkout_link_suffix ?? "";
    return message;
  },
};

function createBaseSkinCareProgramParams(): SkinCareProgramParams {
  return {
    primary_skin_issue: "",
    additional_skin_issues: [],
    sensitivity: "",
    skin_type: "",
    target_price: 0,
    target_steps_count: 0,
    pregnancy_safe: false,
    mask_preference: "",
    moisturizing_treatment: "",
    avoid_drying_ingredients: "",
    keep_eye_treatments: "",
    delivery_region: "",
    target_price_low: 0,
    target_price_high: 0,
  };
}

export const SkinCareProgramParams = {
  fromJSON(object: any): SkinCareProgramParams {
    return {
      primary_skin_issue: isSet(object.primary_skin_issue) ? String(object.primary_skin_issue) : "",
      additional_skin_issues: Array.isArray(object?.additional_skin_issues)
        ? object.additional_skin_issues.map((e: any) => String(e))
        : [],
      sensitivity: isSet(object.sensitivity) ? String(object.sensitivity) : "",
      skin_type: isSet(object.skin_type) ? String(object.skin_type) : "",
      target_price: isSet(object.target_price) ? Number(object.target_price) : 0,
      target_steps_count: isSet(object.target_steps_count) ? Number(object.target_steps_count) : 0,
      pregnancy_safe: isSet(object.pregnancy_safe) ? Boolean(object.pregnancy_safe) : false,
      mask_preference: isSet(object.mask_preference) ? String(object.mask_preference) : "",
      moisturizing_treatment: isSet(object.moisturizing_treatment) ? String(object.moisturizing_treatment) : "",
      avoid_drying_ingredients: isSet(object.avoid_drying_ingredients) ? String(object.avoid_drying_ingredients) : "",
      keep_eye_treatments: isSet(object.keep_eye_treatments) ? String(object.keep_eye_treatments) : "",
      delivery_region: isSet(object.delivery_region) ? String(object.delivery_region) : "",
      target_price_low: isSet(object.target_price_low) ? Number(object.target_price_low) : 0,
      target_price_high: isSet(object.target_price_high) ? Number(object.target_price_high) : 0,
    };
  },

  toJSON(message: SkinCareProgramParams): unknown {
    const obj: any = {};
    message.primary_skin_issue !== undefined && (obj.primary_skin_issue = message.primary_skin_issue);
    if (message.additional_skin_issues) {
      obj.additional_skin_issues = message.additional_skin_issues.map((e) => e);
    } else {
      obj.additional_skin_issues = [];
    }
    message.sensitivity !== undefined && (obj.sensitivity = message.sensitivity);
    message.skin_type !== undefined && (obj.skin_type = message.skin_type);
    message.target_price !== undefined && (obj.target_price = message.target_price);
    message.target_steps_count !== undefined && (obj.target_steps_count = Math.round(message.target_steps_count));
    message.pregnancy_safe !== undefined && (obj.pregnancy_safe = message.pregnancy_safe);
    message.mask_preference !== undefined && (obj.mask_preference = message.mask_preference);
    message.moisturizing_treatment !== undefined && (obj.moisturizing_treatment = message.moisturizing_treatment);
    message.avoid_drying_ingredients !== undefined && (obj.avoid_drying_ingredients = message.avoid_drying_ingredients);
    message.keep_eye_treatments !== undefined && (obj.keep_eye_treatments = message.keep_eye_treatments);
    message.delivery_region !== undefined && (obj.delivery_region = message.delivery_region);
    message.target_price_low !== undefined && (obj.target_price_low = message.target_price_low);
    message.target_price_high !== undefined && (obj.target_price_high = message.target_price_high);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinCareProgramParams>, I>>(base?: I): SkinCareProgramParams {
    return SkinCareProgramParams.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinCareProgramParams>, I>>(object: I): SkinCareProgramParams {
    const message = createBaseSkinCareProgramParams();
    message.primary_skin_issue = object.primary_skin_issue ?? "";
    message.additional_skin_issues = object.additional_skin_issues?.map((e) => e) || [];
    message.sensitivity = object.sensitivity ?? "";
    message.skin_type = object.skin_type ?? "";
    message.target_price = object.target_price ?? 0;
    message.target_steps_count = object.target_steps_count ?? 0;
    message.pregnancy_safe = object.pregnancy_safe ?? false;
    message.mask_preference = object.mask_preference ?? "";
    message.moisturizing_treatment = object.moisturizing_treatment ?? "";
    message.avoid_drying_ingredients = object.avoid_drying_ingredients ?? "";
    message.keep_eye_treatments = object.keep_eye_treatments ?? "";
    message.delivery_region = object.delivery_region ?? "";
    message.target_price_low = object.target_price_low ?? 0;
    message.target_price_high = object.target_price_high ?? 0;
    return message;
  },
};

function createBaseSkinCareProgram(): SkinCareProgram {
  return {
    program_params: undefined,
    program_id: "",
    generation_datetime: "",
    program_type: "",
    main_condition: "",
    additional_conditions: [],
    skin_type: "",
    pregnancy_safety: false,
    breastfeeding_safety: false,
    price_range: "",
    rituals: [],
    jar_links: [],
    scanning_results_used: false,
    ts: 0,
    product_search_statistics: [],
    checkout_link: "",
    step_costs: {},
    step_product_ids: {},
    checkout_data: undefined,
    version: 0,
    recommender_url: "",
  };
}

export const SkinCareProgram = {
  fromJSON(object: any): SkinCareProgram {
    return {
      program_params: isSet(object.program_params) ? SkinCareProgramParams.fromJSON(object.program_params) : undefined,
      program_id: isSet(object.program_id) ? String(object.program_id) : "",
      generation_datetime: isSet(object.generation_datetime) ? String(object.generation_datetime) : "",
      program_type: isSet(object.program_type) ? String(object.program_type) : "",
      main_condition: isSet(object.main_condition) ? String(object.main_condition) : "",
      additional_conditions: Array.isArray(object?.additional_conditions)
        ? object.additional_conditions.map((e: any) => String(e))
        : [],
      skin_type: isSet(object.skin_type) ? String(object.skin_type) : "",
      pregnancy_safety: isSet(object.pregnancy_safety) ? Boolean(object.pregnancy_safety) : false,
      breastfeeding_safety: isSet(object.breastfeeding_safety) ? Boolean(object.breastfeeding_safety) : false,
      price_range: isSet(object.price_range) ? String(object.price_range) : "",
      rituals: Array.isArray(object?.rituals) ? object.rituals.map((e: any) => SkinCareRitual.fromJSON(e)) : [],
      jar_links: Array.isArray(object?.jar_links) ? object.jar_links.map((e: any) => String(e)) : [],
      scanning_results_used: isSet(object.scanning_results_used) ? Boolean(object.scanning_results_used) : false,
      ts: isSet(object.ts) ? Number(object.ts) : 0,
      product_search_statistics: Array.isArray(object?.product_search_statistics)
        ? object.product_search_statistics.map((e: any) => ProductSearchStepStatistics.fromJSON(e))
        : [],
      checkout_link: isSet(object.checkout_link) ? String(object.checkout_link) : "",
      step_costs: isObject(object.step_costs)
        ? Object.entries(object.step_costs).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      step_product_ids: isObject(object.step_product_ids)
        ? Object.entries(object.step_product_ids).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      checkout_data: isSet(object.checkout_data) ? CheckoutData.fromJSON(object.checkout_data) : undefined,
      version: isSet(object.version) ? Number(object.version) : 0,
      recommender_url: isSet(object.recommender_url) ? String(object.recommender_url) : "",
    };
  },

  toJSON(message: SkinCareProgram): unknown {
    const obj: any = {};
    message.program_params !== undefined &&
      (obj.program_params = message.program_params ? SkinCareProgramParams.toJSON(message.program_params) : undefined);
    message.program_id !== undefined && (obj.program_id = message.program_id);
    message.generation_datetime !== undefined && (obj.generation_datetime = message.generation_datetime);
    message.program_type !== undefined && (obj.program_type = message.program_type);
    message.main_condition !== undefined && (obj.main_condition = message.main_condition);
    if (message.additional_conditions) {
      obj.additional_conditions = message.additional_conditions.map((e) => e);
    } else {
      obj.additional_conditions = [];
    }
    message.skin_type !== undefined && (obj.skin_type = message.skin_type);
    message.pregnancy_safety !== undefined && (obj.pregnancy_safety = message.pregnancy_safety);
    message.breastfeeding_safety !== undefined && (obj.breastfeeding_safety = message.breastfeeding_safety);
    message.price_range !== undefined && (obj.price_range = message.price_range);
    if (message.rituals) {
      obj.rituals = message.rituals.map((e) => e ? SkinCareRitual.toJSON(e) : undefined);
    } else {
      obj.rituals = [];
    }
    if (message.jar_links) {
      obj.jar_links = message.jar_links.map((e) => e);
    } else {
      obj.jar_links = [];
    }
    message.scanning_results_used !== undefined && (obj.scanning_results_used = message.scanning_results_used);
    message.ts !== undefined && (obj.ts = Math.round(message.ts));
    if (message.product_search_statistics) {
      obj.product_search_statistics = message.product_search_statistics.map((e) =>
        e ? ProductSearchStepStatistics.toJSON(e) : undefined
      );
    } else {
      obj.product_search_statistics = [];
    }
    message.checkout_link !== undefined && (obj.checkout_link = message.checkout_link);
    obj.step_costs = {};
    if (message.step_costs) {
      Object.entries(message.step_costs).forEach(([k, v]) => {
        obj.step_costs[k] = v;
      });
    }
    obj.step_product_ids = {};
    if (message.step_product_ids) {
      Object.entries(message.step_product_ids).forEach(([k, v]) => {
        obj.step_product_ids[k] = Math.round(v);
      });
    }
    message.checkout_data !== undefined &&
      (obj.checkout_data = message.checkout_data ? CheckoutData.toJSON(message.checkout_data) : undefined);
    message.version !== undefined && (obj.version = Math.round(message.version));
    message.recommender_url !== undefined && (obj.recommender_url = message.recommender_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinCareProgram>, I>>(base?: I): SkinCareProgram {
    return SkinCareProgram.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinCareProgram>, I>>(object: I): SkinCareProgram {
    const message = createBaseSkinCareProgram();
    message.program_params = (object.program_params !== undefined && object.program_params !== null)
      ? SkinCareProgramParams.fromPartial(object.program_params)
      : undefined;
    message.program_id = object.program_id ?? "";
    message.generation_datetime = object.generation_datetime ?? "";
    message.program_type = object.program_type ?? "";
    message.main_condition = object.main_condition ?? "";
    message.additional_conditions = object.additional_conditions?.map((e) => e) || [];
    message.skin_type = object.skin_type ?? "";
    message.pregnancy_safety = object.pregnancy_safety ?? false;
    message.breastfeeding_safety = object.breastfeeding_safety ?? false;
    message.price_range = object.price_range ?? "";
    message.rituals = object.rituals?.map((e) => SkinCareRitual.fromPartial(e)) || [];
    message.jar_links = object.jar_links?.map((e) => e) || [];
    message.scanning_results_used = object.scanning_results_used ?? false;
    message.ts = object.ts ?? 0;
    message.product_search_statistics =
      object.product_search_statistics?.map((e) => ProductSearchStepStatistics.fromPartial(e)) || [];
    message.checkout_link = object.checkout_link ?? "";
    message.step_costs = Object.entries(object.step_costs ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Number(value);
        }
        return acc;
      },
      {},
    );
    message.step_product_ids = Object.entries(object.step_product_ids ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Number(value);
        }
        return acc;
      },
      {},
    );
    message.checkout_data = (object.checkout_data !== undefined && object.checkout_data !== null)
      ? CheckoutData.fromPartial(object.checkout_data)
      : undefined;
    message.version = object.version ?? 0;
    message.recommender_url = object.recommender_url ?? "";
    return message;
  },
};

function createBaseSkinCareProgram_StepCostsEntry(): SkinCareProgram_StepCostsEntry {
  return { key: "", value: 0 };
}

export const SkinCareProgram_StepCostsEntry = {
  fromJSON(object: any): SkinCareProgram_StepCostsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: SkinCareProgram_StepCostsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinCareProgram_StepCostsEntry>, I>>(base?: I): SkinCareProgram_StepCostsEntry {
    return SkinCareProgram_StepCostsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinCareProgram_StepCostsEntry>, I>>(
    object: I,
  ): SkinCareProgram_StepCostsEntry {
    const message = createBaseSkinCareProgram_StepCostsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseSkinCareProgram_StepProductIdsEntry(): SkinCareProgram_StepProductIdsEntry {
  return { key: "", value: 0 };
}

export const SkinCareProgram_StepProductIdsEntry = {
  fromJSON(object: any): SkinCareProgram_StepProductIdsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? Number(object.value) : 0 };
  },

  toJSON(message: SkinCareProgram_StepProductIdsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinCareProgram_StepProductIdsEntry>, I>>(
    base?: I,
  ): SkinCareProgram_StepProductIdsEntry {
    return SkinCareProgram_StepProductIdsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinCareProgram_StepProductIdsEntry>, I>>(
    object: I,
  ): SkinCareProgram_StepProductIdsEntry {
    const message = createBaseSkinCareProgram_StepProductIdsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseConditionsResponse(): ConditionsResponse {
  return { main_condition: "", additional_conditions: [], program: undefined, program_quality: 0 };
}

export const ConditionsResponse = {
  fromJSON(object: any): ConditionsResponse {
    return {
      main_condition: isSet(object.main_condition) ? String(object.main_condition) : "",
      additional_conditions: Array.isArray(object?.additional_conditions)
        ? object.additional_conditions.map((e: any) => String(e))
        : [],
      program: isSet(object.program) ? SkinCareProgram.fromJSON(object.program) : undefined,
      program_quality: isSet(object.program_quality) ? Number(object.program_quality) : 0,
    };
  },

  toJSON(message: ConditionsResponse): unknown {
    const obj: any = {};
    message.main_condition !== undefined && (obj.main_condition = message.main_condition);
    if (message.additional_conditions) {
      obj.additional_conditions = message.additional_conditions.map((e) => e);
    } else {
      obj.additional_conditions = [];
    }
    message.program !== undefined &&
      (obj.program = message.program ? SkinCareProgram.toJSON(message.program) : undefined);
    message.program_quality !== undefined && (obj.program_quality = message.program_quality);
    return obj;
  },

  create<I extends Exact<DeepPartial<ConditionsResponse>, I>>(base?: I): ConditionsResponse {
    return ConditionsResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ConditionsResponse>, I>>(object: I): ConditionsResponse {
    const message = createBaseConditionsResponse();
    message.main_condition = object.main_condition ?? "";
    message.additional_conditions = object.additional_conditions?.map((e) => e) || [];
    message.program = (object.program !== undefined && object.program !== null)
      ? SkinCareProgram.fromPartial(object.program)
      : undefined;
    message.program_quality = object.program_quality ?? 0;
    return message;
  },
};

function createBaseSplitProductsResponse(): SplitProductsResponse {
  return { fit_products: [], non_fit_products: [] };
}

export const SplitProductsResponse = {
  fromJSON(object: any): SplitProductsResponse {
    return {
      fit_products: Array.isArray(object?.fit_products)
        ? object.fit_products.map((e: any) => CosmeticJar.fromJSON(e))
        : [],
      non_fit_products: Array.isArray(object?.non_fit_products)
        ? object.non_fit_products.map((e: any) => CosmeticJar.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SplitProductsResponse): unknown {
    const obj: any = {};
    if (message.fit_products) {
      obj.fit_products = message.fit_products.map((e) => e ? CosmeticJar.toJSON(e) : undefined);
    } else {
      obj.fit_products = [];
    }
    if (message.non_fit_products) {
      obj.non_fit_products = message.non_fit_products.map((e) => e ? CosmeticJar.toJSON(e) : undefined);
    } else {
      obj.non_fit_products = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SplitProductsResponse>, I>>(base?: I): SplitProductsResponse {
    return SplitProductsResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SplitProductsResponse>, I>>(object: I): SplitProductsResponse {
    const message = createBaseSplitProductsResponse();
    message.fit_products = object.fit_products?.map((e) => CosmeticJar.fromPartial(e)) || [];
    message.non_fit_products = object.non_fit_products?.map((e) => CosmeticJar.fromPartial(e)) || [];
    return message;
  },
};

function createBaseButton(): Button {
  return { title: "", action: undefined };
}

export const Button = {
  fromJSON(object: any): Button {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      action: isSet(object.action) ? Button_Action.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: Button): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.action !== undefined && (obj.action = message.action ? Button_Action.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Button>, I>>(base?: I): Button {
    return Button.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Button>, I>>(object: I): Button {
    const message = createBaseButton();
    message.title = object.title ?? "";
    message.action = (object.action !== undefined && object.action !== null)
      ? Button_Action.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBaseButton_Action(): Button_Action {
  return { action: undefined };
}

export const Button_Action = {
  fromJSON(object: any): Button_Action {
    return {
      action: isSet(object.nothing)
        ? { $case: "nothing", nothing: QuizActionNothing.fromJSON(object.nothing) }
        : isSet(object.new_test_request)
        ? { $case: "new_test_request", new_test_request: QuizActionRequestNewTest.fromJSON(object.new_test_request) }
        : isSet(object.sign_in)
        ? { $case: "sign_in", sign_in: QuizActionSignIn.fromJSON(object.sign_in) }
        : undefined,
    };
  },

  toJSON(message: Button_Action): unknown {
    const obj: any = {};
    message.action?.$case === "nothing" &&
      (obj.nothing = message.action?.nothing ? QuizActionNothing.toJSON(message.action?.nothing) : undefined);
    message.action?.$case === "new_test_request" && (obj.new_test_request = message.action?.new_test_request
      ? QuizActionRequestNewTest.toJSON(message.action?.new_test_request)
      : undefined);
    message.action?.$case === "sign_in" &&
      (obj.sign_in = message.action?.sign_in ? QuizActionSignIn.toJSON(message.action?.sign_in) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<Button_Action>, I>>(base?: I): Button_Action {
    return Button_Action.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Button_Action>, I>>(object: I): Button_Action {
    const message = createBaseButton_Action();
    if (object.action?.$case === "nothing" && object.action?.nothing !== undefined && object.action?.nothing !== null) {
      message.action = { $case: "nothing", nothing: QuizActionNothing.fromPartial(object.action.nothing) };
    }
    if (
      object.action?.$case === "new_test_request" &&
      object.action?.new_test_request !== undefined &&
      object.action?.new_test_request !== null
    ) {
      message.action = {
        $case: "new_test_request",
        new_test_request: QuizActionRequestNewTest.fromPartial(object.action.new_test_request),
      };
    }
    if (object.action?.$case === "sign_in" && object.action?.sign_in !== undefined && object.action?.sign_in !== null) {
      message.action = { $case: "sign_in", sign_in: QuizActionSignIn.fromPartial(object.action.sign_in) };
    }
    return message;
  },
};

function createBaseWelcomeIosScreen(): WelcomeIosScreen {
  return {
    sub_screens: [],
    terms_url: "",
    privacy_url: "",
    primary_button: undefined,
    secondary_button: undefined,
    disabled_logo: false,
    disabled_shading_gradient: false,
    screens_auto_scroll_delay: 0,
  };
}

export const WelcomeIosScreen = {
  fromJSON(object: any): WelcomeIosScreen {
    return {
      sub_screens: Array.isArray(object?.sub_screens)
        ? object.sub_screens.map((e: any) => WelcomeIosScreen_SubScreen.fromJSON(e))
        : [],
      terms_url: isSet(object.terms_url) ? String(object.terms_url) : "",
      privacy_url: isSet(object.privacy_url) ? String(object.privacy_url) : "",
      primary_button: isSet(object.primary_button) ? Button.fromJSON(object.primary_button) : undefined,
      secondary_button: isSet(object.secondary_button) ? Button.fromJSON(object.secondary_button) : undefined,
      disabled_logo: isSet(object.disabled_logo) ? Boolean(object.disabled_logo) : false,
      disabled_shading_gradient: isSet(object.disabled_shading_gradient)
        ? Boolean(object.disabled_shading_gradient)
        : false,
      screens_auto_scroll_delay: isSet(object.screens_auto_scroll_delay) ? Number(object.screens_auto_scroll_delay) : 0,
    };
  },

  toJSON(message: WelcomeIosScreen): unknown {
    const obj: any = {};
    if (message.sub_screens) {
      obj.sub_screens = message.sub_screens.map((e) => e ? WelcomeIosScreen_SubScreen.toJSON(e) : undefined);
    } else {
      obj.sub_screens = [];
    }
    message.terms_url !== undefined && (obj.terms_url = message.terms_url);
    message.privacy_url !== undefined && (obj.privacy_url = message.privacy_url);
    message.primary_button !== undefined &&
      (obj.primary_button = message.primary_button ? Button.toJSON(message.primary_button) : undefined);
    message.secondary_button !== undefined &&
      (obj.secondary_button = message.secondary_button ? Button.toJSON(message.secondary_button) : undefined);
    message.disabled_logo !== undefined && (obj.disabled_logo = message.disabled_logo);
    message.disabled_shading_gradient !== undefined &&
      (obj.disabled_shading_gradient = message.disabled_shading_gradient);
    message.screens_auto_scroll_delay !== undefined &&
      (obj.screens_auto_scroll_delay = message.screens_auto_scroll_delay);
    return obj;
  },

  create<I extends Exact<DeepPartial<WelcomeIosScreen>, I>>(base?: I): WelcomeIosScreen {
    return WelcomeIosScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WelcomeIosScreen>, I>>(object: I): WelcomeIosScreen {
    const message = createBaseWelcomeIosScreen();
    message.sub_screens = object.sub_screens?.map((e) => WelcomeIosScreen_SubScreen.fromPartial(e)) || [];
    message.terms_url = object.terms_url ?? "";
    message.privacy_url = object.privacy_url ?? "";
    message.primary_button = (object.primary_button !== undefined && object.primary_button !== null)
      ? Button.fromPartial(object.primary_button)
      : undefined;
    message.secondary_button = (object.secondary_button !== undefined && object.secondary_button !== null)
      ? Button.fromPartial(object.secondary_button)
      : undefined;
    message.disabled_logo = object.disabled_logo ?? false;
    message.disabled_shading_gradient = object.disabled_shading_gradient ?? false;
    message.screens_auto_scroll_delay = object.screens_auto_scroll_delay ?? 0;
    return message;
  },
};

function createBaseWelcomeIosScreen_SubScreen(): WelcomeIosScreen_SubScreen {
  return {
    title: "",
    sub_title: "",
    button_text: "",
    media: undefined,
    primary_button: undefined,
    secondary_button: undefined,
    analytics_event_parameters: undefined,
  };
}

export const WelcomeIosScreen_SubScreen = {
  fromJSON(object: any): WelcomeIosScreen_SubScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      sub_title: isSet(object.sub_title) ? String(object.sub_title) : "",
      button_text: isSet(object.button_text) ? String(object.button_text) : "",
      media: isSet(object.media) ? WelcomeIosScreen_SubScreen_Media.fromJSON(object.media) : undefined,
      primary_button: isSet(object.primary_button) ? Button.fromJSON(object.primary_button) : undefined,
      secondary_button: isSet(object.secondary_button) ? Button.fromJSON(object.secondary_button) : undefined,
      analytics_event_parameters: isSet(object.analytics_event_parameters)
        ? AnalyticsEventParameters.fromJSON(object.analytics_event_parameters)
        : undefined,
    };
  },

  toJSON(message: WelcomeIosScreen_SubScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.sub_title !== undefined && (obj.sub_title = message.sub_title);
    message.button_text !== undefined && (obj.button_text = message.button_text);
    message.media !== undefined &&
      (obj.media = message.media ? WelcomeIosScreen_SubScreen_Media.toJSON(message.media) : undefined);
    message.primary_button !== undefined &&
      (obj.primary_button = message.primary_button ? Button.toJSON(message.primary_button) : undefined);
    message.secondary_button !== undefined &&
      (obj.secondary_button = message.secondary_button ? Button.toJSON(message.secondary_button) : undefined);
    message.analytics_event_parameters !== undefined &&
      (obj.analytics_event_parameters = message.analytics_event_parameters
        ? AnalyticsEventParameters.toJSON(message.analytics_event_parameters)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<WelcomeIosScreen_SubScreen>, I>>(base?: I): WelcomeIosScreen_SubScreen {
    return WelcomeIosScreen_SubScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WelcomeIosScreen_SubScreen>, I>>(object: I): WelcomeIosScreen_SubScreen {
    const message = createBaseWelcomeIosScreen_SubScreen();
    message.title = object.title ?? "";
    message.sub_title = object.sub_title ?? "";
    message.button_text = object.button_text ?? "";
    message.media = (object.media !== undefined && object.media !== null)
      ? WelcomeIosScreen_SubScreen_Media.fromPartial(object.media)
      : undefined;
    message.primary_button = (object.primary_button !== undefined && object.primary_button !== null)
      ? Button.fromPartial(object.primary_button)
      : undefined;
    message.secondary_button = (object.secondary_button !== undefined && object.secondary_button !== null)
      ? Button.fromPartial(object.secondary_button)
      : undefined;
    message.analytics_event_parameters =
      (object.analytics_event_parameters !== undefined && object.analytics_event_parameters !== null)
        ? AnalyticsEventParameters.fromPartial(object.analytics_event_parameters)
        : undefined;
    return message;
  },
};

function createBaseWelcomeIosScreen_SubScreen_Media(): WelcomeIosScreen_SubScreen_Media {
  return { type: WelcomeIosScreen_SubScreen_Media_Type.NONE, file_name: "" };
}

export const WelcomeIosScreen_SubScreen_Media = {
  fromJSON(object: any): WelcomeIosScreen_SubScreen_Media {
    return {
      type: isSet(object.type)
        ? welcomeIosScreen_SubScreen_Media_TypeFromJSON(object.type)
        : WelcomeIosScreen_SubScreen_Media_Type.NONE,
      file_name: isSet(object.file_name) ? String(object.file_name) : "",
    };
  },

  toJSON(message: WelcomeIosScreen_SubScreen_Media): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = welcomeIosScreen_SubScreen_Media_TypeToJSON(message.type));
    message.file_name !== undefined && (obj.file_name = message.file_name);
    return obj;
  },

  create<I extends Exact<DeepPartial<WelcomeIosScreen_SubScreen_Media>, I>>(
    base?: I,
  ): WelcomeIosScreen_SubScreen_Media {
    return WelcomeIosScreen_SubScreen_Media.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<WelcomeIosScreen_SubScreen_Media>, I>>(
    object: I,
  ): WelcomeIosScreen_SubScreen_Media {
    const message = createBaseWelcomeIosScreen_SubScreen_Media();
    message.type = object.type ?? WelcomeIosScreen_SubScreen_Media_Type.NONE;
    message.file_name = object.file_name ?? "";
    return message;
  },
};

function createBaseMultilineTextField(): MultilineTextField {
  return { placeholder: undefined, default_text: undefined, caption: undefined };
}

export const MultilineTextField = {
  fromJSON(object: any): MultilineTextField {
    return {
      placeholder: isSet(object.placeholder) ? String(object.placeholder) : undefined,
      default_text: isSet(object.default_text) ? String(object.default_text) : undefined,
      caption: isSet(object.caption) ? String(object.caption) : undefined,
    };
  },

  toJSON(message: MultilineTextField): unknown {
    const obj: any = {};
    message.placeholder !== undefined && (obj.placeholder = message.placeholder);
    message.default_text !== undefined && (obj.default_text = message.default_text);
    message.caption !== undefined && (obj.caption = message.caption);
    return obj;
  },

  create<I extends Exact<DeepPartial<MultilineTextField>, I>>(base?: I): MultilineTextField {
    return MultilineTextField.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MultilineTextField>, I>>(object: I): MultilineTextField {
    const message = createBaseMultilineTextField();
    message.placeholder = object.placeholder ?? undefined;
    message.default_text = object.default_text ?? undefined;
    message.caption = object.caption ?? undefined;
    return message;
  },
};

function createBaseFeedbackScreen(): FeedbackScreen {
  return { title: "", text_field: undefined, skip_button_text: undefined };
}

export const FeedbackScreen = {
  fromJSON(object: any): FeedbackScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      text_field: isSet(object.text_field) ? MultilineTextField.fromJSON(object.text_field) : undefined,
      skip_button_text: isSet(object.skip_button_text) ? String(object.skip_button_text) : undefined,
    };
  },

  toJSON(message: FeedbackScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.text_field !== undefined &&
      (obj.text_field = message.text_field ? MultilineTextField.toJSON(message.text_field) : undefined);
    message.skip_button_text !== undefined && (obj.skip_button_text = message.skip_button_text);
    return obj;
  },

  create<I extends Exact<DeepPartial<FeedbackScreen>, I>>(base?: I): FeedbackScreen {
    return FeedbackScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FeedbackScreen>, I>>(object: I): FeedbackScreen {
    const message = createBaseFeedbackScreen();
    message.title = object.title ?? "";
    message.text_field = (object.text_field !== undefined && object.text_field !== null)
      ? MultilineTextField.fromPartial(object.text_field)
      : undefined;
    message.skip_button_text = object.skip_button_text ?? undefined;
    return message;
  },
};

function createBaseSlideLoaderScreen(): SlideLoaderScreen {
  return { process_title: "", slides: [] };
}

export const SlideLoaderScreen = {
  fromJSON(object: any): SlideLoaderScreen {
    return {
      process_title: isSet(object.process_title) ? String(object.process_title) : "",
      slides: Array.isArray(object?.slides) ? object.slides.map((e: any) => SlideLoaderScreen_Slide.fromJSON(e)) : [],
    };
  },

  toJSON(message: SlideLoaderScreen): unknown {
    const obj: any = {};
    message.process_title !== undefined && (obj.process_title = message.process_title);
    if (message.slides) {
      obj.slides = message.slides.map((e) => e ? SlideLoaderScreen_Slide.toJSON(e) : undefined);
    } else {
      obj.slides = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SlideLoaderScreen>, I>>(base?: I): SlideLoaderScreen {
    return SlideLoaderScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SlideLoaderScreen>, I>>(object: I): SlideLoaderScreen {
    const message = createBaseSlideLoaderScreen();
    message.process_title = object.process_title ?? "";
    message.slides = object.slides?.map((e) => SlideLoaderScreen_Slide.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSlideLoaderScreen_Slide(): SlideLoaderScreen_Slide {
  return { title: undefined, image: undefined, duration_ms: 0 };
}

export const SlideLoaderScreen_Slide = {
  fromJSON(object: any): SlideLoaderScreen_Slide {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      duration_ms: isSet(object.duration_ms) ? Number(object.duration_ms) : 0,
    };
  },

  toJSON(message: SlideLoaderScreen_Slide): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.image !== undefined && (obj.image = message.image ? Image.toJSON(message.image) : undefined);
    message.duration_ms !== undefined && (obj.duration_ms = Math.round(message.duration_ms));
    return obj;
  },

  create<I extends Exact<DeepPartial<SlideLoaderScreen_Slide>, I>>(base?: I): SlideLoaderScreen_Slide {
    return SlideLoaderScreen_Slide.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SlideLoaderScreen_Slide>, I>>(object: I): SlideLoaderScreen_Slide {
    const message = createBaseSlideLoaderScreen_Slide();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.duration_ms = object.duration_ms ?? 0;
    return message;
  },
};

function createBaseMessageListScreen(): MessageListScreen {
  return { title: undefined, items: [] };
}

export const MessageListScreen = {
  fromJSON(object: any): MessageListScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      items: Array.isArray(object?.items) ? object.items.map((e: any) => MessageListScreen_ListItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: MessageListScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.items) {
      obj.items = message.items.map((e) => e ? MessageListScreen_ListItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageListScreen>, I>>(base?: I): MessageListScreen {
    return MessageListScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageListScreen>, I>>(object: I): MessageListScreen {
    const message = createBaseMessageListScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.items = object.items?.map((e) => MessageListScreen_ListItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMessageListScreen_Explanation(): MessageListScreen_Explanation {
  return { explanation: undefined };
}

export const MessageListScreen_Explanation = {
  fromJSON(object: any): MessageListScreen_Explanation {
    return {
      explanation: isSet(object.title)
        ? { $case: "title", title: AttributedString.fromJSON(object.title) }
        : isSet(object.text)
        ? { $case: "text", text: AttributedString.fromJSON(object.text) }
        : isSet(object.image)
        ? { $case: "image", image: Image.fromJSON(object.image) }
        : isSet(object.notice)
        ? { $case: "notice", notice: MessageListScreen_Explanation_NoticeText.fromJSON(object.notice) }
        : undefined,
    };
  },

  toJSON(message: MessageListScreen_Explanation): unknown {
    const obj: any = {};
    message.explanation?.$case === "title" &&
      (obj.title = message.explanation?.title ? AttributedString.toJSON(message.explanation?.title) : undefined);
    message.explanation?.$case === "text" &&
      (obj.text = message.explanation?.text ? AttributedString.toJSON(message.explanation?.text) : undefined);
    message.explanation?.$case === "image" &&
      (obj.image = message.explanation?.image ? Image.toJSON(message.explanation?.image) : undefined);
    message.explanation?.$case === "notice" && (obj.notice = message.explanation?.notice
      ? MessageListScreen_Explanation_NoticeText.toJSON(message.explanation?.notice)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageListScreen_Explanation>, I>>(base?: I): MessageListScreen_Explanation {
    return MessageListScreen_Explanation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageListScreen_Explanation>, I>>(
    object: I,
  ): MessageListScreen_Explanation {
    const message = createBaseMessageListScreen_Explanation();
    if (
      object.explanation?.$case === "title" &&
      object.explanation?.title !== undefined &&
      object.explanation?.title !== null
    ) {
      message.explanation = { $case: "title", title: AttributedString.fromPartial(object.explanation.title) };
    }
    if (
      object.explanation?.$case === "text" &&
      object.explanation?.text !== undefined &&
      object.explanation?.text !== null
    ) {
      message.explanation = { $case: "text", text: AttributedString.fromPartial(object.explanation.text) };
    }
    if (
      object.explanation?.$case === "image" &&
      object.explanation?.image !== undefined &&
      object.explanation?.image !== null
    ) {
      message.explanation = { $case: "image", image: Image.fromPartial(object.explanation.image) };
    }
    if (
      object.explanation?.$case === "notice" &&
      object.explanation?.notice !== undefined &&
      object.explanation?.notice !== null
    ) {
      message.explanation = {
        $case: "notice",
        notice: MessageListScreen_Explanation_NoticeText.fromPartial(object.explanation.notice),
      };
    }
    return message;
  },
};

function createBaseMessageListScreen_Explanation_NoticeText(): MessageListScreen_Explanation_NoticeText {
  return { image: undefined, text: "" };
}

export const MessageListScreen_Explanation_NoticeText = {
  fromJSON(object: any): MessageListScreen_Explanation_NoticeText {
    return {
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      text: isSet(object.text) ? String(object.text) : "",
    };
  },

  toJSON(message: MessageListScreen_Explanation_NoticeText): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image ? Image.toJSON(message.image) : undefined);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageListScreen_Explanation_NoticeText>, I>>(
    base?: I,
  ): MessageListScreen_Explanation_NoticeText {
    return MessageListScreen_Explanation_NoticeText.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageListScreen_Explanation_NoticeText>, I>>(
    object: I,
  ): MessageListScreen_Explanation_NoticeText {
    const message = createBaseMessageListScreen_Explanation_NoticeText();
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseMessageListScreen_ListItem(): MessageListScreen_ListItem {
  return { symbol: undefined, title: undefined, explanations: [], required_answer_text: "", allowed_answers_texts: [] };
}

export const MessageListScreen_ListItem = {
  fromJSON(object: any): MessageListScreen_ListItem {
    return {
      symbol: isSet(object.symbol) ? messageListScreen_ListItem_BulletSymbolFromJSON(object.symbol) : undefined,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      explanations: Array.isArray(object?.explanations)
        ? object.explanations.map((e: any) => MessageListScreen_Explanation.fromJSON(e))
        : [],
      required_answer_text: isSet(object.required_answer_text) ? String(object.required_answer_text) : "",
      allowed_answers_texts: Array.isArray(object?.allowed_answers_texts)
        ? object.allowed_answers_texts.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: MessageListScreen_ListItem): unknown {
    const obj: any = {};
    message.symbol !== undefined && (obj.symbol = message.symbol !== undefined
      ? messageListScreen_ListItem_BulletSymbolToJSON(message.symbol)
      : undefined);
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.explanations) {
      obj.explanations = message.explanations.map((e) => e ? MessageListScreen_Explanation.toJSON(e) : undefined);
    } else {
      obj.explanations = [];
    }
    message.required_answer_text !== undefined && (obj.required_answer_text = message.required_answer_text);
    if (message.allowed_answers_texts) {
      obj.allowed_answers_texts = message.allowed_answers_texts.map((e) => e);
    } else {
      obj.allowed_answers_texts = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MessageListScreen_ListItem>, I>>(base?: I): MessageListScreen_ListItem {
    return MessageListScreen_ListItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MessageListScreen_ListItem>, I>>(object: I): MessageListScreen_ListItem {
    const message = createBaseMessageListScreen_ListItem();
    message.symbol = object.symbol ?? undefined;
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.explanations = object.explanations?.map((e) => MessageListScreen_Explanation.fromPartial(e)) || [];
    message.required_answer_text = object.required_answer_text ?? "";
    message.allowed_answers_texts = object.allowed_answers_texts?.map((e) => e) || [];
    return message;
  },
};

function createBaseStaticScreen(): StaticScreen {
  return { title: undefined };
}

export const StaticScreen = {
  fromJSON(object: any): StaticScreen {
    return { title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined };
  },

  toJSON(message: StaticScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<StaticScreen>, I>>(base?: I): StaticScreen {
    return StaticScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<StaticScreen>, I>>(object: I): StaticScreen {
    const message = createBaseStaticScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    return message;
  },
};

function createBaseProgressGraphScreen(): ProgressGraphScreen {
  return { image: "" };
}

export const ProgressGraphScreen = {
  fromJSON(object: any): ProgressGraphScreen {
    return { image: isSet(object.image) ? String(object.image) : "" };
  },

  toJSON(message: ProgressGraphScreen): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProgressGraphScreen>, I>>(base?: I): ProgressGraphScreen {
    return ProgressGraphScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProgressGraphScreen>, I>>(object: I): ProgressGraphScreen {
    const message = createBaseProgressGraphScreen();
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseProgressWeeksScreen(): ProgressWeeksScreen {
  return { image: "", view_variant: "", main_issue: "", title: "" };
}

export const ProgressWeeksScreen = {
  fromJSON(object: any): ProgressWeeksScreen {
    return {
      image: isSet(object.image) ? String(object.image) : "",
      view_variant: isSet(object.view_variant) ? String(object.view_variant) : "",
      main_issue: isSet(object.main_issue) ? String(object.main_issue) : "",
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: ProgressWeeksScreen): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    message.view_variant !== undefined && (obj.view_variant = message.view_variant);
    message.main_issue !== undefined && (obj.main_issue = message.main_issue);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProgressWeeksScreen>, I>>(base?: I): ProgressWeeksScreen {
    return ProgressWeeksScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProgressWeeksScreen>, I>>(object: I): ProgressWeeksScreen {
    const message = createBaseProgressWeeksScreen();
    message.image = object.image ?? "";
    message.view_variant = object.view_variant ?? "";
    message.main_issue = object.main_issue ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseFaceScanIdData(): FaceScanIdData {
  return { scan_id: "", when: "" };
}

export const FaceScanIdData = {
  fromJSON(object: any): FaceScanIdData {
    return {
      scan_id: isSet(object.scan_id) ? String(object.scan_id) : "",
      when: isSet(object.when) ? String(object.when) : "",
    };
  },

  toJSON(message: FaceScanIdData): unknown {
    const obj: any = {};
    message.scan_id !== undefined && (obj.scan_id = message.scan_id);
    message.when !== undefined && (obj.when = message.when);
    return obj;
  },

  create<I extends Exact<DeepPartial<FaceScanIdData>, I>>(base?: I): FaceScanIdData {
    return FaceScanIdData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FaceScanIdData>, I>>(object: I): FaceScanIdData {
    const message = createBaseFaceScanIdData();
    message.scan_id = object.scan_id ?? "";
    message.when = object.when ?? "";
    return message;
  },
};

function createBaseFaceScansHistoryResponse(): FaceScansHistoryResponse {
  return { scan_ids: [] };
}

export const FaceScansHistoryResponse = {
  fromJSON(object: any): FaceScansHistoryResponse {
    return {
      scan_ids: Array.isArray(object?.scan_ids) ? object.scan_ids.map((e: any) => FaceScanIdData.fromJSON(e)) : [],
    };
  },

  toJSON(message: FaceScansHistoryResponse): unknown {
    const obj: any = {};
    if (message.scan_ids) {
      obj.scan_ids = message.scan_ids.map((e) => e ? FaceScanIdData.toJSON(e) : undefined);
    } else {
      obj.scan_ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FaceScansHistoryResponse>, I>>(base?: I): FaceScansHistoryResponse {
    return FaceScansHistoryResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FaceScansHistoryResponse>, I>>(object: I): FaceScansHistoryResponse {
    const message = createBaseFaceScansHistoryResponse();
    message.scan_ids = object.scan_ids?.map((e) => FaceScanIdData.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAboutProgramScreen(): AboutProgramScreen {
  return { title: undefined, description: undefined, image: "", subtitle: "" };
}

export const AboutProgramScreen = {
  fromJSON(object: any): AboutProgramScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
    };
  },

  toJSON(message: AboutProgramScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.image !== undefined && (obj.image = message.image);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    return obj;
  },

  create<I extends Exact<DeepPartial<AboutProgramScreen>, I>>(base?: I): AboutProgramScreen {
    return AboutProgramScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AboutProgramScreen>, I>>(object: I): AboutProgramScreen {
    const message = createBaseAboutProgramScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.image = object.image ?? "";
    message.subtitle = object.subtitle ?? "";
    return message;
  },
};

function createBaseEnhancePhotoRequest(): EnhancePhotoRequest {
  return { photo_url: "" };
}

export const EnhancePhotoRequest = {
  fromJSON(object: any): EnhancePhotoRequest {
    return { photo_url: isSet(object.photo_url) ? String(object.photo_url) : "" };
  },

  toJSON(message: EnhancePhotoRequest): unknown {
    const obj: any = {};
    message.photo_url !== undefined && (obj.photo_url = message.photo_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnhancePhotoRequest>, I>>(base?: I): EnhancePhotoRequest {
    return EnhancePhotoRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnhancePhotoRequest>, I>>(object: I): EnhancePhotoRequest {
    const message = createBaseEnhancePhotoRequest();
    message.photo_url = object.photo_url ?? "";
    return message;
  },
};

function createBaseEnhancePhotoResponse(): EnhancePhotoResponse {
  return { photo_url: "" };
}

export const EnhancePhotoResponse = {
  fromJSON(object: any): EnhancePhotoResponse {
    return { photo_url: isSet(object.photo_url) ? String(object.photo_url) : "" };
  },

  toJSON(message: EnhancePhotoResponse): unknown {
    const obj: any = {};
    message.photo_url !== undefined && (obj.photo_url = message.photo_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<EnhancePhotoResponse>, I>>(base?: I): EnhancePhotoResponse {
    return EnhancePhotoResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EnhancePhotoResponse>, I>>(object: I): EnhancePhotoResponse {
    const message = createBaseEnhancePhotoResponse();
    message.photo_url = object.photo_url ?? "";
    return message;
  },
};

function createBaseGetHomeTasksActionsResponse(): GetHomeTasksActionsResponse {
  return { actions: {}, task_contents: {} };
}

export const GetHomeTasksActionsResponse = {
  fromJSON(object: any): GetHomeTasksActionsResponse {
    return {
      actions: isObject(object.actions)
        ? Object.entries(object.actions).reduce<{ [key: string]: ActionType }>((acc, [key, value]) => {
          acc[key] = ActionType.fromJSON(value);
          return acc;
        }, {})
        : {},
      task_contents: isObject(object.task_contents)
        ? Object.entries(object.task_contents).reduce<{ [key: string]: GetHomeTasksActionsResponse_TaskContent }>(
          (acc, [key, value]) => {
            acc[key] = GetHomeTasksActionsResponse_TaskContent.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: GetHomeTasksActionsResponse): unknown {
    const obj: any = {};
    obj.actions = {};
    if (message.actions) {
      Object.entries(message.actions).forEach(([k, v]) => {
        obj.actions[k] = ActionType.toJSON(v);
      });
    }
    obj.task_contents = {};
    if (message.task_contents) {
      Object.entries(message.task_contents).forEach(([k, v]) => {
        obj.task_contents[k] = GetHomeTasksActionsResponse_TaskContent.toJSON(v);
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetHomeTasksActionsResponse>, I>>(base?: I): GetHomeTasksActionsResponse {
    return GetHomeTasksActionsResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetHomeTasksActionsResponse>, I>>(object: I): GetHomeTasksActionsResponse {
    const message = createBaseGetHomeTasksActionsResponse();
    message.actions = Object.entries(object.actions ?? {}).reduce<{ [key: string]: ActionType }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = ActionType.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.task_contents = Object.entries(object.task_contents ?? {}).reduce<
      { [key: string]: GetHomeTasksActionsResponse_TaskContent }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = GetHomeTasksActionsResponse_TaskContent.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseGetHomeTasksActionsResponse_TaskContent(): GetHomeTasksActionsResponse_TaskContent {
  return { title: "", subtitle: "" };
}

export const GetHomeTasksActionsResponse_TaskContent = {
  fromJSON(object: any): GetHomeTasksActionsResponse_TaskContent {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
    };
  },

  toJSON(message: GetHomeTasksActionsResponse_TaskContent): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetHomeTasksActionsResponse_TaskContent>, I>>(
    base?: I,
  ): GetHomeTasksActionsResponse_TaskContent {
    return GetHomeTasksActionsResponse_TaskContent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetHomeTasksActionsResponse_TaskContent>, I>>(
    object: I,
  ): GetHomeTasksActionsResponse_TaskContent {
    const message = createBaseGetHomeTasksActionsResponse_TaskContent();
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    return message;
  },
};

function createBaseGetHomeTasksActionsResponse_ActionsEntry(): GetHomeTasksActionsResponse_ActionsEntry {
  return { key: "", value: undefined };
}

export const GetHomeTasksActionsResponse_ActionsEntry = {
  fromJSON(object: any): GetHomeTasksActionsResponse_ActionsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? ActionType.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetHomeTasksActionsResponse_ActionsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value ? ActionType.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetHomeTasksActionsResponse_ActionsEntry>, I>>(
    base?: I,
  ): GetHomeTasksActionsResponse_ActionsEntry {
    return GetHomeTasksActionsResponse_ActionsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetHomeTasksActionsResponse_ActionsEntry>, I>>(
    object: I,
  ): GetHomeTasksActionsResponse_ActionsEntry {
    const message = createBaseGetHomeTasksActionsResponse_ActionsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? ActionType.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseGetHomeTasksActionsResponse_TaskContentsEntry(): GetHomeTasksActionsResponse_TaskContentsEntry {
  return { key: "", value: undefined };
}

export const GetHomeTasksActionsResponse_TaskContentsEntry = {
  fromJSON(object: any): GetHomeTasksActionsResponse_TaskContentsEntry {
    return {
      key: isSet(object.key) ? String(object.key) : "",
      value: isSet(object.value) ? GetHomeTasksActionsResponse_TaskContent.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: GetHomeTasksActionsResponse_TaskContentsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? GetHomeTasksActionsResponse_TaskContent.toJSON(message.value) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetHomeTasksActionsResponse_TaskContentsEntry>, I>>(
    base?: I,
  ): GetHomeTasksActionsResponse_TaskContentsEntry {
    return GetHomeTasksActionsResponse_TaskContentsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetHomeTasksActionsResponse_TaskContentsEntry>, I>>(
    object: I,
  ): GetHomeTasksActionsResponse_TaskContentsEntry {
    const message = createBaseGetHomeTasksActionsResponse_TaskContentsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? GetHomeTasksActionsResponse_TaskContent.fromPartial(object.value)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
