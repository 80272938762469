const poraTextStyles = {
  mediumCondensed: {
    // Paragraph/Primary
    fontSize: "16px",
    lineHeight: "20px",
  },
  small: {
    // Paragraph/Secondary
    fontSize: "14px",
    lineHeight: "20px",
  },
  // web library
  RegularParagraph: {
    // Paragraph/Primary
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "-0.5px",
  },
  RegularParagraphSecondary: {
    //Paragraph/Secondary
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "-0.5px",
    fontWeight: 400,
  },
  MediumHeaderPrimary: {
    // Header/Primary
    fontSize: "32px",
    lineHeight: "36px",
    fontWeight: 500,
  },
  MediumHeaderSecondary: {
    // Header/Secondary
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: 500,
  },
  MediumSubtitlePrimary: {
    // Subtitle/Primary
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "-0.5px",
    fontWeight: 600,
  },
  MediumSubtitleSecondary: {
    // Subtitle/Secondary
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "-0.5px",
    fontWeight: 500,
  },
  MediumSubtitleTertiary: {
    // Subtitle/Tertiary
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "-0.5px",
    fontWeight: 500,
  },
  MediumHintPrimary: {
    // Subtitle/Hint
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "-0.3px",
    fontWeight: 500,
  },
  // new version https://www.figma.com/file/mWtnz6G5UtG0gNK5vzFNT4/Web-Library?node-id=1107-4176&t=41b8iGzgiTXoWHWH-4
  "Lead/Primary": { fontSize: "88px", lineHeight: "88px", fontWeight: 500 },
  "Lead/Secondary": { fontSize: "44px", lineHeight: "48px", fontWeight: 500 },
  "Header/Primary": {
    fontSize: "32px",
    lineHeight: "36px",
    fontWeight: 500,
    letterSpacing: "-0.5px",
  },
  "Header/Secondary": {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: 500,
    letterSpacing: "-0.5px",
  },
  "Header/Tertiary": {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 600,
    letterSpacing: "-0.5px",
  },
  "Header/Quaternary": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
    letterSpacing: "-0.5px",
  },
  "Subtitle/Primary": {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 500,
    letterSpacing: "-0.5px",
  },
  "Subtitle/Secondary": {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 500,
    letterSpacing: "-0.5px",
  },
  "Subtitle/AccentSecondary": {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 700,
    letterSpacing: "-0.5px",
  },
  "Subtitle/Tertiary": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    letterSpacing: "-0.5px",
  },
  "Subtitle/Hint": {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 500,
    letterSpacing: "-0.3px",
  },
  "Subtitle/AccentPrimary": {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 600,
    letterSpacing: "-0.3px",
  },
  "Paragraph/AccentPrimary": {
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    letterSpacing: "-0.5px",
  },
  "Paragraph/Primary": {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 400,
    letterSpacing: "-0.5px",
  },
  "Paragraph/Secondary": {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
    letterSpacing: "-0.5px",
  },
  "Paragraph/Tertiary": {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 400,
    letterSpacing: "-0.3px",
  },
  "Other/PriceTag": {
    fontSize: "10px",
    lineHeight: "16px",
    fontWeight: 500,
    letterSpacing: "-0.3px",
  },
  "Other/Price2": {
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
    letterSpacing: "-0.5px",
  },
}
const loviTextStyles = {
  ...poraTextStyles,
  "Header/Primary": {
    ...poraTextStyles["Header/Primary"],
    lineHeight: "32px",
  },
  "Header/Monospaced": {
    ...poraTextStyles["Header/Primary"],
    lineHeight: "32px",
    fontVariantNumeric: "lining-nums tabular-nums",
  },
  "Header/MonospacedSecondary": {
    fontSize: "28px",
    lineHeight: "28px",
    fontWeight: 600,
    letterSpacing: "-0.5px",
    fontVariantNumeric: "lining-nums tabular-nums",
  },
  "Lead/Secondary": {
    ...poraTextStyles["Lead/Secondary"],
    lineHeight: "44px",
  },
  "Lead/Monospaced": {
    ...poraTextStyles["Lead/Secondary"],
    lineHeight: "44px",
    fontVariantNumeric: "lining-nums tabular-nums",
  },
}
export type TextStyle = keyof typeof poraTextStyles

export function getTextStyles(theme: Theme) {
  return theme === "lovi" ? loviTextStyles : poraTextStyles
}
