import { Box, Flex, keyframes } from "@chakra-ui/react"
import React, {
  FC,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { PaymentProviders } from "~/generated/paywall"
import {
  useCountry,
  usePaymentProvidersPreference,
  useSelectedPrice,
  useUserEmail,
  useUserId,
} from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

import { PaymentFormEvent, showPaltaPaymentsForm } from "./showPaltaPaymentsForm"
import spinnerSvg from "./payment-spinner.svg?url"
import { PaymentFormStripeMultilineContainer, type PaymentIntent } from "./PaymentFormStripe"
import { PaymentProviderSwitch } from "./PaymentSwitch"
import { useOnPaymentStatusChange, type PaymentStatus } from "./useOnPaymentStatusChange"
import { PaymentFormProvider } from "./PaymentFormContext"
import { InternalPaymentProviders, PaymentProvidersUnion } from "./InternalPaymentProviders"
import { insertBefore } from "./insertBefore"

const formEventToAnalytics =
  (log: ReturnType<typeof useAmplitude>) =>
  (event: PaymentFormEvent, params: Record<string, unknown>): void => {
    if (event === "form.click") {
      log.paymentFormButtonClick(params)
    } else if (event === "form.field.focusin") {
      log.paymentFormInputFocusIn(params)
    } else if (event === "form.field.focusout") {
      log.paymentFormInputFocusOut(params)
    } else if (event === "form.init") {
      log.paymentFormInitStarted(params)
    } else if (event === "form.init.success") {
      log.paymentFormShowSuccess(params)
    } else if (event === "form.init.fail") {
      log.paymentFormShowFail(params)
    } else if (event === "form.init.complete") {
      log.paymentFormSceneChange(params)
    } else if (event === "payment.change") {
      log.paymentStatusChange(params)
    } else if (event === "payment.error") {
      log.paymentError(params)
    }
  }

const rotateKeyframes = keyframes`
0% { rotate: 0; }
100% { rotate: 360deg; }
`

const rotateAnimation = `${rotateKeyframes} 1s linear infinite`
const FormLoadingSpinner: FC<{ externalRef: MutableRefObject<null> }> = ({ externalRef }) => (
  <Flex
    ref={externalRef}
    w="full"
    minW="250px"
    direction="column"
    justifyContent="center"
    sx={{
      "&:empty::before": {
        marginTop: "35px",
        marginBottom: "18px",
        backgroundImage: spinnerSvg,
        content: "' '",
        width: "27px",
        height: "27px",

        display: "inline-block",
        animation: rotateAnimation,
        alignSelf: "center",
      },
      "& #primer-checkout-apm-paypal": {
        display: "none",
      },
      "& #primer-checkout-apm-applePay": {
        marginBottom: "16px",
      },
      "& .PrimerCheckout__sceneElement:not(:last-child)": {
        marginBottom: 0,
      },
    }}
  />
)

const PaltaPaymentFormPlaceholder: FC<{
  paymentFormRef: MutableRefObject<null>
}> = ({ paymentFormRef }) => (
  <Flex
    direction="column"
    gap={8}
    sx={{
      "& .PrimerCheckout__apmButton:focus-within": {
        outline: "none",
      },
    }}
  >
    <FormLoadingSpinner externalRef={paymentFormRef} />
  </Flex>
)

const convertStatus = (status: PaymentIntent["status"]): PaymentStatus => {
  switch (status) {
    case "succeeded":
      return "COMPLETE"
    case "canceled":
      return "FAIL"
    case "processing":
      return "PENDING"
    default:
      return "ERROR"
  }
}

export const PaymentFormContainer: FC<{ hidden?: boolean; children?: ReactNode }> = ({
  hidden = false,
  children,
}) => {
  const paymentFormRef = useRef(null)
  const paypalRef = useRef(null)

  const selectedPrice = useSelectedPrice()

  const log = useAmplitude()

  const onPaymentComplete = useOnPaymentStatusChange()
  const formSubscribe = useCallback(
    (event: PaymentFormEvent, params: { status?: PaymentStatus }) => {
      formEventToAnalytics(log)(event, params)
      if (event === "payment.change") {
        const { status = "UNKNOWN" } = params
        onPaymentComplete("PALTA", status)
      }
    },
    [log, onPaymentComplete]
  )

  const _providers = usePaymentProvidersPreference()
  const [selectedProvider, setSelectedProvider] = useState<PaymentProvidersUnion>(
    _providers[0] ?? PaymentProviders.UNRECOGNIZED
  )
  const providers: PaymentProvidersUnion[] = Array.from(_providers)

  const insert = insertBefore(providers)
  insert(PaymentProviders.PAYMENT_PROVIDER_STRIPE, InternalPaymentProviders.STRIPE_APPLE_PAY)

  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)

  const userId = useUserId()
  const emailAddress = useUserEmail()
  const countryCode = useCountry()
  const updatePaymentForm = useCallback(
    (provider: PaymentProvidersUnion | undefined) => {
      if (paymentFormRef.current && paypalRef.current && selectedPrice) {
        if (
          provider === PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD ||
          provider === PaymentProviders.PAYMENT_PROVIDER_PALTA_PAYPAL
        ) {
          const opts =
            provider === PaymentProviders.PAYMENT_PROVIDER_PALTA_PAYPAL
              ? ({
                  type: "paypal",
                  // todo fix
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  priceId: selectedPrice.payment_providers?.palta_paypal?.price_id!,
                } as const)
              : ({
                  type: "card",
                  // todo fix
                  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                  priceId: selectedPrice.payment_providers?.palta_card?.price_id!,
                } as const)

          showPaltaPaymentsForm({
            formType: opts.type,
            priceId: opts.priceId,
            container: paymentFormRef.current,
            paypalContainer: paypalRef.current,
            subscribe: formSubscribe,
            userId,
            countryCode,
            emailAddress,
          }).finally(() => {
            setIsFormLoading(false)
          })
          setIsFormLoading(true)
        } else if (provider === InternalPaymentProviders.STRIPE_APPLE_PAY) {
          // Do Nothings
          // Same as PaymentProviders.PAYMENT_PROVIDER_STRIPE
        } else if (provider === PaymentProviders.PAYMENT_PROVIDER_STRIPE) {
          // Do Nothings
          // Show form on react side
        } else if (provider === PaymentProviders.PAYMENT_PROVIDER_PAYPAL) {
          // Do Nothings
          // Form generated by Paypal button
        } else {
          console.error(`Unknown provider ${provider}`)
        }
      }
    },
    [setIsFormLoading, selectedPrice, formSubscribe, userId, countryCode, emailAddress]
  )

  useEffect(() => {
    /* Change form type */
    updatePaymentForm(selectedProvider)
  }, [log, selectedProvider, updatePaymentForm])

  const onChangePaymentProvider = useCallback(
    (provider: PaymentProvidersUnion) => {
      setSelectedProvider(provider)
      log.paymentFormChangeType({ type: provider })
    },
    [setSelectedProvider, log]
  )

  const onPaymentCompleteFn = useCallback(
    (intent: PaymentIntent) => {
      onPaymentComplete("STRIPE", convertStatus(intent.status), { paymentId: intent.id })
    },
    [onPaymentComplete]
  )

  const PaltaProviders: PaymentProvidersUnion[] = [
    InternalPaymentProviders.PALTA_APPLE_PAY,
    PaymentProviders.PAYMENT_PROVIDER_PALTA_CARD,
    PaymentProviders.PAYMENT_PROVIDER_PALTA_PAYPAL,
  ]
  const StripeProviders: PaymentProvidersUnion[] = [
    PaymentProviders.PAYMENT_PROVIDER_STRIPE,
    InternalPaymentProviders.STRIPE_APPLE_PAY,
  ]

  return (
    <Flex direction="column" hidden={hidden} w="full" overflow="hidden" gap={6}>
      <PaymentFormProvider>
        <PaymentProviderSwitch
          onChange={onChangePaymentProvider}
          selected={selectedProvider}
          isDisabled={isFormLoading}
          providers={providers}
          paypalRef={paypalRef}
        />

        {children}

        <Box hidden={!StripeProviders.includes(selectedProvider)}>
          <PaymentFormStripeMultilineContainer
            onError={(error) => {
              console.error(error)
              onPaymentComplete("STRIPE", "FAIL")
            }}
            onPaymentComplete={onPaymentCompleteFn}
            type={
              selectedProvider === InternalPaymentProviders.STRIPE_APPLE_PAY ? "APPLE_PAY" : "CARD"
            }
          />
        </Box>
        <Box hidden={!PaltaProviders.includes(selectedProvider)}>
          <PaltaPaymentFormPlaceholder paymentFormRef={paymentFormRef} />
        </Box>
      </PaymentFormProvider>
    </Flex>
  )
}
