import { motion } from "framer-motion"
import React, { FC, ReactNode, useEffect, useMemo, useState } from "react"
import { FlexProps, Flex, Box } from "@chakra-ui/react"
import { ReactComponent as MailIcon } from "./assets/mailIcon.min.svg"
import { VFlex } from "~/components"
import { mails1, mails2 } from "./mailData"
import { spliceCircularArray } from "~/utils/spliceCircularArray"

type MailChipsContentVariant = "icon" | "fade"
type MailChipsColorVariant = "white" | "grey"

const INITIAL_COUNT = 264
const UPDATE_INTERVAL = 2500

const MailChipLayout: FC<FlexProps> = (props) => (
  <Box
    borderRadius="full"
    paddingX={3}
    paddingY={1.5}
    marginRight={1.5}
    textAlign="center"
    textStyle="Paragraph/Secondary"
    userSelect="none"
    {...props}
  />
)

const Content1: FC<FlexProps & { planSlot: ReactNode }> = ({ children, planSlot: _, ...props }) => (
  <Flex gap={1} alignItems="center" {...props}>
    <Box width="16px">
      <MailIcon width="16px" />
    </Box>
    {children}
  </Flex>
)

const Content2: FC<FlexProps & { planSlot: ReactNode }> = ({ children, planSlot, ...props }) => (
  <Flex gap={1} {...props}>
    <Box
      sx={{
        maskImage:
          "linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.6) 39.53%, rgba(0, 0, 0, 0) 100%)",
      }}
    >
      {children}
    </Box>
    <Box whiteSpace="nowrap">{planSlot}</Box>
  </Flex>
)

const Chip: FC<{
  variant: MailChipsContentVariant | string
  colorVariant?: MailChipsColorVariant
  contentItem: number
}> = ({ colorVariant = "white", variant, contentItem }) => {
  const Content = useMemo(
    () => ({ icon: Content1, fade: Content2 }[variant] ?? Content1),
    [variant]
  )
  const { boxShadow, bgColor } = useMemo(
    () =>
      ({
        white: { boxShadow: "LoviShort", bgColor: "Base/neutralPrimary" },
        grey: { boxShadow: "none", bgColor: "Base/neutralSecondary" },
      }[colorVariant]),
    [colorVariant]
  )

  const [children = "", planSlot = ""] = useMemo(() => {
    let data = (mails1[contentItem] ?? mails1[0]) as [string, string]
    if (variant === "fade") {
      data = (mails2[contentItem] ?? mails2[0]) as [string, string]
    }
    return data
  }, [contentItem])

  return (
    <motion.div
      transition={{ duration: 1.5, times: [0, 0.3, 1] }}
      initial={{ opacity: 0, scale: 0 }}
      animate={{
        opacity: [null, 0.8, 1],
        scale: [0.3, 1, 1],
        width: ["0%", "100%", "100%"],
      }}
    >
      <MailChipLayout bgColor={bgColor} boxShadow={boxShadow}>
        <Content planSlot={planSlot}>{children}</Content>
      </MailChipLayout>
    </motion.div>
  )
}

const mailArr = spliceCircularArray(
  Array.from({ length: mails1.length }, (v, i) => i),
  4
)

export const MailChips: FC<
  {
    variant: MailChipsContentVariant | string
    colorVariant?: MailChipsColorVariant
    textUnder?: boolean
    initialCount?: number
  } & FlexProps
> = ({ variant, colorVariant, textUnder, initialCount, ...props }) => {
  const [count, setCount] = useState(initialCount || INITIAL_COUNT)

  useEffect(() => {
    const intId = setInterval(() => {
      setCount((c) => c + 1)
    }, UPDATE_INTERVAL)
    return () => clearInterval(intId)
  }, [])

  const arr = useMemo(() => mailArr(count % mails1.length).reverse(), [count, mails1])
  if (variant !== "icon" && variant !== "fade") {
    return null
  }

  return (
    <VFlex paddingY={4} alignItems="flex-start" gap={2} width="full" overflow="hidden" {...props}>
      {!textUnder && (
        <Box textStyle="Subtitle/Tertiary" alignSelf="stretch" textAlign="center" paddingX={6}>
          {count} people bought our plans in the last 1 hour
        </Box>
      )}
      <Flex paddingLeft={6}>
        {arr.map((m) => (
          <Chip key={m} variant={variant} contentItem={m} colorVariant={colorVariant} />
        ))}
        <Box width="full"></Box>
      </Flex>
      {textUnder && (
        <Box
          mt={2}
          textStyle="Paragraph/Primary"
          color="Base/baseSecondary"
          alignSelf="stretch"
          textAlign="center"
          paddingX={6}
        >
          <Box color="Base/accentPrimary" fontWeight="bold" as="span">
            {count} people
          </Box>{" "}
          have bought our plans in the last 1 hour
        </Box>
      )}
    </VFlex>
  )
}
