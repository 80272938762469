import React, {
  FC,
  FormEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import type { TokenPayload } from "@recurly/recurly-js"
import {
  CardNumberElement,
  CardMonthElement,
  CardCvvElement,
  CardYearElement,
  useRecurly,
  UseRecurlyInstance,
} from "@recurly/react-recurly"
import { Box, Button, Flex, keyframes } from "@chakra-ui/react"

import { useSelectedPrice, useUserId, useCountry } from "~/store/selectors"
import { PaymentError } from "~/errors"
import { VFlex } from "~/components"
import { recurlyBindUser, recurlyMakePurchase } from "~/api/api"
import { PurchaseResponse } from "~/generated/recurly"
import { isApplePayAvailable, isGooglePayAvailable } from "~/utils"
import { CONFIG } from "~/config"

import { useOnPaymentStatusChange } from "./useOnPaymentStatusChange"
import { ApplePayButton } from "./PaymentFormStripe/ApplePayButton"
import { PaymentProviderSwitch } from "./PaymentSwitch"
import { InternalPaymentProviders, PaymentProvidersUnion } from "./InternalPaymentProviders"

const handleBlur = () => console.log("[blur]")
const handleChange = (change: unknown) => console.log("[change]", change)
const handleFocus = () => console.log("[focus]")
const handleReady = () => console.log("[ready]")
const handleSubmit = () => console.log("[submit]")

const getTokenByCard = (
  recurly: UseRecurlyInstance,
  formRef: MutableRefObject<null>
): Promise<TokenPayload> => {
  const p = new Promise<TokenPayload>((resolve, reject) => {
    if (formRef.current === null) {
      return Promise.reject("Form is not initialized")
    }
    recurly.token(formRef.current, (err: unknown, token: TokenPayload) => {
      if (err) {
        reject(err)
      } else {
        resolve(token)
      }
    })
    return
  })
  return p
}

const getTokenByApplePay = (
  apInstance: ReturnType<(typeof recurly)["ApplePay"]>
): Promise<TokenPayload> => {
  const p = new Promise<TokenPayload>((resolve, reject) => {
    apInstance.once("error", (err) => {
      reject(err)
    })
    apInstance.once("cancel", (err) => {
      reject(new PaymentError("ApplePay canceled", err))
    })
    apInstance.once("token", (token) => {
      resolve(token)
    })
    apInstance.once("ready", () => {
      console.log("Apple Pay ready")
    })
  })
  apInstance.begin()
  return p
}

type RecurlyPaymentToken = string

const useRecurlyMakePurchase = () => {
  const selectedPrice = useSelectedPrice()
  const userId = useUserId()
  const onPaymentComplete = useOnPaymentStatusChange()

  const onMakePurchase = useCallback(
    async (token: RecurlyPaymentToken): Promise<PurchaseResponse> => {
      const bindStatus = await recurlyBindUser({ userId, token })
      console.debug("bindStatus", bindStatus)

      // Make purchase
      const priceId = selectedPrice?.payment_providers?.recurly?.plan_id ?? "UNKNOWN_PLAN_ID"
      const currency = selectedPrice?.currency_code ?? "USD"
      const purchaseStatus = await recurlyMakePurchase({ userId, priceId, currency })
      console.debug("purchaseStatus", purchaseStatus)
      if (purchaseStatus.status === "success") {
        onPaymentComplete("RECURLY", "COMPLETE")
      } else {
        onPaymentComplete("RECURLY", "FAIL")
      }
      return purchaseStatus
    },
    [onPaymentComplete, userId, selectedPrice]
  )

  return onMakePurchase
}

export const RecurlyApplePayButton: FC = () => {
  const recurly = useRecurly()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [recurlyReady, setRecurlyReady] = useState<boolean>(false)
  const [applePayInstance, setApplePayInstance] = useState<
    undefined | ReturnType<(typeof recurly)["ApplePay"]>
  >()
  const selectedPrice = useSelectedPrice()
  const makePurchase = useRecurlyMakePurchase()
  const onPaymentComplete = useOnPaymentStatusChange()
  const country = useCountry()

  useEffect(() => {
    recurly.ready(() => {
      setRecurlyReady(true)
    })
  }, [recurly])

  useEffect(() => {
    if (!isApplePayAvailable()) {
      return
    }

    const currency = selectedPrice?.currency_code ?? "USD"
    const priceTotal = (selectedPrice?.trial_price_cents ?? 0) / 100

    console.log("[ApplePay init]", { currency, priceTotal })
    const _applePay = recurly.ApplePay({
      country,
      currency,
      label: selectedPrice?.title ?? "Lovi.care",
      total: String(priceTotal),
    })

    _applePay.once("error", (args) => {
      console.error("[ApplePay error]", args)
      onPaymentComplete("RECURLY", "ERROR")
    })

    _applePay.once("ready", (args) => {
      console.log("[ApplePay ready]", args)
      setApplePayInstance(_applePay)
    })
  }, [selectedPrice, onPaymentComplete, country])

  const onApplePayClick = useCallback(async () => {
    if (applePayInstance) {
      setIsLoading(true)
      try {
        const token = await getTokenByApplePay(applePayInstance)
        await makePurchase(token.id)
      } catch (error) {
        console.error(error)
        onPaymentComplete("RECURLY", "ERROR")
      } finally {
        setIsLoading(false)
      }
    }
  }, [makePurchase, applePayInstance, onPaymentComplete])

  if (!recurlyReady) {
    console.error("Recurly is not ready")
    return null
  }

  if (!applePayInstance) {
    console.error("ApplePay is not ready")
    return null
  }

  return <ApplePayButton onClick={onApplePayClick} isLoading={isLoading} w="full" />
}

const googlePayLoading = keyframes`
    0% { opacity: 0.5; }
  100% { opacity: 0.4; }
`
const googlePayLoadingAnimation = `0.6s ease-in-out infinite alternate ${googlePayLoading}`

export const RecurlyGooglePayButton: FC = () => {
  const recurly = useRecurly()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const selectedPrice = useSelectedPrice()
  const makePurchase = useRecurlyMakePurchase()
  const onPaymentComplete = useOnPaymentStatusChange()
  const country = useCountry()

  const buttonRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!isGooglePayAvailable()) {
      return
    }

    const currency = selectedPrice?.currency_code ?? "USD"
    const priceTotal = (selectedPrice?.trial_price_cents ?? 0) / 100

    //console.log("[GooglePay init]", { currency, priceTotal })

    const _googlePay = recurly.GooglePay({
      country,
      currency,
      total: String(priceTotal),
      googleMerchantId: CONFIG.google.merchantId,
      googleBusinessName: CONFIG.google.businessName,
      billingAddressRequired: true,
      buttonOptions: {
        buttonColor: "black",
        buttonType: "buy",
        buttonRadius: 100,
        buttonSizeMode: "fill",
      },
    })

    _googlePay.once("error", (args) => {
      console.error("[GooglePay error]", args)
      onPaymentComplete("RECURLY", "ERROR")
    })

    _googlePay.once("ready", (googlePayButton, ...args) => {
      //console.log("[GooglePay ready]", googlePayButton, args)
      if (buttonRef.current) {
        buttonRef.current.replaceChildren(googlePayButton)
      }
    })

    _googlePay.once("token", async (token: TokenPayload) => {
      setIsLoading(true)
      try {
        await makePurchase(token.id)
      } catch (error) {
        console.error(error)
        onPaymentComplete("RECURLY", "ERROR")
      } finally {
        setIsLoading(false)
      }
    })
  }, [selectedPrice, onPaymentComplete, country, makePurchase])

  if (!isGooglePayAvailable()) {
    return null
  }

  return (
    <Box
      ref={buttonRef}
      onClick={(args) => {
        console.log("[GooglePay onClick]", args)
        setIsLoading(true)
      }}
      animation={isLoading ? googlePayLoadingAnimation : undefined}
      w="full"
      h="58px"
      minH="60px"
      p="1px"
      bgColor="black"
      borderRadius="full"
    />
  )
}

const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70% {
    transform: translateX(-5px);
  }
  20%, 40%, 60% {
    transform: translateX(5px);
  }
  80% {
    transform: translateX(3px);
  }
  90% {
    transform: translateX(-3px);
  }
`
const shakeAnimation = `${shake} 0.5s ease-in-out forwards`

const RECURLY_CARD_ELEMENTS_STYLES = {
  "& .recurly-element": {
    width: "100%",
    border: 0,
    background: "#f8f8f8",
    margin: 0,
  },

  "& .recurly-element-month": {
    borderRightRadius: 0,
  },
  "& .recurly-element-year": {
    borderLeftRadius: 0,
  },

  "& .recurly-element-invalid": {
    outline: "1px solid red",
    outlineOffset: "-1px",
    outlineColor: "Other/Error",
    animation: shakeAnimation,
  },
}
const RECURLY_FORM_INPUT_STYLE = { fontSize: "16px", fontFamily: "Inter" }

const RecurlyCardForm = () => {
  const recurly = useRecurly()
  const formRef = useRef(null)
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false)

  const selectedPrice = useSelectedPrice()

  const onPaymentComplete = useOnPaymentStatusChange()
  const makePurchase = useRecurlyMakePurchase()

  const userId = useUserId()
  const countryCode = useCountry()

  const handleSubmitForm = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      if (event.preventDefault) event.preventDefault()
      if (formRef.current) {
        setIsFormLoading(true)
        try {
          // Fetch token from recurly
          const token = await getTokenByCard(recurly, formRef)
          await makePurchase(token.id)
        } catch (error) {
          console.error(error)
          onPaymentComplete("RECURLY", "ERROR")
        } finally {
          setIsFormLoading(false)
        }
      }
    },
    [selectedPrice, userId, recurly, formRef, onPaymentComplete]
  )

  return (
    <form onSubmit={handleSubmitForm} ref={formRef}>
      <input type="hidden" data-recurly="first_name" value="Empty" />
      <input type="hidden" data-recurly="last_name" value="Empty" />
      <input type="hidden" data-recurly="postal_code" value="11111" />
      <input type="hidden" data-recurly="address1" value="Empty street" />
      <input type="hidden" data-recurly="city" value="Town" />
      <input type="hidden" data-recurly="state" value="Empty" />

      <input type="hidden" data-recurly="country" value={countryCode} />

      <Box sx={RECURLY_CARD_ELEMENTS_STYLES}>
        <VFlex gap={2}>
          <CardNumberElement
            onBlur={handleBlur}
            onChange={handleChange}
            style={{ ...RECURLY_FORM_INPUT_STYLE, placeholder: { content: "Card number" } }}
          />
          <Flex w="full" gap={4}>
            <Flex w="50%">
              <CardMonthElement
                onBlur={handleBlur}
                onChange={handleChange}
                inputType="mobileSelect"
                style={{ ...RECURLY_FORM_INPUT_STYLE, placeholder: { content: "MM" } }}
              />
              <CardYearElement
                onBlur={handleBlur}
                onChange={handleChange}
                inputType="mobileSelect"
                style={{ ...RECURLY_FORM_INPUT_STYLE, placeholder: { content: "YY" } }}
              />
            </Flex>
            <Flex w="50%">
              <CardCvvElement
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onReady={handleReady}
                onSubmit={handleSubmit}
                style={{ ...RECURLY_FORM_INPUT_STYLE, placeholder: { content: "CVV" } }}
              />
            </Flex>
          </Flex>

          <Button
            type="submit"
            isLoading={isFormLoading}
            disabled={isFormLoading}
            w="full"
            variant="next"
            mt={2}
          >
            Pay
          </Button>
        </VFlex>
      </Box>
    </form>
  )
}

export const PaymentFormRecurlyPopupContainer: FC<{ children?: ReactNode }> = ({ children }) => {
  const availableProviders = useMemo(() => {
    const p = [InternalPaymentProviders.RECURLY_CARD]
    isGooglePayAvailable() && p.unshift(InternalPaymentProviders.RECURLY_GOOGLE_PAY)
    isApplePayAvailable() && p.unshift(InternalPaymentProviders.RECURLY_APPLE_PAY)
    return p
  }, [])
  const [selectedProvider, setSelectedProvider] = useState<PaymentProvidersUnion>(
    availableProviders[0]!
  )
  const onChangePaymentProvider = useCallback((p: PaymentProvidersUnion) => {
    setSelectedProvider(p)
  }, [])

  return (
    <VFlex w="full" overflow="hidden">
      {availableProviders.length > 1 && (
        <PaymentProviderSwitch
          onChange={onChangePaymentProvider}
          selected={selectedProvider}
          //isDisabled={isFormLoading}
          providers={availableProviders}
          height="64px"
        />
      )}
      <VFlex w="full" gap={4} pt="26px">
        {children}

        {selectedProvider === InternalPaymentProviders.RECURLY_CARD && <RecurlyCardForm />}
        {selectedProvider === InternalPaymentProviders.RECURLY_APPLE_PAY && (
          <RecurlyApplePayButton />
        )}
        {selectedProvider === InternalPaymentProviders.RECURLY_GOOGLE_PAY && (
          <RecurlyGooglePayButton />
        )}
      </VFlex>
    </VFlex>
  )
}
