import React, { FC, ReactNode } from "react"
import { Box, BoxProps, Button, Flex, Image } from "@chakra-ui/react"

import { BlockHeading, BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { BlockPropertyContent, VFlex } from "~/components"

import photo1 from "./assets/1.webp?url"
import photo2 from "./assets/2.webp?url"
import photo3 from "./assets/3.webp?url"
import photo4 from "./assets/4.webp?url"
import { ActionButton } from "../../ActionButton"

type TipItemData = {
  title: string
  content: string
  image: string
}

const DATA: TipItemData[] = [
  {
    title: "Ice Roller",
    content: "Use an ice roller to reduce redness, soothe skin, and shrink pores.",
    image: photo1,
  },
  {
    title: "Double Cleanse",
    content: "Start with an oil cleanser, then use a water-based cleanser for deep cleaning.",
    image: photo2,
  },
  {
    title: "Hydrating Hack",
    content: "Layer face toner for extra moisture and nourishment. The super effect is guaranteed!",
    image: photo3,
  },
  {
    title: "Spot Treatment",
    content: "Dab on tea tree oil or benzoyl peroxide before bed to target blemishes.",
    image: photo4,
  },
]

const Prefix: FC<BoxProps> = (props) => <Box as="span" color="Base/accentPrimary" {...props} />

const TipItem: FC<{
  layout: "RIGHT" | "LEFT"
  image: string
  title: ReactNode
  content: ReactNode
}> = ({ layout, image, title, content }) => (
  <Flex gap={4} padding={4} layerStyle="internalFrame">
    {layout === "LEFT" && <Image src={image} h="112px" borderRadius="brand16" />}
    <VFlex gap={2} flexGrow={1}>
      <Box fontFamily="heading" textStyle="Header/Tertiary">
        {title}
      </Box>
      <Box textStyle="Paragraph/Secondary">{content}</Box>
    </VFlex>
    {layout === "RIGHT" && <Image src={image} h="112px" borderRadius="brand16" />}
  </Flex>
)

const Tips: FC<{ data: TipItemData[] }> = ({ data }) => (
  <VFlex gap={2}>
    {data.map(({ title, content, image }, i) => (
      <TipItem
        key={i}
        title={
          <>
            <Prefix>Tip#{i + 1}</Prefix> {title}
          </>
        }
        content={content}
        image={image}
        layout={i % 2 === 0 ? "RIGHT" : "LEFT"}
      />
    ))}
  </VFlex>
)

export const TipsBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper paddingY={6} {...props}>
    <BlockHeading>
      <BlockPropertyContent propKey="PB_TIPS.title">Get skin changing secrets</BlockPropertyContent>
    </BlockHeading>
    <Tips data={DATA} />
    <ActionButton marginTop={4} actionSource="PB_TIPS">
      <BlockPropertyContent propKey="PB_TIPS.button_text">Get Full Set</BlockPropertyContent>
    </ActionButton>
  </BlockWrapper>
)
