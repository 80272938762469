import React, { FC } from "react"
import { Flex, List, ListItem, Text } from "@chakra-ui/react"
import { CheckIcon } from "./Icons"

type Item = { text: string }
const ProgramBenefitsListItem: FC<Item> = ({ text }) => (
  <ListItem>
    <Flex gap={2} alignItems="center">
      <CheckIcon mt="1px" color="Base/accentSecondary" fill="none" fontSize={20} />
      <Text as="span" textStyle="RegularParagraph" textColor="Base/basePrimary">
        {text}
      </Text>
    </Flex>
  </ListItem>
)

export const ProgramBenefitsList: FC<{ benefits: Item[] }> = ({ benefits }) => (
  <List width="full" spacing={3}>
    {benefits.map((benefit, i) => (
      <ProgramBenefitsListItem {...benefit} key={i} />
    ))}
  </List>
)
