export function callOnce<In, Out>(cb: (...args: In[]) => Out) {
  let called = false

  return function (...args: In[]) {
    if (called) return

    called = true
    cb(...args)
  }
}
