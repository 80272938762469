import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  Box,
  BoxProps,
  Button,
  Center,
  createIcon,
  Divider,
  Flex,
  FlexProps,
  Heading,
  Image,
  useBoolean,
} from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"
import { ButtonSlideUpContainer } from "~/components/ButtonSlideUpContainer"
import { useUpsaleAIScannerData } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useMakePurchase } from "~/utils/useMakePurchase"
import { UpsaleContext } from "~/pages/Processing/context"
import { Logo } from "~/pages/Main/SkipTrial/Icons"
import {
  AlternativeVariant,
  AlternativeVariantProps,
} from "~/pages/AppLinkPage/AlternativeVariantProps"

import { WhyLoviPart } from "../UpsaleAI/WhyLoviPart"
import { WhatIsIncluded } from "./WhatIsIncluded"
import { Step2Modal } from "./Step2Modal"
import shieldSrc from "./assets/shield.svg?url"

const BenefitCard = ({ image, title }: { image: string; title: string }) => {
  return (
    <VFlex
      width="152px"
      flexShrink={0}
      p={4}
      layerStyle="attentionFrame"
      alignItems="flex-start"
      gap={3}
    >
      <Flex>
        <Image width="64px" height="64px" src={image} />
      </Flex>
      <Flex textStyle="Subtitle/Secondary">{title}</Flex>
    </VFlex>
  )
}

const BENEFIT_CARD_CONFIG = [
  {
    image: "https://storage.pora.ai/7eeb64aa5c9018eb27f47b479698be21.webp",
    title: "Unbiased analysis with no brand affiliation",
  },
  {
    image: "https://storage.pora.ai/2006b876c12f1215a71fb1c92273e53d.webp",
    title: "Find Your Perfect Beauty Matches",
  },
  {
    image: "https://storage.pora.ai/64ee64beb8e74334266d78db54cdad08.webp",
    title: "Personalized Skincare Compatibility",
  },
]
const BenefitCardList = () => {
  return (
    <Flex mx={-6} pl={6} pr={6} gap={2} overflow="scroll">
      {BENEFIT_CARD_CONFIG.map((conf) => (
        <BenefitCard key={conf.title} {...conf} />
      ))}
    </Flex>
  )
}

export const CheckIcon = createIcon({
  viewBox: "0 0 22 22",
  defaultProps: {
    width: "22px",
    height: "22px",
    fill: "none",
    color: "Base/accentSecondary",
  },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4926 0.918631C15.1079 0.837365 19.2685 3.78665 20.6539 8.11048C22.002 12.3179 20.06 16.7804 16.3895 19.3226C12.8785 21.7544 8.18879 21.6441 4.7325 19.1379C1.35734 16.6906 0.150278 12.3843 1.39427 8.44961C2.69449 4.33702 6.10813 0.995834 10.4926 0.918631Z"
        fill="currentColor"
      />
      <path
        d="M6.875 11.0166C7.46429 12.2229 8.05357 13.8754 9.41474 14.6013C10.7759 15.3273 13.3571 9.87786 15.125 7.33398"
        stroke="white"
        strokeWidth="1.46667"
        strokeLinecap="round"
      />
    </>
  ),
})

export const UnorderedList: FC<FlexProps> = (props) => <VFlex gap={3} {...props} />

export const UnorderedListItem = ({ children }: { children: ReactNode }) => (
  <Flex gap={1.5}>
    <CheckIcon />
    <Box textStyle="Subtitle/Secondary">{children}</Box>
  </Flex>
)

const ProductScanReport = () => (
  <Box>
    <VFlex gap={6} p={6} layerStyle="attentionFrame">
      <Heading as="h2" size="Header/Secondary">
        Your Product Scan Report includes:
      </Heading>
      <UnorderedList>
        <UnorderedListItem>Tailored Product Scoring</UnorderedListItem>
        <UnorderedListItem>Ingredient Safety Breakdown</UnorderedListItem>
        <UnorderedListItem>Targeted Solutions & Availability</UnorderedListItem>
        <UnorderedListItem>Curated Alternatives</UnorderedListItem>
        <UnorderedListItem>Marketplace Comparison</UnorderedListItem>
      </UnorderedList>
      <Box mx={-2}>
        <Image src="https://storage.pora.ai/d5ffdc8e224003290af700d30b38f760.webp" />
      </Box>
    </VFlex>
    <Box mt={4} color="Base/baseSecondary" textStyle="Subtitle/Secondary">
      Enhance your skincare routines with AI-Powered scanner
    </Box>
  </Box>
)

const FreeWorkbook = () => (
  <VFlex gap={5} px={6} pt={4} pb={6} layerStyle="attentionFrame">
    <Heading as="h2" size="Header/Secondary" textAlign="center">
      Don’t miss your bonus! Workbook with secret skincare picks
    </Heading>
    <Box>
      <Image src="https://storage.pora.ai/0800215cae599aaf9ec4a00800b87382.webp" />
    </Box>
    <Box textAlign="center" color="Base/baseSecondary">
      <Heading as="h5" color="Base/accentPrimary" size="Header/Tertiary">
        Get it for free!
      </Heading>
      <Box textStyle="Paragraph/Secondary" mt={1}>
        Original price was $49 —{" "}
        <Box as="span" color="Base/accentPrimary">
          save 100%!
        </Box>
      </Box>
      <Divider my={3} />
      <Box textStyle="Paragraph/Secondary">
        You can keep this workbook even if you decide Lóvi isn’t for you.
      </Box>
    </Box>
  </VFlex>
)

export const CircleIcon = createIcon({
  viewBox: "0 0 24 24",
  defaultProps: {
    width: "24px",
    height: "24px",
    color: "Base/accentSecondary",
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4472 1.00179C16.482 0.913134 21.0209 4.13053 22.5322 8.84744C24.0029 13.4373 21.8843 18.3055 17.8801 21.0789C14.05 23.7317 8.93393 23.6114 5.16344 20.8774C1.48145 18.2076 0.16465 13.5098 1.52173 9.2174C2.94015 4.73094 6.66412 1.08601 11.4472 1.00179Z"
      fill="currentColor"
    />
  ),
})

const DiscountShield: FC<BoxProps> = (props) => (
  <Heading
    as="h3"
    position="absolute"
    bgImage={shieldSrc}
    bgPosition="top"
    bgRepeat="no-repeat"
    color="Base/neutralPrimary"
    size="Header/Secondary"
    w="71px"
    h="112px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    mt={-2}
    {...props}
  />
)
export const DiscountCard: FC<{ discount: string }> = ({ discount }) => (
  <Box
    pl={4}
    pr={6}
    py={8}
    id="discount-card"
    layerStyle="attentionFrame"
    textStyle="Subtitle/AccentPrimary"
    bgColor="Base/accentSecondaryBg"
    borderWidth="1px"
    borderColor="rgba(236, 121, 162, 0.16)"
    alignItems="center"
  >
    <Box position="relative" top={-8} alignSelf="start">
      <DiscountShield position="absolute">
        {discount}%
        <br />
        OFF
      </DiscountShield>
    </Box>
    <Box paddingLeft="86px">
      Additional {discount}%<br />
      discount applied!
    </Box>
  </Box>
)

enum UpsaleAIStep {
  START = 0,
  UPSALE_SKIPPED = 100,
  DISCOUNTED_OFFER = 200,
}

export const UpsaleAI2: FC = () => {
  const [step, setStep] = useState<UpsaleAIStep>(UpsaleAIStep.START)

  const { goNext } = useContext(UpsaleContext)
  const [paymentPageVariant, setPaymentPageVariant] = useState<AlternativeVariantProps["variant"]>()
  const makePurchase = useMakePurchase()
  const { price, price_discounted: discountPrice } = useUpsaleAIScannerData()
  const selectedPrice = step === UpsaleAIStep.START ? price : discountPrice

  const [isLoading, setLoading] = useBoolean()
  const log = useAmplitude()
  useEffect(() => {
    log.upsaleAIScannerView({ step })
  }, [log, step])

  useEffect(() => {
    document.querySelector("html")?.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (step === UpsaleAIStep.DISCOUNTED_OFFER) {
      document.querySelector("html")?.scrollTo(0, 0)
    }
  }, [step])

  const onSuccess = useCallback(() => {
    log.upsaleAIScannerBuySuccess()
    setPaymentPageVariant("aiScanSuccess")
  }, [log, setPaymentPageVariant])

  const onConfirmUpsaleModal = () => {
    setStep(UpsaleAIStep.DISCOUNTED_OFFER)
  }

  const skipUpsale = () => {
    if (step === UpsaleAIStep.START) {
      setStep(UpsaleAIStep.UPSALE_SKIPPED)
    }

    if (step === UpsaleAIStep.DISCOUNTED_OFFER) {
      log.upsaleAIScannerExit()
      setPaymentPageVariant("aiScanFail")
    }
  }

  const pay = async () => {
    try {
      if (!selectedPrice) {
        throw new Error("Unknown Price")
      }
      setLoading.on()
      log.upsaleAIScannerBuy({ priceId: selectedPrice.id })
      const result = await makePurchase(selectedPrice)
      if (result) {
        setLoading.off()
        onSuccess()
        return true
      }
    } catch (error) {
      log.upsaleAIScannerBuyError()
      setPaymentPageVariant("aiScanFail")
    }
    return false
  }

  const handlePayButton = async () => {
    pay()
  }

  if (!price || !discountPrice || !selectedPrice) {
    return null
  }

  return (
    <>
      {!paymentPageVariant && (
        <Box paddingBottom="184px" bgColor="white" paddingX={6}>
          <VFlex gap={10}>
            <Box>
              <Center mb={4} height="56px" as="header">
                <Logo />
              </Center>
              <VFlex gap={4}>
                <Heading size="Header/Primary">Improve your skin care with AI Product Scan</Heading>
              </VFlex>
              {step === UpsaleAIStep.DISCOUNTED_OFFER && (
                <Box mt={6} mb={10}>
                  <DiscountCard discount={discountPrice.subscription_text} />
                </Box>
              )}
              <VFlex mt={6}>
                <WhatIsIncluded price={selectedPrice} />
              </VFlex>
            </Box>
            <VFlex gap={4}>
              <Heading as="h2" size="Header/Secondary">
                Why scan your cosmetics?
              </Heading>
              <Box>
                <BenefitCardList />
              </Box>
            </VFlex>
            <ProductScanReport />
            <FreeWorkbook />
            <WhyLoviPart />
          </VFlex>
          <ButtonSlideUpContainer visible>
            <VFlex gap={2}>
              <Button isLoading={isLoading} onClick={handlePayButton} w="full" variant="action">
                Buy AI Product Scan
              </Button>
              <Button disabled={isLoading} onClick={skipUpsale} w="full" variant="secondary">
                Continue without AI Product Scan
              </Button>
            </VFlex>
          </ButtonSlideUpContainer>
          <Step2Modal
            discount={discountPrice.subscription_text}
            isOpen={step === UpsaleAIStep.UPSALE_SKIPPED}
            onClose={onConfirmUpsaleModal}
          />
        </Box>
      )}
      {paymentPageVariant && <AlternativeVariant variant={paymentPageVariant} onClick={goNext} />}
    </>
  )
}
