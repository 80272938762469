import React, { FC, useMemo } from "react"
import { Icon, As, Divider, Box, Flex, FlexProps } from "@chakra-ui/react"
import { useWellnessProfileData } from "~/store/selectors"
import { formatDate, getProgramEndDate } from "~/utils"
import { PROGRAM_LENGTH_WEEKS } from "~/constants"

import { ReactComponent as Goal } from "./assets/goal.min.svg"
import { ReactComponent as Calendar } from "./assets/calendar.min.svg"
import { SkinIssues } from "~/generated/paywall"

const SKIN_ISSUE_MAP: Map<SkinIssues, string> = new Map([
  [SkinIssues.SKIN_ISSUE_ACNE, "Eliminate acne"],
  [SkinIssues.SKIN_ISSUE_BLACKHEADS, "Reduce blackheads"],
  [SkinIssues.SKIN_ISSUE_DRYNESS, "Reduce dryness"],
  [SkinIssues.SKIN_ISSUE_FACE_SHAPE, "Revert the face shape change"],
  [SkinIssues.SKIN_ISSUE_HYPERSENSITIVITY, "Reduce skin sensitivity"],
  [SkinIssues.SKIN_ISSUE_OILY, "Reduce oilness"],
  [SkinIssues.SKIN_ISSUE_PIGMENTATION, "Remove Pigmentation"],
  [SkinIssues.SKIN_ISSUE_POSTACNE, "Reduce postacne"],
  [SkinIssues.SKIN_ISSUE_PUFFINESS, "De-puff face"],
  [SkinIssues.SKIN_ISSUE_REDNESS, "Reduce redness"],
  [SkinIssues.SKIN_ISSUE_SWELLING, "Reduce swellness"],
  [SkinIssues.SKIN_ISSUE_UNDER_EYE_ZONE, "Treat the under eye zone"],
  [SkinIssues.SKIN_ISSUE_WRINKLES, "Get rid of wrinkles"],
])

const mapSkinIssue = (issue: SkinIssues): string => SKIN_ISSUE_MAP.get(issue) ?? ""

const Item: FC<{ title: string; value: string; icon: As } & FlexProps> = ({
  title,
  value,
  icon,
  ...props
}) => (
  <Flex direction="row" gap={1} alignItems="center" {...props}>
    <Icon as={icon} w="36px" h="36px" />
    <Flex direction="column" justify="flex-start" align="flex-start" gap={0.5}>
      <Box color="Base/baseSecondary" textStyle="Paragraph/Secondary">
        {title}
      </Box>
      <Box color="Base/basePrimary" textStyle="Header/Quaternary">
        {value}
      </Box>
    </Flex>
  </Flex>
)

export const GoalAndDate: FC<{ goal: string; targetDate: Date } & FlexProps> = ({
  goal,
  targetDate,
  ...props
}) => (
  <Flex direction="row" gap={4} alignItems="center" justifyContent="center" w="full" {...props}>
    <Item maxWidth="45%" title="Goal" value={goal} icon={Goal} />
    <Divider orientation="vertical" h="20px" />
    <Item
      maxWidth="45%"
      title="Target date"
      value={formatDate("MONTH_DAY")(targetDate)}
      icon={Calendar}
    />
  </Flex>
)

export const GoalAndDateContainer: FC<FlexProps> = (props) => {
  const profile = useWellnessProfileData()
  const targetDate = useMemo(() => getProgramEndDate(PROGRAM_LENGTH_WEEKS), [])

  return <GoalAndDate goal={mapSkinIssue(profile.goal)} targetDate={targetDate} {...props} />
}
