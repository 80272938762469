import { LevelVariant } from "~/components"
import { SkinIssues, WellnessProfile_WellnessLevel } from "~/generated/paywall"

type BeforeAfterGoal = {
  title: string
  value: string
}
export type BeforeAfterState = {
  image: string
  level: LevelVariant
  goal: BeforeAfterGoal
}
type StringTuple = [string, string]

const mapToValue =
  <FROM, TO>(m: Map<FROM, TO>, defaultOption: FROM, defaultValue: TO) =>
  (from: FROM): TO =>
    m.get(from) ?? m.get(defaultOption) ?? defaultValue

const _mapSkinIssueToGoal = new Map<SkinIssues | "UNRECOGNIZED", [string, string]>([
  [SkinIssues.SKIN_ISSUE_ACNE, ["Acne", "Moderate"]],
  [SkinIssues.SKIN_ISSUE_WRINKLES, ["Wrinkles", "Noticeable"]],
  [SkinIssues.SKIN_ISSUE_SWELLING, ["Swellness", "Noticeable"]],
  [SkinIssues.SKIN_ISSUE_FACE_SHAPE, ["Face shape", "Noticeable"]],
  [SkinIssues.SKIN_ISSUE_PIGMENTATION, ["Pigmentation", "Moderate"]],
  [SkinIssues.SKIN_ISSUE_UNDER_EYE_ZONE, ["Under eye zone", "Moderate"]],
  [SkinIssues.SKIN_ISSUE_REDNESS, ["Redness", "Moderate"]],
  [SkinIssues.SKIN_ISSUE_PUFFINESS, ["De-puff face", "Moderate"]],
  ["UNRECOGNIZED", ["", ""]],
])
const mapSkinIssueToGoal = mapToValue(_mapSkinIssueToGoal, "UNRECOGNIZED", ["", ""] as StringTuple)

const _mapSkinIssueToGoalAfter = new Map<SkinIssues | "UNRECOGNIZED", [string, string]>([
  [SkinIssues.SKIN_ISSUE_ACNE, ["Acne", "Clearer"]],
  [SkinIssues.SKIN_ISSUE_WRINKLES, ["Wrinkles", "Softened"]],
  [SkinIssues.SKIN_ISSUE_SWELLING, ["Swellness", "Reduced"]],
  [SkinIssues.SKIN_ISSUE_FACE_SHAPE, ["Face shape", "Restored"]],
  [SkinIssues.SKIN_ISSUE_PIGMENTATION, ["Pigmentation", "Lightened"]],
  [SkinIssues.SKIN_ISSUE_UNDER_EYE_ZONE, ["Under eye zone", "Improved"]],
  [SkinIssues.SKIN_ISSUE_REDNESS, ["Redness", "Soothed"]],
  [SkinIssues.SKIN_ISSUE_PUFFINESS, ["De-puff face", "Revitalized"]],
  ["UNRECOGNIZED", ["", ""]],
])
const mapSkinIssueToGoalAfter = mapToValue(_mapSkinIssueToGoalAfter, "UNRECOGNIZED", [
  "",
  "",
] as StringTuple)

const _mapSkinIssueToImage = new Map<SkinIssues | "UNRECOGNIZED", string>([
  [SkinIssues.SKIN_ISSUE_ACNE, "https://storage.pora.ai/d66f733ff75bd0d63716c12fa8004da0.webp"],
  [SkinIssues.SKIN_ISSUE_REDNESS, "https://storage.pora.ai/d6ce4fb98bdddd36b2408fa12d00e272.webp"],
  [
    SkinIssues.SKIN_ISSUE_FACE_SHAPE,
    "https://storage.pora.ai/eacc445da5e5d946cc7c8c15a9b69755.webp",
  ],

  [
    SkinIssues.SKIN_ISSUE_PIGMENTATION,
    "https://storage.pora.ai/2a74be8edc53cc85ea21b24b61c09708.webp",
  ],
  [SkinIssues.SKIN_ISSUE_SWELLING, "https://storage.pora.ai/94df50a0ce9df8aa9cb4caffedd4eaf2.webp"],
  [SkinIssues.SKIN_ISSUE_WRINKLES, "https://storage.pora.ai/d5a275aaad686917a26ec8e7cd196aff.webp"],
  [
    SkinIssues.SKIN_ISSUE_PUFFINESS,
    "https://storage.pora.ai/7d8684f921afae1c656af1f596ac07cb.webp",
  ],
  [
    SkinIssues.SKIN_ISSUE_UNDER_EYE_ZONE,
    "https://storage.pora.ai/aee386120e5ddfa57f9d7394e257ddfb.webp",
  ],
  ["UNRECOGNIZED", ""],
])
const mapSkinIssueToImage = mapToValue(_mapSkinIssueToImage, "UNRECOGNIZED", "")

const _mapSkinIssueToImageAfter = new Map<SkinIssues | "UNRECOGNIZED", string>([
  [SkinIssues.SKIN_ISSUE_ACNE, "https://storage.pora.ai/f4f52935b3ae78a6e980c87b3a1f343e.webp"],
  [SkinIssues.SKIN_ISSUE_REDNESS, "https://storage.pora.ai/28e652a0cdc007f768dca5e4e5003684.webp"],
  [
    SkinIssues.SKIN_ISSUE_FACE_SHAPE,
    "https://storage.pora.ai/7c25e67fe067f7cb35c9e7d5cabf509e.webp",
  ],
  [
    SkinIssues.SKIN_ISSUE_PIGMENTATION,
    "https://storage.pora.ai/e1a5b8b9bbc8d88c05c76c9b93bd8109.webp",
  ],

  [SkinIssues.SKIN_ISSUE_SWELLING, "https://storage.pora.ai/70c6d09077df40e084ec3e11c0183712.webp"],
  [SkinIssues.SKIN_ISSUE_WRINKLES, "https://storage.pora.ai/f7dd2d67d66a89f1750cc5a6a7fb8b39.webp"],
  [
    SkinIssues.SKIN_ISSUE_PUFFINESS,
    "https://storage.pora.ai/2e9091252dac3e087b4b8f60fd77e479.webp",
  ],
  [
    SkinIssues.SKIN_ISSUE_UNDER_EYE_ZONE,
    "https://storage.pora.ai/af00336cdc7f525a034ee8b5ed1b77fd.webp",
  ],
  ["UNRECOGNIZED", ""],
])
const mapSkinIssueToImageAfter = mapToValue(_mapSkinIssueToImageAfter, "UNRECOGNIZED", "")

const _mapLevelToVariant = new Map<WellnessProfile_WellnessLevel | "UNRECOGNIZED", LevelVariant>([
  [WellnessProfile_WellnessLevel.LOW, "low"],
  [WellnessProfile_WellnessLevel.MODERATE, "moderate"],
  [WellnessProfile_WellnessLevel.GOOD, "good"],
  ["UNRECOGNIZED", "good"],
])
const mapLevelToVariant = mapToValue(_mapLevelToVariant, "UNRECOGNIZED", "good")

const _mapLevelToVariantAfter = new Map<
  WellnessProfile_WellnessLevel | "UNRECOGNIZED",
  LevelVariant
>([
  [WellnessProfile_WellnessLevel.LOW, "good2"],
  [WellnessProfile_WellnessLevel.MODERATE, "good2"],
  [WellnessProfile_WellnessLevel.GOOD, "excellent"],
  ["UNRECOGNIZED", "excellent"],
])
const mapLevelToVariantAfter = mapToValue(_mapLevelToVariantAfter, "UNRECOGNIZED", "good")

export const getBeforeState = (
  goal: SkinIssues,
  level: WellnessProfile_WellnessLevel
): BeforeAfterState => {
  const [title, value] = mapSkinIssueToGoal(goal)
  return {
    goal: { title, value },
    image: mapSkinIssueToImage(goal),
    level: mapLevelToVariant(level),
  }
}

export const getAfterState = (
  goal: SkinIssues,
  level: WellnessProfile_WellnessLevel
): BeforeAfterState => {
  const [title, value] = mapSkinIssueToGoalAfter(goal)
  return {
    goal: { title, value },
    image: mapSkinIssueToImageAfter(goal),
    level: mapLevelToVariantAfter(level),
  }
}
