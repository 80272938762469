/* eslint-disable */

export const protobufPackage = "recurly";

/** Subscription represents a subscription with its plan code and add-ons */
export interface Subscription {
  plan_code: string;
}

/** PurchaseRequest represents the structure of the purchase request */
export interface PurchaseRequest {
  user_id: string;
  subscription: Subscription | undefined;
  currency: string;
}

/** PurchaseResponse represents the response for a purchase request */
export interface PurchaseResponse {
  status: string;
  info: string;
}

export interface PaymentIntentRequest {
  user_id: string;
  token: string;
}

export interface PaymentIntentResponse {
  status: string;
  info: string;
}

function createBaseSubscription(): Subscription {
  return { plan_code: "" };
}

export const Subscription = {
  fromJSON(object: any): Subscription {
    return { plan_code: isSet(object.plan_code) ? String(object.plan_code) : "" };
  },

  toJSON(message: Subscription): unknown {
    const obj: any = {};
    message.plan_code !== undefined && (obj.plan_code = message.plan_code);
    return obj;
  },

  create<I extends Exact<DeepPartial<Subscription>, I>>(base?: I): Subscription {
    return Subscription.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Subscription>, I>>(object: I): Subscription {
    const message = createBaseSubscription();
    message.plan_code = object.plan_code ?? "";
    return message;
  },
};

function createBasePurchaseRequest(): PurchaseRequest {
  return { user_id: "", subscription: undefined, currency: "" };
}

export const PurchaseRequest = {
  fromJSON(object: any): PurchaseRequest {
    return {
      user_id: isSet(object.user_id) ? String(object.user_id) : "",
      subscription: isSet(object.subscription) ? Subscription.fromJSON(object.subscription) : undefined,
      currency: isSet(object.currency) ? String(object.currency) : "",
    };
  },

  toJSON(message: PurchaseRequest): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = message.user_id);
    message.subscription !== undefined &&
      (obj.subscription = message.subscription ? Subscription.toJSON(message.subscription) : undefined);
    message.currency !== undefined && (obj.currency = message.currency);
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchaseRequest>, I>>(base?: I): PurchaseRequest {
    return PurchaseRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchaseRequest>, I>>(object: I): PurchaseRequest {
    const message = createBasePurchaseRequest();
    message.user_id = object.user_id ?? "";
    message.subscription = (object.subscription !== undefined && object.subscription !== null)
      ? Subscription.fromPartial(object.subscription)
      : undefined;
    message.currency = object.currency ?? "";
    return message;
  },
};

function createBasePurchaseResponse(): PurchaseResponse {
  return { status: "", info: "" };
}

export const PurchaseResponse = {
  fromJSON(object: any): PurchaseResponse {
    return {
      status: isSet(object.status) ? String(object.status) : "",
      info: isSet(object.info) ? String(object.info) : "",
    };
  },

  toJSON(message: PurchaseResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status);
    message.info !== undefined && (obj.info = message.info);
    return obj;
  },

  create<I extends Exact<DeepPartial<PurchaseResponse>, I>>(base?: I): PurchaseResponse {
    return PurchaseResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PurchaseResponse>, I>>(object: I): PurchaseResponse {
    const message = createBasePurchaseResponse();
    message.status = object.status ?? "";
    message.info = object.info ?? "";
    return message;
  },
};

function createBasePaymentIntentRequest(): PaymentIntentRequest {
  return { user_id: "", token: "" };
}

export const PaymentIntentRequest = {
  fromJSON(object: any): PaymentIntentRequest {
    return {
      user_id: isSet(object.user_id) ? String(object.user_id) : "",
      token: isSet(object.token) ? String(object.token) : "",
    };
  },

  toJSON(message: PaymentIntentRequest): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = message.user_id);
    message.token !== undefined && (obj.token = message.token);
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentIntentRequest>, I>>(base?: I): PaymentIntentRequest {
    return PaymentIntentRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PaymentIntentRequest>, I>>(object: I): PaymentIntentRequest {
    const message = createBasePaymentIntentRequest();
    message.user_id = object.user_id ?? "";
    message.token = object.token ?? "";
    return message;
  },
};

function createBasePaymentIntentResponse(): PaymentIntentResponse {
  return { status: "", info: "" };
}

export const PaymentIntentResponse = {
  fromJSON(object: any): PaymentIntentResponse {
    return {
      status: isSet(object.status) ? String(object.status) : "",
      info: isSet(object.info) ? String(object.info) : "",
    };
  },

  toJSON(message: PaymentIntentResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = message.status);
    message.info !== undefined && (obj.info = message.info);
    return obj;
  },

  create<I extends Exact<DeepPartial<PaymentIntentResponse>, I>>(base?: I): PaymentIntentResponse {
    return PaymentIntentResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PaymentIntentResponse>, I>>(object: I): PaymentIntentResponse {
    const message = createBasePaymentIntentResponse();
    message.status = object.status ?? "";
    message.info = object.info ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
