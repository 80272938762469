import React, { FC } from "react"
import { Box, Flex, FlexProps, Image, Text, TextProps } from "@chakra-ui/react"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { VFlex } from "~/components"

import photo from "./assets/nadia.webp?url"
import assistant from "./assets/assistant.webp?url"
import sign from "./assets/sign.webp?url"
import gift from "./assets/gift.webp?url"
import bubbles from "./assets/bubbles.svg?url"

const BubbleBox: FC<FlexProps> = (props) => (
  <Flex>
    <VFlex
      paddingX={3}
      paddingY="11px"
      backgroundColor="white"
      borderRadius="24px"
      shadow="LoviShort"
      {...props}
    />
    <Box
      pos="relative"
      left="-12px"
      top="-3px"
      minWidth="20px"
      minHeight="20px"
      backgroundImage={bubbles}
      backgroundRepeat="no-repeat"
    />
  </Flex>
)

const B: FC<TextProps> = (props) => <Text as="span" textStyle="Subtitle/AccentPrimary" {...props} />

const StaticCitationNadya: FC = () => (
  <Box mb={4} bg={`bottom right / 209px 302px no-repeat url(${photo})`} w="full">
    <VFlex paddingY="44px" paddingLeft={6} paddingRight="72px" gap={4}>
      <VFlex gap={1}>
        <Box fontFamily="heading" textStyle="Header/Secondary">
          Nadia Kapleva
        </Box>
        <Box textStyle="Paragraph/Secondary">Lóvi Medical Director, PhD</Box>
      </VFlex>
      <BubbleBox h="146px" justifyContent="center" gap={2} textStyle="Paragraph/AccentPrimary">
        <Box width="255px">
          Lóvi knows what products will fit your skin <B>best out of 500K+ products</B> in our
          database
        </Box>
        <Image src={sign} alt="" h="44px" w="117px" />
      </BubbleBox>
    </VFlex>
  </Box>
)

const StaticCitationAssistant: FC = () => (
  <Box bg={`center right / 209px 254px no-repeat url(${assistant})`} minH="209px">
    <VFlex paddingY="40px" paddingLeft={6} paddingRight="72px" gap={6}>
      <Flex alignItems="center" gap={2} maxW="301px">
        <Image src={gift} alt="" w="40px" h="40px" />
        <Box fontFamily="heading" textStyle="Header/Secondary">
          Your Lifetime
          <br />
          Benefit:
        </Box>
      </Flex>
      <BubbleBox justifyContent="center" textStyle="Paragraph/AccentPrimary">
        <Box>
          Get help from <B>science-backed</B> skincare consultations with the Lóvi AI Assistant
        </Box>
      </BubbleBox>
    </VFlex>
  </Box>
)

const StaticCitationAssistant2: FC = () => (
  <Box bg={`center right / 209px 254px no-repeat url(${assistant})`} minH="209px">
    <VFlex paddingY="40px" paddingLeft={6} paddingRight="72px" gap={6}>
      <Flex alignItems="center" gap={2} maxW="301px">
        <Image src={gift} alt="" w="40px" h="40px" />
        <Box fontFamily="heading" textStyle="Header/Secondary">
          Your Lifetime
          <br />
          Benefit:
        </Box>
      </Flex>
      <BubbleBox justifyContent="center" textStyle="Paragraph/AccentPrimary">
        <Box>
          Get <B>unlimited face scans</B>&nbsp;with a medically trained Face Scanner!
        </Box>
      </BubbleBox>
    </VFlex>
  </Box>
)

export const StaticCitationNadyaBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" paddingTop={6} {...props}>
    <StaticCitationNadya />
  </BlockWrapper>
)

export const StaticCitationAssistantBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" {...props}>
    <StaticCitationAssistant />
  </BlockWrapper>
)

export const StaticCitationAssistant2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" {...props}>
    <StaticCitationAssistant2 />
  </BlockWrapper>
)
