import React, { FC } from "react"
import { Box, Button, ButtonProps, keyframes } from "@chakra-ui/react"

const dotsKeyframes = keyframes`
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: .25em 0 0 rgba(0, 0, 0, 0), .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: .25em 0 0 white, .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: .25em 0 0 white, .5em 0 0 white;
  }
}`
const dotsAnimation = `${dotsKeyframes} 1s steps(5, end) infinite`

const Dots: FC = () => (
  <Box
    as="span"
    sx={{
      animation: dotsAnimation,
      fontSize: "36px",
      marginLeft: "-.5em",
    }}
  >
    ·
  </Box>
)

export const ApplePayButton: FC<ButtonProps> = (props) => (
  <Button variant="applepay" spinner={<Dots />} {...props} />
)
