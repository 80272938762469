import { createAction } from "@reduxjs/toolkit"
import { logError } from "~/utils/logError"
import { getCountryCode, getPaywallData } from "../api/api"
import type { PaywallV2 } from "../generated/paywall"
import type { CountryCode } from "../types"
import type { AppDispatch, PAYMENT_STATUSES_VALUES, PAYMENT_PROVIDER } from "."

export const setLoadingPage = createAction<boolean>("setLoadingPage")

export const setPaywallData = createAction<PaywallV2 | undefined>("setPaywallData")

export const setPrice = createAction<string>("setPrice")

export const setCountryCode = createAction<CountryCode>("setCountryCode")

export const paymentFormPopupSetIsOpen = createAction<boolean>("paymentFormPopup.SetIsOpen")

export const upsaleSubscriptionOnExitSetPopupOpen = createAction<boolean>(
  "upsaleSubscriptionOnExit.SetOpenPopup"
)
export const upsaleSubscriptionOnExitSetActive = createAction<boolean>(
  "upsaleSubscriptionOnExit.SetActivate"
)

export const updatePaymentStatus = createAction<{
  status: PAYMENT_STATUSES_VALUES
  provider: PAYMENT_PROVIDER
}>("update_payment_status")

export const downloadData = (userId: UserId, interviewVariant: string) => {
  return (dispatch: AppDispatch) => {
    dispatch(setLoadingPage(true))
    getCountryCode()
      .then((countryCode) => {
        dispatch(setCountryCode(countryCode))
        return getPaywallData(userId, interviewVariant, countryCode)
      })
      .then((data) => {
        dispatch(setPaywallData(data))
        const prices = data.price?.priceses ?? []
        const selectedPrice = prices.find((price) => price.popular) ?? prices[0]
        const selectedPriceId = selectedPrice?.id
        if (selectedPriceId) {
          dispatch(setPrice(selectedPriceId))
        }
      })
      .catch((e) => {
        logError(String(e))
        /* TODO move redirect to separate function */
        window.location.replace("https://interview.lovi.care")
      })
      .finally(() => {
        dispatch(setLoadingPage(false))
      })
  }
}
