import React from "react"
import { ProgramBenefitsList } from "./ProgramBenefitsList"

const benefits = [
  { text: "Quick and visible results" },
  { text: "Routine tailored to your goals" },
  { text: "Product picks approved by MDs" },
  { text: "Easy-to-follow face massage guides" },
  { text: "Dermatologist support" },
]

export const ProgramBenefitsPrePaywallContainer = () => <ProgramBenefitsList benefits={benefits} />
