import React, { FC, useCallback, useMemo } from "react"
import { Flex } from "@chakra-ui/react"

import { usePrices, useSelectedPrice } from "~/store/selectors"
import { noop } from "~/utils"
import { setPrice } from "~/store/actions"
import { useDispatch } from "~/utils/redux"
import {
  PriceContentV2_PriceItem,
  PriceContentV2_PriceItem_PriceItemStyle,
} from "~/generated/paywall"

import type { Price, PriceId, PriceSelectVariants } from "./types"
import { PriceSelectItem } from "./PriceSelectItem"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

export const transformPriceItem = ({
  id = "",
  title = "",
  previous_price: previousPrice = "",
  price_per_day: pricePerDay = "",
  previous_price_per_day: previousPricePerDay = "",
  popular: isPopular = false,
  trial_period: trialPeriod,
  trial_price_cents: trialPrice = Number.NaN,
  subscription_text: subscriptionText = "",
  economy_price: economyPrice = "",
  economy_percent: economyPercent = "",
  subscription_price_cents: price,
  subscription_period_length,
  subscription_period_unit,
  trial_period_unit,
  trial_period_length,
  badge,
  tags,
  style,
  currency_code,
}: PriceContentV2_PriceItem): Price => ({
  id,
  title,
  price,
  pricePerDay,
  previousPrice,
  previousPricePerDay,
  isPopular,
  subscriptionText,
  trialPrice,
  trialPeriod,
  economyPrice,
  economyPercent,
  subscription_period_length,
  subscription_period_unit,
  badge,
  tags,
  isDisabled: style === PriceContentV2_PriceItem_PriceItemStyle.STYLE_SOLD_OUT,
  currency_code,
  trial_period_length,
  trial_period_unit,
})

export const usePriceSelect = (onChange: (id: PriceId) => void = noop) => {
  const dispatch = useDispatch()
  const origPrices = usePrices()
  const prices = useMemo(() => origPrices.map(transformPriceItem), [origPrices])
  const selectedPrice = useSelectedPrice()
  const selectedPriceId =
    selectedPrice?.id ?? origPrices.find(({ default: _default }) => _default)?.id ?? "UNKNOWN"
  const log = useAmplitude()

  const _onChange = useCallback(
    (priceId: PriceId) => {
      dispatch(setPrice(priceId))
      log.clickPrice({ priceId })
      onChange(priceId)
    },
    [onChange, dispatch, log]
  )

  return { prices, selectedPriceId, selectedPrice, onChange: _onChange }
}

export const PriceSelectRadio: FC<{
  variant: PriceSelectVariants
  prices: Price[]
  selectedPriceId: PriceId
  onChange: (id: PriceId) => void
}> = ({ variant = 1, prices, selectedPriceId, onChange }) => {
  const onChangeFn = useCallback(
    (id: PriceId) => () => {
      onChange(id)
    },
    [onChange]
  )

  const gap = useMemo(() => (variant >= 4 && variant <= 6 ? 3 : 4), [variant])

  return (
    <Flex direction="column" gap={gap} w="full">
      {prices.map((price) => (
        <PriceSelectItem
          variant={variant}
          key={price.id + price.title}
          isSelected={price.id === selectedPriceId}
          onClick={onChangeFn(price.id)}
          {...price}
        />
      ))}
    </Flex>
  )
}

export const PriceSelectRadioContainer: FC<{
  variant: PriceSelectVariants
  onChange?: (id: PriceId) => void
}> = ({ variant, onChange = noop }) => {
  const { prices, selectedPriceId, onChange: _onChange } = usePriceSelect(onChange)
  return (
    <PriceSelectRadio
      variant={variant}
      prices={prices}
      selectedPriceId={selectedPriceId}
      onChange={_onChange}
    />
  )
}
