export const spliceCircularArray =
  <T>(arr: T[], len: number) =>
  (start: number): T[] => {
    let result = arr.slice(start, start + len)
    while (result.length < len) {
      result = result.concat(arr.slice(0, len - result.length))
    }

    return result
  }
