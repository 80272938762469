import React, { FC } from "react"
import {
  AspectRatio,
  Box,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"

import { ReactComponent as IconDatabase } from "./assets/icon-database.svg"
import { ReactComponent as IconGuide } from "./assets/icon-guide.svg"
import { ReactComponent as IconWorkbook } from "./assets/icon-workbook.svg"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { formatLengthWithUnit, formatPrice } from "~/utils"
import { PreloadedStatic } from "~/utils/static_images_to_preload"

const LIST = [
  {
    title: "Comprehensive & Unbiased Product Database",
    icon: IconDatabase,
  },
  {
    title: "Expert Application Guide",
    icon: IconGuide,
  },
  {
    title: "Exclusive Bonus - Intensive Skincare Mastery Workbook",
    icon: IconWorkbook,
  },
]

export const WhatIsIncluded: FC<{ price: PriceContentV2_PriceItem }> = ({ price }) => (
  <VFlex px={6} paddingTop={6} paddingBottom={4} layerStyle="attentionFrame">
    <Heading size="Header/Secondary" textAlign="center" marginBottom={5}>
      Review how your routine fits your skin
    </Heading>
    <Box mb={5} mx={-6}>
      <AspectRatio ratio={741 / 824}>
        <video muted playsInline loop autoPlay src={PreloadedStatic.aiScannerV2Video}></video>
      </AspectRatio>
    </Box>
    <List spacing={3} textStyle="Paragraph/Primary" marginBottom={5}>
      {LIST.map(({ title, icon }, i) => (
        <ListItem color="Base/basePrimary" key={i} display="flex" alignItems="center">
          <ListIcon as={icon} color="Base/accentPrimary" fontSize="40px" marginRight={3} />
          {title}
        </ListItem>
      ))}
    </List>
    <Heading
      as="h3"
      size="Header/Tertiary"
      textAlign="center"
      color="Base/accentPrimary"
      marginBottom={1}
    >
      Just {formatPrice(price.currency_code, parseFloat(price.economy_price))} /{" "}
      {formatLengthWithUnit(price.subscription_period_unit, price.subscription_period_length)}
    </Heading>
    <Text
      textStyle="Paragraph/Secondary"
      color="Base/baseSecondary"
      textAlign="center"
      marginBottom={2}
    >
      Original price was {formatPrice(price.currency_code, parseFloat(price.previous_price))} —{" "}
      <Box as="span" color="Base/accentPrimary">
        save {price.economy_percent}%!
      </Box>
    </Text>
    <Divider marginBottom={3} borderColor="Base/baseDisabled" opacity={0.3} />
    <Text textStyle="Paragraph/Secondary" color="Base/baseSecondary" textAlign="center">
      You can keep this workbook even if you decide Lóvi isn’t for you.
    </Text>
  </VFlex>
)
