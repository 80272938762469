import { RefObject } from "react"

export const clearContentByRef = (src: RefObject<HTMLElement | null>) => {
  if (src.current) {
    src.current.childNodes.forEach((node) => {
      src.current?.removeChild(node)
    })
  }
}

export const moveDomElementsContent = (
  src: HTMLElement | undefined | null,
  dst: HTMLElement | undefined | null
) => {
  if (src && dst) {
    const nodes: ChildNode[] = []
    src.childNodes.forEach((node) => {
      nodes.push(node)
    })

    dst.replaceChildren(...nodes)
  }
}

export const moveContentByRef = (
  src: RefObject<HTMLElement | null>,
  dst: RefObject<HTMLElement | null>
) => {
  if (src.current && dst.current) {
    moveDomElementsContent(src.current, dst.current)
  }
}
