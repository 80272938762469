import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react"
import { useInView } from "react-intersection-observer"
import { DATA } from "./data"

type CommentProps = {
  image: string
  name: string
  issue: string
  text: ReactNode
  onInView: (inView: boolean) => void
}

export const Comment: FC<CommentProps> = ({ image, name, issue, text, onInView }) => {
  const { ref } = useInView({
    threshold: [0.9],
    delay: 500,
    onChange: onInView,
    fallbackInView: false,
  })

  return (
    <Flex
      ref={ref}
      className="CommentItem"
      bgColor="white"
      direction="column"
      paddingX={2}
      paddingTop={2}
      paddingBottom={4}
      justify="flex-start"
      align="center"
      gap={3}
      borderRadius="brand32"
      minWidth="263px"
      scrollSnapAlign="center"
      borderWidth="1px"
      borderColor="Base/neutralSecondary"
    >
      <Center borderRadius="brand24" w="247px" h="186px">
        <Image w="100%" h="186px" objectFit="contain" borderRadius="brand24" src={image} />
      </Center>
      <Flex
        direction="column"
        paddingX={2}
        justify="flex-start"
        align="flex-start"
        alignSelf="stretch"
        gap={1}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="flex-start"
          alignSelf="stretch"
          textStyle="Subtitle/Tertiary"
          color="Base/basePrimary"
        >
          <Text>{name}</Text>
          <Text flex="1" textAlign="end">
            {issue}
          </Text>
        </Flex>
        <Text
          textStyle="Paragraph/Secondary"
          color="Base/basePrimary"
          alignSelf="stretch"
          noOfLines={4}
        >
          {text}
        </Text>
      </Flex>
    </Flex>
  )
}

const IndicatorItem: FC<{ isActive?: boolean }> = ({ isActive = false }) => (
  <Box
    w="20px"
    h={1}
    borderRadius="full"
    bgColor={isActive ? "Base/accentPrimary" : "Base/neutralActive"}
    transition={`background-color 0.5s ease-in-out`}
  />
)

const Indicator: FC<{ count: number; selectedIdx: number }> = ({ count, selectedIdx }) => {
  const arr = useMemo(() => [...Array(count)], [count])
  return (
    <Flex direction="row" gap={1.5} justifyContent="center">
      {arr.map((_, idx) => (
        <IndicatorItem key={idx} isActive={idx === selectedIdx} />
      ))}
    </Flex>
  )
}
export const BeforeAfterReviewsCarousel: FC<{ data?: CommentProps[]; scrollInterval?: number }> = ({
  data = DATA,
  scrollInterval = 5,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [selectedIdx, setSelectedIdx] = useState(0)
  const onInView = useCallback(
    (idx: number) => (inview: boolean) => {
      if (inview && selectedIdx !== idx) {
        setSelectedIdx(idx)
      }
    },
    [setSelectedIdx, selectedIdx]
  )

  const scrollToNext = useCallback(() => {
    const nextIdx = selectedIdx === data.length - 1 ? 0 : selectedIdx + 1
    ref.current?.scrollTo({
      left: nextIdx * (document.querySelector(".CommentItem")?.clientWidth ?? 0),
      behavior: "smooth",
    })
    setSelectedIdx(nextIdx)
  }, [selectedIdx, ref])

  useEffect(() => {
    const interval = setInterval(() => {
      scrollToNext()
    }, scrollInterval * 1000)
    return () => clearInterval(interval)
  }, [scrollInterval, scrollToNext])

  return (
    <Flex direction="column" gap={4} w="full">
      <Flex
        w="full"
        direction="row"
        gap={2}
        overflowX="auto"
        scrollSnapType="x mandatory"
        paddingX={6}
        sx={{
          "&::-webkit-scrollbar": { display: "none" },
        }}
        ref={ref}
      >
        {data.map((comment, idx) => (
          <Comment {...comment} key={idx} onInView={onInView(idx)} />
        ))}
      </Flex>
      <Indicator count={data.length} selectedIdx={selectedIdx} />
    </Flex>
  )
}
