import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import { StarIcon } from "~/pages/Main/ReviewsBetterMe/Icons"

type Review = {
  title: string
  date: string
  author: string
  text: string
}

const ReviewsBetterMeItem = ({ title, date, author, text }: Review) => {
  const [collapsed, setCollapsed] = useState(true)
  const handleShowMoreClick = () => setCollapsed(false)

  return (
    <Flex flexDirection="column" p={4} borderRadius="2xl" bgColor="Base/neutralSecondary">
      <Flex w="full" justifyContent="space-between">
        <Heading as="h5" variant="text" size="MediumSubtitleSecondary">
          {title}
        </Heading>
        <Box textStyle="RegularParagraphSecondary" textColor="Base/baseSecondary">
          {date}
        </Box>
      </Flex>
      <Flex w="full" justifyContent="space-between" alignItems="center" mb={3}>
        <Box>
          <StarIcon fontSize={16} />
          <StarIcon fontSize={16} />
          <StarIcon fontSize={16} />
          <StarIcon fontSize={16} />
          <StarIcon fontSize={16} />
        </Box>
        <Box textStyle="RegularParagraphSecondary" textColor="Base/baseSecondary">
          {author}
        </Box>
      </Flex>
      <Box pos="relative">
        <Text textStyle="RegularParagraphSecondary" noOfLines={collapsed ? 4 : undefined}>
          {text}
        </Text>
        {collapsed && (
          <Box
            m={-1}
            p={1}
            onClick={handleShowMoreClick}
            cursor="pointer"
            as="span"
            pos="absolute"
            right={0}
            bottom={0}
            textStyle="MediumSubtitleTertiary"
            textColor="Base/accentPrimary"
          >
            <Box
              h="full"
              w="calc(100% + 33px)"
              background="linear-gradient(270deg, #F2F1F7 73.19%, rgba(242, 241, 247, 0) 100%);"
              pos="absolute"
              right={0}
              bottom={0}
            />
            <Box pos="relative">See more</Box>
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export const ReviewsBetterMe = ({ header, reviews }: { header: string; reviews: Review[] }) => {
  return (
    <>
      <Heading size="MediumHeaderSecondary" textAlign="center" marginBottom={4}>
        {header}
      </Heading>
      <Flex flexDirection="column" gap={3}>
        {reviews.map((review, i) => (
          <ReviewsBetterMeItem {...review} key={i} />
        ))}
      </Flex>
    </>
  )
}
