/* eslint-disable */

export const protobufPackage = "networking";

/** Response data shared by the other response types */
export enum ResponseStatus {
  /** RESPONSE_STATUS_NONE - So that every meaningful status is not zero, hence exported. */
  RESPONSE_STATUS_NONE = "RESPONSE_STATUS_NONE",
  SUCCESS = "SUCCESS",
  ERROR_RETRYABLE = "ERROR_RETRYABLE",
  ERROR_FATAL = "ERROR_FATAL",
  ERROR_VERSION = "ERROR_VERSION",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function responseStatusFromJSON(object: any): ResponseStatus {
  switch (object) {
    case 0:
    case "RESPONSE_STATUS_NONE":
      return ResponseStatus.RESPONSE_STATUS_NONE;
    case 1:
    case "SUCCESS":
      return ResponseStatus.SUCCESS;
    case 2:
    case "ERROR_RETRYABLE":
      return ResponseStatus.ERROR_RETRYABLE;
    case 3:
    case "ERROR_FATAL":
      return ResponseStatus.ERROR_FATAL;
    case 4:
    case "ERROR_VERSION":
      return ResponseStatus.ERROR_VERSION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ResponseStatus.UNRECOGNIZED;
  }
}

export function responseStatusToJSON(object: ResponseStatus): string {
  switch (object) {
    case ResponseStatus.RESPONSE_STATUS_NONE:
      return "RESPONSE_STATUS_NONE";
    case ResponseStatus.SUCCESS:
      return "SUCCESS";
    case ResponseStatus.ERROR_RETRYABLE:
      return "ERROR_RETRYABLE";
    case ResponseStatus.ERROR_FATAL:
      return "ERROR_FATAL";
    case ResponseStatus.ERROR_VERSION:
      return "ERROR_VERSION";
    case ResponseStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Request data shared by all the other request types */
export interface Request {
  userID: string;
  client: string;
  params: { [key: string]: string };
}

export interface Request_ParamsEntry {
  key: string;
  value: string;
}

export interface Response {
  status: ResponseStatus;
  message: string;
}

export interface ParameterValues {
  values: string[];
}

/** Subscription purchase webhook request schema */
export interface SubscriptionPurchasedRequest {
  request: Request | undefined;
  original_transaction_id: string;
}

/** Subscription check request schema */
export interface SubscriptionCheckRequest {
  user_id: string;
}

/** Subscription check response schema */
export interface SubscriptionCheckResponse {
  has_subscription: boolean;
}

/** Subscription check response schema */
export interface SavePaymentDataRequest {
  email: string;
  provider: string;
  payment_id: string;
  user_id: string;
}

function createBaseRequest(): Request {
  return { userID: "", client: "", params: {} };
}

export const Request = {
  fromJSON(object: any): Request {
    return {
      userID: isSet(object.userID) ? String(object.userID) : "",
      client: isSet(object.client) ? String(object.client) : "",
      params: isObject(object.params)
        ? Object.entries(object.params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: Request): unknown {
    const obj: any = {};
    message.userID !== undefined && (obj.userID = message.userID);
    message.client !== undefined && (obj.client = message.client);
    obj.params = {};
    if (message.params) {
      Object.entries(message.params).forEach(([k, v]) => {
        obj.params[k] = v;
      });
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Request>, I>>(base?: I): Request {
    return Request.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Request>, I>>(object: I): Request {
    const message = createBaseRequest();
    message.userID = object.userID ?? "";
    message.client = object.client ?? "";
    message.params = Object.entries(object.params ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseRequest_ParamsEntry(): Request_ParamsEntry {
  return { key: "", value: "" };
}

export const Request_ParamsEntry = {
  fromJSON(object: any): Request_ParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: Request_ParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<Request_ParamsEntry>, I>>(base?: I): Request_ParamsEntry {
    return Request_ParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Request_ParamsEntry>, I>>(object: I): Request_ParamsEntry {
    const message = createBaseRequest_ParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseResponse(): Response {
  return { status: ResponseStatus.RESPONSE_STATUS_NONE, message: "" };
}

export const Response = {
  fromJSON(object: any): Response {
    return {
      status: isSet(object.status) ? responseStatusFromJSON(object.status) : ResponseStatus.RESPONSE_STATUS_NONE,
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: Response): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = responseStatusToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  create<I extends Exact<DeepPartial<Response>, I>>(base?: I): Response {
    return Response.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Response>, I>>(object: I): Response {
    const message = createBaseResponse();
    message.status = object.status ?? ResponseStatus.RESPONSE_STATUS_NONE;
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseParameterValues(): ParameterValues {
  return { values: [] };
}

export const ParameterValues = {
  fromJSON(object: any): ParameterValues {
    return { values: Array.isArray(object?.values) ? object.values.map((e: any) => String(e)) : [] };
  },

  toJSON(message: ParameterValues): unknown {
    const obj: any = {};
    if (message.values) {
      obj.values = message.values.map((e) => e);
    } else {
      obj.values = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ParameterValues>, I>>(base?: I): ParameterValues {
    return ParameterValues.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ParameterValues>, I>>(object: I): ParameterValues {
    const message = createBaseParameterValues();
    message.values = object.values?.map((e) => e) || [];
    return message;
  },
};

function createBaseSubscriptionPurchasedRequest(): SubscriptionPurchasedRequest {
  return { request: undefined, original_transaction_id: "" };
}

export const SubscriptionPurchasedRequest = {
  fromJSON(object: any): SubscriptionPurchasedRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      original_transaction_id: isSet(object.original_transaction_id) ? String(object.original_transaction_id) : "",
    };
  },

  toJSON(message: SubscriptionPurchasedRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.original_transaction_id !== undefined && (obj.original_transaction_id = message.original_transaction_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionPurchasedRequest>, I>>(base?: I): SubscriptionPurchasedRequest {
    return SubscriptionPurchasedRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionPurchasedRequest>, I>>(object: I): SubscriptionPurchasedRequest {
    const message = createBaseSubscriptionPurchasedRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.original_transaction_id = object.original_transaction_id ?? "";
    return message;
  },
};

function createBaseSubscriptionCheckRequest(): SubscriptionCheckRequest {
  return { user_id: "" };
}

export const SubscriptionCheckRequest = {
  fromJSON(object: any): SubscriptionCheckRequest {
    return { user_id: isSet(object.user_id) ? String(object.user_id) : "" };
  },

  toJSON(message: SubscriptionCheckRequest): unknown {
    const obj: any = {};
    message.user_id !== undefined && (obj.user_id = message.user_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionCheckRequest>, I>>(base?: I): SubscriptionCheckRequest {
    return SubscriptionCheckRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionCheckRequest>, I>>(object: I): SubscriptionCheckRequest {
    const message = createBaseSubscriptionCheckRequest();
    message.user_id = object.user_id ?? "";
    return message;
  },
};

function createBaseSubscriptionCheckResponse(): SubscriptionCheckResponse {
  return { has_subscription: false };
}

export const SubscriptionCheckResponse = {
  fromJSON(object: any): SubscriptionCheckResponse {
    return { has_subscription: isSet(object.has_subscription) ? Boolean(object.has_subscription) : false };
  },

  toJSON(message: SubscriptionCheckResponse): unknown {
    const obj: any = {};
    message.has_subscription !== undefined && (obj.has_subscription = message.has_subscription);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionCheckResponse>, I>>(base?: I): SubscriptionCheckResponse {
    return SubscriptionCheckResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionCheckResponse>, I>>(object: I): SubscriptionCheckResponse {
    const message = createBaseSubscriptionCheckResponse();
    message.has_subscription = object.has_subscription ?? false;
    return message;
  },
};

function createBaseSavePaymentDataRequest(): SavePaymentDataRequest {
  return { email: "", provider: "", payment_id: "", user_id: "" };
}

export const SavePaymentDataRequest = {
  fromJSON(object: any): SavePaymentDataRequest {
    return {
      email: isSet(object.email) ? String(object.email) : "",
      provider: isSet(object.provider) ? String(object.provider) : "",
      payment_id: isSet(object.payment_id) ? String(object.payment_id) : "",
      user_id: isSet(object.user_id) ? String(object.user_id) : "",
    };
  },

  toJSON(message: SavePaymentDataRequest): unknown {
    const obj: any = {};
    message.email !== undefined && (obj.email = message.email);
    message.provider !== undefined && (obj.provider = message.provider);
    message.payment_id !== undefined && (obj.payment_id = message.payment_id);
    message.user_id !== undefined && (obj.user_id = message.user_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<SavePaymentDataRequest>, I>>(base?: I): SavePaymentDataRequest {
    return SavePaymentDataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SavePaymentDataRequest>, I>>(object: I): SavePaymentDataRequest {
    const message = createBaseSavePaymentDataRequest();
    message.email = object.email ?? "";
    message.provider = object.provider ?? "";
    message.payment_id = object.payment_id ?? "";
    message.user_id = object.user_id ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
