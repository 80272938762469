import React, { FC, useEffect } from "react"
import { Box, Button, Flex, Heading, Image } from "@chakra-ui/react"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useNavigateWithSearchParams } from "~/utils/useNavigateWithSearchParams"
import faceSvg from "./assets/face-confused2.svg"
import { ProcessingBase } from "./ProcessingBase"

export const ProcessingError: FC = () => {
  const log = useAmplitude()
  const navigate = useNavigateWithSearchParams()

  useEffect(() => {
    log.offerContinueResult({ result: "payment_error" })
  }, [log])

  const onClick = () => {
    log.offerTryAgain()

    navigate("/")
  }

  return (
    <ProcessingBase
      topSlot={<Image src={faceSvg} alt="" w="68px" h="73px" />}
      bottomSlot={
        <Button variant="next" onClick={onClick} w="100%" minH="56px">
          Try again
        </Button>
      }
    >
      <Flex direction="column" h="full" justifyContent="flex-end" flexGrow={1} paddingY={5} gap={4}>
        <Heading size="MediumHeaderPrimary">Oh, something went wrong...</Heading>
        <Box color="Base/baseSecondary" textStyle="Paragraph/Primary">
          The payment failed due to a technical error. Try again or choose a different payment
          method.
        </Box>
      </Flex>
    </ProcessingBase>
  )
}
