import React, { FC, useCallback } from "react"
import { Button, ButtonProps } from "@chakra-ui/react"
import { useScrollToOffer } from "./scrollToOffer"

export const ActionButton: FC<{ actionSource: string } & ButtonProps> = ({
  actionSource,
  children,
  ...props
}) => {
  const onClickFn = useScrollToOffer(actionSource)
  const onClick = useCallback(() => onClickFn({ scroll: true, openModal: false }), [onClickFn])

  return (
    <Button variant="action" w="full" onClick={onClick} {...props}>
      {children}
    </Button>
  )
}
