import React, { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBoolean,
} from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"
import { Logo } from "~/pages/Main/SkipTrial/Icons"
import { useUpsaleBundleData } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { useMakePurchase } from "~/utils/useMakePurchase"
import { formatLengthWithUnit, formatPrice } from "~/utils"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { UpsaleContext } from "~/pages/Processing/context"
import {
  AlternativeVariant,
  SuccessScreenVariant,
} from "~/pages/AppLinkPage/AlternativeVariantProps"
import { PreloadedStatic } from "~/utils/static_images_to_preload"
import { MailChips } from "~/pages/Main/PriceSelectWithPaymentForm"
import { UpsaleBundlePrices } from "~/pages/Processing/UpsaleBundle/UpsaleBundlePrices"
import { TextMoneyBack } from "~/components/StaticTexts"

import { Step2Modal } from "./Step2Modal"
import {
  BenefitCardList,
  DiscountCard,
  LegalNotice,
  PriceBox,
  PromoPriceText,
  SubmitButtons,
  TimerBlock,
  WhyLoviList,
} from "../components"
import { CheckIcon, CrossIcon } from "./icons"

import "./table.css"

const UnorderedListItem = ({ children }: { children: ReactNode }) => (
  <Flex gap={1.5}>
    <CheckIcon />
    <Box textStyle="Subtitle/Secondary">{children}</Box>
  </Flex>
)

const BundleIncludes = () => {
  return (
    <Box>
      <VFlex gap={6} p={6} layerStyle="attentionFrame">
        <Heading as="h2" size="Header/Secondary">
          What&apos;s Included in the All-In-One Lóvi Bundle
        </Heading>
        <VFlex gap={3}>
          <UnorderedListItem>Unbiased Advice</UnorderedListItem>
          <UnorderedListItem>Expert Video Guidance</UnorderedListItem>
          <UnorderedListItem>Tailored Product Suggestions</UnorderedListItem>
          <UnorderedListItem>Ingredient Safety Insights</UnorderedListItem>
          <UnorderedListItem>Curated Alternatives</UnorderedListItem>
        </VFlex>
        <Box bgColor="white" layerStyle="attentionFrame" p={4}>
          <Heading as="h2" size="Header/Tertiary">
            Discover The Ultimate Products With Your Phone!
          </Heading>
          <Box my={4}>
            <AspectRatio ratio={741 / 824}>
              <video
                muted
                playsInline
                loop
                autoPlay
                src={PreloadedStatic.aiScannerV2VideoGrey}
              ></video>
            </AspectRatio>
          </Box>
        </Box>
        <Box mb={-4}>
          <Image src={"https://storage.pora.ai/f530ea967db305c9a7fe230f8415aaa5.webp"} />
        </Box>
        <Box bgColor="white" layerStyle="attentionFrame" p={4}>
          <Heading as="h2" size="Header/Tertiary">
            Your personal 24/7 skincare AI-assistant
          </Heading>
          <Box my={4}>
            <AspectRatio ratio={741 / 824}>
              <video
                muted
                playsInline
                loop
                autoPlay
                src={PreloadedStatic.assistantVideoGrey}
              ></video>
            </AspectRatio>
          </Box>
        </Box>
        <Box mt={-4}>
          <Image src={"https://storage.pora.ai/1374e3a87347f555cbd2c83e3303f6b1.webp"} />
        </Box>
      </VFlex>
      <Box mt={4} color="Base/baseSecondary" textStyle="Subtitle/Secondary">
        Enhance your skincare routines with AI-Powered scanner
      </Box>
    </Box>
  )
}

const ComparisonTableHeaderCell: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box
      color="Base/basePrimary"
      textTransform="none"
      textStyle="Subtitle/Tertiary"
      fontFamily="body"
      textAlign="center"
    >
      {children}
    </Box>
  )
}

const ComparisonTable: FC<{ children: ReactNode; prices: PriceContentV2_PriceItem[] }> = ({
  children,
  prices,
}) => {
  if (prices.length < 3) {
    return <Box>error: not enough prices info</Box>
  }
  return (
    <Box p={6} layerStyle="attentionFrame">
      <Heading textAlign="center" as="h2" size="Header/Secondary">
        All-In-One Bundle vs Single Options
      </Heading>
      <Box mt={4} textStyle="Paragraph/Secondary" textAlign="center" color="Base/baseSecondary">
        *Prices per month for each option
      </Box>
      <TableContainer whiteSpace="break-spaces">
        <Table mt={6} className="upsale-table" border="none">
          <Thead>
            <Tr borderBottom="1px solid" borderColor="#E4E3E1">
              <Th pt={0} pr={1} pl={0}></Th>
              <Th pt={0} px={1}>
                <ComparisonTableHeaderCell>
                  <Box color="Base/accentPrimary">{prices[0] && prices[0].badge?.title}</Box>
                  <Box color="Base/accentPrimary" textStyle="Paragraph/Secondary">
                    {prices[0] &&
                      formatPrice(prices[0].currency_code, parseFloat(prices[0].economy_price))}
                    *
                  </Box>
                </ComparisonTableHeaderCell>
              </Th>
              <Th pt={0} px={1}>
                <ComparisonTableHeaderCell>
                  <Box>{prices[1] && prices[1].badge?.title}</Box>
                  <Box color="Base/baseSecondary" textStyle="Paragraph/Secondary">
                    {prices[1] &&
                      formatPrice(prices[1].currency_code, parseFloat(prices[1].economy_price))}
                  </Box>
                </ComparisonTableHeaderCell>
              </Th>
              <Th pt={0} pl={1} pr={0}>
                <ComparisonTableHeaderCell>
                  <Box>{prices[2] && prices[2].badge?.title}</Box>
                  <Box color="Base/baseSecondary" textStyle="Paragraph/Secondary">
                    {prices[2] &&
                      formatPrice(prices[2].currency_code, parseFloat(prices[2].economy_price))}
                  </Box>
                </ComparisonTableHeaderCell>
              </Th>
            </Tr>
          </Thead>
          <Tbody textStyle="Paragraph/Secondary">
            {[
              ["Personalized Skincare Guidance", 1, 0, 1],
              ["Real-time Skincare Tips 24/7", 1, 0, 1],
              ["Unlimited Product Scans", 1, 1, 0],
              ["Ingredient Safety Checks", 1, 1, 0],
              ["Product Suitability Analysis", 1, 1, 0],
              ["Product Alternatives", 1, 0, 1],
            ].map((info, i) => (
              <Tr key={i}>
                <Td pl={0} pr={1}>
                  {info[0]}
                </Td>
                <Td
                  textAlign="center"
                  px={1}
                  background={
                    "linear-gradient(180deg, #F6F5F4 0%, #F7F6F5 8%, #FFF 50%, #F7F6F5 100%)"
                  }
                >
                  {info[1] ? <CheckIcon /> : <CrossIcon />}
                </Td>
                <Td textAlign="center" px={1}>
                  {info[2] ? <CheckIcon /> : <CrossIcon />}
                </Td>
                <Td textAlign="center" pl={1} pr={0}>
                  {info[3] ? <CheckIcon /> : <CrossIcon />}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {children}
    </Box>
  )
}

const PriceBoxInstance: FC<{ price: PriceContentV2_PriceItem }> = ({ price }) => (
  <PriceBox price={price}>
    Amount of scans <br /> and messages
  </PriceBox>
)

export const UpsaleBundle: FC<{
  step: Step
  isLoading: boolean
  prices: PriceContentV2_PriceItem[]
  discountedPrices: PriceContentV2_PriceItem[]
  onClickPay: () => void
  onClickNext: () => void
  selectedPriceId: string /* TODO PriceId */
  selectedPrice?: PriceContentV2_PriceItem
  setSelectedPriceId: (price: string /* TODO PriceId */) => void
}> = ({
  step,
  isLoading,
  prices,
  discountedPrices,
  onClickPay,
  onClickNext,
  selectedPriceId,
  selectedPrice,
  setSelectedPriceId,
}) => {
  const currentPrices = step === 0 || step === 1 ? prices : discountedPrices
  const currentPricesItems = currentPrices
  const [bundlePrice, ...currentPricesWithoutBundle] = currentPrices

  const priceToShowNotBundleUpsale = currentPricesWithoutBundle[0]

  const purchaseBundle = () => {
    if (!bundlePrice) {
      alert("bundle price is empty")
      return
    }
    setSelectedPriceId(bundlePrice.id)
    // todo absolute shit hack coded from airport to be sure price is changed
    setTimeout(() => {
      onClickPay()
    }, 10)
  }

  return (
    <Box pos="relative" bgColor="white" paddingX={6}>
      <VFlex gap={10}>
        <Box>
          <Center mb={4} height="56px" as="header">
            <Logo />
          </Center>
          {step >= 2 && <DiscountCard discount={selectedPrice?.subscription_text || "25%"} />}
          {bundlePrice && (
            <VFlex py={4} layerStyle="attentionFrame" gap={6}>
              <Heading textAlign="center" as="h2" size="Header/Secondary" px={6}>
                Get Your All-In-One Lóvi Bundle
              </Heading>
              <Image
                src={PreloadedStatic.upsaleBundleImage}
                alt="Bundle"
                sx={{ aspectRatio: "922 / 824" }}
              />
              <PromoPriceText price={bundlePrice} />
              <Box mx={6}>
                <SubmitButtons isLoading={isLoading} onPay={purchaseBundle} onSkip={onClickNext} />
              </Box>
            </VFlex>
          )}
        </Box>
        {selectedPrice && <PriceBoxInstance price={selectedPrice} />}
        {selectedPrice && <LegalNotice price={selectedPrice} />}
        <VFlex gap={4}>
          <Heading as="h2" size="Header/Secondary">
            Why It Is Better To Get The Lóvi Bundle?
          </Heading>
          <BenefitCardList />
        </VFlex>
        <BundleIncludes />
        <WhyLoviList />
        {selectedPrice && <PriceBoxInstance price={selectedPrice} />}
        <MailChips
          variant="icon"
          colorVariant="grey"
          marginLeft={-6}
          marginRight={-6}
          width="calc(100% + 24px + 24px)"
          paddingTop={0}
          initialCount={264}
        />
        <ComparisonTable prices={currentPricesItems}>
          <Box mt={6}>
            <SubmitButtons isLoading={isLoading} onPay={purchaseBundle} onSkip={onClickNext} />
          </Box>
        </ComparisonTable>
        <Box p={6} layerStyle="attentionFrame">
          <Heading mb={6} textAlign="center" as="h2" size="Header/Secondary">
            Or Use Only One Option You Need
          </Heading>
          <Box>
            <UpsaleBundlePrices
              prices={currentPricesWithoutBundle}
              selectedPriceId={selectedPriceId}
              setSelectedPriceId={setSelectedPriceId}
            />
            {priceToShowNotBundleUpsale && (
              <>
                <Heading
                  mt={6}
                  as="h3"
                  size="Header/Tertiary"
                  textAlign="center"
                  color="Base/accentPrimary"
                  marginBottom={1}
                >
                  Just{" "}
                  {formatPrice(
                    priceToShowNotBundleUpsale.currency_code,
                    parseFloat(priceToShowNotBundleUpsale.economy_price)
                  )}{" "}
                  /{" "}
                  {formatLengthWithUnit(
                    priceToShowNotBundleUpsale.subscription_period_unit,
                    priceToShowNotBundleUpsale.subscription_period_length
                  )}
                </Heading>
                <Text
                  textStyle="Paragraph/Secondary"
                  color="Base/baseSecondary"
                  textAlign="center"
                  marginBottom={2}
                >
                  Original price was{" "}
                  {formatPrice(
                    priceToShowNotBundleUpsale.currency_code,
                    parseFloat(priceToShowNotBundleUpsale.previous_price)
                  )}{" "}
                  —{" "}
                  <Box as="span" color="Base/accentPrimary">
                    save {priceToShowNotBundleUpsale.economy_percent}%!
                  </Box>
                </Text>
                <Box mt={6}>
                  <Button
                    isLoading={isLoading}
                    disabled={selectedPriceId === bundlePrice?.id || isLoading}
                    onClick={onClickPay}
                    w="full"
                    variant="action"
                  >
                    Add to My Plan
                  </Button>
                </Box>
                <Box mt={6} textStyle="Paragraph/Secondary" color="Base/baseSecondary">
                  Tailor your routine with individual options, or unlock the full Lóvi experience
                  with our unbeatable bundle.
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box p={6} layerStyle="attentionFrame">
          <TextMoneyBack />
        </Box>
        {selectedPrice && <LegalNotice price={selectedPrice} />}
      </VFlex>
      <TimerBlock />
      <Step2Modal
        additionalDiscount={selectedPrice?.subscription_text || "25%"}
        isOpen={step === 1}
        onClose={onClickNext}
      />
    </Box>
  )
}

type Step = 0 | 1 | 2
export const UpsaleBundleContainer = () => {
  const { goNext } = useContext(UpsaleContext)
  const [paymentPageVariant, setPaymentPageVariant] = useState<SuccessScreenVariant>()

  const makePurchase = useMakePurchase()
  const upsaleBundleData = useUpsaleBundleData()

  const [isLoading, setLoading] = useBoolean()
  const [step, setStep] = useState<Step>(0)
  const findDefaultPriceId = useCallback((prices: PriceContentV2_PriceItem[]): string => {
    return prices.find((p) => p.default)?.id || prices[0]?.id || "error"
  }, [])
  const [selectedPriceId, _setSelectedPriceId] = useState(() =>
    findDefaultPriceId(upsaleBundleData?.prices || [])
  )
  const setSelectedPriceId = useCallback(
    (newSelectedPriceId: string) => {
      if (isLoading) {
        return
      }
      _setSelectedPriceId(newSelectedPriceId)
    },
    [isLoading]
  )

  const selectedPrice = useMemo(() => {
    return [
      ...(upsaleBundleData?.prices || []),
      ...(upsaleBundleData?.discounted_prices || []),
    ].find((p) => p.id === selectedPriceId)
  }, [selectedPriceId, upsaleBundleData?.discounted_prices, upsaleBundleData?.prices])

  const log = useAmplitude()
  useEffect(() => {
    log.upsaleBundleView({ step })
  }, [log, step])

  useEffect(() => {
    document.querySelector("html")?.scrollTo(0, 0)
  }, [])

  const onFail = useCallback(() => {
    log.upsaleBundleBuyFail()
    setPaymentPageVariant("bundle2Fail")
  }, [log, setPaymentPageVariant])

  const onSuccess = useCallback(() => {
    log.upsaleBundleBuySuccess()
    setPaymentPageVariant("bundle2Success")
  }, [log, setPaymentPageVariant])

  const onNext = useCallback(() => {
    if (step === 0) {
      setStep(1)
      return
    }
    if (step === 1) {
      if (upsaleBundleData?.discounted_prices) {
        setSelectedPriceId(findDefaultPriceId(upsaleBundleData.discounted_prices))
      }
      setStep(2)
      return
    }
    if (step === 2) {
      log.upsaleBundleBuyExit()
      onFail()
      return
    }
  }, [
    step,
    upsaleBundleData?.discounted_prices,
    setSelectedPriceId,
    findDefaultPriceId,
    log,
    onFail,
  ])

  const onPay = useCallback(async (): Promise<boolean> => {
    try {
      if (!selectedPrice) {
        throw new Error("Unknown Price")
      }
      setLoading.on()
      log.upsaleBundleBuy({ priceId: selectedPrice.id })
      const result = await makePurchase(selectedPrice)
      if (result) {
        setLoading.off()
        onSuccess()
        return true
      }
    } catch (error) {
      log.upsaleBundleBuyFail()
      onFail()
    }
    return false
  }, [log, onFail, onSuccess, selectedPrice, setLoading, makePurchase])

  return (
    <>
      {!paymentPageVariant && (
        <UpsaleBundle
          step={step}
          isLoading={isLoading}
          onClickNext={onNext}
          onClickPay={onPay}
          prices={upsaleBundleData?.prices || []}
          discountedPrices={upsaleBundleData?.discounted_prices || []}
          selectedPriceId={selectedPriceId}
          selectedPrice={selectedPrice}
          setSelectedPriceId={setSelectedPriceId}
        />
      )}
      {paymentPageVariant && <AlternativeVariant onClick={goNext} variant={paymentPageVariant} />}
    </>
  )
}
