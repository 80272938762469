import React, { FC } from "react"
import { AspectRatio, Box, Flex, Image } from "@chakra-ui/react"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { ReactComponent as RadioSvg } from "./assets/radio.svg"
import { ReactComponent as FilledRadioSvg } from "./assets/filled_radio.svg"
import { VFlex } from "~/components"
import { formatPrice, formatUnitWithPrefixLength } from "~/utils"

type BundlePriceProps = {
  priceItem: PriceContentV2_PriceItem
  isAssistant: boolean
  isScanner: boolean
  workbook: boolean
  workbookPrice: PriceContentV2_PriceItem
  selected: boolean
  onClick: () => void
}

type BundlePricesProps = {
  prices: BundlePriceProps[]
}

export const WorkbookRow = ({ price }: { price: PriceContentV2_PriceItem }) => {
  return (
    <Flex w="full" bgColor="Base/neutralSecondary" borderRadius="16px">
      <Flex mr={2}>
        <Image
          borderTopLeftRadius="16px"
          borderBottomLeftRadius="16px"
          width="56px"
          height="64px"
          src="https://storage.pora.ai/8f6ea17b7710f978e69ee65bfd2cb9ba.webp"
        />
      </Flex>
      <VFlex justifyContent="center" alignItems="flex-start">
        <Box textStyle="Subtitle/Hint">+ Get FREE Lóvi Workbook every month</Box>
        <Flex alignItems="center" mt={1.5}>
          <Box
            py={0.5}
            px={1.5}
            textStyle="Other/PriceTag"
            mr={2}
            color={"white"}
            borderRadius="32px"
            bgColor={"Base/accentPrimary"}
          >
            FREE
          </Box>
          <Box
            color="Base/baseSecondary"
            textStyle="Paragraph/Tertiary"
            textDecoration="line-through"
          >
            {formatPrice(price.currency_code, parseFloat(price.previous_price))}
          </Box>
        </Flex>
      </VFlex>
    </Flex>
  )
}

const BundlePriceItem: FC<BundlePriceProps> = ({
  priceItem,
  isAssistant,
  isScanner,
  selected,
  workbook,
  onClick,
  workbookPrice,
}) => {
  return (
    <VFlex
      p={3}
      flex={1}
      borderColor={selected ? "Base/accentSecondary" : "transparent"}
      bgColor="white"
      borderWidth="2px"
      borderRadius="20px 0px 20px 20px"
      cursor="pointer"
      onClick={onClick}
      pos="relative"
      boxShadow={
        selected
          ? "none"
          : "box-shadow: 0px 1px 2px 0px rgba(41, 40, 36, 0.06), 0px 8px 28px 0px rgba(41, 40, 36, 0.04)"
      }
    >
      <Box
        textStyle="Other/PriceTag"
        borderRadius="8px"
        borderBottomRightRadius={0}
        bgColor="Base/accentSecondary"
        color="Base/neutralPrimary"
        py="1px"
        px={2}
        pos="absolute"
        right="-2px"
        top="-10px"
      >
        {priceItem.economy_percent}% OFF
      </Box>
      <Flex>
        <VFlex
          alignItems="center"
          justifyContent="center"
          padding={isAssistant && isScanner ? "3px 12px" : "9px 12px"}
          mr={2}
          borderRadius="24px"
          borderWidth="2px"
          borderColor="Base/neutralSecondary"
        >
          {isAssistant && <Flex textStyle="Subtitle/Secondary">💬</Flex>}
          {isAssistant && isScanner && (
            <Flex my="1px">
              <svg
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.42756 7.30396V0.741455H4.54119V7.30396H3.42756ZM0.703125 4.57952V3.46589H7.26563V4.57952H0.703125Z"
                  fill="black"
                />
              </svg>
            </Flex>
          )}
          {isScanner && <Flex textStyle="Subtitle/Secondary">🧴</Flex>}
        </VFlex>
        <VFlex justifyContent="space-between" flex={1} mr={3}>
          <Box mb={1.5} textStyle="Subtitle/Secondary">
            {priceItem.title}
          </Box>
          <Box textStyle="Subtitle/Hint">
            <Box as="span" color="Base/accentPrimary">
              {formatPrice(priceItem.currency_code, parseFloat(priceItem.economy_price))} per{" "}
              {formatUnitWithPrefixLength(
                priceItem.subscription_period_unit,
                priceItem.subscription_period_length
              )}
            </Box>
            <Box textDecoration="line-through" ml={1} as="span" color="Base/baseSecondary">
              {formatPrice(priceItem.currency_code, parseFloat(priceItem.previous_price))}
            </Box>
          </Box>
        </VFlex>
        <Flex alignItems="center">{selected ? <FilledRadioSvg /> : <RadioSvg />}</Flex>
      </Flex>
      {workbook && (
        <Flex
          visibility={selected ? "visible" : "hidden"}
          position={selected ? "relative" : "absolute"}
          mt={2}
        >
          <WorkbookRow price={workbookPrice} />
        </Flex>
      )}
    </VFlex>
  )
}

export const NewSubscriptionsPrices: FC<BundlePricesProps> = ({ prices }) => {
  return (
    <VFlex gap={3} w="full">
      {prices.map((price, index) => {
        return <BundlePriceItem key={index} {...price} />
      })}
    </VFlex>
  )
}
