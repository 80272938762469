import { useCallback } from "react"
import { useDispatch } from "~/utils/redux"
import {
  useUpsaleSubscriptionOnExitData,
  useUpsaleSubscriptionOnExitState,
} from "~/store/selectors"
import {
  upsaleSubscriptionOnExitSetActive,
  upsaleSubscriptionOnExitSetPopupOpen,
} from "~/store/actions"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

export const useUpsaleSubscriptionOnExit = () => {
  const log = useAmplitude()
  const dispatch = useDispatch()
  const { active, popupIsOpen } = useUpsaleSubscriptionOnExitState()
  const data = useUpsaleSubscriptionOnExitData()

  const close = useCallback(() => {
    log.upsaleSubscriptionOnExitPopupHide()
    dispatch(upsaleSubscriptionOnExitSetPopupOpen(false))
    log.upsaleSubscriptionOnExitActivate()
    dispatch(upsaleSubscriptionOnExitSetActive(true))
  }, [log])

  const open = useCallback(() => {
    if (!active) {
      log.upsaleSubscriptionOnExitPopupShow()
      dispatch(upsaleSubscriptionOnExitSetPopupOpen(true))
    }
    return
  }, [log, active])

  return { active, popupIsOpen, open, close, data }
}
