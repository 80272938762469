import React, { FC } from "react"
import { Box, Image } from "@chakra-ui/react"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { VFlex } from "~/components"
import avatars from "./assets/avatars2.webp?url"

const StaticExperts: FC = () => (
  <VFlex gap={3} padding={6} layerStyle="internalFrame">
    <Image src={avatars} h="64px" w="176px" alt="" />
    <Box textStyle="Paragraph/Secondary">
      All our programs are created under thorough supervision by a team of Professional
      Cosmetologists, Cosmetic Chemists & Aesthetic Products Developers
    </Box>
  </VFlex>
)

export const StaticExpertsBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper
    paddingY={6}
    header={
      <>
        Gain insights from
        <br />
        professional cosmetologists
      </>
    }
    {...props}
  >
    <StaticExperts />
  </BlockWrapper>
)
