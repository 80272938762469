export const appendParams = (url = "", params: Record<string, string | undefined> = {}): string => {
  const u = new URL(url, String(window.location.origin))

  Object.entries(params).forEach(([k, v]) => {
    if (v) {
      u.searchParams.set(k, v)
    }
  })

  return String(u)
}

export const getUserIdFromUrl = (): UserId | undefined => {
  const user_id = new URL(String(window.location)).searchParams.get("user_id")
  if (user_id) {
    return user_id as UserId
  }
  return undefined
}
