import dayjs from "dayjs"
import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { noop } from "~/utils/noop"

const TimerContext = createContext<{
  time: number
  start: (seconds: number) => void
  stop: () => void
}>({
  time: 0,
  start: noop,
  stop: noop,
})
export const TimerProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [time, setTime] = useState<number>(0)
  const [timerId, setTimerId] = useState<NodeJS.Timer>()

  const stop = useCallback(() => {
    if (timerId) {
      clearInterval(timerId)
    }
  }, [timerId])

  const start = useCallback(
    (seconds: number) => {
      stop()

      setTime(seconds)

      if (seconds > 0) {
        const timer = setInterval(() => {
          setTime((current) => {
            if (current <= 1) {
              clearInterval(timer)
              return 0
            }

            return current - 1
          })
        }, 1000)

        setTimerId(timer)
      }
    },
    [stop]
  )

  return <TimerContext.Provider value={{ time, start, stop }}>{children}</TimerContext.Provider>
}

export const useTimerContext = () => useContext(TimerContext)

export const StartTimer = memo(function StartTimer({ seconds }: { seconds: number }) {
  const { start, stop } = useTimerContext()
  useEffect(() => {
    start(seconds)

    return stop
  }, [seconds])

  return null
})

export const Timer = ({ format = "mm:ss" }: { format?: "mm:ss" | "ss" | "mm" }) => {
  const { time } = useTimerContext()

  const formattedTime = useMemo(() => dayjs(new Date(time * 1000)).format(format), [time, format])
  return <>{formattedTime}</>
}
