import React from "react"
import { createIcon } from "@chakra-ui/react"

export const CheckIcon = createIcon({
  viewBox: "0 0 20 20",
  path: (
    <path
      d="M2.5 7.33441C3.87195 9.92083 4.61436 11.5884 5.52846 15.3043C6.44255 19.0201 10.5156 6.77327 17.5 4"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
})
