import { Box, Flex, FlexProps, Heading, SimpleGrid, Tag, TagProps } from "@chakra-ui/react"
import React, { FC } from "react"
import { easeInOut, useTransform } from "framer-motion"
import { ProgressBar, VFlex } from "~/components"
import { useProgress, useStateFromMV } from "~/shared/useProgress"

import { formatPercent } from "~/utils"
import { getScaleValue } from "~/utils/getScaleValue"

const Subtitle: FC<FlexProps> = (props) => (
  <Flex
    textStyle="Paragraph/Secondary"
    alignItems="center"
    gap={0.5}
    sx={{
      " em": { fontWeight: 500, fontStyle: "normal" },
    }}
    {...props}
  />
)

const PhotoTag: FC<TagProps> = (props) => (
  <Tag
    borderRadius="full"
    paddingX={3}
    paddingY={1.5}
    lineHeight={5}
    textStyle="Subtitle/Tertiary"
    {...props}
  />
)

const PhotoItem: FC<FlexProps> = (props) => (
  <Flex
    flexGrow={1}
    borderRightRadius="brand24"
    borderLeftRadius="brand24"
    bgSize="cover"
    minH="200px"
    alignItems="flex-end"
    justifyContent="center"
    padding={2}
    sx={{ aspectRatio: "0.75" }}
    {...props}
  />
)

type ProgressNumberProps = {
  color: string
  bgColor?: string
  value: number
  title: string
}
const ProgressNumber: FC<ProgressNumberProps> = ({
  color,
  bgColor = "Base/neutralDisabled",
  value,
  title,
}) => (
  <VFlex gap={0.5} justifyContent="stretch" w="full" paddingX={2}>
    <Box textStyle="Header/Tertiary" fontFamily="heading">
      {formatPercent(value)}
    </Box>
    <ProgressBar bgColor={bgColor} h={2} color={color} value={value} />
    <Box textStyle="Paragraph/Secondary">{title}</Box>
  </VFlex>
)

const ProgressNumberAnimated: FC<ProgressNumberProps> = ({ value, ...props }) => {
  const { motionValue } = useProgress({ duration: 0.5 })
  const transformed = useTransform(motionValue, (v) => v * easeInOut(v) * value)
  const progress = useStateFromMV(transformed)

  return <ProgressNumber value={progress} {...props} />
}

const defaultOriginalPhoto = "https://storage.pora.ai/6e4d34d235ebe6cec99b7965b3cef9f5.webp"
const defaultEnhancedPhoto = "https://storage.pora.ai/d1b44adf0113ad30269d6648ab74e4c6.webp"

const texts = {
  default: {
    supTitle: (
      <>
        AI Prediction
        <br /> Based On Your Goals
      </>
    ),
    tagBefore: "Before",
    tagAfter: "After",
  },
  user: {
    supTitle: (
      <>
        AI Prediction <br />
        Based on your goals
      </>
    ),
    tagBefore: "Now",
    tagAfter: "Your Goal",
  },
}

export const EnhancedPhoto3: FC<{
  subTitle?: string
  supTitle?: string | null
  type: "user" | "default"
  originalPhoto: string | undefined
  enhancedPhoto: string | undefined
}> = ({ subTitle, type, supTitle = texts[type].supTitle, originalPhoto, enhancedPhoto }) => (
  <VFlex gap={4} w="full">
    {supTitle !== null && (
      <Heading textAlign="center" as="h2" size="Header/Secondary">
        {supTitle}
      </Heading>
    )}
    <SimpleGrid columns={2} spacingX={0.5} spacingY={4} w="full">
      <PhotoItem
        borderRightRadius="brand4"
        bgImage={type === "user" ? originalPhoto : defaultOriginalPhoto}
        justifyContent="flex-start"
      >
        <PhotoTag color="white" backdropFilter="blur(6px)" background="rgba(255, 255, 255, 0.10)">
          {texts[type].tagBefore}
        </PhotoTag>
      </PhotoItem>
      <PhotoItem
        borderLeftRadius="brand4"
        bgImage={type === "user" ? enhancedPhoto : defaultEnhancedPhoto}
        justifyContent="flex-end"
      >
        <PhotoTag background="white">{texts[type].tagAfter}</PhotoTag>
      </PhotoItem>

      <ProgressNumberAnimated
        value={getScaleValue("HYDRATION", false)}
        color="Other/Info/80"
        title="Skin Hydration"
      />
      <ProgressNumberAnimated
        value={getScaleValue("HYDRATION", true)}
        color="Other/Info"
        title="Skin Hydration"
      />
      <ProgressNumberAnimated
        value={getScaleValue("ELASTICITY", false)}
        color="Other/Green/80"
        title="Skin Elasticity"
      />
      <ProgressNumberAnimated
        value={getScaleValue("ELASTICITY", true)}
        color="Other/Green"
        title="Skin Elasticity"
      />
    </SimpleGrid>
    {subTitle && (
      <Subtitle placeContent="flex-start" color="Base/baseSecondary" paddingX={6}>
        {subTitle}
      </Subtitle>
    )}
  </VFlex>
)
