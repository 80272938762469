import React, { FC, ReactNode, createContext, useCallback, useContext, useState } from "react"
import { noop } from "~/utils"

type FormT = { isApplePayAvailable: boolean }
const DEFAULT_VALUE: FormT = { isApplePayAvailable: false }
const PaymentFormContext = createContext<{
  set: (V: boolean) => void
  value: FormT
}>({
  set: noop,
  value: DEFAULT_VALUE,
})
export const usePaymentFormContext = () => useContext(PaymentFormContext)
export const PaymentFormProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [value, setState] = useState<FormT>(DEFAULT_VALUE)
  const setStateFn = useCallback(
    (isApplePayAvailable: boolean) => {
      setState({ isApplePayAvailable })
    },
    [setState]
  )

  return (
    <PaymentFormContext.Provider value={{ set: setStateFn, value }}>
      {children}
    </PaymentFormContext.Provider>
  )
}
