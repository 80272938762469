/* eslint-disable */
import { AttributedString } from "./presentation";

export const protobufPackage = "skin_diary_checklist";

export interface SkinDiaryScreen {
  blocks: SkinDiaryScreen_Block[];
  title: AttributedString | undefined;
  avatar_placeholder: string;
  skin_feeling_color: string;
}

export interface SkinDiaryScreen_Block {
  id: string;
  image_url: string;
  title: string;
  is_optional: boolean;
  block_content?: { $case: "tags_cloud"; tags_cloud: SkinDiaryScreen_Block_TagCloud } | {
    $case: "slider";
    slider: SkinDiaryScreen_Block_Slider;
  };
}

export interface SkinDiaryScreen_Block_TagCloud {
  tags: SkinDiaryScreen_Block_TagCloud_Tag[];
  text_input?: SkinDiaryScreen_Block_TagCloud_TextInput | undefined;
  is_single_select: boolean;
  blast_confetti_on_fill: boolean;
}

export interface SkinDiaryScreen_Block_TagCloud_TextInput {
  placeholder: string;
  value: string;
  /** / If value is empty, input is always visible */
  bind_with_tag: string;
}

export interface SkinDiaryScreen_Block_TagCloud_Tag {
  value: string;
  image_url: string;
  background_color: string;
  highlight_color: string;
  is_selected: boolean;
}

export interface SkinDiaryScreen_Block_Slider {
  values: SkinDiaryScreen_Block_Slider_Value[];
  selected_index: number;
  animation_type: SkinDiaryScreen_Block_Slider_AnimationType;
}

export enum SkinDiaryScreen_Block_Slider_AnimationType {
  NONE = "NONE",
  ROTATE = "ROTATE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function skinDiaryScreen_Block_Slider_AnimationTypeFromJSON(
  object: any,
): SkinDiaryScreen_Block_Slider_AnimationType {
  switch (object) {
    case 0:
    case "NONE":
      return SkinDiaryScreen_Block_Slider_AnimationType.NONE;
    case 1:
    case "ROTATE":
      return SkinDiaryScreen_Block_Slider_AnimationType.ROTATE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SkinDiaryScreen_Block_Slider_AnimationType.UNRECOGNIZED;
  }
}

export function skinDiaryScreen_Block_Slider_AnimationTypeToJSON(
  object: SkinDiaryScreen_Block_Slider_AnimationType,
): string {
  switch (object) {
    case SkinDiaryScreen_Block_Slider_AnimationType.NONE:
      return "NONE";
    case SkinDiaryScreen_Block_Slider_AnimationType.ROTATE:
      return "ROTATE";
    case SkinDiaryScreen_Block_Slider_AnimationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface SkinDiaryScreen_Block_Slider_Value {
  text: string;
  image_url: string;
}

export interface SkinDiarySaveRequest {
  block_responses: SkinDiarySaveRequest_BlockResponse[];
}

export interface SkinDiarySaveRequest_BlockResponse {
  id: string;
  answers: string[];
}

function createBaseSkinDiaryScreen(): SkinDiaryScreen {
  return { blocks: [], title: undefined, avatar_placeholder: "", skin_feeling_color: "" };
}

export const SkinDiaryScreen = {
  fromJSON(object: any): SkinDiaryScreen {
    return {
      blocks: Array.isArray(object?.blocks) ? object.blocks.map((e: any) => SkinDiaryScreen_Block.fromJSON(e)) : [],
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      avatar_placeholder: isSet(object.avatar_placeholder) ? String(object.avatar_placeholder) : "",
      skin_feeling_color: isSet(object.skin_feeling_color) ? String(object.skin_feeling_color) : "",
    };
  },

  toJSON(message: SkinDiaryScreen): unknown {
    const obj: any = {};
    if (message.blocks) {
      obj.blocks = message.blocks.map((e) => e ? SkinDiaryScreen_Block.toJSON(e) : undefined);
    } else {
      obj.blocks = [];
    }
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.avatar_placeholder !== undefined && (obj.avatar_placeholder = message.avatar_placeholder);
    message.skin_feeling_color !== undefined && (obj.skin_feeling_color = message.skin_feeling_color);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinDiaryScreen>, I>>(base?: I): SkinDiaryScreen {
    return SkinDiaryScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinDiaryScreen>, I>>(object: I): SkinDiaryScreen {
    const message = createBaseSkinDiaryScreen();
    message.blocks = object.blocks?.map((e) => SkinDiaryScreen_Block.fromPartial(e)) || [];
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.avatar_placeholder = object.avatar_placeholder ?? "";
    message.skin_feeling_color = object.skin_feeling_color ?? "";
    return message;
  },
};

function createBaseSkinDiaryScreen_Block(): SkinDiaryScreen_Block {
  return { id: "", image_url: "", title: "", is_optional: false, block_content: undefined };
}

export const SkinDiaryScreen_Block = {
  fromJSON(object: any): SkinDiaryScreen_Block {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      image_url: isSet(object.image_url) ? String(object.image_url) : "",
      title: isSet(object.title) ? String(object.title) : "",
      is_optional: isSet(object.is_optional) ? Boolean(object.is_optional) : false,
      block_content: isSet(object.tags_cloud)
        ? { $case: "tags_cloud", tags_cloud: SkinDiaryScreen_Block_TagCloud.fromJSON(object.tags_cloud) }
        : isSet(object.slider)
        ? { $case: "slider", slider: SkinDiaryScreen_Block_Slider.fromJSON(object.slider) }
        : undefined,
    };
  },

  toJSON(message: SkinDiaryScreen_Block): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.image_url !== undefined && (obj.image_url = message.image_url);
    message.title !== undefined && (obj.title = message.title);
    message.is_optional !== undefined && (obj.is_optional = message.is_optional);
    message.block_content?.$case === "tags_cloud" && (obj.tags_cloud = message.block_content?.tags_cloud
      ? SkinDiaryScreen_Block_TagCloud.toJSON(message.block_content?.tags_cloud)
      : undefined);
    message.block_content?.$case === "slider" && (obj.slider = message.block_content?.slider
      ? SkinDiaryScreen_Block_Slider.toJSON(message.block_content?.slider)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinDiaryScreen_Block>, I>>(base?: I): SkinDiaryScreen_Block {
    return SkinDiaryScreen_Block.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinDiaryScreen_Block>, I>>(object: I): SkinDiaryScreen_Block {
    const message = createBaseSkinDiaryScreen_Block();
    message.id = object.id ?? "";
    message.image_url = object.image_url ?? "";
    message.title = object.title ?? "";
    message.is_optional = object.is_optional ?? false;
    if (
      object.block_content?.$case === "tags_cloud" &&
      object.block_content?.tags_cloud !== undefined &&
      object.block_content?.tags_cloud !== null
    ) {
      message.block_content = {
        $case: "tags_cloud",
        tags_cloud: SkinDiaryScreen_Block_TagCloud.fromPartial(object.block_content.tags_cloud),
      };
    }
    if (
      object.block_content?.$case === "slider" &&
      object.block_content?.slider !== undefined &&
      object.block_content?.slider !== null
    ) {
      message.block_content = {
        $case: "slider",
        slider: SkinDiaryScreen_Block_Slider.fromPartial(object.block_content.slider),
      };
    }
    return message;
  },
};

function createBaseSkinDiaryScreen_Block_TagCloud(): SkinDiaryScreen_Block_TagCloud {
  return { tags: [], text_input: undefined, is_single_select: false, blast_confetti_on_fill: false };
}

export const SkinDiaryScreen_Block_TagCloud = {
  fromJSON(object: any): SkinDiaryScreen_Block_TagCloud {
    return {
      tags: Array.isArray(object?.tags)
        ? object.tags.map((e: any) => SkinDiaryScreen_Block_TagCloud_Tag.fromJSON(e))
        : [],
      text_input: isSet(object.text_input)
        ? SkinDiaryScreen_Block_TagCloud_TextInput.fromJSON(object.text_input)
        : undefined,
      is_single_select: isSet(object.is_single_select) ? Boolean(object.is_single_select) : false,
      blast_confetti_on_fill: isSet(object.blast_confetti_on_fill) ? Boolean(object.blast_confetti_on_fill) : false,
    };
  },

  toJSON(message: SkinDiaryScreen_Block_TagCloud): unknown {
    const obj: any = {};
    if (message.tags) {
      obj.tags = message.tags.map((e) => e ? SkinDiaryScreen_Block_TagCloud_Tag.toJSON(e) : undefined);
    } else {
      obj.tags = [];
    }
    message.text_input !== undefined && (obj.text_input = message.text_input
      ? SkinDiaryScreen_Block_TagCloud_TextInput.toJSON(message.text_input)
      : undefined);
    message.is_single_select !== undefined && (obj.is_single_select = message.is_single_select);
    message.blast_confetti_on_fill !== undefined && (obj.blast_confetti_on_fill = message.blast_confetti_on_fill);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinDiaryScreen_Block_TagCloud>, I>>(base?: I): SkinDiaryScreen_Block_TagCloud {
    return SkinDiaryScreen_Block_TagCloud.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinDiaryScreen_Block_TagCloud>, I>>(
    object: I,
  ): SkinDiaryScreen_Block_TagCloud {
    const message = createBaseSkinDiaryScreen_Block_TagCloud();
    message.tags = object.tags?.map((e) => SkinDiaryScreen_Block_TagCloud_Tag.fromPartial(e)) || [];
    message.text_input = (object.text_input !== undefined && object.text_input !== null)
      ? SkinDiaryScreen_Block_TagCloud_TextInput.fromPartial(object.text_input)
      : undefined;
    message.is_single_select = object.is_single_select ?? false;
    message.blast_confetti_on_fill = object.blast_confetti_on_fill ?? false;
    return message;
  },
};

function createBaseSkinDiaryScreen_Block_TagCloud_TextInput(): SkinDiaryScreen_Block_TagCloud_TextInput {
  return { placeholder: "", value: "", bind_with_tag: "" };
}

export const SkinDiaryScreen_Block_TagCloud_TextInput = {
  fromJSON(object: any): SkinDiaryScreen_Block_TagCloud_TextInput {
    return {
      placeholder: isSet(object.placeholder) ? String(object.placeholder) : "",
      value: isSet(object.value) ? String(object.value) : "",
      bind_with_tag: isSet(object.bind_with_tag) ? String(object.bind_with_tag) : "",
    };
  },

  toJSON(message: SkinDiaryScreen_Block_TagCloud_TextInput): unknown {
    const obj: any = {};
    message.placeholder !== undefined && (obj.placeholder = message.placeholder);
    message.value !== undefined && (obj.value = message.value);
    message.bind_with_tag !== undefined && (obj.bind_with_tag = message.bind_with_tag);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinDiaryScreen_Block_TagCloud_TextInput>, I>>(
    base?: I,
  ): SkinDiaryScreen_Block_TagCloud_TextInput {
    return SkinDiaryScreen_Block_TagCloud_TextInput.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinDiaryScreen_Block_TagCloud_TextInput>, I>>(
    object: I,
  ): SkinDiaryScreen_Block_TagCloud_TextInput {
    const message = createBaseSkinDiaryScreen_Block_TagCloud_TextInput();
    message.placeholder = object.placeholder ?? "";
    message.value = object.value ?? "";
    message.bind_with_tag = object.bind_with_tag ?? "";
    return message;
  },
};

function createBaseSkinDiaryScreen_Block_TagCloud_Tag(): SkinDiaryScreen_Block_TagCloud_Tag {
  return { value: "", image_url: "", background_color: "", highlight_color: "", is_selected: false };
}

export const SkinDiaryScreen_Block_TagCloud_Tag = {
  fromJSON(object: any): SkinDiaryScreen_Block_TagCloud_Tag {
    return {
      value: isSet(object.value) ? String(object.value) : "",
      image_url: isSet(object.image_url) ? String(object.image_url) : "",
      background_color: isSet(object.background_color) ? String(object.background_color) : "",
      highlight_color: isSet(object.highlight_color) ? String(object.highlight_color) : "",
      is_selected: isSet(object.is_selected) ? Boolean(object.is_selected) : false,
    };
  },

  toJSON(message: SkinDiaryScreen_Block_TagCloud_Tag): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.image_url !== undefined && (obj.image_url = message.image_url);
    message.background_color !== undefined && (obj.background_color = message.background_color);
    message.highlight_color !== undefined && (obj.highlight_color = message.highlight_color);
    message.is_selected !== undefined && (obj.is_selected = message.is_selected);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinDiaryScreen_Block_TagCloud_Tag>, I>>(
    base?: I,
  ): SkinDiaryScreen_Block_TagCloud_Tag {
    return SkinDiaryScreen_Block_TagCloud_Tag.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinDiaryScreen_Block_TagCloud_Tag>, I>>(
    object: I,
  ): SkinDiaryScreen_Block_TagCloud_Tag {
    const message = createBaseSkinDiaryScreen_Block_TagCloud_Tag();
    message.value = object.value ?? "";
    message.image_url = object.image_url ?? "";
    message.background_color = object.background_color ?? "";
    message.highlight_color = object.highlight_color ?? "";
    message.is_selected = object.is_selected ?? false;
    return message;
  },
};

function createBaseSkinDiaryScreen_Block_Slider(): SkinDiaryScreen_Block_Slider {
  return { values: [], selected_index: 0, animation_type: SkinDiaryScreen_Block_Slider_AnimationType.NONE };
}

export const SkinDiaryScreen_Block_Slider = {
  fromJSON(object: any): SkinDiaryScreen_Block_Slider {
    return {
      values: Array.isArray(object?.values)
        ? object.values.map((e: any) => SkinDiaryScreen_Block_Slider_Value.fromJSON(e))
        : [],
      selected_index: isSet(object.selected_index) ? Number(object.selected_index) : 0,
      animation_type: isSet(object.animation_type)
        ? skinDiaryScreen_Block_Slider_AnimationTypeFromJSON(object.animation_type)
        : SkinDiaryScreen_Block_Slider_AnimationType.NONE,
    };
  },

  toJSON(message: SkinDiaryScreen_Block_Slider): unknown {
    const obj: any = {};
    if (message.values) {
      obj.values = message.values.map((e) => e ? SkinDiaryScreen_Block_Slider_Value.toJSON(e) : undefined);
    } else {
      obj.values = [];
    }
    message.selected_index !== undefined && (obj.selected_index = Math.round(message.selected_index));
    message.animation_type !== undefined &&
      (obj.animation_type = skinDiaryScreen_Block_Slider_AnimationTypeToJSON(message.animation_type));
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinDiaryScreen_Block_Slider>, I>>(base?: I): SkinDiaryScreen_Block_Slider {
    return SkinDiaryScreen_Block_Slider.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinDiaryScreen_Block_Slider>, I>>(object: I): SkinDiaryScreen_Block_Slider {
    const message = createBaseSkinDiaryScreen_Block_Slider();
    message.values = object.values?.map((e) => SkinDiaryScreen_Block_Slider_Value.fromPartial(e)) || [];
    message.selected_index = object.selected_index ?? 0;
    message.animation_type = object.animation_type ?? SkinDiaryScreen_Block_Slider_AnimationType.NONE;
    return message;
  },
};

function createBaseSkinDiaryScreen_Block_Slider_Value(): SkinDiaryScreen_Block_Slider_Value {
  return { text: "", image_url: "" };
}

export const SkinDiaryScreen_Block_Slider_Value = {
  fromJSON(object: any): SkinDiaryScreen_Block_Slider_Value {
    return {
      text: isSet(object.text) ? String(object.text) : "",
      image_url: isSet(object.image_url) ? String(object.image_url) : "",
    };
  },

  toJSON(message: SkinDiaryScreen_Block_Slider_Value): unknown {
    const obj: any = {};
    message.text !== undefined && (obj.text = message.text);
    message.image_url !== undefined && (obj.image_url = message.image_url);
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinDiaryScreen_Block_Slider_Value>, I>>(
    base?: I,
  ): SkinDiaryScreen_Block_Slider_Value {
    return SkinDiaryScreen_Block_Slider_Value.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinDiaryScreen_Block_Slider_Value>, I>>(
    object: I,
  ): SkinDiaryScreen_Block_Slider_Value {
    const message = createBaseSkinDiaryScreen_Block_Slider_Value();
    message.text = object.text ?? "";
    message.image_url = object.image_url ?? "";
    return message;
  },
};

function createBaseSkinDiarySaveRequest(): SkinDiarySaveRequest {
  return { block_responses: [] };
}

export const SkinDiarySaveRequest = {
  fromJSON(object: any): SkinDiarySaveRequest {
    return {
      block_responses: Array.isArray(object?.block_responses)
        ? object.block_responses.map((e: any) => SkinDiarySaveRequest_BlockResponse.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SkinDiarySaveRequest): unknown {
    const obj: any = {};
    if (message.block_responses) {
      obj.block_responses = message.block_responses.map((e) =>
        e ? SkinDiarySaveRequest_BlockResponse.toJSON(e) : undefined
      );
    } else {
      obj.block_responses = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinDiarySaveRequest>, I>>(base?: I): SkinDiarySaveRequest {
    return SkinDiarySaveRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinDiarySaveRequest>, I>>(object: I): SkinDiarySaveRequest {
    const message = createBaseSkinDiarySaveRequest();
    message.block_responses = object.block_responses?.map((e) => SkinDiarySaveRequest_BlockResponse.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseSkinDiarySaveRequest_BlockResponse(): SkinDiarySaveRequest_BlockResponse {
  return { id: "", answers: [] };
}

export const SkinDiarySaveRequest_BlockResponse = {
  fromJSON(object: any): SkinDiarySaveRequest_BlockResponse {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      answers: Array.isArray(object?.answers) ? object.answers.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: SkinDiarySaveRequest_BlockResponse): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    if (message.answers) {
      obj.answers = message.answers.map((e) => e);
    } else {
      obj.answers = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SkinDiarySaveRequest_BlockResponse>, I>>(
    base?: I,
  ): SkinDiarySaveRequest_BlockResponse {
    return SkinDiarySaveRequest_BlockResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SkinDiarySaveRequest_BlockResponse>, I>>(
    object: I,
  ): SkinDiarySaveRequest_BlockResponse {
    const message = createBaseSkinDiarySaveRequest_BlockResponse();
    message.id = object.id ?? "";
    message.answers = object.answers?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
