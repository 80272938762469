import { useMemo } from "react"
import { PriceInfo } from "./types"
import { getSHA256Hex } from "~/utils/hashString"

const convertPrice = ({
  priceId,
  priceTitle,
  currency,
  value,
}: PriceInfo): Record<string, string | number> => ({
  product_name: priceTitle,
  quantity: 1,
  product_variant_id: priceId,
  value: value,
  currency,
})

const track = (action: string, values?: Record<string, unknown>) => {
  if (window.pintrk) {
    window.pintrk("track", action, values)
  }
}
const completeRegistration = (values: Record<string, unknown> = {}) => track("signup", values)
const initiateCheckout = (price: PriceInfo) => track("addtocart", convertPrice(price))
const purchase = async (price: PriceInfo) => {
  const eventName = "checkout"
  const eventID = await getSHA256Hex(price.userEmail.toLowerCase() + "|" + eventName)
  track(eventName, { event_id: eventID, ...convertPrice(price) })
}

const pageView = (path: string, values: Record<string, unknown>) =>
  track("pagevisit", {
    page_path: path,
    page_title: window.document.title,
    ...values,
  })

export const usePinterest = () =>
  useMemo(
    () => ({
      purchase,
      completeRegistration,
      initiateCheckout,
      pageView,
    }),
    []
  )
