import { ReactNode } from "react"
import { CurrencyCode } from "~/utils"

export type PriceProps = {
  price: number
  currency: CurrencyCode
  previousPrice?: number
  period: ReactNode
}

export const DISCOUNT = 0.15
export const prevPrice = (price: number) => price * (1 + DISCOUNT)
