import React, { FC, ReactNode } from "react"
import {
  Box,
  Heading,
  Image,
  Button,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"

const Content: FC<{ children: ReactNode; discount: string }> = ({ children, discount }) => (
  <VFlex gap={6} alignItems="center" borderRadius="32px" padding={8} bgColor="white">
    <VFlex alignItems="center">
      <Image
        src="https://storage.pora.ai/3e2fdd2f9ea06e659ea1e7fd7b24d4f8.webp"
        w="80px"
        h="80px"
        alt=""
      />
      <Heading size="Header/Secondary" textAlign="center" mt={2} mb={1}>
        Last minute offer!
      </Heading>
      <Box
        textAlign="center"
        textStyle="Paragraph/Primary"
        sx={{
          "& em": {
            color: "Base/accentPrimary",
            fontWeight: 600,
            fontStyle: "normal",
          },
        }}
      >
        It’s the last chance to apply the extra
        <br />
        <em>{discount} off</em> to get your skin improved!
      </Box>
    </VFlex>
    <Image
      src="https://storage.pora.ai/a7ff974a246b8604c4085bf23dc2da49.webp"
      alt="29% maintain their results for next six months"
      w="full"
      sx={{ aspectRatio: 793 / 576 }}
    />
    {children}
  </VFlex>
)

export const Step2Modal2: FC<Pick<ModalProps, "onClose" | "isOpen"> & { discount: string }> = ({
  isOpen,
  onClose,
  discount,
}) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent mx={6} bg="transparent">
      <ModalCloseButton borderRadius="full" color="Base/baseSecondary" />
      <Content discount={discount}>
        <Button w="full" variant="action" onClick={onClose}>
          Continue
        </Button>
      </Content>
    </ModalContent>
  </Modal>
)
