import React, { FC, MutableRefObject, ReactNode, useCallback, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Box, BoxProps } from "@chakra-ui/react"
import { CreateSubscriptionActions, loadScript, type PayPalScriptOptions } from "@paypal/paypal-js"

import { CONFIG } from "~/config"
import { useSelectedPrice, useUserEmail } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { PaymentStatus, useOnPaymentStatusChange } from "../useOnPaymentStatusChange"

const PAYPAL_BUTTON_STYLE = {
  color: "silver",
  height: 55, // 55px is maximum height :(
} as const
export const SCRIPT_OPTIONS: PayPalScriptOptions = {
  "client-id": CONFIG.paypal.clientId,
  currency: "USD", // FIXME
  intent: "subscription",
  components: "buttons",
  vault: true,
  "disable-funding": "credit,card",
}

type PayPalButtonProps = {
  planId: string
  userEmail: string
  scriptOptions: PayPalScriptOptions
  style?: object
  /* eslint-disable @typescript-eslint/ban-types */
  onSuccess?: Function
  catchError?: Function
  onError?: Function
  onButtonReady?: Function
  onClick?: Function
  onCancel?: Function
  /* eslint-enable @typescript-eslint/ban-types */
}

const createSubscription =
  ({ planId: plan_id, email: email_address }: { planId: string; email: string }) =>
  (_: unknown, actions: CreateSubscriptionActions) =>
    actions.subscription.create({
      plan_id,
      custom_id: email_address,
      subscriber: { email_address },
    })

export const PayPalSubscriptionButton: FC<PayPalButtonProps> = ({
  planId,
  userEmail: email,
  scriptOptions,
  style,
  onSuccess = () => undefined,
  onError = () => undefined,
  onButtonReady = () => undefined,
  onClick,
  onCancel,
}) => {
  const [isSdkReady, setSdkReady] = useState(false)

  useEffect(() => {
    if (window?.paypal === undefined) {
      loadScript(scriptOptions)
        .then(() => {
          setSdkReady(true)
          onButtonReady()
        })
        .catch((e) => {
          //FIXME throw new Error("Paypal SDK could not be loaded.")
          onError(e)
        })
    } else {
      onButtonReady()
    }
  }, [onButtonReady, scriptOptions, onError])

  const onApproveFn = useCallback(
    (data: any) => {
      /* data.orderID available here */
      onSuccess({ paymentId: data?.subscriptionID })
    },
    [onSuccess]
  )

  if (!isSdkReady && window?.paypal === undefined) {
    // if paypal sdk is not loaded then we do not render anything.
    return null
  }

  const Button = window?.paypal?.Buttons
    ? /* @ts-expect-error todo fix */
      window.paypal.Buttons.driver("react", {
        React,
        ReactDOM,
      })
    : () => null

  return (
    <Button
      fundingSource="paypal"
      createSubscription={createSubscription({ planId, email })}
      onApprove={onApproveFn}
      style={style}
      onClick={onClick}
      onCancel={onCancel}
      onError={onError}
    />
  )
}

const invisibleBoxStyles = {
  opacity: 0.0001,
  width: "100%",
  minWidth: "100%",
  height: "100%",
  display: "flex",
  zIndex: 4000,
  boxSizing: "border-box",
  padding: 0,
  overflow: "hidden",
  top: 0,
  left: 0,
  justifyContent: "center",
  alignItems: "center",
  "> div": {
    width: "100%",
  },
} as const
const InvisibleBoxWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <Box sx={{ ...invisibleBoxStyles }}>{children}</Box>
)

const InvisiblePaymentButton: FC<BoxProps & { buttonSlot: ReactNode; children: ReactNode }> = ({
  buttonSlot,
  children,
  ...props
}) => (
  <Box overflow="hidden" {...props}>
    <Box position="relative">
      <Box position="absolute" w="full">
        <InvisibleBoxWrapper>{buttonSlot}</InvisibleBoxWrapper>
      </Box>
    </Box>
    {children}
  </Box>
)

export const PaymentButtonPaypalContainer: FC<BoxProps> = ({ children, ...props }) => {
  const selectedPrice = useSelectedPrice()
  const onPaymentStatusChange = useOnPaymentStatusChange()
  const userEmail = useUserEmail()
  const planId = selectedPrice?.payment_providers?.paypal?.plan_id
  const log = useAmplitude()
  const onClickFn = useCallback(() => {
    log.paymentPaypalClick()
  }, [])
  const onPaymentStatusChangeFn = useCallback(
    (status: PaymentStatus) =>
      (...args: unknown[]) => {
        log.paymentPaypalStatusChange({ status })

        onPaymentStatusChange("PAYPAL", status, args)
      },
    []
  )

  return (
    <InvisiblePaymentButton
      {...props}
      buttonSlot={
        <PayPalSubscriptionButton
          scriptOptions={SCRIPT_OPTIONS}
          style={PAYPAL_BUTTON_STYLE}
          planId={planId!}
          userEmail={userEmail}
          onSuccess={onPaymentStatusChangeFn("COMPLETE")}
          onError={onPaymentStatusChangeFn("ERROR")}
          onClick={onClickFn}
          onCancel={onPaymentStatusChangeFn("CANCEL")}
          catchError={onPaymentStatusChangeFn("ERROR")}
        />
      }
    >
      {children}
    </InvisiblePaymentButton>
  )
}

export const PaymentButtonPaypalSlot: FC<BoxProps & { paypalRef: MutableRefObject<null> }> = ({
  children,
  paypalRef,
  ...props
}) => (
  <InvisiblePaymentButton {...props} buttonSlot={<Box ref={paypalRef}></Box>}>
    {children}
  </InvisiblePaymentButton>
)
