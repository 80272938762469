import React, { FC, ReactNode } from "react"
import { Button, Heading, Text, Image, Flex } from "@chakra-ui/react"
import { ButtonSlideUpContainer } from "~/components/ButtonSlideUpContainer"
import iconSuccess from "./assets/icon-success.svg?url"
import iconFailure from "./assets/icon-failure.svg?url"
import iconLoading from "./assets/icon-loading.svg?url"

const variantMap = {
  workbookSuccess: {
    title: "Congratulations!",
    description: (
      <span>
        Lóvi Workbook is sent to your email.
        <br />
        Let&apos;s start your personal skincare journey in the Lóvi app!
      </span>
    ),
    icon: iconSuccess,
  },
  workbookFail: {
    title: "Workbook wasn't purchased",
    description: <span>But let&apos;s start your personal skincare journey in Lóvi app!</span>,
    icon: iconFailure,
  },
  aiScanSuccess: {
    title: "Success",
    description: (
      <span>
        AI Product Scan is now unlocked in the Lovi App and the Workbook is in your email.
        <br />
        Open the app and start your personal skincare journey!
      </span>
    ),
    icon: iconSuccess,
  },
  aiScanFail: {
    title: "AI product scan and Workbook weren’t purchased ",
    description: <span>Don&apos;t worry, you will be able to purchase them in the app</span>,
    icon: iconFailure,
  },
  bundleSuccess: {
    title: "Success",
    description: (
      <span>
        AI Product Scan and AI Assistant are now unlocked in the Lovi App and the Workbook is in
        your email.
        <br />
        Start skincare journey!
      </span>
    ),
    icon: iconSuccess,
  },
  bundleFail: {
    title: "AI product scan and AI assistant weren’t purchased",
    description: <span>Don&apos;t worry, you will be able to purchase them in the app</span>,
    icon: iconFailure,
  },
  bundle2Success: {
    title: "Success",
    description: (
      <span>
        AI Product Scan and AI Assistant are now unlocked in the Lovi App and the Workbook is in
        your email.
        <br />
        Start skincare journey!
      </span>
    ),
    icon: iconSuccess,
  },
  bundle2Fail: {
    title: "AI Skincare Assistant & Cosmetic Scanner purchase problem",
    description: <span>Don&apos;t worry, you will be able to purchase everything in the app</span>,
    icon: iconFailure,
  },
  aiAssistantSuccess: {
    title: "Success",
    description: (
      <span>
        AI Assistant is now unlocked in the Lovi App and the Workbook is in your email.
        <br />
        Open the app and start your personal skincare journey!
      </span>
    ),
    icon: iconSuccess,
  },
  aiAssistantFail: {
    title: "AI Assistant and Workbook weren’t purchased",
    description: <span>Don&apos;t worry, you will be able to purchase them in the app</span>,
    icon: iconFailure,
  },
  aiAssistant2Success: {
    title: "Success",
    description: (
      <span>
        AI Assistant is now unlocked in the Lovi App.
        <br />
        Open the app and start your personal skincare journey!
      </span>
    ),
    icon: iconSuccess,
  },
  aiAssistant2Fail: {
    title: "AI Assistant wasn’t purchased",
    description: <span>Don&apos;t worry, you will be able to purchase them in the app</span>,
    icon: iconFailure,
  },
  shopifyProductsSuccess: {
    title: "Success!",
    description: (
      <>
        Your tailored skincare product kit is on its way! 🎉 While waiting, why not start your
        skincare journey with our app today?
      </>
    ),
    icon: iconSuccess,
  },
  shopifyProductsFail: {
    title: "No pressure!",
    description: (
      <>
        You can order your delivery of skincare products directly in the app during the first few
        days of your plan.
        <br />
        Start your journey with Lovi today!
      </>
    ),
    icon: iconFailure,
  },
  paymentProcessing: {
    title: "Processing Payment",
    description: <>Just a moment...</>,
    icon: iconLoading,
  },
  appSubscriptionSuccess: {
    title: "Success!",
    description: <>Your Lóvi Subscription is available now.</>,
    icon: iconSuccess,
  },
} as const

export type SuccessScreenVariant = keyof typeof variantMap
export const SUCCESS_SCREEN_VARIANTS = new Set<SuccessScreenVariant>([
  ...(Object.keys(variantMap) as SuccessScreenVariant[]),
])

export type AlternativeVariantProps = {
  variant: Exclude<SuccessScreenVariant, "default">
  onClick: () => void
  title?: ReactNode
  description?: ReactNode
}

export const AlternativeVariant: FC<AlternativeVariantProps> = ({ variant, onClick }) => {
  const { title, description, icon } = variantMap[variant]

  return <ImageTitlePage onClick={onClick} title={title} description={description} icon={icon} />
}

const ImageTitlePage: FC<{
  title: ReactNode
  description: ReactNode
  icon: string
  onClick: () => void
}> = ({ title, description, icon, onClick }) => {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      minH="$100vh"
      paddingTop={10}
      paddingX={6}
    >
      <Image src={icon} maxWidth="60%" />
      <Heading marginTop={6} marginBottom={4} textAlign="center" size="Header/Primary">
        {title}
      </Heading>
      {/* margin bottom for Download button compensation */}
      <Text marginBottom={110} textAlign="center" maxWidth="85%">
        {description}
      </Text>
      <ButtonSlideUpContainer visible showGradient={false}>
        <Button onClick={onClick} variant="next" w="full">
          Continue
        </Button>
      </ButtonSlideUpContainer>
    </Flex>
  )
}
