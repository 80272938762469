import { createIcon } from "@chakra-ui/react"
import React from "react"

export const MoneyIcon = createIcon({
  displayName: "MoneyIcon",
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M8.05273 14.1055C11.3633 14.1055 14.1055 11.3691 14.1055 8.05273C14.1055 4.74219 11.3633 2 8.04688 2C4.73633 2 2 4.74219 2 8.05273C2 11.3691 4.74219 14.1055 8.05273 14.1055ZM8.05273 12.916C5.35742 12.916 3.19531 10.748 3.19531 8.05273C3.19531 5.35742 5.35156 3.19531 8.04688 3.19531C10.7422 3.19531 12.916 5.35742 12.916 8.05273C12.916 10.748 10.748 12.916 8.05273 12.916ZM8.04688 11.3457C8.19922 11.3457 8.31641 11.2402 8.31641 11.0762V10.6836C9.26562 10.5957 9.97461 10.0566 9.97461 9.16602C9.97461 8.36328 9.45898 7.89453 8.43945 7.70703L8.31641 7.68359V6.20703C8.69141 6.27734 8.95508 6.5 9.06641 6.85156C9.14844 7.03906 9.27734 7.15625 9.48242 7.15625C9.7168 7.15625 9.875 6.99805 9.875 6.77539C9.875 6.70508 9.86328 6.64062 9.8457 6.57617C9.6875 5.9668 9.0957 5.5332 8.31641 5.45117V5.05859C8.31641 4.89453 8.19922 4.7832 8.04688 4.7832C7.88867 4.7832 7.77734 4.89453 7.77734 5.05859V5.45117C6.85742 5.53906 6.20703 6.10156 6.20703 6.92188C6.20703 7.67773 6.71094 8.12891 7.68945 8.35156L7.77734 8.36914V9.93359C7.30273 9.86328 7.02148 9.60547 6.92773 9.25977C6.85156 9.04883 6.7168 8.94336 6.51758 8.94336C6.27734 8.94336 6.11914 9.10742 6.11914 9.33594C6.11914 9.40625 6.13086 9.4707 6.1543 9.54102C6.32422 10.168 6.95117 10.6016 7.77734 10.6777V11.0762C7.77734 11.2402 7.89453 11.3457 8.04688 11.3457ZM7.77734 7.54297L7.73633 7.53125C7.26172 7.39648 7.05078 7.18555 7.05078 6.86914C7.05078 6.54102 7.32617 6.27734 7.77734 6.20117V7.54297ZM8.31641 8.50977L8.42773 8.5332C8.94336 8.67383 9.125 8.88477 9.125 9.22461C9.125 9.59961 8.85547 9.875 8.31641 9.93359V8.50977Z"
      fill="#ABBBCC"
    />
  ),
})

export const PoraLogoIcon = createIcon({
  displayName: "PoraLogoIcon",
  viewBox: "0 0 33 33",
  path: (
    <>
      <g clipPath="url(#clip0_11162_60046)">
        <circle cx="16.5" cy="16" r="16" fill="#00C0EB" />
        <path
          d="M9.56278 19.4693C9.93316 19.7732 10.6602 20.1565 11.6342 20.1565C13.9662 20.1565 15.3928 18.5309 15.3928 16.4823C15.3928 14.3544 13.9387 12.8477 11.7165 12.8477C10.5505 12.8477 9.83714 13.2838 9.46676 13.6407V12.9666H7.72461V22.3505H9.56278V19.4693ZM9.50791 16.5087C9.50791 15.306 10.2761 14.4205 11.5244 14.4205C12.7453 14.4205 13.5546 15.306 13.5546 16.5087C13.5546 17.7114 12.7864 18.5837 11.5381 18.5837C10.3173 18.5837 9.50791 17.7114 9.50791 16.5087Z"
          fill="white"
        />
        <path
          d="M15.9166 16.5219C15.9166 18.6102 17.453 20.1565 19.7439 20.1565C22.0073 20.1565 23.5848 18.5705 23.5848 16.4955C23.5848 14.4072 22.0622 12.8477 19.7439 12.8477C17.5353 12.8477 15.9166 14.4205 15.9166 16.5219ZM17.7548 16.5087C17.7548 15.3192 18.5367 14.4469 19.7439 14.4469C20.9236 14.4469 21.7467 15.3324 21.7467 16.5087C21.7467 17.685 20.9648 18.5573 19.7576 18.5573C18.5779 18.5573 17.7548 17.6982 17.7548 16.5087Z"
          fill="white"
        />
        <path
          d="M27.6409 12.9402C26.8864 12.9402 26.4749 13.1781 26.2142 13.5349V12.9666H24.3761V20.0376H26.2142V15.7289C26.2142 15.1209 26.4612 14.7905 27.1745 14.7905H27.6409V12.9402Z"
          fill="white"
        />
        <path
          d="M27.8801 17.7246C27.8801 19.1917 28.9638 20.1301 30.4316 20.1301C31.2821 20.1301 31.7759 19.7997 31.9817 19.5882V20.0376H33.5181V15.5835C33.5181 14.0107 32.8596 12.8873 30.7471 12.8873C29.0187 12.8873 28.1819 13.7728 28.1407 15.1077H29.8554C29.8554 14.7112 30.0612 14.2883 30.7471 14.2883C31.433 14.2883 31.7073 14.7244 31.7073 15.3324V15.6893C31.5153 15.5703 31.0626 15.3985 30.4041 15.3985C28.8815 15.3985 27.8801 16.403 27.8801 17.7246ZM29.6085 17.7114C29.6085 17.1431 30.0338 16.707 30.6648 16.707C31.2821 16.707 31.7348 17.1167 31.7348 17.7114C31.7348 18.3062 31.2821 18.7291 30.6785 18.7291C30.0475 18.7291 29.6085 18.3062 29.6085 17.7114Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11162_60046">
          <rect x="0.5" width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
})
