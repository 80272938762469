import React, { FC } from "react"
import { BlockPropertyContent } from "~/components"

import { BlockHeading, BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { ImagesCarousel } from "./ImagesCarousel"

export const ImagesCarouselBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper paddingY={6} variant="transparent" {...props}>
    <BlockHeading paddingX={6}>
      <BlockPropertyContent propKey="PB_IMAGES_CAROUSEL.title">
        Stay on top of your skincare journey with Lóvi!
      </BlockPropertyContent>
    </BlockHeading>
    <ImagesCarousel />
  </BlockWrapper>
)
