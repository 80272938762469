import React, { FC } from "react"

import { VFlex } from "~/components"
import { useBlockProperties } from "~/store/selectors"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"

import { HeaderLogo } from "./HeaderLogo"
import { GoalsList } from "./GoalsList"
import { HeaderHeadingPart2 } from "./HeaderHeadingPart"

const defaultTitle = `Your personalized
4-week program`

export const HeaderWithListContainer: FC = () => {
  const blockProperties = useBlockProperties()
  const title = blockProperties["PB_HEADER_WITH_LIST.title"] ?? defaultTitle
  return (
    <VFlex gap={6}>
      <HeaderLogo />
      <HeaderHeadingPart2>{title}</HeaderHeadingPart2>
      <GoalsList />
    </VFlex>
  )
}

export const HeaderWithListBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper
    borderBottomRadius="brand24"
    paddingX={6}
    paddingTop={4}
    paddingBottom={8}
    {...props}
  >
    <HeaderWithListContainer />
  </BlockWrapper>
)
