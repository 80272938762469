import React, {
  createContext,
  ReactNode,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  SetStateAction,
  Dispatch,
  FC,
} from "react"

const ThemeContext = createContext<{
  theme: Theme
  setTheme: (theme: Theme) => void
}>({
  theme: "pora",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTheme: () => {},
})

let isShiftDown = false
export function ThemeProvider({ theme: _theme, children }: { theme: Theme; children: ReactNode }) {
  const [theme, _setTheme] = useState(_theme)
  const setTheme = useCallback((themeToSet: Theme) => {
    _setTheme(themeToSet)
  }, [])

  useEffect(() => {
    setTheme(_theme)
  }, [setTheme, _theme])

  useDevModeThemeChange({ _setTheme })

  const value = useMemo(() => {
    return {
      theme,
      setTheme,
    }
  }, [setTheme, theme])

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

function useDevModeThemeChange({ _setTheme }: { _setTheme: Dispatch<SetStateAction<Theme>> }) {
  const handleUserKeyPress = useCallback((event: globalThis.KeyboardEvent) => {
    if (event.key === "Shift") {
      isShiftDown = true
      return
    }
    if (event.metaKey && isShiftDown && event.key === "k") {
      event.preventDefault()
      _setTheme((curTheme) => {
        if (curTheme === "lovi") {
          return "pora"
        } else {
          return "lovi"
        }
      })
    }
  }, [])

  const handleKeyUp = useCallback((event: globalThis.KeyboardEvent) => {
    if (event.key === "Shift") {
      isShiftDown = false
    }
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)
    window.addEventListener("keyup", handleKeyUp)
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress)
      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [handleUserKeyPress, handleKeyUp])
}

export function useCurrentTheme(): Theme {
  return useContext(ThemeContext).theme
}

export function useSetCurrentTheme() {
  return useContext(ThemeContext).setTheme
}

export function useIsLoviTheme() {
  return useCurrentTheme() === "lovi"
}

export function useIsPoraTheme() {
  return useCurrentTheme() === "pora"
}

export const ThemeContentSwitch: FC<{ pora: ReactNode; lovi: ReactNode; children?: ReactNode }> = ({
  pora,
  lovi,
  children = null,
}) => {
  const isLovi = useIsLoviTheme()
  const isPora = useIsPoraTheme()
  if (isLovi) {
    return <>{lovi}</>
  }
  if (isPora) {
    return <>{pora}</>
  }
  return <>{children}</>
}

export function useThemeContentSwitch<T>({ pora, lovi }: { pora: T; lovi: T }): T {
  const isLovi = useIsLoviTheme()
  return isLovi ? lovi : pora
}

const noop = (s: string) => s
const poraReplacer = (s: string) => s.replace("Pora", "Lovi")

export function usePoraReplacer(): (s: string) => string {
  const isLovi = useIsLoviTheme()
  return isLovi ? poraReplacer : noop
}
