export const shadows = {
  bigOnePrice: "0px 4px 20px 0px #00485826, 0px 1px 3px 0px #00485812",
  smallOne: "0px 1px 2px rgba(12, 35, 48, 0.05), 0px 2px 6px rgba(12, 35, 48, 0.1)",

  LoviShort: "0px 8px 28px 0px rgba(41, 40, 36, 0.04), 0px 1px 2px 0px rgba(41, 40, 36, 0.06)",
  LoviMedium: "0px 2px 6px 0px rgba(12, 35, 48, 0.10), 0px 1px 2px 0px rgba(12, 35, 48, 0.05)",
  LoviBig: "0px 8px 28px 0px rgba(41, 40, 36, 0.24), 0px 1px 2px 0px rgba(41, 40, 36, 0.06)",

  LoviActionButton:
    "0px 4px 24px 0px rgba(255, 255, 255, 0.8) inset, 0px -3px 12px 0px rgba(255, 218, 235, 1) inset",
}
