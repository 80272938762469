/* eslint-disable */

export const protobufPackage = "video_instruction";

export interface VideoInstructionScreen {
  stories: VideoInstructionScreen_Story[];
  /** @deprecated */
  video: string;
  /** @deprecated */
  title: string;
  /** @deprecated */
  list_items: VideoInstructionScreen_ListItem[];
  /** @deprecated */
  list_preview_capacity?: number | undefined;
  close_is_availible: boolean;
  back_is_availible: boolean;
}

export interface VideoInstructionScreen_Story {
  video: string;
  title: string;
  subtitle: string;
}

export interface VideoInstructionScreen_ListItem {
  /** @deprecated */
  image: string;
  /** @deprecated */
  text: string;
}

function createBaseVideoInstructionScreen(): VideoInstructionScreen {
  return {
    stories: [],
    video: "",
    title: "",
    list_items: [],
    list_preview_capacity: undefined,
    close_is_availible: false,
    back_is_availible: false,
  };
}

export const VideoInstructionScreen = {
  fromJSON(object: any): VideoInstructionScreen {
    return {
      stories: Array.isArray(object?.stories)
        ? object.stories.map((e: any) => VideoInstructionScreen_Story.fromJSON(e))
        : [],
      video: isSet(object.video) ? String(object.video) : "",
      title: isSet(object.title) ? String(object.title) : "",
      list_items: Array.isArray(object?.list_items)
        ? object.list_items.map((e: any) => VideoInstructionScreen_ListItem.fromJSON(e))
        : [],
      list_preview_capacity: isSet(object.list_preview_capacity) ? Number(object.list_preview_capacity) : undefined,
      close_is_availible: isSet(object.close_is_availible) ? Boolean(object.close_is_availible) : false,
      back_is_availible: isSet(object.back_is_availible) ? Boolean(object.back_is_availible) : false,
    };
  },

  toJSON(message: VideoInstructionScreen): unknown {
    const obj: any = {};
    if (message.stories) {
      obj.stories = message.stories.map((e) => e ? VideoInstructionScreen_Story.toJSON(e) : undefined);
    } else {
      obj.stories = [];
    }
    message.video !== undefined && (obj.video = message.video);
    message.title !== undefined && (obj.title = message.title);
    if (message.list_items) {
      obj.list_items = message.list_items.map((e) => e ? VideoInstructionScreen_ListItem.toJSON(e) : undefined);
    } else {
      obj.list_items = [];
    }
    message.list_preview_capacity !== undefined &&
      (obj.list_preview_capacity = Math.round(message.list_preview_capacity));
    message.close_is_availible !== undefined && (obj.close_is_availible = message.close_is_availible);
    message.back_is_availible !== undefined && (obj.back_is_availible = message.back_is_availible);
    return obj;
  },

  create<I extends Exact<DeepPartial<VideoInstructionScreen>, I>>(base?: I): VideoInstructionScreen {
    return VideoInstructionScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VideoInstructionScreen>, I>>(object: I): VideoInstructionScreen {
    const message = createBaseVideoInstructionScreen();
    message.stories = object.stories?.map((e) => VideoInstructionScreen_Story.fromPartial(e)) || [];
    message.video = object.video ?? "";
    message.title = object.title ?? "";
    message.list_items = object.list_items?.map((e) => VideoInstructionScreen_ListItem.fromPartial(e)) || [];
    message.list_preview_capacity = object.list_preview_capacity ?? undefined;
    message.close_is_availible = object.close_is_availible ?? false;
    message.back_is_availible = object.back_is_availible ?? false;
    return message;
  },
};

function createBaseVideoInstructionScreen_Story(): VideoInstructionScreen_Story {
  return { video: "", title: "", subtitle: "" };
}

export const VideoInstructionScreen_Story = {
  fromJSON(object: any): VideoInstructionScreen_Story {
    return {
      video: isSet(object.video) ? String(object.video) : "",
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
    };
  },

  toJSON(message: VideoInstructionScreen_Story): unknown {
    const obj: any = {};
    message.video !== undefined && (obj.video = message.video);
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    return obj;
  },

  create<I extends Exact<DeepPartial<VideoInstructionScreen_Story>, I>>(base?: I): VideoInstructionScreen_Story {
    return VideoInstructionScreen_Story.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VideoInstructionScreen_Story>, I>>(object: I): VideoInstructionScreen_Story {
    const message = createBaseVideoInstructionScreen_Story();
    message.video = object.video ?? "";
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    return message;
  },
};

function createBaseVideoInstructionScreen_ListItem(): VideoInstructionScreen_ListItem {
  return { image: "", text: "" };
}

export const VideoInstructionScreen_ListItem = {
  fromJSON(object: any): VideoInstructionScreen_ListItem {
    return {
      image: isSet(object.image) ? String(object.image) : "",
      text: isSet(object.text) ? String(object.text) : "",
    };
  },

  toJSON(message: VideoInstructionScreen_ListItem): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<VideoInstructionScreen_ListItem>, I>>(base?: I): VideoInstructionScreen_ListItem {
    return VideoInstructionScreen_ListItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<VideoInstructionScreen_ListItem>, I>>(
    object: I,
  ): VideoInstructionScreen_ListItem {
    const message = createBaseVideoInstructionScreen_ListItem();
    message.image = object.image ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
