import { useMemo } from "react"
import { CountryCode } from "~/types"
if (!("fbq" in window)) {
  ;(window as Window)["fbq"] = () => undefined
}
type PriceInfo = { priceId: string; currency: string; value: number | string } // FIXME value must be number
/* FIXME rename to useFbPixel() */
export const useMetaPixel = () => {
  const init = ({
    userId,
    email,
    country,
  }: {
    userId: string
    country?: CountryCode
    email: string
  }) => {
    if (window.fb_id) {
      const opts: Record<string, string> = { em: email, external_id: userId }
      if (country && country != "UNKNOWN_COUNTRY") {
        opts["country"] = country.toLocaleLowerCase()
      }
      window.fbq("init", window.fb_id, opts)
    }
  }

  const completeRegistration = () => {
    window.fbq("trackCustom", "CompleteRegistration")
  }

  const contact = (email: string) => {
    window.fbq("track", "Contact", { email })
  }

  const pageView = () => {
    window.fbq("track", "PageView")
  }

  const initiateCheckout = ({ priceId, currency, value }: PriceInfo) => {
    //console.log('MetaPixel: sending InitiateCheckout', { priceId, currency, value });

    window.fbq("track", "InitiateCheckout", {
      content_category: "plan",
      content_ids: [priceId],
      currency,
      value,
      num_items: 1,
    })
  }
  const purchase = ({ priceId, currency, value }: PriceInfo) => {
    //console.log('MetaPixel: sending Purchase', { priceId, currency, value });

    window.fbq("track", "Purchase", {
      content_name: "plan",
      content_type: "product",
      content_ids: [priceId],
      currency,
      value,
      num_items: 1,
    })
  }

  return useMemo(
    () => ({
      completeRegistration,
      initiateCheckout,
      contact,
      purchase,
      pageView,
      init,
    }),
    []
  )
}
