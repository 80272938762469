import { CurrencyCode, getCookie, getQueryParams, noop } from "~/utils"
import { getTestQueryParams } from "~/utils/getTestQueryParams"
import { appendParams, getUserIdFromUrl } from "~/utils/url"
import { ApiError, PaymentApiError, UnauthorizedApiError } from "~/errors"

import { CONFIG } from "~/config"
import type { CountryCode } from "~/types"
import { PaywallV2 } from "~/generated/paywall"
import { SavePaymentDataRequest } from "~/generated/paid_users_processor/requests"
import { ConditionsResponse as ProgramConditionsResponse } from "~/generated/interview_service"
import {
  PaymentIntentRequest,
  PaymentIntentResponse,
  PurchaseRequest,
  PurchaseResponse,
} from "~/generated/recurly"

// Universal Api Methods
type URLString = string
type SimpleObject = Record<string, string>
const getUrl = appendParams

const validateResponse = (resp: Response) => {
  if (resp.status === 401) {
    throw new UnauthorizedApiError(`Unauthorized request to ${resp.url}`)
  }

  if (!resp.ok) {
    throw new ApiError(`Response from ${resp.url} with status ${resp.status}`)
  }
}

const apiPost = async (url: URLString, body: object, params: SimpleObject = {}) => {
  const resp = await fetch(getUrl(url, params), {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
  validateResponse(resp)

  const json = await resp.json()
  return json
}
// End Universal Api Methods

const defaultParams = {
  client: "web",
  format: "json",
}
const defaultParamsWithTest = { ...getTestQueryParams(), ...defaultParams }

const paths = {
  getCountryCode: appendParams(CONFIG.endpoints.getCountryCode, defaultParams),
  getPaywallData: appendParams(CONFIG.endpoints.getPaywallData, defaultParams),
  getProgramData: appendParams(CONFIG.endpoints.getProgramData, defaultParams),

  log: CONFIG.endpoints.log,
  marketingAttributes: CONFIG.endpoints.marketingAttributes,

  savePaymentStatus: appendParams(CONFIG.endpoints.savePaymentData, defaultParamsWithTest),

  stripeCreateIntent: appendParams(CONFIG.endpoints.stripeCreateIntent, defaultParamsWithTest),
  stripeSkipTrialIntent: appendParams(
    CONFIG.endpoints.stripeCreateSkipTrialIntent,
    defaultParamsWithTest
  ),
  paltaMakePurchase: appendParams(CONFIG.endpoints.paltaMakePurchase, defaultParamsWithTest),
  recurlyMakePurchase: appendParams(CONFIG.endpoints.recurlyMakePurchase, defaultParamsWithTest),
  recurlyBindUser: appendParams(CONFIG.endpoints.recurlyBindUser, defaultParamsWithTest),
}

const JSON_POST_OPTIONS = {
  method: "POST",
  mode: "cors",
  cache: "no-cache",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
} as const

export let sendLog: (event: string, params?: Record<string, unknown>) => void = noop

const user_id = getUserIdFromUrl() ?? ""
if (paths.log) {
  sendLog = (event, _params = {}) => {
    const params = { ..._params }
    params["page_url"] = window.location.href
    params["user_id"] = user_id
    navigator.sendBeacon(paths.log, JSON.stringify({ event, params }))
  }
}

export const sendMarketingAttributes = () => {
  const params: Record<string, string | undefined> = getQueryParams() ?? {}
  params["_fbc"] = getCookie("_fbc")
  params["_fbp"] = getCookie("_fbp")
  params["_ttp"] = getCookie("_ttp")
  params["_url"] = window.location.href
  if (Object.keys(params).length > 0) {
    sendLog("MARKETING_ATTRIBUTES", params)
    if (paths.marketingAttributes) {
      const p = { userID: user_id, params }
      navigator.sendBeacon(paths.marketingAttributes, JSON.stringify(p))
    }
  }
}

const getPaywallDataFetchParams = ({
  user_id,
  interview_variant,
  country,
}: {
  user_id: UserId
  interview_variant: string
  country: CountryCode
}) => {
  return appendParams(paths.getPaywallData, {
    ...getTestQueryParams(),
    user_id,
    interview_variant,
    country,
  })
}

export const getPaywallData = async (
  user_id: UserId,
  interview_variant: string,
  country: CountryCode
): Promise<PaywallV2> => {
  /* TODO: use apiGet() */
  const resp = await fetch(getPaywallDataFetchParams({ user_id, interview_variant, country }))

  if (!resp.ok) {
    throw new Error(`Can't fetch data from ${resp.url}`)
  }
  const data = await resp.json()
  return PaywallV2.fromJSON(data)
}

export const getCountryCode = async (): Promise<CountryCode> => {
  const test_country = getTestQueryParams()["test_country"]
  if (test_country) {
    return test_country.toUpperCase() as CountryCode
  }

  /* TODO: use apiGet() */
  const resp = await fetch(paths.getCountryCode)
  if (!resp.ok) {
    throw new Error(`Can't fetch data from ${resp.url}`)
  }
  const data = await resp.json()
  return data?.country_code as CountryCode
}

export const savePaymentData = async ({
  email,
  provider,
  paymentId,
  userId,
}: {
  email: string
  provider: string
  paymentId: string | undefined
  userId: string
}): Promise<undefined> => {
  const body = SavePaymentDataRequest.toJSON(
    SavePaymentDataRequest.create({ email, provider, payment_id: paymentId, user_id: userId })
  )

  /* TODO: use apiGet() */
  const resp = await fetch(paths.savePaymentStatus, {
    ...JSON_POST_OPTIONS,
    body: JSON.stringify(body),
  })
  if (!resp.ok) {
    throw new Error(`Can't fetch data from ${resp.url}`)
  }
  return
}

type StripeClientSecret = string

export const stripeCreateIntent = async ({
  paymentMethodId,
  priceId,
  email,
  userId,
  initialPriceCents,
}: {
  paymentMethodId: PaymentMethodId
  priceId: PriceId
  email: string
  userId: string
  initialPriceCents: number
}): Promise<StripeClientSecret> => {
  const body = {
    payment_method_id: paymentMethodId,
    stripe_price_id: priceId,
    email,
    user_id: userId,
    initial_price_cents: initialPriceCents,
  }
  /* TODO: use apiGet() */
  const resp = await fetch(paths.stripeCreateIntent, {
    ...JSON_POST_OPTIONS,
    body: JSON.stringify(body),
  })
  const data = await resp.json()
  if (!resp.ok) {
    if (data.message) {
      throw new PaymentApiError(data.message, { cause: data })
    } else {
      throw new ApiError(`Can't fetch data from ${resp.url}`)
    }
  }

  return data.clientSecret
}

export const stripeCreateSkipTrialIntent = async ({
  priceId: user_id,
  userId: price_id,
}: {
  /* TODO fix types */
  priceId: string
  userId: string
}): Promise<StripeClientSecret> => {
  const body = {}
  const url = appendParams(paths.stripeSkipTrialIntent, { user_id, price_id })
  /* TODO: use apiGet() */
  const resp = await fetch(url, {
    ...JSON_POST_OPTIONS,
    body: JSON.stringify(body),
  })
  const data = await resp.json()
  if (!resp.ok) {
    if (data.message) {
      throw new PaymentApiError(data.message, { cause: data })
    } else {
      throw new ApiError(`Can't fetch data from ${resp.url}`)
    }
  }

  return data.clientSecret
}

export const paltaMakePurchase = async ({
  priceId: price_id,
  userId: user_id,
}: {
  /* TODO fix types */
  priceId: string
  userId: string
}): Promise<boolean> => {
  const url = appendParams(paths.paltaMakePurchase, { user_id, price_id })
  /* TODO: use apiGet() */
  const resp = await fetch(url, {
    ...JSON_POST_OPTIONS,
    body: JSON.stringify({}),
  })
  const data = await resp.json()
  if (!resp.ok) {
    if (data.message) {
      throw new PaymentApiError(data.message, { cause: data })
    } else {
      throw new ApiError(`Can't fetch data from ${resp.url}`)
    }
  }

  return true
}

export class ProgramApiError extends ApiError {}

export const getProgramData = async ({
  userId: user_id,
  country,
}: {
  /* TODO fix types */
  userId: string
  country?: string
}): Promise<ProgramConditionsResponse> => {
  const url = appendParams(paths.getProgramData, { user_id, delivery_region: country })
  /* TODO: use apiGet() */
  const resp = await fetch(url)
  if (!resp.ok) {
    throw new ApiError(`Can't fetch data from ${resp.url}`)
  }

  let result
  try {
    const _data = await resp.json()
    result = ProgramConditionsResponse.fromJSON(_data)
  } catch (error) {
    throw new ProgramApiError("Can't parse data", { cause: error as Error })
  }

  return result
}

export const recurlyBindUser = async ({
  userId,
  token,
}: {
  userId: string
  token: string
}): Promise<PaymentIntentResponse> => {
  const body = PaymentIntentRequest.fromJSON({ user_id: userId, token })
  const data = await apiPost(paths.recurlyBindUser, body)
  return PaymentIntentResponse.fromJSON(data)
}

export const recurlyMakePurchase = async ({
  userId,
  priceId,
  currency,
}: {
  userId: string
  priceId: string
  currency: CurrencyCode
}): Promise<PurchaseResponse> => {
  const body = PurchaseRequest.fromJSON({
    user_id: userId,
    subscription: { plan_code: priceId },
    currency,
  })
  const data = await apiPost(paths.recurlyMakePurchase, body)
  return PurchaseResponse.fromJSON(data)
}
