import React, { FC, ReactNode } from "react"
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHidden,
  ModalOverlay,
} from "~/components/ModalHidden"
import { noop } from "~/utils"

export const ModalHiddenWrapper: FC<{
  hidden?: boolean
  onClose?: () => void
  children: ReactNode
}> = ({ hidden = false, onClose = noop, children }) => {
  return (
    <ModalHidden
      isOpen={!hidden}
      onClose={onClose}
      size="full"
      motionPreset="none"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent borderRadius="none" maxW="450px">
        <ModalCloseButton
          color="Base/baseSecondary"
          bgColor="Base/neutralSecondary"
          borderRadius="full"
          top={2}
          right={4}
          sx={{ "--close-button-size": "40px" }}
        />
        <ModalBody padding={6} zIndex={-1}>
          {children}
        </ModalBody>
      </ModalContent>
    </ModalHidden>
  )
}
