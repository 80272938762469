import { LOCALE } from "./locale"

const select = (num: number) => new Intl.PluralRules(LOCALE).select(num)

export const plurals = (
  variants: Map<Intl.LDMLPluralRule, string>,
  num = 0,
  formatted?: string
): string => {
  const variant = variants.get(select(num)) ?? ""
  return variant.replaceAll("%%", formatted ?? String(num))
}

export const makePlurals =
  (variants: Map<Intl.LDMLPluralRule, string> | string[]) => (num: number, formatted?: string) => {
    let variantsMap = new Map<Intl.LDMLPluralRule, string>()
    let [one = "", two = "", few = "", many = "", other = "", zero = ""] = []
    if (variants instanceof Map) {
      variantsMap = variants
    } else {
      if (variants.length <= 3) {
        ;[one = "", other = "", zero = ""] = variants
      } else {
        ;[one = "", two = "", few = "", many = "", other = "", zero = ""] = variants
      }
      variantsMap = new Map<Intl.LDMLPluralRule, string>([
        ["one", one],
        ["two", two],
        ["few", few],
        ["many", many],
        ["other", other],
        ["zero", zero],
      ])
    }

    return plurals(variantsMap, num, formatted)
  }
