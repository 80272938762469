import { Box, HStack } from "@chakra-ui/react"
import React, { FC, ReactNode } from "react"

export const ListItemGeneric: FC<{
  children: ReactNode
  title: string
  description?: string
}> = ({ children, title, description }) => (
  <HStack gap={4} userSelect="none">
    {children}
    <Box flexGrow={1} rowGap={0.5}>
      <Box textStyle="mediumCondensed" fontWeight="medium">
        {title}
      </Box>
      {description && (
        <Box textStyle="Paragraph/Tertiary" color="Base/baseSecondary" marginTop={0.5}>
          {description}
        </Box>
      )}
    </Box>
  </HStack>
)
