import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { PAYMENT_STATUSES } from "~/store"
import { usePaymentInfo } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { nullAsUndefined } from "~/utils"
import { useNavigateWithSearchParams } from "~/utils/useNavigateWithSearchParams"

import { ProcessingError } from "./ProcessingError"
import { ProcessingInProgress } from "./ProcessingInProgress"

export const Processing = () => {
  const [searchParams] = useSearchParams()
  const payment = usePaymentInfo()
  const paymentStatus = nullAsUndefined(searchParams.get("test_payment_status")) ?? payment.status
  const navigate = useNavigateWithSearchParams()
  const log = useAmplitude()

  useEffect(() => {
    log.offerResult({ status: paymentStatus })

    if (paymentStatus === PAYMENT_STATUSES.NOT_READY) {
      navigate("/")
    }

    if (paymentStatus === PAYMENT_STATUSES.SUCCESS) {
      navigate("/processing-success")
    }
  }, [log, navigate, paymentStatus])

  if (paymentStatus === PAYMENT_STATUSES.PENDING || paymentStatus === PAYMENT_STATUSES.SUCCESS) {
    return <ProcessingInProgress />
  }

  return <ProcessingError />
}
