import { ComponentStyleConfig } from "@chakra-ui/react"

const pora = {
  baseStyle: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    borderRadius: 8,
    bgColor: "Base/neutralSecondary",
    color: "Base/basePrimary",
    textStyle: "Subtitle/Secondary",
    _checked: {
      bgColor: "Base/accentPrimary",
      color: "Base/neutralPrimary",
    },
    py: 5,
    px: 3,
  },
}

const lovi = {
  baseStyle: {
    display: "flex",
    height: 16,
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: "9px",

    borderRadius: "full",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "transparent",
    background: "white",

    color: "Base/basePrimary",
    textStyle: "Subtitle/Secondary",

    shadow: "LoviShort",
    _checked: {
      borderColor: "Base/accentPrimary",
      color: "Base/accentPrimary",
    },
  },
}

export const RadioAppButtonTheme = (theme: Theme): ComponentStyleConfig =>
  theme === "lovi" ? lovi : pora
