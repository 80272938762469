import { Box, Flex, Heading, Image } from "@chakra-ui/react"
import React, { FC } from "react"
import { CardPreview } from "~/components/CardPreview"
import { SkinIssues } from "~/generated/paywall"
import acneSrc from "./images/acne.webp?url"
import blackheadsSrc from "./images/blackheads.webp?url"
import drynessSrc from "./images/dryness.webp?url"
import faceShapeSrc from "./images/faceShape.webp?url"
import hypersensitivitySrc from "./images/hypersensitivity.webp?url"
import oilySrc from "./images/oily.webp?url"
import pigmentationSrc from "./images/pigmentation.webp?url"
import postacneSrc from "./images/postacne.webp?url"
import rednessSrc from "./images/redness.webp?url"
import swellingSrc from "./images/swelling.webp?url"
import wrinklesSrc from "./images/wrinkles.webp?url"

export { SkinIssues }

type IssueData = {
  image: string
  title: string
}

const ISSUE_MAP = new Map<SkinIssues, IssueData>([
  [SkinIssues.UNRECOGNIZED, { image: "", title: "" }],
  [SkinIssues.SKIN_ISSUE_UNSPECIFIED, { image: "", title: "" }],
  [SkinIssues.SKIN_ISSUE_ACNE, { image: acneSrc, title: "Get Rid of Acne & Pimples" }],
  [SkinIssues.SKIN_ISSUE_BLACKHEADS, { image: blackheadsSrc, title: "Say No to Blackheads" }],
  [SkinIssues.SKIN_ISSUE_DRYNESS, { image: drynessSrc, title: "Say No to Dry or Flaky Skin" }],
  [SkinIssues.SKIN_ISSUE_FACE_SHAPE, { image: faceShapeSrc, title: "Get Rid of Double Chin" }],
  [
    SkinIssues.SKIN_ISSUE_HYPERSENSITIVITY,
    {
      image: hypersensitivitySrc,
      title: "Say No to Skin Hypersensitivity",
    },
  ],
  [SkinIssues.SKIN_ISSUE_OILY, { image: oilySrc, title: "Get Rid of Oily & Shiny Skin" }],
  [SkinIssues.SKIN_ISSUE_PIGMENTATION, { image: pigmentationSrc, title: "Say No to Pigmentation" }],
  [SkinIssues.SKIN_ISSUE_POSTACNE, { image: postacneSrc, title: "Say No to Post Acne Marks" }],
  [SkinIssues.SKIN_ISSUE_REDNESS, { image: rednessSrc, title: "Get Rid of Rosacea & Redness" }],
  [SkinIssues.SKIN_ISSUE_SWELLING, { image: swellingSrc, title: "Get Rid of Swelling" }],
  [
    SkinIssues.SKIN_ISSUE_WRINKLES,
    {
      image: wrinklesSrc,
      title: "Say No to Wrinkles and Look Younger",
    },
  ],
])

const getIssue = (n: SkinIssues): IssueData =>
  ISSUE_MAP.get(n) ?? ISSUE_MAP.get(SkinIssues.SKIN_ISSUE_UNSPECIFIED)!

export const SkinIssueCard: FC<{ issue: SkinIssues }> = ({ issue }) => {
  const { image: src, title } = getIssue(issue)
  if (!src) {
    return null
  }
  return (
    <CardPreview minW="196px" maxW="196px">
      <Image src={src} alt="" />
      <Flex direction="column" gap={1}>
        <Heading as="h5" size="MediumSubtitleSecondary" variant="text">
          {title}
        </Heading>
        <Box textStyle="MediumHintPrimary" color="Base/accentActive">
          14-days course
        </Box>
      </Flex>
    </CardPreview>
  )
}
