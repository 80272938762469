import { Box, Flex, Heading, Image, List, ListItem, Text } from "@chakra-ui/react"
import React from "react"

type ProgramBenefitsItem = { img: string; title: string; description: string }

const ProgramBenefitsListItem = ({ img, title, description }: ProgramBenefitsItem) => (
  <ListItem>
    <Flex gap={4}>
      <Image w="68px" h="88px" src={img} alt={title} />
      <Box>
        <Heading as="h4" size="MediumSubtitlePrimary" variant="text" mb={2}>
          {title}
        </Heading>
        <Text textStyle="RegularParagraph" textColor="Base/baseSecondary">
          {description}
        </Text>
      </Box>
    </Flex>
  </ListItem>
)

export const ProgramBenefitsBetterMe = ({
  benefits,
  header,
}: {
  header: string
  benefits: ProgramBenefitsItem[]
}) => {
  return (
    <>
      <Heading size="MediumHeaderSecondary" textAlign="center" marginBottom={4}>
        {header}
      </Heading>
      <List width="full" spacing={8}>
        {benefits.map((benefit, i) => (
          <ProgramBenefitsListItem {...benefit} key={i} />
        ))}
      </List>
    </>
  )
}
