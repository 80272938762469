import type { Product } from "./HarmfulProductsBlock"

export const data: Product[] = [
  {
    producer: "Kiehl's",
    title: "Micro-Dose Anti-Aging Retinol Serum",
    imageSrc: "https://storage.pora.ai/5dd86dda6becbb743cf09f850a33a529.webp",
    tags: [
      { title: "Pregnancy warning", icon: "warning" },
      { title: "Overpriced", icon: "price" },
      { title: "Not suitable for sensitive skin" },
      { title: "May cause irritation" },
    ],
  },
  {
    producer: "Check",
    title: "Your skincare product",
    imageSrc: "https://storage.pora.ai/9ef7c96518eef72b0cdc3cfedb43185b.svg",
    tags: [
      {
        title: "Scan your product to see all the potential warnings",
        icon: "warning",
      },
    ],
    button: true,
  },
  {
    producer: "HydroPeptide",
    title: "Firma-Bright, 20% Vitamin C Booster",
    imageSrc: "https://storage.pora.ai/ea897195278e4b8600c4c9addfbcf7ac.webp",
    tags: [
      { title: "Not suitable for flaky skin", icon: "warning" },
      { title: "Overpriced", icon: "price" },
      { title: "High concentration of Vitamin C" },
      { title: "May cause irritation" },
    ],
  },
  {
    producer: "The Ordinary",
    title: "AHA 30%+BHA 2% Peeling Solution",
    imageSrc: "https://storage.pora.ai/70cbdf5dd39b19219c207ef83759b0de.webp",
    tags: [
      { title: "Extreme acids", icon: "warning" },
      { title: "Redness" },
      { title: "May cause irritation" },
      { title: "Destroys skin barrier" },
    ],
  },
  {
    producer: "CeraVe",
    title: "Moisturizing Cream",
    imageSrc: "https://storage.pora.ai/0e1634a78e2ba79de098c9f98cf54f15.webp",
    tags: [
      {
        title: `Not suitable for sensitive,
flaky, dry, normal skin`,
        icon: "warning",
      },
      { title: "May cause breakouts" },
      { title: "Heavy texture" },
    ],
  },
  {
    producer: "Paula's Choice",
    title: "Skin Perfecting 2% BHA Liquid Exfoliant",
    imageSrc: "https://storage.pora.ai/7fb3246789a1204cb8d69e687650be5f.webp",
    tags: [
      {
        title: "Not suitable for sensitive, flaky, dry, normal skin",
        icon: "warning",
      },
      { title: "Apply SPF if used in mornings" },
    ],
  },
  {
    producer: "RoC",
    title: "Retinol Correxion Max Daily Hydration Creme",
    imageSrc: "https://storage.pora.ai/700905d4a0e8c4a70323c3278fe1a15f.webp",
    tags: [{ title: "Pregnancy warning", icon: "warning" }, { title: "May cause irritation" }],
  },
]
