import { MotionValue, animate, useMotionValue } from "framer-motion"
import { useEffect, useState } from "react"

export const useProgress = ({
  duration,
  delay = 0,
  onComplete = () => void 0,
  map,
}: {
  duration: number
  delay?: number
  onComplete?: () => void
  map?: (n: number) => number
}) => {
  const motionValue = useMotionValue(0)
  const [progress, setProgress] = useState(motionValue.get())
  useEffect(() => motionValue.on("change", setProgress), [motionValue])
  useEffect(() => {
    const controls = animate(motionValue, 1, {
      duration,
      delay,
      onComplete,
    })
    return controls.stop
  }, [delay, duration, motionValue, onComplete])

  return {
    motionValue,
    progress,
    progress100: progress * 100,
    mapped: map && map(progress),
  }
}

export const useStateFromMV = <T>(mv: MotionValue<T>) => {
  const [result, setResult] = useState<T>(mv.get())

  useEffect(() => mv.on("change", setResult), [mv])
  return result
}
