import InterRegular from "./fonts/Inter-Regular.woff2?url"
import InterMedium from "./fonts/Inter-Medium.woff2?url"
import InterSemiBold from "./fonts/Inter-SemiBold.woff2?url"
import PlatformLcWeb from "./fonts/Platform-Medium-Web.woff2?url"
import ESRebondGrotesqueMedium from "./fonts/ESRebondGrotesque-Medium.woff2?url"
import ESRebondGrotesqueSemiBold from "./fonts/ESRebondGrotesque-Semibold.woff2?url"

const fontFace = (family: string, src: string, weight: number) => `
@font-face {
  font-family: ${family};
  font-style: normal;
  font-display: fallback;
  font-weight: ${weight};
  src: url("${src}") format("woff2");
}
`
const getThemeHeadingFamily = (theme: Theme) =>
  theme === "lovi" ? "ES Rebond Grotesque" : "Platform LC Web"

const pora = [fontFace(getThemeHeadingFamily("pora"), PlatformLcWeb, 500)]
const lovi = [
  fontFace(getThemeHeadingFamily("lovi"), ESRebondGrotesqueMedium, 500),
  fontFace(getThemeHeadingFamily("lovi"), ESRebondGrotesqueSemiBold, 600),
]

const getThemeFontStyles = (theme: Theme) => (theme === "lovi" ? lovi : pora)

export const commonFontFaces = (theme: Theme) =>
  [
    /* https://rsms.me/inter/ */
    fontFace("Inter", InterRegular, 400),
    fontFace("Inter", InterMedium, 500),
    fontFace("Inter", InterSemiBold, 600),
    ...getThemeFontStyles(theme),
  ].join(" ")

export const getFonts = (theme: Theme) => ({
  heading: `'${getThemeHeadingFamily(theme)}', sans-serif`,
  body: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif`,
})
