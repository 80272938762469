import React, { FC } from "react"
import { Box, BoxProps, Divider, Flex, Grid, GridItem, Image } from "@chakra-ui/react"
import { Level, LevelVariant } from "~/components"

import { ReactComponent as LinesDivider } from "./lines.min.svg"
import { BeforeAfterState, getBeforeState, getAfterState } from "./mapData"
import { SkinIssues, WellnessProfile_WellnessLevel } from "~/generated/paywall"
import { useWellnessProfileData } from "~/store/selectors"

const SkinPropertyItem: FC<{ title: string; value: string }> = ({ title, value }) => (
  <Flex direction="column" gap={0.5}>
    <Box textStyle="Header/Quaternary">{title}</Box>
    <Box textStyle="Paragraph/Secondary">{value}</Box>
  </Flex>
)

const Chips: FC<BoxProps> = (props) => (
  <Box
    paddingY="6px"
    paddingX="10px"
    borderRadius="full"
    textStyle="Subtitle/Tertiary"
    {...props}
  />
)

const mapLevelToTitle = new Map<LevelVariant, string>([
  ["low", "Low"],
  ["moderate", "Moderate"],
  ["good", "Good"],
  ["good2", "Good"],
  ["excellent", "Excellent"],
])

const GoalAndLevel: FC<{ title: string; value: string; level: LevelVariant }> = ({
  title,
  value,
  level,
}) => (
  <Flex direction="column" gap="10px">
    <SkinPropertyItem title={title} value={value} />
    <Divider borderColor="Base/baseDisabled" opacity={0.3} w="full" />
    <Flex direction="column" gap={0.5}>
      <SkinPropertyItem title="Skin Wellness Level" value={mapLevelToTitle.get(level) ?? ""} />
      <Level variant={level} width="full" />
    </Flex>
  </Flex>
)

export const BeforeAfterBasedOnGoalLayout: FC<{
  after: BeforeAfterState
  before: BeforeAfterState
}> = ({ after, before }) => (
  <Grid templateColumns="1fr 30px 1fr" paddingX={6} paddingTop={0} paddingBottom={5} columnGap={1}>
    <GridItem marginY={3} alignSelf="center" justifySelf="center" flexGrow={1}>
      <Chips color="Base/accentPrimary" bgColor="Base/neutralPrimary">
        Now
      </Chips>
    </GridItem>
    <GridItem marginY={3} alignSelf="center" justifySelf="center">
      <Divider borderColor="Base/accentPrimary" h="20px" orientation="vertical" />
    </GridItem>
    <GridItem marginY={3} alignSelf="center" justifySelf="center" flexGrow={1}>
      <Chips bgColor="Base/accentPrimary" color="Base/neutralPrimary">
        Your goal
      </Chips>
    </GridItem>
    <GridItem justifySelf="center">
      <Image src={before.image} width="147px" borderRadius="brandSmall" />
    </GridItem>
    <GridItem alignSelf="center" justifySelf="center">
      <LinesDivider />
    </GridItem>
    <GridItem justifySelf="center">
      <Image src={after.image} width="147px" borderRadius="brandSmall" />
    </GridItem>
    <GridItem marginTop={6}>
      <GoalAndLevel title={before.goal.title} value={before.goal.value} level={before.level} />
    </GridItem>
    <GridItem marginTop={6} alignSelf="center" justifySelf="center">
      <Box>
        <Divider borderColor="Base/baseDisabled" opacity={0.3} h="100px" orientation="vertical" />
      </Box>
    </GridItem>
    <GridItem marginTop={6}>
      <GoalAndLevel title={after.goal.title} value={after.goal.value} level={after.level} />
    </GridItem>
  </Grid>
)

export const BeforeAfterBasedOnGoal: FC<{
  goal: SkinIssues
  level: WellnessProfile_WellnessLevel
}> = ({ goal, level }) => {
  const before = getBeforeState(goal, level)
  const after = getAfterState(goal, level)

  return <BeforeAfterBasedOnGoalLayout before={before} after={after} />
}

export const BeforeAfterBasedOnGoalContainer: FC = () => {
  const { goal, level } = useWellnessProfileData()

  return <BeforeAfterBasedOnGoal goal={goal} level={level} />
}
