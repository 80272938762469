import { useCallback } from "react"
import { PAYMENT_FORM_ID, PRICE_SELECT_ID } from "~/constants"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { usePaymentFormPopupDisclosure } from "./Blocks/PaymentFormBlock"

const scrollToElement = (elId = PAYMENT_FORM_ID): boolean => {
  const el = document.getElementById(elId)
  if (el) {
    el.scrollIntoView({ block: "start", behavior: "smooth" })
    return true
  }

  return false
}

/* TODO: rename this file to useScrollToOffer */
export const useScrollToOffer = (source: string) => {
  const { scrollToOffer: logScrollToOffer } = useAmplitude()
  const { open: openPopup } = usePaymentFormPopupDisclosure()
  return useCallback(
    (opts?: { scroll: boolean; openModal: boolean }) => {
      const { scroll, openModal } = opts ?? { scroll: true, openModal: true }
      logScrollToOffer({ source })
      if (scroll) {
        scrollToElement(PAYMENT_FORM_ID) || scrollToElement(PRICE_SELECT_ID)
      }
      if (openModal) {
        openPopup()
      }
    },
    [logScrollToOffer, source, openPopup]
  )
}
