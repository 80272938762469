import { Box, Flex, Heading, List, ListIcon, ListItem, ListProps } from "@chakra-ui/react"
import React, { FC, ReactNode } from "react"
import { ReactComponent as Icon1 } from "./icon1.svg"
import { ReactComponent as Icon2 } from "./icon2.svg"
import { ReactComponent as Icon3 } from "./icon3.svg"

const getIcon = (n: number) => {
  switch (n) {
    case 1:
      return Icon1
    case 2:
      return Icon2
    case 3:
      return Icon3
    default:
      return undefined
  }
}

export const OrderedListItem: FC<{
  icon: 1 | 2 | 3
  title: ReactNode
  subtitle?: string
  children?: ReactNode
}> = ({ icon, title, subtitle, children }) => (
  <ListItem>
    <Flex direction="column" gap={3}>
      <Flex direction="row" alignItems="center" gap={3}>
        <ListIcon as={getIcon(icon)} boxSize={9} m={0} />
        <Flex direction="column" alignItems="start" gap={0.5} textStyle="MediumSubtitlePrimary">
          {title && (
            <Heading as="h4" size="MediumSubtitlePrimary" variant="text">
              {title}
            </Heading>
          )}
          {subtitle && (
            <Box textStyle="RegularParagraphSecondary" color="Base/baseSecondary">
              {subtitle}
            </Box>
          )}
        </Flex>
      </Flex>

      {children}
    </Flex>
  </ListItem>
)
export const OrderedList: FC<ListProps> = (props) => <List spacing={8} {...props} />
