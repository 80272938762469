import React, { FC, ReactNode } from "react"
import { Box, Center, Portal, Slide } from "@chakra-ui/react"

export const ButtonSlideUpContainer: FC<{
  visible: boolean
  showGradient?: boolean
  customGradient?: string
  children: ReactNode
  enterDelay?: number
}> = ({ visible, children, enterDelay = 0, showGradient = true, customGradient }) => (
  <Portal>
    <Slide
      direction="bottom"
      in={visible}
      transition={{
        exit: { repeatType: "reverse", duration: 0.1 },
        enter: { delay: enterDelay },
      }}
    >
      <Center
        width="100%"
        paddingY={4}
        bgGradient={
          customGradient ??
          (showGradient
            ? "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 22.97%, #FFF 100%);"
            : undefined)
        }
      >
        <Box maxWidth="450px" width="100%" paddingX={6}>
          {children}
        </Box>
      </Center>
    </Slide>
  </Portal>
)
