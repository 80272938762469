import { FC, useCallback, useEffect } from "react"
import { useNavigateWithSearchParams } from "~/utils/useNavigateWithSearchParams"
import { SuccessScreenVariant } from "~/pages/AppLinkPage/AlternativeVariantProps"

export const useNavigateSuccess = () => {
  const navigate = useNavigateWithSearchParams()
  return useCallback(
    (variant?: SuccessScreenVariant) => {
      navigate("/app/", variant ? { variant } : {})
    },
    [navigate]
  )
}

export const NavigateSuccessLink: FC<{ variant?: SuccessScreenVariant }> = ({ variant }) => {
  const navigate = useNavigateSuccess()

  useEffect(() => {
    navigate(variant)
  }, [navigate, variant])

  return null
}
