import React, { useMemo } from "react"
import { WeArePalta } from "~/pages/Main/WeArePalta/WeArePalta"
import { useThemeContentSwitch } from "~/theme/theme-from-interview/themeProvider"
import floImg from "./images/flo.webp?url"
import loonaImg from "./images/loona.webp?url"
import loviImg from "./images/lovi.webp?url"
import poraImg from "./images/pora.webp?url"
import simpleImg from "./images/simple.webp?url"
import weatherImg from "./images/weather.webp?url"
import zingImg from "./images/zing.webp?url"

const companies1 = [
  { title: "Flo: Period\nTracker", img: floImg },
  { title: "Simple:\nFasting", img: simpleImg },
]
const companies2 = [
  { title: "Weather\nWell", img: weatherImg },
  { title: "Zing \nCoach", img: zingImg },
  { title: "Loona:\nCalm sleep", img: loonaImg },
]

const pora = { title: "pora: Smart Skincare", img: poraImg }
const lovi = { title: "Lóvi: Smart Skincare", img: loviImg }

export const WeArePaltaContainer = () => {
  const currentApp = useThemeContentSwitch({ pora, lovi })
  const companies = useMemo(() => [...companies1, currentApp, ...companies2], [currentApp])
  return <WeArePalta companies={companies} />
}
