import {
  AspectRatio,
  Box,
  BoxProps,
  Divider,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react"
import React, { FC, ReactNode, SVGProps } from "react"
import { formatPrice } from "~/utils"
import { VFlex } from "~/components/VFlex"

import bookCoverSrc from "./assets/book_cover.webp?url"
import { ReactComponent as Icon1 } from "./assets/icon1.svg"
import { ReactComponent as Icon2 } from "./assets/icon2.svg"
import { ReactComponent as Icon3 } from "./assets/icon3.svg"
import shieldSrc from "./assets/shield.svg?url"
import { PriceContentV2_PriceItem } from "~/generated/paywall"

export const WorkbookHeader: FC = () => (
  <VFlex gap={4}>
    <Heading size="Header/Primary">See faster results with Lóvi Workbook</Heading>
    <Box textStyle="Paragraph/Primary">
      Achieve skin care goals faster with this exclusive offer, only for Lóvi users!
    </Box>
  </VFlex>
)
const ListItem2: FC<{ icon: FC<SVGProps<SVGSVGElement>>; children: ReactNode }> = ({
  icon,
  children,
}) => (
  <ListItem display="flex" alignItems="center">
    <ListIcon as={icon} w={10} h={10} />
    {children}
  </ListItem>
)
const WhatIncludedList: FC = () => (
  <VFlex gap={3}>
    <Heading size="Header/Tertiary" textAlign="center">
      What’s included?
    </Heading>
    <List spacing={2.5}>
      <ListItem2 icon={Icon1}>Personalized cosmetics plan</ListItem2>
      <ListItem2 icon={Icon2}>Skincare Plan</ListItem2>
      <ListItem2 icon={Icon3}>Self-Care Tips</ListItem2>
    </List>
  </VFlex>
)

export const PriceText: FC<{ slot1: ReactNode; slot2: ReactNode }> = ({ slot1, slot2 }) => (
  <VFlex gap={1} alignItems="center">
    <Heading as="div" size="Header/Tertiary">
      {slot1}
    </Heading>
    <Box textStyle="Paragraph/Secondary" color="Base/baseSecondary">
      {slot2}
    </Box>
  </VFlex>
)

const PriceText1: FC<{ price: PriceContentV2_PriceItem }> = ({
  price: { currency_code, subscription_price_cents, previous_price, economy_percent },
}) => (
  <PriceText
    slot1={`One-time price of ${formatPrice(currency_code, subscription_price_cents / 100)}`}
    slot2={
      <>
        Original price was {previous_price} —
        <Text as="span" color="Base/accentPrimary">
          {" "}
          save {economy_percent}%!
        </Text>
      </>
    }
  />
)
const PriceText2: FC<{
  price: PriceContentV2_PriceItem
  priceDiscounted: PriceContentV2_PriceItem
}> = ({ price, priceDiscounted }) => (
  <PriceText
    slot1={
      <>
        One-time price of{" "}
        <Text as="span" color="Base/baseSecondary" textDecoration="line-through">
          {formatPrice(price.currency_code, price.subscription_price_cents / 100)}
        </Text>{" "}
        {formatPrice(priceDiscounted.currency_code, priceDiscounted.subscription_price_cents / 100)}
      </>
    }
    slot2={
      <>
        Original price was {price.previous_price} — save {priceDiscounted.economy_percent}%!
      </>
    }
  />
)

export const PriceInfo: FC<{
  price: PriceContentV2_PriceItem
  priceDiscounted: PriceContentV2_PriceItem
  isDiscounted: boolean
}> = ({ price, priceDiscounted, isDiscounted = false }) => (
  <VFlex alignContent="center" gap={3} justifyContent="flex-start">
    {isDiscounted ? (
      <PriceText2 price={price} priceDiscounted={priceDiscounted} />
    ) : (
      <PriceText1 price={price} />
    )}

    {/* TODO use VStack with divider prop */}
    <Divider w="280px" color="Base/baseDisabled" />

    <Box textStyle="Paragraph/Secondary" color="Base/baseSecondary" textAlign="center">
      You can keep this workbook even if you
      <br />
      decide Lóvi isn’t for you.
    </Box>
  </VFlex>
)
export const WorkbookCard: FC<{ children: ReactNode }> = ({ children }) => (
  <VFlex px={6} py={4} gap={5} layerStyle="attentionFrame">
    <AspectRatio ratio={280 / 215}>
      <Image src={bookCoverSrc} alt="Cover" />
    </AspectRatio>
    <WhatIncludedList />

    {children}
  </VFlex>
)
const DiscountShield: FC<BoxProps> = (props) => (
  <Box
    position="absolute"
    bgImage={shieldSrc}
    bgPosition="top"
    bgRepeat="no-repeat"
    color="Base/neutralPrimary"
    textStyle="Subtitle/Primary"
    w="71px"
    h="91px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    {...props}
  />
)
export const DiscountCard: FC<{ discount: string }> = ({ discount }) => (
  <Box
    px={6}
    py={6}
    id="discount-card"
    layerStyle="attentionFrame"
    textStyle="Paragraph/Primary"
    minH="92px"
    alignItems="center"
  >
    <Box position="relative" top={-6} alignSelf="start">
      <DiscountShield position="absolute">
        {discount}%
        <br />
        OFF
      </DiscountShield>
    </Box>
    <Box paddingLeft="115px">Additional {discount}% discount applied!</Box>
  </Box>
)
