import React, { FC } from "react"
import { Box, Flex, Heading } from "@chakra-ui/react"
import { VFlex } from "~/components"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { HeaderLogo } from "./HeaderLogo"
import { useBlockProperties } from "~/store/selectors"

const defaultProps = {
  title: `Achieve your
dream skin with a
personalized program`,
  image: "https://storage.pora.ai/b4ec791b8c262ba9f44a401f3e4d6a9a.webp",
} as const

const HeaderWithPhoto: FC<{ title?: string; image?: string }> = ({
  title = defaultProps.title,
  image = defaultProps.image,
}) => (
  <Flex w="full">
    <VFlex
      paddingTop={4}
      paddingBottom={8}
      paddingLeft={6}
      gap={5}
      alignItems="flex-start"
      grow={1}
    >
      <HeaderLogo />
      <Heading as="h1" size="Header/Secondary" whiteSpace="pre-wrap">
        {title}
      </Heading>
    </VFlex>
    <Box
      w="135px"
      bgImage={image}
      bgPosition="bottom right"
      bgSize="contain"
      bgRepeat="no-repeat"
    ></Box>
  </Flex>
)

export const HeaderWithPhotoContainer: FC = () => {
  const blockProperties = useBlockProperties()
  return (
    <HeaderWithPhoto
      title={blockProperties["PB_HEADER_WITH_PHOTO.title"]}
      image={blockProperties["PB_HEADER_WITH_PHOTO.image"]}
    />
  )
}

export const HeaderWithPhotoBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper padding={0} borderBottomRadius="brand24" overflow="hidden" {...props}>
    <HeaderWithPhotoContainer />
  </BlockWrapper>
)
