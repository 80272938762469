import React, { FC } from "react"
import { Box, Heading } from "@chakra-ui/react"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { ActionButton } from "~/pages/Main/ActionButton"
import { BlockPropertyContent, VFlex } from "~/components"

import product1 from "./assets/1.webp?url"
import product2 from "./assets/2.webp?url"

export const ProductsImages1Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper maxW="450px" variant="transparent" paddingY={6} {...props}>
    <VFlex gap={4} w="full" alignItems="center">
      <Heading size="Header/Secondary" textAlign="center">
        Discover your best
        <br />
        skincare matches
      </Heading>

      <Box
        h="250px"
        w="full"
        bgSize="360px"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgImage={product1}
      />
    </VFlex>
  </BlockWrapper>
)

export const ProductsImages2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper maxW="450px" variant="transparent" paddingY={4} {...props}>
    <VFlex gap={2} w="full">
      <VFlex gap={4} w="full">
        <VFlex gap={1} w="full" alignItems="center">
          <Heading size="Header/Secondary" textAlign="center">
            <BlockPropertyContent propKey="PB_PRODUCTS_IMAGES_2.title">
              Other products fit
            </BlockPropertyContent>
          </Heading>
          <Box>Check out products that fit your skin best</Box>
        </VFlex>
        <Box
          w="full"
          h="167px"
          bgSize="auto 167px"
          bgPosition="center"
          bgRepeat="no-repeat"
          bgImage={product2}
        />
      </VFlex>
      <Box mx={6}>
        <ActionButton actionSource="PB_PRODUCTS_IMAGES_2">
          <BlockPropertyContent propKey="PB_PRODUCTS_IMAGES_2.button_text">
            Get Full Product List
          </BlockPropertyContent>
        </ActionButton>
      </Box>
    </VFlex>
  </BlockWrapper>
)
