import React, { FC, ReactNode, SVGProps } from "react"
import { Box, Icon, SimpleGrid } from "@chakra-ui/react"

import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { VFlex } from "~/components"

import { ReactComponent as IconPerson } from "./assets/iconPerson.svg"
import { ReactComponent as IconChart } from "./assets/iconChart.svg"
import { ReactComponent as IconJars } from "./assets/iconJars.svg"
import { ReactComponent as IconMessage } from "./assets/iconMessage.svg"

const BenefitItem: FC<{ children: ReactNode; icon: FC<SVGProps<SVGSVGElement>> }> = ({
  children,
  icon,
}) => (
  <VFlex gap={2} layerStyle="attentionFrame" padding={4}>
    <Icon as={icon} boxSize="40px" />
    <Box textStyle="Paragraph/Primary">{children}</Box>
  </VFlex>
)

const StaticBenefits: FC = () => (
  <VFlex gap={4}>
    <Box textStyle="Paragraph/Secondary">
      Lóvi cutting-edge AI designs an optimized skincare plan tailored to your goals, needs and skin
      conditions.
    </Box>
    <Box textStyle="Subtitle/Secondary">Reach your skincare goals with:</Box>
    <SimpleGrid columns={2} spacing={2}>
      <BenefitItem icon={IconPerson}>
        Enhance skincare with expert-approved videos, tips, and tricks.
      </BenefitItem>
      <BenefitItem icon={IconChart}>
        Achieve lasting results with tailored skincare tips and see changes with the face scanner.
      </BenefitItem>
      <BenefitItem icon={IconMessage}>
        Gain knowledge anytime with the AI Lóvi Assistant, an AI certified by a medical test.
      </BenefitItem>
      <BenefitItem icon={IconJars}>
        Find skincare products using our AI scanner - no brand bias, just pure efficacy.
      </BenefitItem>
    </SimpleGrid>
  </VFlex>
)

export const StaticBenefitsBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper paddingY={6} header={<>10x Your Results With Lóvi</>} {...props}>
    <StaticBenefits />
  </BlockWrapper>
)
