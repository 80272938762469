import { Box } from "@chakra-ui/react"
import React, { FC } from "react"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { Highlight } from "../HeaderBlock"
import { SummaryListContainer as SummaryList } from "./SummaryList"

export const SummaryBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} header="Your summary">
    <SummaryList />
    <Box marginTop={8} textAlign="center" textStyle="Subtitle/Secondary" color="Base/baseSecondary">
      and{" "}
      <Highlight isMedium color="Base/basePrimary">
        130+
      </Highlight>{" "}
      factors to build your program
    </Box>
  </BlockWrapper>
)
