import React, { useMemo } from "react"
import { AttributedStringType, Timer, TimerFloatContainer } from "~/components"
import { getProgramEndDate } from "~/utils"
import { PROGRAM_LENGTH_WEEKS } from "~/constants"
import { useTimerData, useHeaderGT2Data } from "~/store/selectors"

import { HeaderGT2 } from "./HeaderGT2"
import { useScrollToOffer } from "../scrollToOffer"

const title = {
  parts: [
    {
      content: "Your ",
      format: [],
    },
    {
      content: "personalized\n",
      format: [
        {
          format: {
            emphasis: true,
            $case: "emphasis",
          },
        },
      ],
    },
    {
      content: `${PROGRAM_LENGTH_WEEKS}-week program`,
      format: [],
    },
  ],
} as AttributedStringType

export const HeaderGT2Container = () => {
  const { main_goal_text: resultsText, main_goal_img: graph } = useHeaderGT2Data()
  const handleTimerBlockClick = useScrollToOffer("header_timer")
  const startDate = useMemo(() => new Date(), [])
  const endDate = useMemo(() => getProgramEndDate(PROGRAM_LENGTH_WEEKS), [])
  const { minutes } = useTimerData()
  const timerDescription = `Your personalized skin care program has been reserved for the next ${minutes} minutes`

  return (
    <>
      <TimerFloatContainer variant={2} />
      <HeaderGT2
        onTimerBlockClick={handleTimerBlockClick}
        title={title}
        timerDescription={timerDescription}
        timeBlock={<Timer />}
        graphImg={graph}
        startDate={startDate}
        endDate={endDate}
        resultsText={resultsText}
        programLengthWeeks={PROGRAM_LENGTH_WEEKS}
      />
    </>
  )
}
