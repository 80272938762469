import React, { FC } from "react"
import { Image } from "@chakra-ui/react"
import { BlockHeading, BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { useBlockProperties } from "~/store/selectors"
import { BlockPropertyContent, VFlex } from "~/components"
import { ActionButton } from "../../ActionButton"

const ImageBase: FC<BlockWrapperProps & { image: string; title: string }> = ({
  image,
  title,
  ...props
}) => {
  return (
    <BlockWrapper header={title} paddingX={0} paddingBottom={0} overflow="hidden" {...props}>
      <Image src={image} alt={title} />
    </BlockWrapper>
  )
}

export const ImageBlock: FC<BlockWrapperProps> = (props) => {
  const blockProperties = useBlockProperties()

  const title = blockProperties["PB_IMAGE.title"] ?? "Why Lóvi?"
  const image =
    blockProperties["PB_IMAGE.image"] ??
    "https://storage.pora.ai/a7330c8e5807ab50452d1787f0780eae.webp"
  return <ImageBase title={title} image={image} {...props} />
}

export const ImageDuplicateBlock: FC<BlockWrapperProps> = (props) => {
  const blockProperties = useBlockProperties()

  const title = blockProperties["PB_IMAGE_DUPLICATE.title"] ?? "Why Lóvi?"
  const image =
    blockProperties["PB_IMAGE_DUPLICATE.image"] ??
    "https://storage.pora.ai/a7330c8e5807ab50452d1787f0780eae.webp"
  return <ImageBase title={title} image={image} {...props} />
}

export const Image2Block: FC<BlockWrapperProps> = (props) => {
  const blockProperties = useBlockProperties()

  const header = blockProperties["PB_IMAGE2.title"] ?? "Your Recommended Skincare"
  const image =
    blockProperties["PB_IMAGE2.image"] ??
    "https://storage.pora.ai/92aa06a197b3a29d25272df6b3113771.webp"
  return (
    <BlockWrapper paddingX={6} paddingY={6} variant="transparent" overflow="hidden" {...props}>
      <BlockHeading marginBottom={3}>{header}</BlockHeading>
      <Image src={image} alt="" title={header} />
    </BlockWrapper>
  )
}

export const ImageWithButtonBlock: FC<BlockWrapperProps> = (props) => {
  const blockProperties = useBlockProperties()
  const image =
    blockProperties["PB_IMAGE_WITH_BUTTON.image"] ??
    "https://storage.pora.ai/81314436963dc716465ab8f9930eaad5.webp"
  return (
    <BlockWrapper {...props}>
      <VFlex bgColor="Base/accentPrimaryBg2" borderRadius="brand24" p={6} gap={6}>
        <Image src={image} alt="" />
        <ActionButton actionSource="PB_IMAGE_WITH_BUTTON">
          <BlockPropertyContent propKey="PB_IMAGE_WITH_BUTTON.button_text">
            Get My Plan
          </BlockPropertyContent>
        </ActionButton>
      </VFlex>
    </BlockWrapper>
  )
}
