/* eslint-disable */
import { Any } from "./google/protobuf/any";
import { Request } from "./networking";
import { Image } from "./presentation";
import { VideoInstructionScreen } from "./video_instruction";

export const protobufPackage = "face_camera";

export interface FaceCameraScreen {
  variant: FaceCameraScreen_ScannerVariant;
  allow_closing: boolean;
  allow_photo_retake: boolean;
  photo_attempts: number;
  analysis_steps: string[];
  target_positions: FaceCameraScreen_TargetPositions;
  allow_arkit_camera: boolean;
  scan_endpoint_params: { [key: string]: string };
  video_instruction: VideoInstructionScreen | undefined;
  should_show_instructions_on_appear: boolean;
  consent_action: Any | undefined;
  request: FaceScanUploadFrontImageRequest | undefined;
}

export enum FaceCameraScreen_TargetPositions {
  TARGET_POSITIONS_NONE = "TARGET_POSITIONS_NONE",
  TARGET_POSITIONS_FRONT = "TARGET_POSITIONS_FRONT",
  TARGET_POSITIONS_LEFT_FRONT_RIGHT = "TARGET_POSITIONS_LEFT_FRONT_RIGHT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function faceCameraScreen_TargetPositionsFromJSON(object: any): FaceCameraScreen_TargetPositions {
  switch (object) {
    case 0:
    case "TARGET_POSITIONS_NONE":
      return FaceCameraScreen_TargetPositions.TARGET_POSITIONS_NONE;
    case 1:
    case "TARGET_POSITIONS_FRONT":
      return FaceCameraScreen_TargetPositions.TARGET_POSITIONS_FRONT;
    case 2:
    case "TARGET_POSITIONS_LEFT_FRONT_RIGHT":
      return FaceCameraScreen_TargetPositions.TARGET_POSITIONS_LEFT_FRONT_RIGHT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FaceCameraScreen_TargetPositions.UNRECOGNIZED;
  }
}

export function faceCameraScreen_TargetPositionsToJSON(object: FaceCameraScreen_TargetPositions): string {
  switch (object) {
    case FaceCameraScreen_TargetPositions.TARGET_POSITIONS_NONE:
      return "TARGET_POSITIONS_NONE";
    case FaceCameraScreen_TargetPositions.TARGET_POSITIONS_FRONT:
      return "TARGET_POSITIONS_FRONT";
    case FaceCameraScreen_TargetPositions.TARGET_POSITIONS_LEFT_FRONT_RIGHT:
      return "TARGET_POSITIONS_LEFT_FRONT_RIGHT";
    case FaceCameraScreen_TargetPositions.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FaceCameraScreen_ScannerVariant {
  SCANNER_VARIANT_NOTHING = "SCANNER_VARIANT_NOTHING",
  SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS = "SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS",
  SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS = "SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function faceCameraScreen_ScannerVariantFromJSON(object: any): FaceCameraScreen_ScannerVariant {
  switch (object) {
    case 0:
    case "SCANNER_VARIANT_NOTHING":
      return FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_NOTHING;
    case 1:
    case "SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS":
      return FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS;
    case 2:
    case "SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS":
      return FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FaceCameraScreen_ScannerVariant.UNRECOGNIZED;
  }
}

export function faceCameraScreen_ScannerVariantToJSON(object: FaceCameraScreen_ScannerVariant): string {
  switch (object) {
    case FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_NOTHING:
      return "SCANNER_VARIANT_NOTHING";
    case FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS:
      return "SCANNER_VARIANT_POINT_AIM_THREE_POSITIONS";
    case FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS:
      return "SCANNER_VARIANT_HAPTIC_FEEDBACK_THREE_POSITIONS";
    case FaceCameraScreen_ScannerVariant.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface FaceCameraScreen_ScanEndpointParamsEntry {
  key: string;
  value: string;
}

export interface FaceScanUploadFrontImageRequest {
  request: Request | undefined;
  url: string;
  frontal_image: Image | undefined;
}

function createBaseFaceCameraScreen(): FaceCameraScreen {
  return {
    variant: FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_NOTHING,
    allow_closing: false,
    allow_photo_retake: false,
    photo_attempts: 0,
    analysis_steps: [],
    target_positions: FaceCameraScreen_TargetPositions.TARGET_POSITIONS_NONE,
    allow_arkit_camera: false,
    scan_endpoint_params: {},
    video_instruction: undefined,
    should_show_instructions_on_appear: false,
    consent_action: undefined,
    request: undefined,
  };
}

export const FaceCameraScreen = {
  fromJSON(object: any): FaceCameraScreen {
    return {
      variant: isSet(object.variant)
        ? faceCameraScreen_ScannerVariantFromJSON(object.variant)
        : FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_NOTHING,
      allow_closing: isSet(object.allow_closing) ? Boolean(object.allow_closing) : false,
      allow_photo_retake: isSet(object.allow_photo_retake) ? Boolean(object.allow_photo_retake) : false,
      photo_attempts: isSet(object.photo_attempts) ? Number(object.photo_attempts) : 0,
      analysis_steps: Array.isArray(object?.analysis_steps) ? object.analysis_steps.map((e: any) => String(e)) : [],
      target_positions: isSet(object.target_positions)
        ? faceCameraScreen_TargetPositionsFromJSON(object.target_positions)
        : FaceCameraScreen_TargetPositions.TARGET_POSITIONS_NONE,
      allow_arkit_camera: isSet(object.allow_arkit_camera) ? Boolean(object.allow_arkit_camera) : false,
      scan_endpoint_params: isObject(object.scan_endpoint_params)
        ? Object.entries(object.scan_endpoint_params).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      video_instruction: isSet(object.video_instruction)
        ? VideoInstructionScreen.fromJSON(object.video_instruction)
        : undefined,
      should_show_instructions_on_appear: isSet(object.should_show_instructions_on_appear)
        ? Boolean(object.should_show_instructions_on_appear)
        : false,
      consent_action: isSet(object.consent_action) ? Any.fromJSON(object.consent_action) : undefined,
      request: isSet(object.request) ? FaceScanUploadFrontImageRequest.fromJSON(object.request) : undefined,
    };
  },

  toJSON(message: FaceCameraScreen): unknown {
    const obj: any = {};
    message.variant !== undefined && (obj.variant = faceCameraScreen_ScannerVariantToJSON(message.variant));
    message.allow_closing !== undefined && (obj.allow_closing = message.allow_closing);
    message.allow_photo_retake !== undefined && (obj.allow_photo_retake = message.allow_photo_retake);
    message.photo_attempts !== undefined && (obj.photo_attempts = Math.round(message.photo_attempts));
    if (message.analysis_steps) {
      obj.analysis_steps = message.analysis_steps.map((e) => e);
    } else {
      obj.analysis_steps = [];
    }
    message.target_positions !== undefined &&
      (obj.target_positions = faceCameraScreen_TargetPositionsToJSON(message.target_positions));
    message.allow_arkit_camera !== undefined && (obj.allow_arkit_camera = message.allow_arkit_camera);
    obj.scan_endpoint_params = {};
    if (message.scan_endpoint_params) {
      Object.entries(message.scan_endpoint_params).forEach(([k, v]) => {
        obj.scan_endpoint_params[k] = v;
      });
    }
    message.video_instruction !== undefined && (obj.video_instruction = message.video_instruction
      ? VideoInstructionScreen.toJSON(message.video_instruction)
      : undefined);
    message.should_show_instructions_on_appear !== undefined &&
      (obj.should_show_instructions_on_appear = message.should_show_instructions_on_appear);
    message.consent_action !== undefined &&
      (obj.consent_action = message.consent_action ? Any.toJSON(message.consent_action) : undefined);
    message.request !== undefined &&
      (obj.request = message.request ? FaceScanUploadFrontImageRequest.toJSON(message.request) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<FaceCameraScreen>, I>>(base?: I): FaceCameraScreen {
    return FaceCameraScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FaceCameraScreen>, I>>(object: I): FaceCameraScreen {
    const message = createBaseFaceCameraScreen();
    message.variant = object.variant ?? FaceCameraScreen_ScannerVariant.SCANNER_VARIANT_NOTHING;
    message.allow_closing = object.allow_closing ?? false;
    message.allow_photo_retake = object.allow_photo_retake ?? false;
    message.photo_attempts = object.photo_attempts ?? 0;
    message.analysis_steps = object.analysis_steps?.map((e) => e) || [];
    message.target_positions = object.target_positions ?? FaceCameraScreen_TargetPositions.TARGET_POSITIONS_NONE;
    message.allow_arkit_camera = object.allow_arkit_camera ?? false;
    message.scan_endpoint_params = Object.entries(object.scan_endpoint_params ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = String(value);
        }
        return acc;
      },
      {},
    );
    message.video_instruction = (object.video_instruction !== undefined && object.video_instruction !== null)
      ? VideoInstructionScreen.fromPartial(object.video_instruction)
      : undefined;
    message.should_show_instructions_on_appear = object.should_show_instructions_on_appear ?? false;
    message.consent_action = (object.consent_action !== undefined && object.consent_action !== null)
      ? Any.fromPartial(object.consent_action)
      : undefined;
    message.request = (object.request !== undefined && object.request !== null)
      ? FaceScanUploadFrontImageRequest.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseFaceCameraScreen_ScanEndpointParamsEntry(): FaceCameraScreen_ScanEndpointParamsEntry {
  return { key: "", value: "" };
}

export const FaceCameraScreen_ScanEndpointParamsEntry = {
  fromJSON(object: any): FaceCameraScreen_ScanEndpointParamsEntry {
    return { key: isSet(object.key) ? String(object.key) : "", value: isSet(object.value) ? String(object.value) : "" };
  },

  toJSON(message: FaceCameraScreen_ScanEndpointParamsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<FaceCameraScreen_ScanEndpointParamsEntry>, I>>(
    base?: I,
  ): FaceCameraScreen_ScanEndpointParamsEntry {
    return FaceCameraScreen_ScanEndpointParamsEntry.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FaceCameraScreen_ScanEndpointParamsEntry>, I>>(
    object: I,
  ): FaceCameraScreen_ScanEndpointParamsEntry {
    const message = createBaseFaceCameraScreen_ScanEndpointParamsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseFaceScanUploadFrontImageRequest(): FaceScanUploadFrontImageRequest {
  return { request: undefined, url: "", frontal_image: undefined };
}

export const FaceScanUploadFrontImageRequest = {
  fromJSON(object: any): FaceScanUploadFrontImageRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      url: isSet(object.url) ? String(object.url) : "",
      frontal_image: isSet(object.frontal_image) ? Image.fromJSON(object.frontal_image) : undefined,
    };
  },

  toJSON(message: FaceScanUploadFrontImageRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.url !== undefined && (obj.url = message.url);
    message.frontal_image !== undefined &&
      (obj.frontal_image = message.frontal_image ? Image.toJSON(message.frontal_image) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<FaceScanUploadFrontImageRequest>, I>>(base?: I): FaceScanUploadFrontImageRequest {
    return FaceScanUploadFrontImageRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<FaceScanUploadFrontImageRequest>, I>>(
    object: I,
  ): FaceScanUploadFrontImageRequest {
    const message = createBaseFaceScanUploadFrontImageRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.url = object.url ?? "";
    message.frontal_image = (object.frontal_image !== undefined && object.frontal_image !== null)
      ? Image.fromPartial(object.frontal_image)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
