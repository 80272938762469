import { Center, Portal, Slide, SlideProps } from "@chakra-ui/react"
import React, { FC, ReactNode } from "react"

export const FloatPanel: FC<{
  visible: boolean
  direction?: SlideProps["direction"]
  children: ReactNode
}> = ({ visible, direction = "top", children }) => (
  <Portal>
    <Slide
      direction={direction}
      in={visible}
      transition={{ exit: { repeatType: "reverse", duration: 0.1 } }}
    >
      <Center width="100%">{children}</Center>
    </Slide>
  </Portal>
)
