import React, { FC, MouseEventHandler, useCallback, useState } from "react"
import { PanInfo, motion } from "framer-motion"
import { Box, BoxProps, Flex } from "@chakra-ui/react"
import { VFlex } from "~/components"

const Slide: FC<BoxProps> = (props) => (
  <Box
    position="absolute"
    transitionProperty="all"
    transitionDuration="500ms"
    transitionTimingFunction="cubic-bezier(.4,0,.2,1)"
    sx={{
      '&[data-position="0"]': {
        transform:
          "perspective(1300px) translateZ(-120px) translateX(+105px) rotate3d(0, 1, 0, 15deg)",
        zIndex: 0,
      },
      '&[data-position="1"]': {
        transform: "perspective(1300px) translateZ(0) translateX(0)  rotate3d(0, 1, 0, 0)",
        zIndex: 1,
      },
      '&[data-position="2"]': {
        transform:
          "perspective(1300px) translateZ(-120px) translateX(-105px) rotate3d(0, 1, 0, -15deg)",
        zIndex: 0,
      },
    }}
    {...props}
  />
)

const Dot: FC<BoxProps> = (props) => (
  <Box
    w={2}
    h={2}
    borderRadius="full"
    bgColor="Base/accentPrimaryBg2"
    _selected={{ bgColor: "Base/accentPrimary" }}
    {...props}
  />
)

export const ImagesCarousel: FC = () => {
  const total = 3
  const [step, setStep] = useState(0)
  const onClick: MouseEventHandler<HTMLDivElement> = useCallback((ev) => {
    const direction = ev.clientX - ev.currentTarget.clientWidth / 2 < 0 ? 1 : -1
    setStep((step) => (step + direction + total) % total)
  }, [])

  const onPan = useCallback((e: PointerEvent, panInfo: PanInfo) => {
    const direction = Math.sign(-panInfo.offset.x)
    setStep((step) => (step + direction + total) % total)
  }, [])

  return (
    <VFlex w="full" onClick={onClick} userSelect="none">
      <motion.div style={{ width: "100%", overflow: "hidden" }} onPanStart={onPan}>
        <Flex
          w="full"
          overflow="hidden"
          minW="150px"
          minH="400px"
          placeItems="center"
          placeContent="center"
        >
          <Slide data-position={(0 + step) % total}>
            <img
              src="https://storage.pora.ai/99621f6fe801eb681a70703a3f43b2cb.webp"
              alt=""
              width="195"
              height="400"
            />
          </Slide>
          <Slide data-position={(1 + step) % total}>
            <img
              src="https://storage.pora.ai/aa49ba55775ca4fdafb62935fd20012b.webp"
              alt=""
              width="195"
              height="400"
            />
          </Slide>
          <Slide data-position={(2 + step) % total}>
            <img
              src="https://storage.pora.ai/0b7fd702a7ea44d434ff3471fa6cfa89.webp"
              alt=""
              width="195"
              height="400"
            />
          </Slide>
        </Flex>
      </motion.div>
      <Flex gap={2} placeContent="center" marginTop={6}>
        <Dot data-selected={step === 2 ? true : null} />
        <Dot data-selected={step === 0 ? true : null} />
        <Dot data-selected={step === 1 ? true : null} />
      </Flex>
    </VFlex>
  )
}
