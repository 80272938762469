import React, { FC } from "react"
import { useFaceScanResultScan } from "~/store/selectors"
import { FaceScanResultRender } from "~/shared/FaceScanResultRender"
import { BoxProps } from "@chakra-ui/react"

export const FaceScanResultRenderContainer: FC<BoxProps> = (props) => {
  const scanUrl = useFaceScanResultScan()

  if (!scanUrl) {
    return null
  }

  return <FaceScanResultRender image={scanUrl} {...props} />
}
