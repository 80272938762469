import { useEffect } from "react"
import { useSelectedPrice, useUserEmail } from "~/store/selectors"
import { useMetaPixel } from "~/utils/analytics/useMetaPixel"
import { useTiktokPixel } from "~/utils/analytics/useTiktokPixel"
import { useGoogleAnalytics } from "~/utils/analytics/useGoogleAnalytics"
import { useInternalAnalytics } from "~/utils/analytics/useInternalAnalytics"
import { usePinterest } from "~/utils/analytics/usePinterest"
import type { PriceInfo } from "~/utils/analytics/types"
import { useSnapPixel } from "~/utils/analytics/useSnapPixel"

export function useLogPurchase() {
  const selectedPrice = useSelectedPrice()
  const logMp = useMetaPixel()
  const logTtp = useTiktokPixel()
  const logGa = useGoogleAnalytics()
  const logPin = usePinterest()
  const logIa = useInternalAnalytics()
  const logSnap = useSnapPixel()
  const email = useUserEmail()
  useEffect(() => {
    if (selectedPrice) {
      const productInfo: PriceInfo = {
        priceId: selectedPrice.id,
        priceTitle: selectedPrice.title,
        value: selectedPrice.trial_price_cents / 100,
        currency: selectedPrice.currency_code,
        userEmail: email,
      }
      logMp.purchase(productInfo)
      logTtp.purchase(productInfo)
      logPin.purchase(productInfo)
      logGa.purchase(productInfo)
      logIa.purchase(productInfo)
      logSnap.purchase(productInfo)
    }
  }, [logMp, logTtp, logIa, logGa, selectedPrice])
}
