import React, { FC, ReactNode } from "react"
import { VFlex } from "~/components"
import {
  Flex,
  FlexProps,
  Heading,
  Image,
  List,
  ListItem,
  ListItemProps,
  SpaceProps,
} from "@chakra-ui/react"
import { formatPercent } from "~/utils"

import bulletMark from "./BulletMark.min.svg?url"
import { DISCOUNT } from "./constants"

export const CardPlain: FC<FlexProps> = (props) => (
  <Flex w="full" p={3} borderRadius="brand24" bgColor="Base/neutralPrimary" {...props} />
)

export const Card: FC<FlexProps> = (props) => <CardPlain boxShadow="LoviShort" {...props} />

export const ActionButtonPanel: FC<{ children: ReactNode }> = ({ children }) => (
  <VFlex
    gap={2}
    w="full"
    py={4}
    marginTop={-4}
    position="sticky"
    bottom="0"
    height="160px"
    bgGradient="linear-gradient(180deg, #F6F5F400 0%, #F6F5F4 40%, #F6F5F4 100%)"
  >
    {children}
  </VFlex>
)

const PromoListItem: FC<ListItemProps> = (props) => (
  <ListItem
    w="full"
    textStyle="Paragraph/Secondary"
    display="inline-flex"
    gap={1}
    alignItems="flex-start"
    _before={{
      content: "' '",
      display: "inline-block",
      minWidth: "20px",
      height: "20px",
      bgImage: `url(${bulletMark})`,
    }}
    {...props}
  />
)
export const HowWePickProductsList: FC<SpaceProps> = (props) => (
  <VFlex
    gap={6}
    borderRadius="brand24"
    bgGradient="linear(to-b, Base/neutralPrimary, transparent)"
    {...props}
  >
    <Heading as="h3" size="Header/Secondary">
      How We Pick Products?
    </Heading>
    <List display="flex" flexDirection="column" gap={3}>
      <PromoListItem>Science-Backed Product Scoring</PromoListItem>
      <PromoListItem>Ingredient Safety Analysis</PromoListItem>
      <PromoListItem>Targeted Solutions & Availability</PromoListItem>
      <PromoListItem>Comprehensive Marketplace Comparison</PromoListItem>
      <PromoListItem>Carefully Selected Alternatives</PromoListItem>
    </List>
    <Image src="https://storage.pora.ai/d5ffdc8e224003290af700d30b38f760.webp" />
  </VFlex>
)

export const PromoList: FC = () => (
  <List display="flex" flexDirection="column" gap={1.5}>
    <PromoListItem>100% Match to Your Skin</PromoListItem>
    <PromoListItem>Free Delivery</PromoListItem>
    <PromoListItem>Cancel any time</PromoListItem>
    <PromoListItem>-{formatPercent(DISCOUNT * 100)} Discount</PromoListItem>
  </List>
)

export const PromoListCard: FC = () => (
  <CardPlain>
    <PromoList />
  </CardPlain>
)
