import React, { FC, ReactNode } from "react"
import { Box, BoxProps, Divider, Flex } from "@chakra-ui/react"
import { formatPrice } from "~/utils"
import type { Price } from "../PriceSelectWithPaymentForm/types"

const formatPriceStr = (currency_code: string, s: string) =>
  formatPrice(currency_code, parseFloat(s))

const PriceLine: FC<{
  left: ReactNode
  right: ReactNode
  leftProps?: BoxProps
  rightProps?: BoxProps
}> = ({ left, right, leftProps = {}, rightProps = {} }) => (
  <Flex direction="row" w="100%" gap={4} justifyContent="space-between">
    <Box {...leftProps}>{left}</Box>
    <Box {...rightProps}>{right}</Box>
  </Flex>
)

const TotalPriceLine: FC<{ left: ReactNode; right: ReactNode; children?: ReactNode }> = ({
  left,
  right,
  children = null,
}) => (
  <Box>
    <Flex
      direction="row"
      w="100%"
      gap={4}
      justifyContent="space-between"
      color="Base/basePrimary"
      textStyle="Subtitle/Secondary"
    >
      <Box fontWeight="medium">{left}</Box>
      <Box textStyle="Header/Tertiary">{right}</Box>
    </Flex>
    {children}
  </Box>
)

const PreviousPriceLine: FC<Pick<Price, "previousPrice" | "currency_code">> = ({
  currency_code,
  previousPrice,
}) => (
  <PriceLine
    left="Personalized skin care program"
    right={formatPriceStr(currency_code, previousPrice)}
  />
)

const EconomyPriceLine: FC<Pick<Price, "currency_code" | "economyPercent" | "economyPrice">> = ({
  currency_code,
  economyPercent,
  economyPrice,
}) => (
  <PriceLine
    left={`${economyPercent}% Introductory offer discount`}
    right={`- ${formatPriceStr(currency_code, economyPrice)}`}
    rightProps={{ color: "Other/Error" }}
  />
)

const TotalLine: FC<Pick<Price, "price" | "economyPercent" | "economyPrice" | "currency_code">> = ({
  currency_code,
  price,
  economyPrice,
  economyPercent,
}) => (
  <TotalPriceLine left="Total:" right={formatPrice(currency_code, price / 100)}>
    <Box marginTop={1.5} color="Other/Error" textAlign="right" textStyle="small">
      You saved {formatPriceStr(currency_code, economyPrice)} ({economyPercent}% off)
    </Box>
  </TotalPriceLine>
)

const TrialPriceLine: FC<Pick<Price, "trialPeriod" | "trialPrice" | "currency_code">> = ({
  currency_code,
  trialPeriod,
  trialPrice,
}) => (
  <PriceLine left={`${trialPeriod} trial`} right={`${formatPrice(currency_code, trialPrice)}`} />
)

const TrialTotalLine: FC<Pick<Price, "trialPrice" | "currency_code">> = ({
  currency_code,
  trialPrice,
}) => <TotalPriceLine left="Total today:" right={formatPrice(currency_code, trialPrice)} />

const TrialInvoice: FC<Price> = ({
  currency_code,
  trialPeriod,
  trialPrice,
  economyPrice,
  economyPercent,
  previousPrice,
}) => (
  <Flex direction="column" w="100%" gap={3} color="Base/baseSecondary" textStyle="RegularParagraph">
    <EconomyPriceLine {...{ currency_code, economyPrice, economyPercent }} />
    <PreviousPriceLine {...{ currency_code, previousPrice }} />
    <TrialPriceLine {...{ currency_code, trialPeriod, trialPrice }} />
    <Divider color="Base/neutralSecondary" />
    <TrialTotalLine {...{ currency_code, trialPrice }} />
  </Flex>
)

const OrdinaryInvoice: FC<Price> = ({
  currency_code,
  price,
  previousPrice,
  economyPrice,
  economyPercent,
}) => (
  <Flex direction="column" w="100%" gap={3} color="Base/baseSecondary" textStyle="RegularParagraph">
    <PreviousPriceLine {...{ currency_code, previousPrice }} />
    <EconomyPriceLine {...{ currency_code, economyPrice, economyPercent }} />
    <Divider color="Base/neutralSecondary" />
    <TotalLine {...{ currency_code, price, economyPrice, economyPercent }} />
  </Flex>
)

export const Invoice: FC<Price> = (props) =>
  props.trialPrice ? <TrialInvoice {...props} /> : <OrdinaryInvoice {...props} />
