import React from "react"
import { PeopleOftenAsk } from "./PeopleOftenAsk"

const header = "People Often Ask"
const questions = [
  {
    question: "Will you send me the products or should i buy them myself?",
    responseTexts: [
      "No, we won't send you the products.",
      "It's likely that you have cosmetic products that can still be utilized. Let’s practice mindful consumption. Maybe you encounter cosmetic products that don't seem effective. But it's not always about the product, but rather the frequency and method of use.",
      "If you're lacking products for certain skincare routine steps, select from a personalized recommendation list that fits your budget, arrange, and tailor your skincare routine.",
    ],
  },
  {
    question: "What happens if I won't be consistent with the program?",
    responseTexts: [
      "It’s ok. No worries if you miss a night of cleansing your skin. Keep in mind that throughout the day, even when staying at home, skin accumulates dust and dirt particles.",
      "Without cleansing at night, the skin will prioritize protection against impurities instead of restoring vital activity.",
      "Establish an evening routine by setting a reminder in Pora to maintain healthy, youthful skin.",
    ],
  },
  {
    question: "Should i give up my cosmetologist while using Pora?",
    responseTexts: [
      "Pora and a cosmetologist work together in harmony, much like water and food. Relying solely on one or the other is insufficient.",
      "If you have found your trusted cosmetologist, you can enhance their services by sending them a report from your skin condition tracker for improved professional procedures.",
      "Pora's notifications and daily skin care routine videos will assist you in following your cosmetologist's recommendations and achieving even better results than with home care alone.",
    ],
  },
]

export const PeopleOftenAskContainer = () => {
  return <PeopleOftenAsk header={header} questions={questions} />
}
