import { useDisclosure } from "@chakra-ui/react"
import { useCallback, useEffect } from "react"
import { useScrollToOffer } from "~/pages/Main/scrollToOffer"
import { useBlockProperties, useSelectedPrice } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

export const useFloatTimerLogic = ({
  display_timeout,
  await_scroll,
  close_after_action = true,
}: {
  display_timeout?: number
  await_scroll: boolean
  close_after_action?: boolean
}) => {
  const selectedPrice = useSelectedPrice()
  const selectedPriceId = selectedPrice?.id
  const log = useAmplitude()
  const { isOpen: isOpenOffer, onOpen: onOpenOffer, onClose: onCloseOffer } = useDisclosure()
  const scrollToOffer = useScrollToOffer("float_panel_button")
  const blockProperties = useBlockProperties()
  const timerFloatAction = blockProperties["TIMER_FLOAT.action"] ?? "scroll"

  const onClickSpecialOffer = useCallback(() => {
    log.clickOfferButton({ priceId: selectedPriceId! })
    if (close_after_action) {
      onCloseOffer()
    }
    scrollToOffer(
      timerFloatAction === "scroll"
        ? { scroll: true, openModal: false }
        : { scroll: false, openModal: true }
    )
  }, [log, onCloseOffer, selectedPriceId, scrollToOffer, close_after_action])

  useEffect(() => {
    const displayTimeout = (display_timeout ?? 3) * 1000
    const el = window
    const handler = (): void => {
      if ((el?.scrollY ?? 0) > 50) {
        el?.removeEventListener("scroll", handler)
        setTimeout(() => {
          onOpenOffer()
        }, displayTimeout)
      }
    }
    if (await_scroll) {
      el?.addEventListener("scroll", handler)
    } else {
      setTimeout(() => {
        onOpenOffer()
      }, displayTimeout)
    }

    return () => {
      el?.removeEventListener("scroll", handler)
    }
  }, [onOpenOffer, display_timeout, await_scroll])

  return {
    isOpenOffer,
    onClickSpecialOffer,
  }
}
