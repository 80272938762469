import React, { FC } from "react"
import { BlockPropertyContent } from "~/components"

import { BlockHeading, BlockWrapper, BlockWrapperProps } from "../BlockWrapper"
import { VideoReviews } from "./VideoReviews"

export const VideoReviewsBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper paddingY={6} variant="transparent" {...props}>
    <BlockHeading paddingX={6}>
      <BlockPropertyContent propKey="PB_VIDEO_REVIEWS.title">
        Our Users Meet Their Goals
      </BlockPropertyContent>
    </BlockHeading>
    <VideoReviews />
  </BlockWrapper>
)
