import React, { FC, useCallback } from "react"
import { Button } from "@chakra-ui/react"
import { useDeepLink } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"

export const DownloadButton: FC = () => {
  const deepLink = useDeepLink()
  const log = useAmplitude()

  const onClickLink = useCallback(() => {
    log.successContinuePress()
    window.location.href = deepLink // TODO use redirect here
  }, [deepLink, log])

  return (
    <Button onClick={onClickLink} variant="next" w="full">
      Get the App
    </Button>
  )
}
