/* eslint-disable */

export const protobufPackage = "ios_presentation";

/** iOS-style detent for a sheet. */
export interface SheetDetent {
  detent?: { $case: "large"; large: SheetDetent_Large } | { $case: "medium"; medium: SheetDetent_Medium } | {
    $case: "percent";
    percent: SheetDetent_Percent;
  } | { $case: "wraps_content"; wraps_content: SheetDetent_WrapsContent };
}

export interface SheetDetent_Large {
}

export interface SheetDetent_Medium {
}

export interface SheetDetent_WrapsContent {
}

export interface SheetDetent_Percent {
  percent: number;
}

function createBaseSheetDetent(): SheetDetent {
  return { detent: undefined };
}

export const SheetDetent = {
  fromJSON(object: any): SheetDetent {
    return {
      detent: isSet(object.large)
        ? { $case: "large", large: SheetDetent_Large.fromJSON(object.large) }
        : isSet(object.medium)
        ? { $case: "medium", medium: SheetDetent_Medium.fromJSON(object.medium) }
        : isSet(object.percent)
        ? { $case: "percent", percent: SheetDetent_Percent.fromJSON(object.percent) }
        : isSet(object.wraps_content)
        ? { $case: "wraps_content", wraps_content: SheetDetent_WrapsContent.fromJSON(object.wraps_content) }
        : undefined,
    };
  },

  toJSON(message: SheetDetent): unknown {
    const obj: any = {};
    message.detent?.$case === "large" &&
      (obj.large = message.detent?.large ? SheetDetent_Large.toJSON(message.detent?.large) : undefined);
    message.detent?.$case === "medium" &&
      (obj.medium = message.detent?.medium ? SheetDetent_Medium.toJSON(message.detent?.medium) : undefined);
    message.detent?.$case === "percent" &&
      (obj.percent = message.detent?.percent ? SheetDetent_Percent.toJSON(message.detent?.percent) : undefined);
    message.detent?.$case === "wraps_content" && (obj.wraps_content = message.detent?.wraps_content
      ? SheetDetent_WrapsContent.toJSON(message.detent?.wraps_content)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SheetDetent>, I>>(base?: I): SheetDetent {
    return SheetDetent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SheetDetent>, I>>(object: I): SheetDetent {
    const message = createBaseSheetDetent();
    if (object.detent?.$case === "large" && object.detent?.large !== undefined && object.detent?.large !== null) {
      message.detent = { $case: "large", large: SheetDetent_Large.fromPartial(object.detent.large) };
    }
    if (object.detent?.$case === "medium" && object.detent?.medium !== undefined && object.detent?.medium !== null) {
      message.detent = { $case: "medium", medium: SheetDetent_Medium.fromPartial(object.detent.medium) };
    }
    if (object.detent?.$case === "percent" && object.detent?.percent !== undefined && object.detent?.percent !== null) {
      message.detent = { $case: "percent", percent: SheetDetent_Percent.fromPartial(object.detent.percent) };
    }
    if (
      object.detent?.$case === "wraps_content" &&
      object.detent?.wraps_content !== undefined &&
      object.detent?.wraps_content !== null
    ) {
      message.detent = {
        $case: "wraps_content",
        wraps_content: SheetDetent_WrapsContent.fromPartial(object.detent.wraps_content),
      };
    }
    return message;
  },
};

function createBaseSheetDetent_Large(): SheetDetent_Large {
  return {};
}

export const SheetDetent_Large = {
  fromJSON(_: any): SheetDetent_Large {
    return {};
  },

  toJSON(_: SheetDetent_Large): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SheetDetent_Large>, I>>(base?: I): SheetDetent_Large {
    return SheetDetent_Large.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SheetDetent_Large>, I>>(_: I): SheetDetent_Large {
    const message = createBaseSheetDetent_Large();
    return message;
  },
};

function createBaseSheetDetent_Medium(): SheetDetent_Medium {
  return {};
}

export const SheetDetent_Medium = {
  fromJSON(_: any): SheetDetent_Medium {
    return {};
  },

  toJSON(_: SheetDetent_Medium): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SheetDetent_Medium>, I>>(base?: I): SheetDetent_Medium {
    return SheetDetent_Medium.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SheetDetent_Medium>, I>>(_: I): SheetDetent_Medium {
    const message = createBaseSheetDetent_Medium();
    return message;
  },
};

function createBaseSheetDetent_WrapsContent(): SheetDetent_WrapsContent {
  return {};
}

export const SheetDetent_WrapsContent = {
  fromJSON(_: any): SheetDetent_WrapsContent {
    return {};
  },

  toJSON(_: SheetDetent_WrapsContent): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SheetDetent_WrapsContent>, I>>(base?: I): SheetDetent_WrapsContent {
    return SheetDetent_WrapsContent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SheetDetent_WrapsContent>, I>>(_: I): SheetDetent_WrapsContent {
    const message = createBaseSheetDetent_WrapsContent();
    return message;
  },
};

function createBaseSheetDetent_Percent(): SheetDetent_Percent {
  return { percent: 0 };
}

export const SheetDetent_Percent = {
  fromJSON(object: any): SheetDetent_Percent {
    return { percent: isSet(object.percent) ? Number(object.percent) : 0 };
  },

  toJSON(message: SheetDetent_Percent): unknown {
    const obj: any = {};
    message.percent !== undefined && (obj.percent = message.percent);
    return obj;
  },

  create<I extends Exact<DeepPartial<SheetDetent_Percent>, I>>(base?: I): SheetDetent_Percent {
    return SheetDetent_Percent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SheetDetent_Percent>, I>>(object: I): SheetDetent_Percent {
    const message = createBaseSheetDetent_Percent();
    message.percent = object.percent ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
