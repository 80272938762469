import React, { FC } from "react"
import { Box, Divider, Heading, List, ListIcon, ListItem, Text } from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"

import { ReactComponent as IconDatabase } from "./assets/icon-database.svg"
import { ReactComponent as IconGuide } from "./assets/icon-guide.svg"
import { ReactComponent as IconWorkbook } from "./assets/icon-workbook.svg"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { formatPrice } from "~/utils"

const LIST = [
  {
    title: "Comprehensive Product Database",
    icon: IconDatabase,
  },
  {
    title: "Expert Application Guide",
    icon: IconGuide,
  },
  {
    title: "Exclusive Bonus - Intensive Skincare Mastery Workbook",
    icon: IconWorkbook,
  },
]

export const WhatIsIncluded: FC<{ price: PriceContentV2_PriceItem }> = ({
  price: { currency_code, subscription_price_cents, previous_price, economy_percent },
}) => (
  <VFlex px={6} paddingTop={6} paddingBottom={4} layerStyle="attentionFrame">
    <Heading size="Header/Secondary" textAlign="center" marginBottom={4}>
      What’s included?
    </Heading>
    <List spacing={3} textStyle="Paragraph/Primary" marginBottom={5}>
      {LIST.map(({ title, icon }, i) => (
        <ListItem key={i} display="flex" alignItems="center">
          <ListIcon as={icon} color="Base/accentPrimary" fontSize="40px" marginRight={3} />
          {title}
        </ListItem>
      ))}
    </List>
    <Heading
      as="h3"
      size="Header/Tertiary"
      textAlign="center"
      color="Base/accentPrimary"
      marginBottom={1}
    >
      One-time price of {formatPrice(currency_code, subscription_price_cents / 100)}
    </Heading>
    <Text textStyle="Paragraph/Secondary" textAlign="center" marginBottom={2}>
      Original price was {previous_price} —{" "}
      <Box as="span" color="Base/accentPrimary">
        save {economy_percent}%!
      </Box>
    </Text>
    <Divider marginBottom={3} />
    <Text textStyle="Paragraph/Secondary" textAlign="center">
      You can keep this workbook even if you decide Lóvi isn’t for you.
    </Text>
  </VFlex>
)
