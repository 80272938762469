import { useCallback } from "react"
import { paltaMakePurchase, recurlyMakePurchase } from "~/api/api"
import { PaymentError } from "~/errors"
import { PriceContentV2_PriceItem } from "~/generated/paywall"
import { useRecurlyEnabled, useUserId } from "~/store/selectors"

const _makePurchase = ({
  userId,
  selectedPrice,
  recurlyEnabled,
}: {
  userId: string
  selectedPrice: PriceContentV2_PriceItem
  recurlyEnabled: boolean
}) => {
  if (recurlyEnabled) {
    const priceId = selectedPrice.payment_providers?.recurly?.plan_id
    if (!priceId) {
      throw new PaymentError("Could not find Recurly plan_id")
    }
    return recurlyMakePurchase({ userId, priceId, currency: selectedPrice.currency_code }).then(
      (resp) => {
        return resp.status === "success"
      }
    )
  }

  const priceId = selectedPrice.payment_providers?.palta_card?.price_id
  if (!priceId) {
    throw new PaymentError("Could not find Palta payment card price_id")
  }
  return paltaMakePurchase({ priceId, userId })
}

export const useMakePurchase = () => {
  const userId = useUserId()
  const recurlyEnabled = useRecurlyEnabled()

  return useCallback(
    (selectedPrice: PriceContentV2_PriceItem) =>
      _makePurchase({ userId, selectedPrice, recurlyEnabled }),
    [userId, recurlyEnabled]
  )
}
