import React, { FC } from "react"

import { BlockWrapperProps, BlockWrapper, BlockHeading } from "../BlockWrapper"
import { FaceScanResult } from "./FaceScanResult"
import { BlockPropertyContent } from "~/components"
import { Box } from "@chakra-ui/react"

export const FaceScanResultBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper paddingX={6} paddingY={6} {...props}>
    <BlockHeading>
      <BlockPropertyContent propKey="PB_FACESCAN_RESULTS.title">
        Analysis Overview
      </BlockPropertyContent>
    </BlockHeading>
    <Box mt={2} w="full">
      <FaceScanResult />
    </Box>
  </BlockWrapper>
)
