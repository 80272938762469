import React, { FC, ReactNode } from "react"
import { Box, Divider, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { StarIcon } from "./Icons"
import { ThemeContentSwitch, usePoraReplacer } from "~/theme/theme-from-interview/themeProvider"

type Review = {
  author: string
  usageLength: number
  img: string
  date: string
  title: string
  text: string
}

const Stars: FC = () => (
  <Flex gap={0.5}>
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
    <StarIcon fill="none" fontSize={18} />
  </Flex>
)

const ReviewsPrePaywallItem: FC<Review> = ({ img, usageLength, author, text, title, date }) => {
  const replacer = usePoraReplacer()
  return (
    <Flex
      borderWidth={1}
      borderColor="Base/neutralSecondary"
      flexDirection="column"
      p={4}
      borderRadius="3xl"
      bgColor="Base/neutralPrimary"
    >
      <Flex w="full" gap={2} alignItems="center">
        <Image flexShrink={0} w="40px" h="40px" borderRadius="full" src={img} alt="author" />
        <Box>
          <Box textStyle="Subtitle/Tertiary" textColor="Base/basePrimary" mb={0.5}>
            {author}
          </Box>
          <Box textStyle="Paragraph/Tertiary" textColor="Base/baseSecondary">
            Used <ThemeContentSwitch pora="Pora" lovi="Lovi" /> for {usageLength} months
          </Box>
        </Box>
      </Flex>
      <Divider borderColor="Base/neutralSecondary" my={3} />
      <Flex w="full" justifyContent="space-between" alignItems="center" mb={3}>
        <Stars />
        <Box textStyle="Paragraph/Tertiary" textColor="Base/baseSecondary">
          {date}
        </Box>
      </Flex>
      <Heading
        mb={1}
        as="h5"
        size="Subtitle/Secondary"
        textStyle="Subtitle/Secondary"
        variant="text"
      >
        {replacer(title)}
      </Heading>
      <Text textStyle="Paragraph/Secondary">{replacer(text)}</Text>
    </Flex>
  )
}

export const ReviewsPrePaywall: FC<{ reviews: Review[] }> = ({ reviews }) => (
  <Flex flexDirection="column" gap={3}>
    {reviews.map((review, i) => (
      <ReviewsPrePaywallItem {...review} key={i} />
    ))}
  </Flex>
)
