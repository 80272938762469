import { PaymentProviders } from "~/generated/paywall"

export enum InternalPaymentProviders {
  STRIPE_APPLE_PAY = "INTERNAL_PAYMENT_PROVIDER_STRIPE_APPLE_PAY",
  PALTA_APPLE_PAY = "INTERNAL_PAYMENT_PROVIDER_PALTA_APPLE_PAY",
  RECURLY_APPLE_PAY = "INTERNAL_PAYMENT_PROVIDER_RECURLY_APPLE_PAY",
  RECURLY_GOOGLE_PAY = "INTERNAL_PAYMENT_PROVIDER_RECURLY_GOOGLE_PAY",
  RECURLY_CARD = "INTERNAL_PAYMENT_PROVIDER_RECURLY_CARD",
}

export type PaymentProvidersUnion = PaymentProviders | InternalPaymentProviders
