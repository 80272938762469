import React, { useState } from "react"
import { Flex } from "@chakra-ui/react"
import { useSearchParams } from "react-router-dom"
import { nullAsUndefined } from "~/utils"
import { DefaultVariant } from "./DefaultVariant/DefaultVariant"
import {
  SUCCESS_SCREEN_VARIANTS,
  SuccessScreenVariant,
  AlternativeVariant,
} from "./AlternativeVariantProps"

export const AppLinkPage = () => {
  const [searchParams] = useSearchParams()
  const _variant = nullAsUndefined(searchParams.get("variant")) ?? "default"
  const [variant, setVariant] = useState<SuccessScreenVariant | "default">(
    _variant as SuccessScreenVariant
  )
  const isUnsupportedVariant = !SUCCESS_SCREEN_VARIANTS.has(variant as SuccessScreenVariant)

  return (
    <Flex
      direction="column"
      w="full"
      minH="$100vh"
      alignItems="center"
      bgColor="Base/neutralSecondary"
    >
      {variant === "default" || isUnsupportedVariant ? (
        <DefaultVariant />
      ) : (
        <AlternativeVariant variant={variant} onClick={() => setVariant("default")} />
      )}
    </Flex>
  )
}
