import React, { FC } from "react"
import { Box } from "@chakra-ui/react"
import { PAYMENT_FORM_ID } from "~/constants"
import { ThemeContentSwitch } from "~/theme/theme-from-interview/themeProvider"
import {
  TextAbovePaymentFormContainer,
  TextBelowPaymentFormContainer,
} from "~/components/PaymentTexts"
import {
  TextNeedHelp,
  TextPart,
  TextSecureCheckout,
  TextYouInformationIsSafe,
} from "~/components/StaticTexts"

import { Invoice2Container } from "../../Invoice"
import { PaymentFormContainer } from "../../PaymentForm"
import { BlockWrapper, BlockWrapperProps } from "../BlockWrapper"

export const PaymentFormBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} overflow="hidden" id={PAYMENT_FORM_ID} header="Payment method">
    <PaymentFormContainer />
    <TextBelowPaymentFormContainer mt={3} />
  </BlockWrapper>
)

export const PaymentFormWithInvoiceBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} id={PAYMENT_FORM_ID} header="Get your program">
    <Box mb={8}>
      <Invoice2Container />
    </Box>
    <PaymentFormContainer />
    <TextBelowPaymentFormContainer mt={3} />
  </BlockWrapper>
)

export const PaymentFormWithStaticTextBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} id={PAYMENT_FORM_ID} header="Payment method">
    <TextAbovePaymentFormContainer mb={3} />
    <PaymentFormContainer />
    <Box textAlign="center" textStyle="Subtitle/Tertiary" mt={3}>
      You will need an iPhone to use <ThemeContentSwitch pora="Pora" lovi="Lovi" />
    </Box>
    <TextBelowPaymentFormContainer mt={6} variant={2} />
    <TextPart mt={9}>
      <TextYouInformationIsSafe />
    </TextPart>

    <TextPart mt={2}>
      <TextSecureCheckout />
    </TextPart>

    <Box w="full" paddingX={6} marginTop={8}>
      <TextNeedHelp />
    </Box>
  </BlockWrapper>
)
