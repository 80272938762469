const TEST_QUERY_PREFIX = "test_"
const UTM_QUERY_PREFIX = "utm_"
const QUERY_PARAMS = ["user_id", "quiz_id"]

export const getTestQueryParams = () => {
  const queryParamsEntries = new URLSearchParams(window.location.search).entries()
  const testQueryParamsEntries = Array.from(queryParamsEntries).filter(
    ([queryParamName]) =>
      queryParamName.startsWith(TEST_QUERY_PREFIX) ||
      queryParamName.startsWith(UTM_QUERY_PREFIX) ||
      QUERY_PARAMS.includes(queryParamName)
  )

  return Object.fromEntries(testQueryParamsEntries)
}

export const getQueryParams = () => {
  const queryParamsEntries = new URLSearchParams(window.location.search).entries()

  return Object.fromEntries(queryParamsEntries)
}
