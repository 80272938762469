import { Box, BoxProps, Flex, Heading, HeadingProps, Image, Spacer } from "@chakra-ui/react"
import React, { FC, ReactNode } from "react"
import { CardPreview } from "~/components"
import expertAvatar from "./expertAvatar.webp?url"
import expertPhoto from "./expertPhoto.webp?url"
import jars from "./jars.webp?url"

export const Expert: FC<{ avatar: string; title: ReactNode }> = ({ avatar, title }) => (
  <Flex direction="row" gap={2} justifyContent="left" alignItems="center">
    <Image src={avatar} h={33} w={33} />
    <Box color="Base/accentActive" textStyle="MediumHintPrimary">
      {title}
    </Box>
  </Flex>
)

const CardTitle: FC<HeadingProps> = (props) => (
  <Heading size="MediumSubtitleSecondary" variant="text" as="h5" {...props} />
)

const CardSecondaryText: FC<BoxProps> = (props) => (
  <Box color="Base/baseSecondary" textStyle="RegularParagraphSecondary" {...props} />
)

export const CardExpertAdvice: FC = () => (
  <CardPreview
    h="160px"
    minW="272px"
    bgImage={expertPhoto}
    bgRepeat="no-repeat"
    bgPosition="right center"
    bgSize="contain"
    overflow="hidden"
  >
    <Flex direction="column" marginRight="72px" gap={1}>
      <CardTitle>Connect with personal dermatologists</CardTitle>
      <CardSecondaryText>
        They can answer all your questions, and are by your side for fastest and most effective
        results
      </CardSecondaryText>
    </Flex>
  </CardPreview>
)

export const CardProducts: FC = () => (
  <CardPreview
    h="160px"
    minW="272px"
    bgImage={jars}
    bgRepeat="no-repeat"
    bgPosition="right bottom"
    bgSize="contain"
    overflow="hidden"
  >
    <Flex direction="column" marginRight="72px" gap={1} h="full">
      {/* fixme */}
      {/* eslint-disable-next-line no-irregular-whitespace */}
      <CardTitle>20 products for any budget</CardTitle>
      <CardSecondaryText>based on your SkinID</CardSecondaryText>
      <Spacer />
      <Expert
        avatar={expertAvatar}
        title={
          <>
            Verified by
            <br />
            Pora Medical Advisor
          </>
        }
      />
    </Flex>
  </CardPreview>
)
