export const mails1 = [
  ["la***th@gmail.com", ""],
  ["be***ly@hotmail.com", ""],
  ["ant***man@hotmail.com", ""],
  ["bea***01@gmail.com", ""],
  ["imn***22@gmail.com", ""],
  ["333***17@gmail.com", ""],
  ["wrf***11@gmail.com", ""],
  ["roc***o1@gmail.com", ""],
  ["cla***31@gmail.com", ""],
  ["sim0***ge@gmail.com", ""],
]

export const mails2 = [
  ["par***@", "1-Month Plan"],
  ["lau***@", "3-Month Plan"],
  ["bea***@", "1-Year Plan"],
  ["lot***@", "3-Month Plan"],
  ["hiu***@", "1-Month Plan"],
  ["gre***@", "3-Month Plan"],
  ["nnn***@", "3-Month Plan"],
  ["120***@", "3-Month Plan"],
  ["rge***@", "1-Year Plan"],
  ["wrf***@", "3-Month Plan"],
]
