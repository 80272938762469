export function getCookie(name: string): string {
  const nameEQ = name + "="
  const cookiesArray = document.cookie?.split(";")

  if (!cookiesArray) {
    return ""
  }

  for (let i = 0; i < cookiesArray.length; i++) {
    const cookie = cookiesArray[i]?.trim()

    if (!cookie) {
      continue
    }

    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }

  return ""
}
