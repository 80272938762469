import React, { FC, ReactNode } from "react"
import { Box, BoxProps, Flex, FlexProps, Icon } from "@chakra-ui/react"

import { ExternalBox, VFlex } from "~/components"
import { CurrencyCode, formatPrice, noop } from "~/utils"

import { CheckedRadio2 as CheckedRadio, UncheckedRadio } from "./Icons"
import { Strike } from "./Strike"
import type { PriceItemProps } from "./types"
import tagBgSrc from "./assets/tagBg2.webp" // Webp because SVG in mask-image in Safari doesn't resize correctly

const PriceItemBadge: FC<BoxProps & { isSelected?: boolean; isDisabled?: boolean }> = ({
  isSelected = false,
  isDisabled = false,
  ...props
}) => (
  <Box
    paddingX={2}
    paddingY="2px"
    borderRadius="8px"
    borderBottomLeftRadius={0}
    bgColor="white"
    bgImage="linear-gradient(0, var(--chakra-colors-Base\/accentPrimaryBg2), var(--chakra-colors-Base\/accentPrimaryBg2))"
    color="Base/accentPrimary"
    textStyle="Other/PriceTag"
    aria-checked={isSelected}
    _checked={{
      background: "Base/accentPrimary",
      color: "Base/neutralPrimary",
    }}
    aria-disabled={isDisabled}
    _disabled={{
      background: "Base/neutralSecondary",
      color: "Base/baseDisabled",
    }}
    {...props}
  />
)

const PriceTag: FC<BoxProps> = (props) => (
  <Box
    display="inline-block"
    mr={0.5}
    borderRadius="8px"
    bgColor="Base/neutralPrimary"
    color="Other/Error"
    paddingY={0.5}
    paddingX={1.5}
    width="fit-content"
    textStyle="Other/PriceTag"
    whiteSpace="pre"
    {...props}
  />
)

const PriceLayout: FC<{
  isSelected?: boolean
  isDisabled?: boolean
  badge?: ReactNode
  children: ReactNode
  onClick?: () => void
  unselectedBorderColor?: FlexProps["borderColor"]
}> = ({
  isSelected = false,
  isDisabled = false,
  badge,
  children,
  onClick = noop,
  unselectedBorderColor = "Base/neutralPrimary",
}) => (
  <Box as="label" aria-checked={isSelected}>
    <input type="checkbox" aria-checked={isSelected} hidden />

    {badge && (
      <ExternalBox top="-10px">
        <PriceItemBadge isSelected={isSelected} isDisabled={isDisabled}>
          {badge}
        </PriceItemBadge>
      </ExternalBox>
    )}
    <Flex
      paddingStart={4}
      paddingEnd={1.5}
      direction="row"
      justify="flex-start"
      align="center"
      gap={3}
      borderColor={unselectedBorderColor}
      bgColor="Base/neutralPrimary"
      borderWidth={2}
      borderRadius={20}
      borderTopLeftRadius={badge ? 0 : undefined}
      onClick={onClick}
      w="full"
      cursor="pointer"
      userSelect="none"
      aria-checked={isSelected}
      _checked={{
        borderColor: "Base/accentPrimary",
        bgColor: "Base/accentPrimaryBg",
      }}
    >
      <Icon
        fontSize={28}
        as={isSelected ? CheckedRadio : UncheckedRadio}
        color="Base/baseDisabled"
        aria-checked={isSelected}
        _checked={{
          color: "Base/accentPrimary",
        }}
      />
      {children}
    </Flex>
  </Box>
)

const PriceNumbers: FC<{
  currency: CurrencyCode
  prevPricePerPeriod: number
  pricePerPeriod: number
  isSelected?: boolean
  isDisabled?: boolean
}> = ({ currency, pricePerPeriod, prevPricePerPeriod, isSelected = false, isDisabled = false }) => (
  <VFlex
    minH="92px"
    minW="111px"
    paddingY={1.5}
    paddingLeft={5}
    paddingRight={3}
    alignItems="center"
    justifyContent="center"
    color="Base/baseSecondary"
    bgColor="Base/neutralSecondary"
    aria-checked={isSelected}
    _checked={{ bgColor: "Base/accentPrimaryBg2" }}
    aria-disabled={isDisabled}
    _disabled={{ color: "Base/baseDisabled" }}
    sx={{
      maskImage: `url(${tagBgSrc})`,
      maskPosition: "center right",
      maskRepeat: "no-repeat",
      maskType: "alpha",
      maskSize: "100% 80px",
    }}
  >
    <Box textStyle="Subtitle/Hint">
      <Strike>{formatPrice(currency, prevPricePerPeriod)}</Strike>
    </Box>
    <Box
      textStyle="Header/MonospacedSecondary"
      fontFamily="heading"
      aria-checked={isSelected}
      _checked={{ color: "Base/basePrimary" }}
    >
      {formatPrice(currency, pricePerPeriod)}
    </Box>
    <Box textStyle="Subtitle/Hint">per day</Box>
  </VFlex>
)

const PriceItemWrapper: FC<
  PriceItemProps & { children: ReactNode; unselectedBorderColor: FlexProps["borderColor"] }
> = ({
  children,
  isSelected,
  isDisabled,
  pricePerDay,
  previousPricePerDay,
  isPopular = false,
  onClick = noop,
  unselectedBorderColor,
  badge: { title = "" } = {},
  currency_code,
}) => {
  let _badge = title
  if (!_badge && isPopular) {
    _badge = "MOST POPULAR"
  }

  return (
    <PriceLayout
      unselectedBorderColor={unselectedBorderColor}
      isSelected={isSelected}
      isDisabled={isDisabled}
      badge={_badge}
      onClick={onClick}
    >
      {children}
      <PriceNumbers
        currency={currency_code}
        isSelected={isSelected}
        isDisabled={isDisabled}
        /* FIXME parseFloat */
        pricePerPeriod={parseFloat(pricePerDay)}
        /* FIXME parseFloat */
        prevPricePerPeriod={parseFloat(previousPricePerDay)}
      />
    </PriceLayout>
  )
}

const PriceTagsList: FC<{
  data: Array<{ title: string; view_variant: string }>
  isSelected?: boolean
}> = ({ data, isSelected = false }) => {
  return (
    <Flex gap={0.5}>
      {data.map(({ title, view_variant }, i) => (
        <PriceTag
          key={i}
          bgColor="Base/neutralSecondary"
          aria-checked={isSelected}
          _checked={{ bgColor: "Base/neutralPrimary" }}
          aria-disabled={view_variant === "SOLD_OUT"}
          _disabled={{ bgColor: "Base/neutralSecondary", color: "Base/baseDisabled" }}
        >
          {title}
        </PriceTag>
      ))}
    </Flex>
  )
}

const PriceItem7Wrapper: FC<
  PriceItemProps & { unselectedBorderColor: FlexProps["borderColor"] }
> = (props) => {
  const {
    title,
    trialPrice,
    previousPrice,
    isSelected = false,
    isDisabled = false,
    tags = [],
    currency_code,
  } = props

  return (
    <PriceItemWrapper {...props}>
      <VFlex gap={1} flex="1" paddingY={1.5}>
        <Box
          textStyle="Subtitle/Secondary"
          color="Base/basePrimary"
          aria-disabled={isDisabled}
          _disabled={{ color: "Base/baseSecondary" }}
        >
          {title}
        </Box>
        <Box textStyle="Subtitle/Hint" color="Base/baseSecondary">
          <Strike
            textDecorationColor="Base/accentPrimary"
            aria-disabled={isDisabled}
            _disabled={{ textDecorationColor: "Base/baseSecondary" }}
          >
            {formatPrice(currency_code, parseFloat(previousPrice))}
          </Strike>
          {" → "}
          {formatPrice(currency_code, trialPrice / 100)}
        </Box>

        <PriceTagsList data={tags} isSelected={isSelected} />
      </VFlex>
    </PriceItemWrapper>
  )
}

export const PriceItem7: FC<PriceItemProps> = (props) => (
  <PriceItem7Wrapper unselectedBorderColor="Base/neutralSecondary" {...props} />
)

export const PriceItem8: FC<PriceItemProps> = (props) => (
  <PriceItem7Wrapper unselectedBorderColor="Base/neutralPrimary" {...props} />
)
