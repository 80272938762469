import React, { FC, PropsWithChildren } from "react"
import { Box, Flex, Heading, List, ListItem, Text, Center } from "@chakra-ui/react"
import { useUserEmail } from "~/store/selectors"
import { ReactComponent as IconUnderline } from "./assets/icon-underline.svg"

const StepListItem: FC<PropsWithChildren<{ orderNumber: number }>> = ({
  orderNumber,
  children,
}) => (
  <ListItem display="flex">
    <Flex
      textStyle="Subtitle/Tertiary"
      backgroundColor="Base/neutralPrimary"
      width="6"
      height="6"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      shadow="LoviShort"
      marginRight={2}
      flexShrink={0}
    >
      {orderNumber}
    </Flex>
    <Box textStyle="Paragraph/AccentPrimary">{children}</Box>
  </ListItem>
)

export const NextSteps: FC = () => {
  const email = useUserEmail()

  return (
    <Box marginBottom={6} paddingX={6}>
      <Heading marginBottom={5} textAlign="center" size="Header/Secondary">
        <span>Next steps</span>
        <Center>
          <IconUnderline />
        </Center>
      </Heading>
      <List spacing={4} listStylePosition="outside" display="inline-block">
        <StepListItem orderNumber={1}>
          Download <b>Lóvi App</b>
        </StepListItem>
        <StepListItem orderNumber={2}>
          Open it and tap <b>“I Already Have an Account”</b>
        </StepListItem>
        <StepListItem orderNumber={3}>
          Enter your email{" "}
          <Text
            as="span"
            wordBreak="break-word" // to avoid overflow in case of long email
            color="Base/accentPrimary"
            textStyle="Subtitle/AccentPrimary"
          >
            {email}
          </Text>
        </StepListItem>
        <StepListItem orderNumber={4}>
          Start your <b>first Lóvi Task</b>
        </StepListItem>
      </List>
    </Box>
  )
}
