import { createIcon } from "@chakra-ui/react"
import React from "react"

export const Logo = createIcon({
  viewBox: "0 0 47 24",
  defaultProps: {
    width: "47px",
    height: "24px",
    fill: "none",
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3062 0C23.0175 0 21.9632 1.05433 21.9632 2.37229C21.9632 3.66099 23.0175 4.71528 24.3062 4.71528C25.6242 4.71528 26.6787 3.66099 26.6787 2.37229C26.6787 1.05433 25.6242 0 24.3062 0ZM13.4249 20.4034C12.124 21.6165 10.6398 22.4428 9.21996 22.9567C6.96864 23.7713 4.95444 23.7175 3.34821 23.0154C1.75173 22.3176 0.657067 21.0174 0.216335 19.55C-0.224339 18.0829 -0.0101069 16.4312 1.02821 15.1228C2.07128 13.8088 3.79961 13.018 6.056 12.9429C8.69168 12.8553 11.3398 13.8564 13.75 15.4083C13.7787 15.3407 13.8068 15.272 13.8341 15.2028C14.9664 12.3377 15.0787 7.99673 12.4529 1.6158L14.8999 0.608819C17.6866 7.38065 17.7613 12.4657 16.2949 16.1754C15.6753 17.7435 14.6538 19.2575 13.4249 20.4034ZM12.3755 17.6698C11.2185 19.0556 9.74744 19.9518 8.31956 20.4684C6.5838 21.0966 5.28371 20.9737 4.40798 20.5909C3.5225 20.2039 2.96756 19.5112 2.75056 18.7889C2.53354 18.0663 2.65352 17.3315 3.10084 16.7678C3.54343 16.2103 4.45221 15.6437 6.14394 15.5876C8.11601 15.5218 10.272 16.3045 12.3755 17.6698ZM15.2047 19.8822C15.2938 19.9639 15.3828 20.0468 15.4713 20.1304C17.4508 22.0022 20.327 23.6554 23.5702 23.9527C26.8811 24.2563 30.4309 23.1299 33.5958 19.6887L31.6481 17.8974C29.0149 20.7607 26.2627 21.5424 23.8117 21.3177C21.2931 21.0868 18.9474 19.7757 17.2891 18.2079C17.096 18.0253 16.9 17.845 16.7009 17.6675C16.3639 18.3017 15.7726 19.2226 15.2047 19.8822ZM18.6812 12.7672C18.6812 15.9423 21.0146 18.6605 24.5748 18.6605C28.1831 18.6605 30.5163 15.9423 30.5163 12.7672C30.5163 9.59181 28.1831 6.87359 24.5748 6.87359C21.0146 6.87359 18.6812 9.59181 18.6812 12.7672ZM27.8945 12.7672C27.8945 14.6914 26.5954 16.3273 24.5748 16.3273C22.6021 16.3273 21.3033 14.6914 21.3033 12.7672C21.3033 10.8427 22.6021 9.20703 24.5748 9.20703C26.5954 9.20703 27.8945 10.8427 27.8945 12.7672ZM30.163 7.16223H32.9773L36.0564 14.6193L39.1836 7.16223H41.9981L36.9225 18.3719H35.1665L30.163 7.16223ZM43.4087 7.16223H46.0308V18.3719H43.4087V7.16223Z"
      fill="black"
    />
  ),
})
