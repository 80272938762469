import React, { FC } from "react"
import { TimerFloatContainer } from "~/components"
import { BlockWrapper, BlockWrapperProps } from "~/pages/Main/Blocks/BlockWrapper"
import { HeaderGTContainer } from "~/pages/Main/HeaderGT/HeaderGTContainer"

export const HeaderGTBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props} paddingTop={4} overflow="hidden">
    <TimerFloatContainer />
    <HeaderGTContainer />
  </BlockWrapper>
)
