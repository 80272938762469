import React, { FC, ReactNode, useMemo } from "react"
import { Box, Flex, Icon } from "@chakra-ui/react"

import { ExternalBox } from "~/components"
import { CurrencyCode, formatPrice, getCurrencySymbol, noop } from "~/utils"

import type { PriceItemProps } from "./types"
import { CheckedRadio, UncheckedRadio } from "./Icons"
import { Strike } from "./Strike"
import { toFractionTuple } from "./toFractionTuple"

const PriceItemBadge: FC<{ children: ReactNode }> = ({ children }) => (
  <Box
    paddingX={2}
    paddingY={0.25}
    borderRadius="8px"
    background="Base/accentSecondary"
    color="Base/neutralPrimary"
    textStyle="Other/PriceTag"
  >
    {children}
  </Box>
)

const PriceLayout: FC<{
  isSelected?: boolean
  label?: ReactNode
  children: ReactNode
  onClick?: () => void
}> = ({ isSelected = false, label, children, onClick = noop }) => (
  <Box as="label" aria-checked={isSelected}>
    <input type="checkbox" aria-checked={isSelected} hidden />
    {label && (
      <ExternalBox top="-8px" left={4}>
        <PriceItemBadge>{label}</PriceItemBadge>
      </ExternalBox>
    )}
    <Flex
      direction="row"
      justify="flex-start"
      align="center"
      gap={3}
      bgColor="Base/neutralPrimary"
      borderColor="transparent"
      borderWidth={2}
      borderRadius="full"
      onClick={onClick}
      paddingLeft={4}
      py={0}
      w="full"
      cursor="pointer"
      userSelect="none"
      overflow="hidden"
      aria-checked={isSelected}
      _checked={{
        borderColor: "Base/accentPrimary",
      }}
    >
      <Icon
        fontSize={28}
        as={isSelected ? CheckedRadio : UncheckedRadio}
        aria-checked={isSelected}
        color="Base/neutralDisabled"
        _checked={{
          color: "Base/accentPrimary",
        }}
      />

      {children}
    </Flex>
  </Box>
)

const PriceNumbers: FC<{
  currency: CurrencyCode
  prevPricePerPeriod: number
  pricePerPeriod: number
  isSelected?: boolean
}> = ({ currency, pricePerPeriod, prevPricePerPeriod, isSelected = false }) => {
  const price = useMemo(() => toFractionTuple(pricePerPeriod), [pricePerPeriod])
  const priceSymbol = useMemo(() => getCurrencySymbol(currency), [currency])
  return (
    <Flex direction="row" justify="flex-start" align="center" minW="114px">
      <Flex
        direction="row"
        color="Base/basePrimary"
        background="none"
        borderColor="Base/neutralSecondary"
        borderLeftWidth="1px"
        paddingY={3.5}
        paddingLeft={4}
        paddingRight={2}
        gap={0.5}
        w="full"
        aria-checked={isSelected}
        _checked={{
          color: "Base/neutralPrimary",
          background: "Base/accentPrimary",
          borderColor: "Base/accentPrimary",
        }}
      >
        <Box paddingY={1} textStyle="Subtitle/Secondary">
          {priceSymbol}
        </Box>

        <Box>
          <ExternalBox top="-17px">
            <Strike
              textStyle="Subtitle/Hint"
              color="Base/baseSecondary"
              textDecorationColor="Base/accentSecondary"
              aria-checked={isSelected}
              _checked={{
                color: "#ffffffb3",
                textDecorationColor: "Base/neutralPrimary",
              }}
            >
              {formatPrice(currency, prevPricePerPeriod)}
            </Strike>
          </ExternalBox>
          <Box fontFamily="heading" textStyle="Lead/Monospaced">
            {price[0]}
          </Box>
        </Box>
        <Flex direction="column">
          <Box marginTop={1} textStyle="Subtitle/Secondary">
            {price[1]}
          </Box>
          <Box marginTop={-0.5} textStyle="Subtitle/Hint">
            per day
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}
const PriceLayoutTitle: FC<{ title: ReactNode }> = ({ title }) => (
  <Box textStyle="Subtitle/Secondary" flexGrow={1}>
    {title}
  </Box>
)

export const PriceItem4: FC<PriceItemProps> = (props) => {
  const { currency_code, title, isPopular, isSelected, pricePerDay, previousPricePerDay, onClick } =
    props
  let label = null
  if (isPopular) {
    label = "MOST POPULAR"
  }

  return (
    <PriceLayout isSelected={isSelected} label={label} onClick={onClick}>
      <PriceLayoutTitle title={title} />
      <PriceNumbers
        currency={currency_code}
        isSelected={isSelected}
        /* FIXME parseFloat */
        pricePerPeriod={parseFloat(pricePerDay)}
        /* FIXME parseFloat */
        prevPricePerPeriod={parseFloat(previousPricePerDay)}
      />
    </PriceLayout>
  )
}
