import React, { FC, useState, useRef, useEffect } from "react"
import { ButtonSlideUpContainer } from "~/components/ButtonSlideUpContainer"

import { Header } from "./Header"
import { NextSteps } from "./NextSteps"
import { DownloadButton } from "./DownloadButton"
import { StepsContent } from "./StepsContent"

export const DefaultVariant: FC = () => {
  const [isButtonSlideVisible, setIsButtonSlideVisible] = useState(false)
  const downloadButtonRef = useRef<HTMLDivElement>(null)

  // to have only one download button visible at a time
  useEffect(() => {
    const downloadButtonRefCurrent = downloadButtonRef.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsButtonSlideVisible(!entry?.isIntersecting ?? true)
      },
      {
        threshold: 0.1,
      }
    )

    if (downloadButtonRefCurrent) {
      observer.observe(downloadButtonRefCurrent)
    }

    return () => {
      if (downloadButtonRefCurrent) {
        observer.unobserve(downloadButtonRefCurrent)
      }
    }
  }, [])

  return (
    <>
      <Header />
      <NextSteps />
      <StepsContent downloadButtonRef={downloadButtonRef} />
      <ButtonSlideUpContainer
        visible={isButtonSlideVisible}
        customGradient="linear-gradient(180deg, rgba(246, 245, 244, 0.00) 0%, #F6F5F4 22.97%, #F6F5F4 100%)"
      >
        <DownloadButton />
      </ButtonSlideUpContainer>
    </>
  )
}
