import React, { FC, ReactNode } from "react"
import { Box, Flex, Heading, LayoutProps, SpaceProps, createIcon } from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"

const CircleIcon = createIcon({
  viewBox: "0 0 20 20",
  defaultProps: {
    width: "20px",
    height: "20px",
    color: "Base/accentSecondary",
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="currentColor"
    />
  ),
})

const helpTexts = [
  {
    title: "Fit score ",
    text: "The Lóvi’s Fit Score objectively assesses skincare products on safety, cost, efficacy, ingredient concentration, and FDA compliance. Experience our detailed tailored to your skin analysis firsthand!",
  },
  {
    title: "Product’s Alternatives",
    text: "Join the 71% of consumers seeking better skincare solutions. Lóvi's extensive database of over 500K products ensures you find the perfect match for your skin.",
  },
  {
    title: "Unbiased Insights",
    text: "As a 100% independent project, Lóvi focuses on the science of skincare, providing impartial recommendations based on product composition, not brand hype.",
  },
  {
    title: "Safety",
    text: "Lóvi's recommendations are safe for all skin types, including sensitive skin, and for pregnant or breastfeeding women. Your security is our priority; we never share your data.",
  },
]

const Item: FC<{
  num: number
  title: ReactNode
  text: ReactNode
}> = ({ num, title, text }) => (
  <Flex gap={2}>
    <Flex as="span" display="flex" pos="relative" alignSelf="flex-start">
      <CircleIcon />
      <Box
        textStyle="Subtitle/Tertiary"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="white"
        pos="absolute"
        left="0"
        top="0"
        right="0"
        bottom="0"
      >
        {num}
      </Box>
    </Flex>
    <VFlex gap={1.5} top="-3px" pos="relative">
      <Box textStyle="Subtitle/Primary">{title}</Box>
      <Box textStyle="Paragraph/Secondary">{text}</Box>
    </VFlex>
  </Flex>
)

export const WhyLoviPart: FC<SpaceProps> = (props) => (
  <VFlex gap={4} {...props}>
    <Heading as="h2" size="Header/Secondary">
      Why Lóvi?
    </Heading>
    <VFlex gap={4}>
      {helpTexts.map(({ text, title }, i) => (
        <Item key={i} {...{ text, title, num: i + 1 }} />
      ))}
    </VFlex>
  </VFlex>
)
