import React, { FC } from "react"
import { useSelectedPrice } from "~/store/selectors"
import { Invoice2 } from "./Invoice2"

export const Invoice2Container: FC = () => {
  const {
    currency_code: currency = "USD",
    trial_period_length: trialLength = 0,
    trial_period_unit: trialUnit,
    trial_price_cents: trialPrice = Number.NaN,
    subscription_period_length: subscriptionLength = 0,
    subscription_period_unit: subscriptionUnit,
    subscription_price_per_unit_cents: subscriptionPricePerUnit = Number.NaN,
    subscription_price_cents: subscriptionTotalPrice = Number.NaN,
    economy_percent: discount = "",
  } = useSelectedPrice() ?? {}
  const props = {
    currency,
    trialLength,
    trialUnit,
    trialPrice,
    subscriptionLength,
    subscriptionUnit,
    subscriptionPricePerUnit,
    subscriptionTotalPrice,
    discount,
  }
  return <Invoice2 {...props} />
}
