import React, { FC, ReactNode, useCallback, useContext, useEffect, useState } from "react"
import {
  AspectRatio,
  Box,
  BoxProps,
  Button,
  Center,
  createIcon,
  Divider,
  Flex,
  FlexProps,
  Heading,
  Image,
  Text,
  useBoolean,
} from "@chakra-ui/react"

import { VFlex } from "~/components/VFlex"
import { ButtonSlideUpContainer } from "~/components/ButtonSlideUpContainer"
import { Logo } from "~/pages/Main/SkipTrial/Icons"
import { useBlockPropertiesExtended } from "~/store/selectors"
import { useAmplitude } from "~/utils/analytics/useAmplitude"
import { formatLengthWithUnit, formatPrice, formatUnitWithPrefixLength } from "~/utils"
import { useMakePurchase } from "~/utils/useMakePurchase"
import type { PriceContentV2_PriceItem } from "~/generated/paywall"
import { UpsaleContext } from "~/pages/Processing/context"
import {
  AlternativeVariant,
  AlternativeVariantProps,
} from "~/pages/AppLinkPage/AlternativeVariantProps"

import bookCoverSrc from "./assets/book_cover.webp?url"
import { WhatIsIncluded } from "./WhatIsIncluded"
import { Step2Modal } from "./Step2Modal"
import shieldSrc from "./assets/shield.svg?url"

const BenefitCard = ({ image, title }: { image: string; title: string }) => {
  return (
    <VFlex
      width="152px"
      flexShrink={0}
      p={4}
      layerStyle="attentionFrame"
      alignItems="flex-start"
      gap={3}
    >
      <Flex>
        <Image height="64px" src={image} />
      </Flex>
      <Flex textStyle="Subtitle/Secondary">{title}</Flex>
    </VFlex>
  )
}

const BENEFIT_CARD_CONFIG = [
  {
    image: "https://storage.pora.ai/aec6bb8cf9ea4e190d5762c66830de0b.webp",
    title: "Evidence-based advices with references",
  },
  {
    image: "https://storage.pora.ai/46be8d4738d0b9fb1d5066aee6cde9a7.webp",
    title: "Instant, relevant skincare advice",
  },
  {
    image: "https://storage.pora.ai/7d6081baa6b843c3a010dd4a90154b83.webp",
    title: "Personalized skin insights",
  },
]
const BenefitCardList = () => {
  return (
    <Flex mx={-6} pl={6} pr={6} gap={2} overflow="scroll">
      {BENEFIT_CARD_CONFIG.map((conf) => (
        <BenefitCard key={conf.title} {...conf} />
      ))}
    </Flex>
  )
}

export const UnorderedList: FC<FlexProps> = ({ children, ...rest }) => {
  return (
    <VFlex gap={3} {...rest}>
      {children}
    </VFlex>
  )
}

export const CheckIcon = createIcon({
  viewBox: "0 0 22 22",
  defaultProps: {
    width: "22px",
    height: "22px",
    fill: "none",
    color: "Base/accentSecondary",
  },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4926 0.918631C15.1079 0.837365 19.2685 3.78665 20.6539 8.11048C22.002 12.3179 20.06 16.7804 16.3895 19.3226C12.8785 21.7544 8.18879 21.6441 4.7325 19.1379C1.35734 16.6906 0.150278 12.3843 1.39427 8.44961C2.69449 4.33702 6.10813 0.995834 10.4926 0.918631Z"
        fill="currentColor"
      />
      <path
        d="M6.875 11.0166C7.46429 12.2229 8.05357 13.8754 9.41474 14.6013C10.7759 15.3273 13.3571 9.87786 15.125 7.33398"
        stroke="white"
        strokeWidth="1.46667"
        strokeLinecap="round"
      />
    </>
  ),
})

export const UnorderedListItem = ({ children }: { children: ReactNode }) => {
  return (
    <Box display="flex" gap={1.5}>
      <CheckIcon />
      <Box textStyle="Subtitle/Secondary">{children}</Box>
    </Box>
  )
}

const AssistantProvides = () => {
  return (
    <>
      <VFlex gap={6} p={6} layerStyle="attentionFrame">
        <Heading as="h2" size="Header/Secondary">
          Lóvi AI Skincare Assistant provides:
        </Heading>
        <Box>
          <UnorderedList>
            <UnorderedListItem>Pocket Dermatologist Available 24/7</UnorderedListItem>
            <UnorderedListItem>Deep Knowledge About Your Skin</UnorderedListItem>
            <UnorderedListItem>Tailored Suggestions</UnorderedListItem>
            <UnorderedListItem>Detailed Skincare Product Insights</UnorderedListItem>
            <UnorderedListItem>Science-supported Research References</UnorderedListItem>
          </UnorderedList>
        </Box>
        <Box mx={-2}>
          <Image src="https://storage.pora.ai/a2270da6f5121397316acd88de6963d0.webp" />
        </Box>
      </VFlex>
      <Box mt={4} color="Base/baseSecondary" textStyle="Subtitle/Secondary">
        Enhance your skincare experience with Lóvi AI Assistant
      </Box>
    </>
  )
}

export const CircleIcon = createIcon({
  viewBox: "0 0 24 24",
  defaultProps: {
    width: "24px",
    height: "24px",
    color: "Base/accentSecondary",
  },
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4472 1.00179C16.482 0.913134 21.0209 4.13053 22.5322 8.84744C24.0029 13.4373 21.8843 18.3055 17.8801 21.0789C14.05 23.7317 8.93393 23.6114 5.16344 20.8774C1.48145 18.2076 0.16465 13.5098 1.52173 9.2174C2.94015 4.73094 6.66412 1.08601 11.4472 1.00179Z"
      fill="currentColor"
    />
  ),
})

const helpTexts = [
  {
    title: "Fit score ",
    text: "The Lóvi’s Fit Score objectively assesses skincare products on safety, cost, efficacy, ingredient concentration, and FDA compliance. Experience our detailed tailored to your skin analysis firsthand!",
  },
  {
    title: "Product’s Alternatives",
    text: "Join the 71% of consumers seeking better skincare solutions. Lóvi's extensive database of over 500K products ensures you find the perfect match for your skin.",
  },
  {
    title: "Unbiased Insights",
    text: "As a 100% independent project, Lóvi focuses on the science of skincare, providing impartial recommendations based on product composition, not brand hype.",
  },
  {
    title: "Safety",
    text: "Lóvi's recommendations are safe for all skin types, including sensitive skin, and for pregnant or breastfeeding women. Your security is our priority; we never share your data.",
  },
]
const WhyLovi = () => {
  return (
    <VFlex gap={4}>
      <Heading as="h2" size="Header/Secondary">
        Why Lóvi?
      </Heading>
      <VFlex gap={4}>
        {helpTexts.map(({ text, title }, i) => {
          return (
            <Flex key={i} gap={2}>
              <Flex as="span" display="flex" pos="relative" alignSelf="flex-start">
                <CircleIcon />
                <Box
                  textStyle="Subtitle/Tertiary"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  color="white"
                  pos="absolute"
                  left="0"
                  top="0"
                  right="0"
                  bottom="0"
                >
                  {i + 1}
                </Box>
              </Flex>
              <VFlex gap={1.5} top="-3px" pos="relative">
                <Box textStyle="Subtitle/Primary">{title}</Box>
                <Box textStyle="Paragraph/Secondary">{text}</Box>
              </VFlex>
            </Flex>
          )
        })}
      </VFlex>
    </VFlex>
  )
}

const DiscountShield: FC<BoxProps> = (props) => (
  <Heading
    as="h3"
    position="absolute"
    bgImage={shieldSrc}
    bgPosition="top"
    bgRepeat="no-repeat"
    color="Base/neutralPrimary"
    size="Header/Secondary"
    w="71px"
    h="112px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    mt={-2}
    {...props}
  />
)
export const DiscountCard: FC<{ discount: string }> = ({ discount }) => (
  <Box
    pl={4}
    pr={6}
    py={8}
    id="discount-card"
    layerStyle="attentionFrame"
    textStyle="Subtitle/AccentPrimary"
    bgColor="Base/accentSecondaryBg"
    borderWidth="1px"
    borderColor="rgba(236, 121, 162, 0.16)"
    alignItems="center"
  >
    <Box position="relative" top={-8} alignSelf="start">
      <DiscountShield position="absolute">
        {discount}
        <br />
        OFF
      </DiscountShield>
    </Box>
    <Box paddingLeft="86px">
      Additional {discount}
      <br />
      discount applied!
    </Box>
  </Box>
)

export enum UpsaleAIStep {
  START = 0,
  UPSALE_SKIPPED = 100,
  DISCOUNTED_OFFER = 200,
}

export const WorkbookCard: FC<{ workbookPrice: PriceContentV2_PriceItem }> = ({
  workbookPrice,
}) => (
  <VFlex px={6} py={4} layerStyle="attentionFrame">
    <Flex justifyContent="center" w="full">
      <Box
        py={1}
        px={2}
        borderRadius="16px"
        bgColor="Base/neutralPrimary"
        color="Base/accentPrimary"
      >
        <Box textStyle="Subtitle/Tertiary">Don’t miss your bonus!</Box>
      </Box>
    </Flex>
    <Heading mt={3} textAlign="center" as="h2" size="Header/Secondary">
      Get FREE Lóvi workbook every month with skincare picks in your plan
    </Heading>
    <AspectRatio ratio={319 / 247}>
      <Image src={bookCoverSrc} alt="Cover" />
    </AspectRatio>
    <Heading
      as="h3"
      size="Header/Tertiary"
      textAlign="center"
      color="Base/accentPrimary"
      marginBottom={1}
    >
      Get it for free every month
      <br />
      with any offer!
    </Heading>
    <Text
      textStyle="Paragraph/Secondary"
      color="Base/baseSecondary"
      textAlign="center"
      marginBottom={2}
    >
      Original price was{" "}
      {formatPrice(workbookPrice.currency_code, parseFloat(workbookPrice.previous_price))} —{" "}
      <Box as="span" color="Base/accentPrimary">
        save {workbookPrice.economy_percent}%!
      </Box>
    </Text>
    <Divider marginBottom={3} borderColor="Base/baseDisabled" opacity={0.3} />
    <Text textStyle="Paragraph/Secondary" color="Base/baseSecondary" textAlign="center">
      You can keep this workbook even if you decide Lóvi isn’t for you.
    </Text>
  </VFlex>
)

const PriceAndLegal: FC<{ selectedPrice: PriceContentV2_PriceItem }> = ({ selectedPrice }) => {
  return (
    <>
      <Box padding={6} layerStyle="attentionFrame">
        <VFlex alignItems="center" textStyle="Header/Secondary">
          <Heading as="h2" size="Header/Secondary" textAlign="center">
            <Box>Get Lóvi AI Assistant with</Box>
            <Box color="Base/accentPrimary">{selectedPrice.economy_percent}% OFF</Box>
          </Heading>
        </VFlex>
        <Box textStyle="Paragraph/Primary" mt={5}>
          <Flex justifyContent="space-between">
            <Box>Original price</Box>
            <Box
              textStyle="Subtitle/Secondary"
              textDecoration="line-through"
              textDecorationThickness="2px"
              textDecorationColor="Base/accentSecondary"
            >
              {formatPrice(selectedPrice.currency_code, parseFloat(selectedPrice.previous_price))}
            </Box>
          </Flex>
          <Divider my={3} borderColor="Base/baseDisabled" opacity={0.3} />
          <Flex justifyContent="space-between">
            <Box>Current price</Box>
            <Box textStyle="Subtitle/Secondary">
              {formatPrice(selectedPrice.currency_code, parseFloat(selectedPrice.economy_price))}/
              {formatLengthWithUnit(
                selectedPrice.subscription_period_unit,
                selectedPrice.subscription_period_length
              ).replace("month", "mo")}
            </Box>
          </Flex>
          <Flex alignItems="center" mt={3} justifyContent="space-between">
            <Box>Amount of scans</Box>
            <Flex
              alignItems="center"
              borderRadius="100px"
              color="white"
              bgColor="Base/accentSecondary"
              textStyle="Subtitle/Secondary"
              gap={1}
              py={2}
              px={3}
            >
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.02344 9.87793C3.02344 9.71387 3.0918 9.57031 3.21484 9.41992L9.59277 1.51758C10.085 0.90918 10.8574 1.30566 10.5771 2.05078L8.49219 7.65625H12.457C12.7578 7.65625 12.9766 7.86133 12.9766 8.14844C12.9766 8.30566 12.915 8.44922 12.792 8.60645L6.40723 16.5088C5.91504 17.1104 5.14258 16.7139 5.42285 15.9688L7.50781 10.3701H3.54297C3.24219 10.3701 3.02344 10.1582 3.02344 9.87793Z"
                  fill="white"
                />
              </svg>
              <Box>Unlimited</Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box textStyle="Paragraph/Tertiary" textColor="Base/baseSecondary">
        PLEASE NOTE: In addition to your subscription, your account will be charged [
        {formatPrice(selectedPrice.currency_code, parseFloat(selectedPrice.economy_price))}] for [
        {formatUnitWithPrefixLength(
          selectedPrice.subscription_period_unit,
          selectedPrice.subscription_period_length
        )}
        ] for the selected add-ons when you click Buy Lóvi AI Assistant. Payment will be processed
        automatically based on the billing information you provided earlier. You can cancel the
        subscription by reaching out to support at care@lovi.care Please note that this service is
        bound by Lóvi’s Privacy Policy, Terms of Use, and Refund Policy.
      </Box>
    </>
  )
}

export const UpsaleAIAssistant: FC<{
  step: UpsaleAIStep
  discount: string
  selectedPrice: PriceContentV2_PriceItem
  workbookPrice?: PriceContentV2_PriceItem
  isLoading: boolean
  onPay: () => void
  onSkip: () => void
  onConfirmUpsaleModal: () => void
}> = ({
  step,
  selectedPrice,
  discount,
  workbookPrice,
  isLoading,
  onPay,
  onSkip,
  onConfirmUpsaleModal,
}) => (
  <Box paddingBottom="184px" bgColor="white" paddingX={6}>
    <VFlex gap={10}>
      <Box>
        <Center mb={4} height="56px" as="header">
          <Logo />
        </Center>
        <VFlex gap={4}>
          <Heading size="Header/Primary">Perfect your skin care with AI Assistant</Heading>
        </VFlex>
        {step === UpsaleAIStep.DISCOUNTED_OFFER && (
          <Box mt={6} mb={10}>
            <DiscountCard discount={discount} />
          </Box>
        )}
        <VFlex mt={6}>
          <WhatIsIncluded price={selectedPrice} />
        </VFlex>
      </Box>
      <PriceAndLegal selectedPrice={selectedPrice} />
      <VFlex gap={4}>
        <Heading as="h2" size="Header/Secondary">
          Why trust Lóvi AI Assistant?
        </Heading>
        <Box>
          <BenefitCardList />
        </Box>
      </VFlex>
      <Box>
        <AssistantProvides />
      </Box>
      <Box>{workbookPrice && <WorkbookCard workbookPrice={workbookPrice} />}</Box>
      <Box>
        <WhyLovi />
      </Box>
      <PriceAndLegal selectedPrice={selectedPrice} />
    </VFlex>
    <ButtonSlideUpContainer visible>
      <VFlex gap={2}>
        <Button isLoading={isLoading} onClick={onPay} w="full" variant="action">
          Buy Lóvi AI Assistant
        </Button>
        <Button disabled={isLoading} onClick={onSkip} w="full" variant="secondary">
          Continue without Lóvi AI Assistant
        </Button>
      </VFlex>
    </ButtonSlideUpContainer>
    <Step2Modal
      discount={discount}
      isOpen={step === UpsaleAIStep.UPSALE_SKIPPED}
      onClose={onConfirmUpsaleModal}
    />
  </Box>
)

export const UpsaleAIAssistantContainer: FC = () => {
  const [step, setStep] = useState<UpsaleAIStep>(UpsaleAIStep.START)
  const { goNext } = useContext(UpsaleContext)
  const makePurchase = useMakePurchase()
  const blockProperties = useBlockPropertiesExtended()

  const price = blockProperties["PB_UPSALE_AI_ASSISTANT_1.prices"]?.prices?.[0]
  const discountPrice = blockProperties["PB_UPSALE_AI_ASSISTANT_1.discountedPrices"]?.prices[0]
  const workbookPrice = blockProperties["PB_UPSALE_AI_ASSISTANT_1.workbookPrice"]?.prices[0]

  const selectedPrice = step === UpsaleAIStep.START ? price : discountPrice
  const [paymentPageVariant, setPaymentPageVariant] = useState<AlternativeVariantProps["variant"]>()

  const [isLoading, setLoading] = useBoolean()
  const log = useAmplitude()
  useEffect(() => {
    log.upsaleAIAssistantView({ step })
  }, [log, step])

  useEffect(() => {
    document.querySelector("html")?.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (step === UpsaleAIStep.DISCOUNTED_OFFER) {
      document.querySelector("html")?.scrollTo(0, 0)
    }
  }, [step])

  const onSuccess = useCallback(() => {
    log.upsaleAIAssistantBuySuccess()
    setPaymentPageVariant("aiAssistantSuccess")
  }, [log, setPaymentPageVariant])

  const onConfirmUpsaleModal = () => {
    setStep(UpsaleAIStep.DISCOUNTED_OFFER)
  }

  const onSkip = () => {
    if (step === UpsaleAIStep.START) {
      setStep(UpsaleAIStep.UPSALE_SKIPPED)
    }

    if (step === UpsaleAIStep.DISCOUNTED_OFFER) {
      log.upsaleAIAssistantExit()
      setPaymentPageVariant("aiAssistantFail")
    }
  }

  const pay = async () => {
    try {
      if (!selectedPrice) {
        throw new Error("Unknown Price")
      }
      setLoading.on()
      log.upsaleAIAssistantBuy({ priceId: selectedPrice.id })
      const result = await makePurchase(selectedPrice)
      if (result) {
        setLoading.off()
        onSuccess()
        return true
      }
    } catch (error) {
      log.upsaleAIAssistantBuyError()
      setPaymentPageVariant("aiAssistantFail")
    }
    return false
  }

  const onPay = async () => {
    pay()
  }

  if (!price || !discountPrice || !selectedPrice) {
    console.error("empty prices", price, discountPrice, selectedPrice)
    goNext()
    return null
  }

  return (
    <>
      {!paymentPageVariant && (
        <UpsaleAIAssistant
          step={step}
          discount={discountPrice.subscription_text}
          selectedPrice={selectedPrice}
          workbookPrice={workbookPrice}
          isLoading={isLoading}
          onPay={onPay}
          onSkip={onSkip}
          onConfirmUpsaleModal={onConfirmUpsaleModal}
        />
      )}
      {paymentPageVariant && <AlternativeVariant variant={paymentPageVariant} onClick={goNext} />}
    </>
  )
}
