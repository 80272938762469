import { ManipulateType } from "dayjs"
import { PriceContentV2_PeriodUnit } from "~/generated/paywall"

export type PeriodUnit = PriceContentV2_PeriodUnit
export const PeriodUnit = PriceContentV2_PeriodUnit

export const convertToDayjsUnit = (period: PeriodUnit): ManipulateType => {
  switch (period) {
    case PeriodUnit.PERIOD_UNIT_DAY:
      return "day"
    case PeriodUnit.PERIOD_UNIT_WEEK:
      return "week"
    case PeriodUnit.PERIOD_UNIT_MONTH:
      return "month"
    default:
      throw new Error(`Unknown unit ${period}`)
  }
}
