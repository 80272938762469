import { Box, Flex } from "@chakra-ui/react"
import React, { FC } from "react"
import { PaywallBlocks } from "~/generated/paywall"
import { TimerFloatContainer } from "~/components"
import { ThemeContentSwitch } from "~/theme/theme-from-interview/themeProvider"

import { HeaderPrePaywallBlock } from "../HeaderPrePaywall"
import { HeaderGT2Block } from "../HeaderGT2"
import { HeaderGTIBlock } from "../HeaderGTI/HeaderGTIBlock"
import { ReviewsPrePaywallContainer } from "../ReviewsPrePaywall/ReviewsPrePaywallContainer"
import { FaceExercisesContainer } from "../FaceExercises/FaceExercisesContainer"
import { PeopleOftenAskContainer } from "../PeopleOftenAsk/PeopleOftenAskContainer"
import { PersonalProductsContainer } from "../PersonalProducts/PersonalProductsContainer"
import { PriceSelectFullscreenPopupContainer, PriceSelectPopupContainer } from "../PriceSelectPopup"
import { ProgramBenefitsBetterMeContainer } from "../ProgramBenefitsBetterMe/ProgramBenefitsBetterMeContainer"
import {
  ProgramBenefitsGTContainer,
  ProgramBenefitsList2Container,
  ProgramBenefitsPrePaywallContainer,
} from "../ProgramBenefitsList"
import { ReviewsBetterMeContainer } from "../ReviewsBetterMe/ReviewsBetterMeContainer"
import { HeaderGTBlock } from "../HeaderGT/HeaderGTBlock"
import { WeArePaltaContainer } from "../WeArePalta/WeArePaltaContainer"
import { AppScreenshots } from "./AppScreenshots/AppScreenshots"
import { BlockWithScrollAnalytics, BlockWrapper, BlockWrapperProps } from "./BlockWrapper"
import {
  Header2Block,
  Header3Block,
  Header4Block,
  Header5Block,
  HeaderBlock,
  HeaderLuvlyBlock,
  HeaderWithListBlock,
  HeaderWithListTimerChartBlock,
  HeaderWithPhotoBlock,
} from "./HeaderBlock"
import { BeforeAfterBasedOnGoalContainer } from "./BeforeAfterBasedOnGoal"
import {
  Invoice2BlockContainer,
  Invoice2BlockContainerImpl,
  InvoicePrePaywallBlock,
} from "./InvoiceBlock"
import {
  PaymentFormBlock,
  PaymentFormWithInvoice3InPopupBlock,
  PaymentFormWithInvoiceBlock,
  PaymentFormWithStaticTextBlock,
} from "./PaymentFormBlock"
import { PriceSelectWithPaymentFormBlock } from "./PriceSelectWithPaymentFormBlock"
import { Program2Block, ProgramBlock } from "./ProgramBlock"
import { ReviewsBlock } from "./ReviewsBlock"
import {
  MoneyBack2Block,
  MoneyBack3Block,
  MoneyBackBlock,
  MoneyBackIntroBlock,
  StaticBenefitsBlock,
  StaticCitationAssistant2Block,
  StaticCitationAssistantBlock,
  StaticCitationNadyaBlock,
  StaticCopyrightBlock,
  StaticExpertsBlock,
  StaticMapBlock,
  StaticMilestonesBlock,
} from "./StaticBlock"
import { SummaryBlock } from "./SummaryBlock"
import { HarmfulProductsContainer } from "./HarmfulProductsBlock"
import {
  PriceSelect2Block,
  PriceSelect3Block,
  PriceSelect4_1Block,
  PriceSelect4_2Block,
  PriceSelect4Block,
  PriceSelect5_2Block,
  PriceSelect5Block,
  PriceSelect6_2Block,
  PriceSelect6Block,
  PriceSelect7Block,
  PriceSelect8Block,
  PriceSelect9_2Block,
  PriceSelect9Block,
  PriceSelectWithDate2Block,
  PriceSelectWithDate3Block,
  PriceSelectWithDate4Block,
  PriceSelectWithDateBlock,
} from "./PriceSelectBlock"
import { HowTrialWorks } from "../HowTrialWorks/HowTrialWorks"
import { BeforeAfterReviewsCarouselBlock } from "./BeforeAfterReviewsCarouselBlock"
import { FaceScanResultBlock } from "./FaceScanResultBlock"
import { TipsBlock } from "./TipsBlock"
import { ProductsImages1Block, ProductsImages2Block } from "./ProductsImagesBlock"
import {
  TimerFloat4Block,
  TimerFloat5BottomBlock,
  TimerFloat5TopBlock,
  TimerFloat6Block,
} from "./TimerFloatBlock"
import { UpsaleSubscriptionOnExitBlock } from "./UpsaleSubscriptionOnExitBlock"
import { Image2Block, ImageBlock, ImageDuplicateBlock, ImageWithButtonBlock } from "./ImageBlock"
import { EnhancedPhoto2Block, EnhancedPhotoBlock } from "./EnhancedPhotoBlock"
import { AIReviewsGeneratedPlanBlock } from "./AIGeneratedPlanBlock"
import { VideoReviewsBlock } from "./VideoReviewsBlock"
import { ImagesCarouselBlock } from "./ImagesCarouselBlock"
import { SubscriptionInfoTextBlock } from "./SubscriptionInfoTextBlock"
import { BeforeAfterWithTimerBlock } from "./BeforeAfterWithTimerBlock"
import { EnhancedPhoto3Block } from "~/pages/Main/Blocks/EnhancedPhotoBlock/EnhancedPhoto3Block"
import { Reviews5Container } from "../Reviews5/Reviews5Container"
import { Reviews6Container } from "~/pages/Main/Reviews6/Reviews6Container"

const PersonalProductsBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <PersonalProductsContainer />
  </BlockWrapper>
)
const FaceExercisesBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <FaceExercisesContainer />
  </BlockWrapper>
)

const ProgramBenefitsBetterMeBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <ProgramBenefitsBetterMeContainer />
  </BlockWrapper>
)

const PeopleOftenAskBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <PeopleOftenAskContainer />
  </BlockWrapper>
)

const ReviewsBetterMeBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <ReviewsBetterMeContainer />
  </BlockWrapper>
)

const WeArePaltaBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <WeArePaltaContainer />
  </BlockWrapper>
)

const BlockHeader = () => <ThemeContentSwitch pora="Why Pora?" lovi="Why Lovi?" />

const ProgramBenefitsPrePaywall: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper header={<BlockHeader />} {...props}>
    <ProgramBenefitsPrePaywallContainer />
  </BlockWrapper>
)

const ProgramBenefitsGT: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper header={<BlockHeader />} {...props}>
    <ProgramBenefitsGTContainer />
  </BlockWrapper>
)

const ProgramBenefitsList2Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper header={<BlockHeader />} {...props}>
    <ProgramBenefitsList2Container />
  </BlockWrapper>
)

const header = `Here are a few stories
from Lóvi users:`

const ReviewsPrePaywallBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper paddingX={6} paddingY={6} header={header} {...props}>
    <ReviewsPrePaywallContainer />
  </BlockWrapper>
)

const Reviews5Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper paddingX={6} paddingY={6} header={"What Lóvi Users Say"} {...props}>
    <Reviews5Container />
  </BlockWrapper>
)

const Reviews6Block: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper paddingX={6} paddingY={6} header={"Hundreds More Five-Star Reviews"} {...props}>
    <Reviews6Container />
  </BlockWrapper>
)

const AppScreenshotsBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <AppScreenshots />
  </BlockWrapper>
)

const HowTrialWorksInvoiceBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <Box mb={8}>
      <HowTrialWorks />
    </Box>
    <Invoice2BlockContainerImpl />
  </BlockWrapper>
)

const HowTrialWorksShortInvoiceBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper {...props}>
    <Box mb={8}>
      <HowTrialWorks showDay2={false} />
    </Box>
    <Invoice2BlockContainerImpl />
  </BlockWrapper>
)

const BeforeAfterBasedOnGoalBlock: FC<BlockWrapperProps> = (props) => (
  <BlockWrapper variant="transparent" paddingX={6} {...props}>
    <TimerFloatContainer variant={3} />
    <BeforeAfterBasedOnGoalContainer />
  </BlockWrapper>
)

export const getBlockComponentByType = (type: PaywallBlocks): FC<BlockWithScrollAnalytics> => {
  switch (type) {
    case PaywallBlocks.PB_APP_SCREENSHOTS:
      return AppScreenshotsBlock
    case PaywallBlocks.PB_HEADER:
      return HeaderBlock
    case PaywallBlocks.PB_HEADER_2:
      return Header2Block
    case PaywallBlocks.PB_HEADER_3:
      return Header3Block
    case PaywallBlocks.PB_MILESTONES:
      return StaticMilestonesBlock
    case PaywallBlocks.PB_PROGRAM_2:
      return Program2Block
    case PaywallBlocks.PB_PROGRAM_BENEFITS:
      return ProgramBlock
    case PaywallBlocks.PB_REVIEWS:
      return ReviewsBlock
    case PaywallBlocks.PB_SUMMARY:
      return SummaryBlock
    case PaywallBlocks.PB_MONEYBACK:
      return MoneyBackBlock
    case PaywallBlocks.PB_MONEYBACK_2:
      return MoneyBack2Block
    case PaywallBlocks.PB_MONEYBACK_INTRO:
      return MoneyBackIntroBlock
    case PaywallBlocks.PB_INVOICE:
      return Invoice2BlockContainer
    case PaywallBlocks.PB_PERSONAL_PRODUCTS:
      return PersonalProductsBlock
    case PaywallBlocks.PB_FACE_EXERCISES:
      return FaceExercisesBlock
    case PaywallBlocks.PB_PROGRAM_BENEFITS_BETTER_ME:
      return ProgramBenefitsBetterMeBlock
    case PaywallBlocks.PB_PEOPLE_OFTEN_ASK:
      return PeopleOftenAskBlock
    case PaywallBlocks.PB_WE_ARE_PALTA:
      return WeArePaltaBlock
    case PaywallBlocks.PB_REVIEWS_BETTER_ME:
      return ReviewsBetterMeBlock
    case PaywallBlocks.PB_PROGRAM_BENEFITS_PRE_PAYWALL:
      return ProgramBenefitsPrePaywall
    case PaywallBlocks.PB_REVIEWS_PRE_PAYWALL:
      return ReviewsPrePaywallBlock
    case PaywallBlocks.PB_PRICE_SELECT:
      return PriceSelectWithPaymentFormBlock
    case PaywallBlocks.PB_PRICE_SELECT2:
      return PriceSelect2Block
    case PaywallBlocks.PB_PRICE_SELECT_3:
      return PriceSelect3Block
    case PaywallBlocks.PB_PRICE_SELECT_4:
      return PriceSelect4Block
    case PaywallBlocks.PB_PAYMENT_FORM:
      return PaymentFormBlock
    case PaywallBlocks.PB_PAYMENT_FORM_WITH_STATIC_TEXT:
      return PaymentFormWithStaticTextBlock
    case PaywallBlocks.PB_PAYMENT_FORM_WITH_INVOICE:
      return PaymentFormWithInvoiceBlock
    case PaywallBlocks.PB_PAYMENT_FORM_WITH_INVOICE_3_IN_POPUP:
      return PaymentFormWithInvoice3InPopupBlock
    case PaywallBlocks.PB_PRICE_SELECT_POPUP:
      return PriceSelectPopupContainer
    case PaywallBlocks.PB_PRICE_SELECT_POPUP_PRE_PAYWALL:
      return PriceSelectFullscreenPopupContainer
    case PaywallBlocks.PB_HEADER_PRE_PAYWALL:
      return HeaderPrePaywallBlock
    case PaywallBlocks.PB_INVOICE_PRE_PAYWALL:
      return InvoicePrePaywallBlock
    case PaywallBlocks.PB_HEADER_GT:
      return HeaderGTBlock
    case PaywallBlocks.PB_PROGRAM_BENEFITS_GT:
      return ProgramBenefitsGT
    case PaywallBlocks.PB_HARMFUL_PRODUCTS:
      return HarmfulProductsContainer
    case PaywallBlocks.PB_HEADER_GT2:
      return HeaderGT2Block
    case PaywallBlocks.PB_HEADER_GTI:
      return HeaderGTIBlock
    case PaywallBlocks.PB_HOW_TRIAL_WORKS_INVOICE:
      return HowTrialWorksInvoiceBlock
    case PaywallBlocks.PB_HOW_TRIAL_WORKS_SHORT_INVOICE:
      return HowTrialWorksShortInvoiceBlock
    case PaywallBlocks.PB_BEFORE_AFTER_BASED_ON_GOAL:
      return BeforeAfterBasedOnGoalBlock
    case PaywallBlocks.PB_REVIEWS_WITH_BEFORE_AFTER_PHOTOS:
      return BeforeAfterReviewsCarouselBlock
    case PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE:
      return PriceSelectWithDateBlock
    case PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE_2:
      return PriceSelectWithDate2Block
    case PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE_3:
      return PriceSelectWithDate3Block
    case PaywallBlocks.PB_PRICE_SELECT_WITH_GOAL_AND_DATE_4:
      return PriceSelectWithDate4Block
    case PaywallBlocks.PB_PROGRAM_BENEFITS_2:
      return ProgramBenefitsList2Block
    case PaywallBlocks.PB_HEADER_LUVLY:
    case PaywallBlocks.PB_HEADER_LUVLY_STATIC:
      return HeaderLuvlyBlock
    case PaywallBlocks.PB_HEADER_WITH_LIST:
      return HeaderWithListBlock
    case PaywallBlocks.PB_HEADER_WITH_PHOTO:
      return HeaderWithPhotoBlock
    case PaywallBlocks.PB_FACESCAN_RESULTS:
      return FaceScanResultBlock
    case PaywallBlocks.PB_TIPS:
      return TipsBlock
    case PaywallBlocks.PB_STATIC_CITATION_1:
      return StaticCitationNadyaBlock
    case PaywallBlocks.PB_STATIC_CITATION_2:
      return StaticCitationAssistantBlock
    case PaywallBlocks.PB_STATIC_CITATION_3:
      return StaticCitationAssistant2Block
    case PaywallBlocks.PB_STATIC_EXPERTS:
      return StaticExpertsBlock
    case PaywallBlocks.PB_PRODUCTS_IMAGES_1:
      return ProductsImages1Block
    case PaywallBlocks.PB_PRODUCTS_IMAGES_2:
      return ProductsImages2Block
    case PaywallBlocks.PB_STATIC_MAP:
      return StaticMapBlock
    case PaywallBlocks.PB_PRICE_SELECT_4_1:
      return PriceSelect4_1Block
    case PaywallBlocks.PB_PRICE_SELECT_4_2:
      return PriceSelect4_2Block
    case PaywallBlocks.PB_TIMER_FLOAT_4:
      return TimerFloat4Block
    case PaywallBlocks.PB_TIMER_FLOAT_5_BOTTOM:
      return TimerFloat5BottomBlock
    case PaywallBlocks.PB_TIMER_FLOAT_5_TOP:
      return TimerFloat5TopBlock
    case PaywallBlocks.PB_TIMER_FLOAT_6:
      return TimerFloat6Block
    case PaywallBlocks.PB_UPSALE_SUBSCRIPTION_ON_EXIT:
      return UpsaleSubscriptionOnExitBlock
    case PaywallBlocks.PB_IMAGE:
      return ImageBlock
    case PaywallBlocks.PB_IMAGE_DUPLICATE:
      return ImageDuplicateBlock
    case PaywallBlocks.PB_IMAGE_2:
      return Image2Block
    case PaywallBlocks.PB_HEADER_WITH_LIST_TIMER_CHART:
      return HeaderWithListTimerChartBlock
    case PaywallBlocks.PB_PRICE_SELECT_5:
      return PriceSelect5Block
    case PaywallBlocks.PB_PRICE_SELECT_5_2:
      return PriceSelect5_2Block
    case PaywallBlocks.PB_PRICE_SELECT_6:
      return PriceSelect6Block
    case PaywallBlocks.PB_PRICE_SELECT_6_2:
      return PriceSelect6_2Block
    case PaywallBlocks.PB_PRICE_SELECT_7:
      return PriceSelect7Block
    case PaywallBlocks.PB_PRICE_SELECT_8:
      return PriceSelect8Block
    case PaywallBlocks.PB_PRICE_SELECT_9:
      return PriceSelect9Block
    case PaywallBlocks.PB_PRICE_SELECT_9_2:
      return PriceSelect9_2Block
    case PaywallBlocks.PB_ENHANCED_PHOTO:
      return EnhancedPhotoBlock
    case PaywallBlocks.PB_ENHANCED_PHOTO_2:
      return EnhancedPhoto2Block
    case PaywallBlocks.PB_AI_GENERATED_PLAN:
      return AIReviewsGeneratedPlanBlock
    case PaywallBlocks.PB_VIDEO_REVIEWS:
      return VideoReviewsBlock
    case PaywallBlocks.PB_IMAGES_CAROUSEL:
      return ImagesCarouselBlock
    case PaywallBlocks.PB_IMAGE_WITH_BUTTON:
      return ImageWithButtonBlock
    case PaywallBlocks.PB_STATIC_BENEFITS:
      return StaticBenefitsBlock
    case PaywallBlocks.PB_MONEYBACK_3:
      return MoneyBack3Block
    case PaywallBlocks.PB_STATIC_COPYRIGHT:
      return StaticCopyrightBlock
    case PaywallBlocks.PB_SUBSCRIPTION_INFO_TEXT:
    case PaywallBlocks.PB_SUBSCRIPTION_INFO_TEXT_2:
      return SubscriptionInfoTextBlock
    case PaywallBlocks.PB_BEFORE_AFTER_WITH_TIMER:
      return BeforeAfterWithTimerBlock
    case PaywallBlocks.PB_HEADER_4:
      return Header4Block
    case PaywallBlocks.PB_ENHANCED_PHOTO_3:
      return EnhancedPhoto3Block
    case PaywallBlocks.PB_REVIEWS_5:
      return Reviews5Block
    case PaywallBlocks.PB_HEADER_5:
      return Header5Block
    case PaywallBlocks.PB_REVIEWS_6:
      return Reviews6Block
  }
  return () => null
}

export const Blocks: FC<{ data: PaywallBlocks[] }> = ({ data }) => (
  <Box w="full" minH="$100vh" bgColor="Base/neutralSecondary">
    <Flex direction="column" gap={2} className="BlocksList">
      {data.map((type, i) => {
        const BlockComponent = getBlockComponentByType(type)
        return <BlockComponent analyticsId={type} key={i} />
      })}
    </Flex>
  </Box>
)
