import React, { FC, ReactNode } from "react"
import {
  Box,
  Heading,
  Image,
  Button,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalProps,
  AspectRatio,
} from "@chakra-ui/react"
import { VFlex } from "~/components/VFlex"

import chartSrc from "./assets/chart.svg?url"
import { WorkbookRow } from "~/pages/Processing/UpsaleNewSubscriptions/NewSubscriptionsPrices"
import { PriceContentV2_PriceItem } from "~/generated/paywall"

const Content: FC<{ children: ReactNode; additionalDiscount: string }> = ({
  children,
  additionalDiscount,
}) => (
  <VFlex
    gap={6}
    alignItems="center"
    borderRadius="32px"
    paddingX={8}
    pt={16}
    pb={8}
    bgColor="white"
  >
    <VFlex gap={2} alignItems="center">
      <Heading size="Header/Secondary" textAlign="center">
        Did you know?
      </Heading>
      <Box textStyle="Paragraph/Primary" textAlign="center">
        Lóvi users who start their program with AI Product Scanner & Assistant get visible results
        34% faster
      </Box>
    </VFlex>
    <Box w="full">
      <AspectRatio ratio={808 / 518}>
        <Image
          src="https://storage.pora.ai/6e0743cb0afb017b6e4a1c4cb7f49215.webp"
          alt="Results 34% faster with workbook"
        />
      </AspectRatio>
    </Box>
    {children}
    <Box
      textStyle="Paragraph/Primary"
      color="Base/baseSecondary"
      textAlign="center"
      sx={{
        "& em": {
          color: "Base/accentPrimary",
          fontWeight: 600,
          fontStyle: "normal",
        },
      }}
    >
      We want you to see visible results, so we’re offering you extra{" "}
      <em>{additionalDiscount} off</em>!
    </Box>
  </VFlex>
)

export const Step2Modal: FC<
  Pick<ModalProps, "onClose" | "isOpen"> & {
    workbookPrice: PriceContentV2_PriceItem
    additionalDiscount: string
  }
> = ({ isOpen, onClose, workbookPrice, additionalDiscount }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="transparent">
        <ModalCloseButton borderRadius="full" color="Base/baseSecondary" />
        <Content additionalDiscount={additionalDiscount}>
          <Box w="full">
            <WorkbookRow price={workbookPrice} />
          </Box>
          <Button w="full" variant="action" onClick={onClose}>
            Continue
          </Button>
        </Content>
      </ModalContent>
    </Modal>
  )
}
