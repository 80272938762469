/* eslint-disable */
import { Timestamp } from "./google/protobuf/timestamp";
import { Request } from "./networking";
import { AttributedString } from "./presentation";

export const protobufPackage = "personalization";

export interface GetPersonalizationScreenRequest {
  request: Request | undefined;
}

export interface SavePersonalizationScreenRequest {
  request: Request | undefined;
  screen: PersonalizationScreen | undefined;
}

export interface PersonalizationScreen {
  title: AttributedString | undefined;
  sections: PersonalizationScreen_Section[];
  button_title: string;
}

export interface PersonalizationScreen_Section {
  title: string;
  items: PersonalizationScreen_Section_Item[];
  is_collapsed: boolean;
  collapsed_subtitle: string;
}

export interface PersonalizationScreen_Section_Item {
  id: string;
  content: PersonalizationScreen_Section_Item_Content | undefined;
  error_message: string;
  is_required: boolean;
}

export interface PersonalizationScreen_Section_Item_Content {
  content?: { $case: "single_select"; single_select: PersonalizationScreen_Section_Item_Content_SingleSelect } | {
    $case: "multi_select";
    multi_select: PersonalizationScreen_Section_Item_Content_MultiSelect;
  } | { $case: "time_picker"; time_picker: PersonalizationScreen_Section_Item_Content_TimePicker };
}

export interface PersonalizationScreen_Section_Item_Content_SingleSelect {
  options: PersonalizationScreen_Section_Item_Content_SingleSelect_Option[];
  selected_option_id: string;
}

export interface PersonalizationScreen_Section_Item_Content_SingleSelect_Option {
  id: string;
  title: string;
  icon: string;
}

export interface PersonalizationScreen_Section_Item_Content_MultiSelect {
  options: PersonalizationScreen_Section_Item_Content_MultiSelect_Option[];
  selected_option_ids: string[];
}

export interface PersonalizationScreen_Section_Item_Content_MultiSelect_Option {
  id: string;
  title: string;
  is_exclusive: boolean;
}

export interface PersonalizationScreen_Section_Item_Content_TimePicker {
  selected_time: Date | undefined;
  title: string;
}

function createBaseGetPersonalizationScreenRequest(): GetPersonalizationScreenRequest {
  return { request: undefined };
}

export const GetPersonalizationScreenRequest = {
  fromJSON(object: any): GetPersonalizationScreenRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: GetPersonalizationScreenRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPersonalizationScreenRequest>, I>>(base?: I): GetPersonalizationScreenRequest {
    return GetPersonalizationScreenRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetPersonalizationScreenRequest>, I>>(
    object: I,
  ): GetPersonalizationScreenRequest {
    const message = createBaseGetPersonalizationScreenRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseSavePersonalizationScreenRequest(): SavePersonalizationScreenRequest {
  return { request: undefined, screen: undefined };
}

export const SavePersonalizationScreenRequest = {
  fromJSON(object: any): SavePersonalizationScreenRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      screen: isSet(object.screen) ? PersonalizationScreen.fromJSON(object.screen) : undefined,
    };
  },

  toJSON(message: SavePersonalizationScreenRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.screen !== undefined &&
      (obj.screen = message.screen ? PersonalizationScreen.toJSON(message.screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SavePersonalizationScreenRequest>, I>>(
    base?: I,
  ): SavePersonalizationScreenRequest {
    return SavePersonalizationScreenRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SavePersonalizationScreenRequest>, I>>(
    object: I,
  ): SavePersonalizationScreenRequest {
    const message = createBaseSavePersonalizationScreenRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.screen = (object.screen !== undefined && object.screen !== null)
      ? PersonalizationScreen.fromPartial(object.screen)
      : undefined;
    return message;
  },
};

function createBasePersonalizationScreen(): PersonalizationScreen {
  return { title: undefined, sections: [], button_title: "" };
}

export const PersonalizationScreen = {
  fromJSON(object: any): PersonalizationScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      sections: Array.isArray(object?.sections)
        ? object.sections.map((e: any) => PersonalizationScreen_Section.fromJSON(e))
        : [],
      button_title: isSet(object.button_title) ? String(object.button_title) : "",
    };
  },

  toJSON(message: PersonalizationScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? PersonalizationScreen_Section.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    message.button_title !== undefined && (obj.button_title = message.button_title);
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalizationScreen>, I>>(base?: I): PersonalizationScreen {
    return PersonalizationScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PersonalizationScreen>, I>>(object: I): PersonalizationScreen {
    const message = createBasePersonalizationScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.sections = object.sections?.map((e) => PersonalizationScreen_Section.fromPartial(e)) || [];
    message.button_title = object.button_title ?? "";
    return message;
  },
};

function createBasePersonalizationScreen_Section(): PersonalizationScreen_Section {
  return { title: "", items: [], is_collapsed: false, collapsed_subtitle: "" };
}

export const PersonalizationScreen_Section = {
  fromJSON(object: any): PersonalizationScreen_Section {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => PersonalizationScreen_Section_Item.fromJSON(e))
        : [],
      is_collapsed: isSet(object.is_collapsed) ? Boolean(object.is_collapsed) : false,
      collapsed_subtitle: isSet(object.collapsed_subtitle) ? String(object.collapsed_subtitle) : "",
    };
  },

  toJSON(message: PersonalizationScreen_Section): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.items) {
      obj.items = message.items.map((e) => e ? PersonalizationScreen_Section_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.is_collapsed !== undefined && (obj.is_collapsed = message.is_collapsed);
    message.collapsed_subtitle !== undefined && (obj.collapsed_subtitle = message.collapsed_subtitle);
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalizationScreen_Section>, I>>(base?: I): PersonalizationScreen_Section {
    return PersonalizationScreen_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PersonalizationScreen_Section>, I>>(
    object: I,
  ): PersonalizationScreen_Section {
    const message = createBasePersonalizationScreen_Section();
    message.title = object.title ?? "";
    message.items = object.items?.map((e) => PersonalizationScreen_Section_Item.fromPartial(e)) || [];
    message.is_collapsed = object.is_collapsed ?? false;
    message.collapsed_subtitle = object.collapsed_subtitle ?? "";
    return message;
  },
};

function createBasePersonalizationScreen_Section_Item(): PersonalizationScreen_Section_Item {
  return { id: "", content: undefined, error_message: "", is_required: false };
}

export const PersonalizationScreen_Section_Item = {
  fromJSON(object: any): PersonalizationScreen_Section_Item {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      content: isSet(object.content) ? PersonalizationScreen_Section_Item_Content.fromJSON(object.content) : undefined,
      error_message: isSet(object.error_message) ? String(object.error_message) : "",
      is_required: isSet(object.is_required) ? Boolean(object.is_required) : false,
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.content !== undefined &&
      (obj.content = message.content ? PersonalizationScreen_Section_Item_Content.toJSON(message.content) : undefined);
    message.error_message !== undefined && (obj.error_message = message.error_message);
    message.is_required !== undefined && (obj.is_required = message.is_required);
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item>, I>>(
    base?: I,
  ): PersonalizationScreen_Section_Item {
    return PersonalizationScreen_Section_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item>, I>>(
    object: I,
  ): PersonalizationScreen_Section_Item {
    const message = createBasePersonalizationScreen_Section_Item();
    message.id = object.id ?? "";
    message.content = (object.content !== undefined && object.content !== null)
      ? PersonalizationScreen_Section_Item_Content.fromPartial(object.content)
      : undefined;
    message.error_message = object.error_message ?? "";
    message.is_required = object.is_required ?? false;
    return message;
  },
};

function createBasePersonalizationScreen_Section_Item_Content(): PersonalizationScreen_Section_Item_Content {
  return { content: undefined };
}

export const PersonalizationScreen_Section_Item_Content = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content {
    return {
      content: isSet(object.single_select)
        ? {
          $case: "single_select",
          single_select: PersonalizationScreen_Section_Item_Content_SingleSelect.fromJSON(object.single_select),
        }
        : isSet(object.multi_select)
        ? {
          $case: "multi_select",
          multi_select: PersonalizationScreen_Section_Item_Content_MultiSelect.fromJSON(object.multi_select),
        }
        : isSet(object.time_picker)
        ? {
          $case: "time_picker",
          time_picker: PersonalizationScreen_Section_Item_Content_TimePicker.fromJSON(object.time_picker),
        }
        : undefined,
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content): unknown {
    const obj: any = {};
    message.content?.$case === "single_select" && (obj.single_select = message.content?.single_select
      ? PersonalizationScreen_Section_Item_Content_SingleSelect.toJSON(message.content?.single_select)
      : undefined);
    message.content?.$case === "multi_select" && (obj.multi_select = message.content?.multi_select
      ? PersonalizationScreen_Section_Item_Content_MultiSelect.toJSON(message.content?.multi_select)
      : undefined);
    message.content?.$case === "time_picker" && (obj.time_picker = message.content?.time_picker
      ? PersonalizationScreen_Section_Item_Content_TimePicker.toJSON(message.content?.time_picker)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content>, I>>(
    base?: I,
  ): PersonalizationScreen_Section_Item_Content {
    return PersonalizationScreen_Section_Item_Content.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content>, I>>(
    object: I,
  ): PersonalizationScreen_Section_Item_Content {
    const message = createBasePersonalizationScreen_Section_Item_Content();
    if (
      object.content?.$case === "single_select" &&
      object.content?.single_select !== undefined &&
      object.content?.single_select !== null
    ) {
      message.content = {
        $case: "single_select",
        single_select: PersonalizationScreen_Section_Item_Content_SingleSelect.fromPartial(
          object.content.single_select,
        ),
      };
    }
    if (
      object.content?.$case === "multi_select" &&
      object.content?.multi_select !== undefined &&
      object.content?.multi_select !== null
    ) {
      message.content = {
        $case: "multi_select",
        multi_select: PersonalizationScreen_Section_Item_Content_MultiSelect.fromPartial(object.content.multi_select),
      };
    }
    if (
      object.content?.$case === "time_picker" &&
      object.content?.time_picker !== undefined &&
      object.content?.time_picker !== null
    ) {
      message.content = {
        $case: "time_picker",
        time_picker: PersonalizationScreen_Section_Item_Content_TimePicker.fromPartial(object.content.time_picker),
      };
    }
    return message;
  },
};

function createBasePersonalizationScreen_Section_Item_Content_SingleSelect(): PersonalizationScreen_Section_Item_Content_SingleSelect {
  return { options: [], selected_option_id: "" };
}

export const PersonalizationScreen_Section_Item_Content_SingleSelect = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_SingleSelect {
    return {
      options: Array.isArray(object?.options)
        ? object.options.map((e: any) => PersonalizationScreen_Section_Item_Content_SingleSelect_Option.fromJSON(e))
        : [],
      selected_option_id: isSet(object.selected_option_id) ? String(object.selected_option_id) : "",
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_SingleSelect): unknown {
    const obj: any = {};
    if (message.options) {
      obj.options = message.options.map((e) =>
        e ? PersonalizationScreen_Section_Item_Content_SingleSelect_Option.toJSON(e) : undefined
      );
    } else {
      obj.options = [];
    }
    message.selected_option_id !== undefined && (obj.selected_option_id = message.selected_option_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_SingleSelect>, I>>(
    base?: I,
  ): PersonalizationScreen_Section_Item_Content_SingleSelect {
    return PersonalizationScreen_Section_Item_Content_SingleSelect.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_SingleSelect>, I>>(
    object: I,
  ): PersonalizationScreen_Section_Item_Content_SingleSelect {
    const message = createBasePersonalizationScreen_Section_Item_Content_SingleSelect();
    message.options =
      object.options?.map((e) => PersonalizationScreen_Section_Item_Content_SingleSelect_Option.fromPartial(e)) || [];
    message.selected_option_id = object.selected_option_id ?? "";
    return message;
  },
};

function createBasePersonalizationScreen_Section_Item_Content_SingleSelect_Option(): PersonalizationScreen_Section_Item_Content_SingleSelect_Option {
  return { id: "", title: "", icon: "" };
}

export const PersonalizationScreen_Section_Item_Content_SingleSelect_Option = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_SingleSelect_Option {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      icon: isSet(object.icon) ? String(object.icon) : "",
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_SingleSelect_Option): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.icon !== undefined && (obj.icon = message.icon);
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_SingleSelect_Option>, I>>(
    base?: I,
  ): PersonalizationScreen_Section_Item_Content_SingleSelect_Option {
    return PersonalizationScreen_Section_Item_Content_SingleSelect_Option.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_SingleSelect_Option>, I>>(
    object: I,
  ): PersonalizationScreen_Section_Item_Content_SingleSelect_Option {
    const message = createBasePersonalizationScreen_Section_Item_Content_SingleSelect_Option();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.icon = object.icon ?? "";
    return message;
  },
};

function createBasePersonalizationScreen_Section_Item_Content_MultiSelect(): PersonalizationScreen_Section_Item_Content_MultiSelect {
  return { options: [], selected_option_ids: [] };
}

export const PersonalizationScreen_Section_Item_Content_MultiSelect = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_MultiSelect {
    return {
      options: Array.isArray(object?.options)
        ? object.options.map((e: any) => PersonalizationScreen_Section_Item_Content_MultiSelect_Option.fromJSON(e))
        : [],
      selected_option_ids: Array.isArray(object?.selected_option_ids)
        ? object.selected_option_ids.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_MultiSelect): unknown {
    const obj: any = {};
    if (message.options) {
      obj.options = message.options.map((e) =>
        e ? PersonalizationScreen_Section_Item_Content_MultiSelect_Option.toJSON(e) : undefined
      );
    } else {
      obj.options = [];
    }
    if (message.selected_option_ids) {
      obj.selected_option_ids = message.selected_option_ids.map((e) => e);
    } else {
      obj.selected_option_ids = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_MultiSelect>, I>>(
    base?: I,
  ): PersonalizationScreen_Section_Item_Content_MultiSelect {
    return PersonalizationScreen_Section_Item_Content_MultiSelect.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_MultiSelect>, I>>(
    object: I,
  ): PersonalizationScreen_Section_Item_Content_MultiSelect {
    const message = createBasePersonalizationScreen_Section_Item_Content_MultiSelect();
    message.options =
      object.options?.map((e) => PersonalizationScreen_Section_Item_Content_MultiSelect_Option.fromPartial(e)) || [];
    message.selected_option_ids = object.selected_option_ids?.map((e) => e) || [];
    return message;
  },
};

function createBasePersonalizationScreen_Section_Item_Content_MultiSelect_Option(): PersonalizationScreen_Section_Item_Content_MultiSelect_Option {
  return { id: "", title: "", is_exclusive: false };
}

export const PersonalizationScreen_Section_Item_Content_MultiSelect_Option = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_MultiSelect_Option {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      is_exclusive: isSet(object.is_exclusive) ? Boolean(object.is_exclusive) : false,
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_MultiSelect_Option): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.is_exclusive !== undefined && (obj.is_exclusive = message.is_exclusive);
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_MultiSelect_Option>, I>>(
    base?: I,
  ): PersonalizationScreen_Section_Item_Content_MultiSelect_Option {
    return PersonalizationScreen_Section_Item_Content_MultiSelect_Option.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_MultiSelect_Option>, I>>(
    object: I,
  ): PersonalizationScreen_Section_Item_Content_MultiSelect_Option {
    const message = createBasePersonalizationScreen_Section_Item_Content_MultiSelect_Option();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.is_exclusive = object.is_exclusive ?? false;
    return message;
  },
};

function createBasePersonalizationScreen_Section_Item_Content_TimePicker(): PersonalizationScreen_Section_Item_Content_TimePicker {
  return { selected_time: undefined, title: "" };
}

export const PersonalizationScreen_Section_Item_Content_TimePicker = {
  fromJSON(object: any): PersonalizationScreen_Section_Item_Content_TimePicker {
    return {
      selected_time: isSet(object.selected_time) ? fromJsonTimestamp(object.selected_time) : undefined,
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: PersonalizationScreen_Section_Item_Content_TimePicker): unknown {
    const obj: any = {};
    message.selected_time !== undefined && (obj.selected_time = message.selected_time.toISOString());
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_TimePicker>, I>>(
    base?: I,
  ): PersonalizationScreen_Section_Item_Content_TimePicker {
    return PersonalizationScreen_Section_Item_Content_TimePicker.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PersonalizationScreen_Section_Item_Content_TimePicker>, I>>(
    object: I,
  ): PersonalizationScreen_Section_Item_Content_TimePicker {
    const message = createBasePersonalizationScreen_Section_Item_Content_TimePicker();
    message.selected_time = object.selected_time ?? undefined;
    message.title = object.title ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === "string") {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
