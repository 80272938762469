import { LOCALE } from "./locale"

const FORMAT_OPTIONS = {
  FULL_NUMERIC: { year: "numeric", month: "numeric", day: "numeric" },
  FULL_LONG: { year: "numeric", month: "long", day: "numeric" },
  FULL_SHORT: { year: "numeric", month: "short", day: "numeric" },
  MONTH_DAY: { month: "long", day: "numeric" },
  MONTH_ONLY_SHORT: { month: "short" },
  SHORT_DAY: { year: "numeric", month: "numeric", day: "2-digit" },
  SHORT_MONTH_DAY: { month: "short", day: "numeric" },
} as const

export const formatDate =
  (format: keyof typeof FORMAT_OPTIONS) =>
  (d: Date): string => {
    return new Intl.DateTimeFormat(LOCALE, FORMAT_OPTIONS[format]).format(d)
  }
