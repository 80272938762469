import React from "react"
import { createIcon } from "@chakra-ui/react"

export const UncheckedRadio = createIcon({
  viewBox: "0 0 28 28",
  path: <circle cx="14" cy="14" r="13" fill="none" stroke="currentColor" strokeWidth="2" />,
})
export const CheckedRadio = createIcon({
  viewBox: "0 0 28 28",
  path: (
    <>
      <circle cx="14" cy="14" r="13" fill="#FFF" stroke="currentColor" strokeWidth="2" />
      <circle cx="14" cy="14" r="9" fill="currentColor" />
    </>
  ),
})

export const CheckedRadio2 = createIcon({
  viewBox: "0 0 28 28",
  path: (
    <>
      <circle cx="14" cy="14" r="13" fill="#FFF" stroke="currentColor" strokeWidth="2" />
      <circle cx="14" cy="14" r="10" fill="currentColor" />
    </>
  ),
})
