import { extendTheme } from "@chakra-ui/react"
import { getColors } from "./theme-from-interview/colors"
import { getComponents } from "./components"
import { getFonts } from "./theme-from-interview/fonts"
import { getTextStyles } from "./theme-from-interview/textStyles"
import { getGlobalStyles } from "./theme-from-interview/globalStyles"
import { shadows } from "./theme-from-interview/shadows"

const breakpoints = {
  sm: "320px",
  md: "375px",
  lg: "450px",
  xl: "900px",
  "2xl": "1200px",
}

const fontSizes = {
  icon: "20px",
}
const radii = {
  brandSmall: "16px",
  norm: "20px",
  "brand-lg": "28px",
  brand4: "4px",
  brand5: "5px",
  brand8: "8px",
  brand12: "12px",
  brand16: "16px",
  brand20: "20px",
  brand24: "24px",
  brand32: "32px",
  huge: "50px",
}

const layerStyles = {
  attentionFrame: {
    borderRadius: "brand24",
    bgColor: "Base/neutralSecondary",
  },
  attentionFrameWhite: {
    borderRadius: "brand24",
    bgColor: "white",
  },
  internalFrame: {
    borderRadius: "brand24",
    borderWidth: "1px",
    borderColor: "Base/neutralSecondary",
  },
}
const globalStyles = (theme: Theme) => {
  const globalStyles = getGlobalStyles()
  const additional = {
    "@media (max-width: 600px)": {
      "body .PrimerCheckout__formField.PrimerCheckout__formField--dual": {
        flexDirection: "row",
      },
      "body .PrimerCheckout__formField.PrimerCheckout__formField--dual > .PrimerCheckout__formField + .PrimerCheckout__formField":
        {
          marginTop: 0,
          marginLeft: "8px",
        },
    },
  }
  globalStyles["html"].backgroundColor =
    theme === "lovi" ? "Utils/screenBgColor" : "Base/neutralDisabled"
  return { ...globalStyles, ...additional }
}

export const getTheme = (theme: Theme) =>
  extendTheme({
    breakpoints,
    colors: getColors(theme),
    fonts: getFonts(theme),
    textStyles: getTextStyles(theme),
    layerStyles,
    fontSizes,
    radii,
    shadows,
    styles: {
      global: globalStyles(theme),
    },

    components: getComponents(theme),
  })
