import { Flex, FlexProps } from "@chakra-ui/react"
import React, { FC } from "react"

export const CardPreview: FC<FlexProps> = (props) => (
  <Flex
    borderRadius="brand-lg"
    bgColor="Base/neutralDisabled"
    p={4}
    gap={4}
    direction="column"
    {...props}
  />
)
